<svg
  width="13"
  height="15"
  viewBox="0 0 13 15"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.0625 14.8672C3.1625 14.8672 4.0625 13.9672 4.0625 12.8672V2.86719C4.0625 1.76719 3.1625 0.867188 2.0625 0.867188C0.9625 0.867188 0.0625 1.76719 0.0625 2.86719V12.8672C0.0625 13.9672 0.9625 14.8672 2.0625 14.8672ZM8.0625 2.86719V12.8672C8.0625 13.9672 8.9625 14.8672 10.0625 14.8672C11.1625 14.8672 12.0625 13.9672 12.0625 12.8672V2.86719C12.0625 1.76719 11.1625 0.867188 10.0625 0.867188C8.9625 0.867188 8.0625 1.76719 8.0625 2.86719Z"
    fill="currentColor"
  />
</svg>
