import { NotificationType } from '@b-cube/database/types';
import { DateTime } from 'luxon';

export interface IUserNotification{
	id: number;
	type: NotificationType;
	title: string;
	body: string;
	createdAt: DateTime;
	readAt: DateTime;
}

export interface IUpdateNotification{
	readAt: string;
}

export { NotificationType } from '@b-cube/database/types';
