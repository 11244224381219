import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-widget-marketplace-about',
	templateUrl: './widget-marketplace-about.component.html'
})
export class WidgetMarketplaceAboutComponent {

	@Input() botDescription: string;
	@Input() botName: string;
	@Input() publisherName: string;
	@Input() publisherPresentation: string;
	@Input() isCompanyProvider: boolean;

}
