<div class="relative bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md p-6">
	<!--Header-->
	<div class="flex items-center justify-between mb-5">
		<h4 class="font-sans text-sm font-semibold tracking-wider text-left text-muted-700 dark:text-muted-300">
			Active agents
		</h4>

		<div class="flex items-center justify-end">
			<a [routerLink]="'/marketplace'" class="hidden lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand
					hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300">
				<app-icon-gear class="w-4 h-4 opacity-75"></app-icon-gear>
				<span class="font-sans text-sm">Add agents</span>
			</a>
		</div>
	</div>
	<!--Body-->
	<div *ngIf="bots === null">
		<span class="flex items-center justify-center">
			<span class="brand-circle-loader"></span>
		</span>
	</div>
	<div *ngIf="bots !== null" class="space-y-10">
		<!--Table-->
		<div class="overflow-x-auto">
			<div class="inline-block min-w-full align-middle">
				<div class="overflow-hidden">
					<table class="min-w-full table-fixed text-left">
						<!--Head-->
						<thead>
							<!-- Row -->
							<tr>
								<!--Header-->
								<th scope="col" class="w-[30px] font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									Status
								</th>
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									#
								</th>
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									Publisher
								</th>
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									Name
								</th>
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									Coin
								</th>
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									Market
								</th>
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									Assets
								</th>
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									PnL
								</th>
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									7d evo.
								</th>
								<!--Header-->
								<!-- <th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									Last 7 days
								</th> -->
								<!--Header-->
								<th scope="col" class="font-sans p-4 text-xs font-semibold tracking-wider
										text-left text-muted-400 dark:text-muted-300">
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							<!--Row-->
							<tr *ngFor="let bot of bots" class="border-t border-muted-200 dark:border-muted-700">
								<!--Cell-->
								<td class="font-sans text-muted-600 whitespace-nowrap dark:text-white p-4">
									<bot-status [bot]="bot"></bot-status>
								</td>
								<!--Cell-->
								<td class="font-sans text-muted-600 whitespace-nowrap dark:text-white p-4">
									<div>
										<span class="text-sm font-semibold text-muted-400">1</span>
									</div>
								</td>
								<!--Cell-->
								<td class="font-sans text-muted-600 whitespace-nowrap dark:text-white p-4">
									<div
										class="flex items-center justify-center w-8 h-8 rounded-full bg-muted-200 dark:bg-muted-700">
										<app-bot-icon [name]="bot?.publisher?.name ? bot.publisher.name : bot?.name"
											[svgClass]="bot?.publisher ? 'bg-white rounded-full w-8 h-8' : 'rounded-full w-8 h-8'">
										</app-bot-icon>
									</div>
								</td>
								<!--Cell-->
								<td class="font-sans text-sm text-muted-600 whitespace-nowrap dark:text-white p-4">
									<span>{{bot?.name}}</span>
								</td>
								<!--Cell-->
								<td class="font-sans text-sm text-muted-600 whitespace-nowrap dark:text-white p-4">
									<span>{{bot.cryptos.join(', ') | uppercase }}</span>
								</td>
								<!--Cell-->
								<td class="font-sans text-sm text-muted-600 whitespace-nowrap dark:text-white p-4">
									<span>{{bot.market | titlecase}}</span>
								</td>
								<!--Cell-->
								<td class="font-sans text-sm text-muted-600 whitespace-nowrap dark:text-white p-4">
									<span>{{bot?.investment ?? 0 | currency:'USD':'symbol':'1.2-2'}}</span>
								</td>
								<!--Cell-->
								<td class="font-sans text-sm text-muted-600 whitespace-nowrap dark:text-white p-4">
									<span class="font-semibold" [class]="{
										'text-green-400': bot.overallPnl > 0,
										'text-red-400': bot.overallPnl < 0
									}">
										{{bot.overallPnl > 0 ? '+' : ''}} {{bot.overallPnl |
										currency:'USD':'symbol':'1.2-2'}}
									</span>
								</td>
								<!--Cell-->
								<td class="font-sans text-sm text-muted-600 whitespace-nowrap dark:text-white p-4">
									<div class="flex items-center gap-x-2" [class]="{
										'text-green-400': bot.weekPnl > 0,
										'text-red-400': bot.weekPnl < 0
									}">
										<app-icon [name]="getEvolutionIcon(bot.weekPnl)"></app-icon>
										<span class="block font-sans font-semibold text-sm">
											{{bot.weekPnl | currency:'USD':'symbol':'1.2-2'}}
										</span>
									</div>
								</td>
								<!--Cell-->
								<!-- <td class="font-sans text-sm text-muted-600 whitespace-nowrap dark:text-white p-4">
									<app-chart-area-mini></app-chart-area-mini>
								</td> -->
								<!--Actions-->
								<td>
									<div class="flex justify-end gap-x-2">
										<!--Action-->
										<button (click)="updateStatus(bot.id)" [class]="
											bot.enabled
											? 'text-amber-500  hover:border-amber-500'
											: 'text-green-500  hover:border-green-500'" class="flex items-center justify-center w-8 h-8 rounded-full text-muted-400
												hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100
												dark:hover:bg-muted-700 transition-colors duration-300 cursor-pointer">
											<app-icon-pause class="w-3 h-3" *ngIf="bot.enabled; else play">
											</app-icon-pause>
											<ng-template #play>
												<app-icon-play></app-icon-play>
											</ng-template>
										</button>
										<!--Action-->
										<a href="#" (click)="navigateTo(bot)" class="flex items-center justify-center w-8 h-8 rounded-full text-muted-400
												hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100
												dark:hover:bg-muted-700 transition-colors duration-300 cursor-pointer">
											<app-icon-eye class="w-4 h-4"></app-icon-eye>
										</a>
										<!--Action-->
										<button (click)="displayBotSettings(bot)"
											class="relative flex items-center justify-center w-8 h-8 rounded-full text-muted-400 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 transition-colors duration-300 cursor-pointer">
											<app-icon-gear class="w-4 h-4">
											</app-icon-gear>
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>

<app-widget-bot-settings-modal *ngIf="isBotSettingsOpen" [botSettingsWrapper]="botSettingsWrapper"
	(close)="closeBotSettings()" [bot]="selectedBot">
</app-widget-bot-settings-modal>
