export enum CHARGEBEE_PLAN {
	FREEMIUM = 'freemium',
	BASIC = 'credit-card_basic',
	BASIC_PLUS = 'credit-card_basic-plus',
	PREMIUM = 'credit-card_premium',
	PREMIUM_PLUS = 'credit-card_premium-plus'
}

export enum PlanTier {
	TIER_0 = 0,
	TIER_1 = 1,
	TIER_2 = 2,
	TIER_3 = 3,
	TIER_4 = 4
}

export interface Addon {
	id: string;
	name: string;
	itemPriceId: string;
	price: number;
	currency: number;
}

export interface Plan extends BasePlan {
	tier: PlanTier;
	tradingLimit: number;
	switchingBetweenBots: boolean;
	sentimentAnalysis: boolean;
	technicalScreener: boolean;
	addons:Addon[];
}

export interface BasePlan {
	id: string;
	name: string;
	price: number;
	itemPriceId: string;
}

export interface BotPlan extends BasePlan {
	botId: number;
}

export const UNLIMITED_TRADING_LIMIT = -1;

export const ITEM_TYPE_PLAN = 'plan';
