import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BcubeContractService } from '@app/core/services/bcube-contract.service';
import { EthereumService } from '@app/core/services/ethereum/ethereum.service';
import { StakingService } from '@app/core/services/staking.service';
import { BehaviorSubject, from, map, Observable } from 'rxjs';

@Component({
	selector: 'app-bcube-payment-form',
	templateUrl: './bcube-payment-form.component.html'
})
export class BcubePaymentFormComponent implements OnInit {
	@Input() months = 1;
	@Output() monthsChange = new EventEmitter();
	@Input() amount: number = null;

	// ethereum
	public isWalletConnected$: BehaviorSubject<boolean>;

	// staking
	public stakingWallet$: Observable<string>;
	public ownedBcubeBalance$: Observable<string>;

	form: FormGroup;

	// Screen transaction loader
	public showScreenLoader$: BehaviorSubject<boolean>;

	constructor(
		private stakingService: StakingService,
		private ethereumService: EthereumService,
		private bcubeContractService: BcubeContractService
	) {}

	ngOnInit(): void {
		this.form = new FormGroup({
			months: new FormControl(this.months),
			wallet: new FormControl(''),
			amount: new FormControl(this.amount),
		});

		this.form.controls['months'].valueChanges.subscribe(month => {
			if(! this.form.controls['months'].valid){
				month = null;
			}

			this.monthsChange.emit(month);
			this.form.controls['amount'].setValue(month * this.amount)
		});

		this.isWalletConnected$ = this.ethereumService.isWalletConnected$;
		this.showScreenLoader$ = this.bcubeContractService.showTxLoader$;
		this.stakingWallet$ = this.getWallet();
	}

	private getWallet(): Observable<string> {
		return this.stakingService.getWallet().pipe(
			map((wallet: string) => {
				this.ownedBcubeBalance$ = from(this.bcubeContractService.getOwnedBCubeBalance(wallet));

				return wallet;
			})
		);
	}
}
