import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[blinkClass]'
})
export class BlinkClassDirective implements OnChanges {
    @Input() blinkClass: boolean;
    @Input() value: string | number;

    private removeTime = 2700;

    constructor(private renderer: Renderer2, private el: ElementRef) { }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['value'].isFirstChange()) {
            const isDark = document.documentElement.classList.contains('dark');
            let finalClass = 'animate-darkBlink';
            if (!isDark) {
                finalClass = 'animate-blink'
            }
            if (this.el.nativeElement?.parentNode?.parentNode) {
                const datatableCell = this.el.nativeElement.parentNode.parentNode;
                this.renderer.addClass(datatableCell, finalClass);
                setTimeout(() => {
                    this.renderer.removeClass(datatableCell, finalClass);
                }, this.removeTime)
            }
        }
    }
}




