<svg
  width="13"
  height="11"
  viewBox="0 0 13 11"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.69709 0.195314L0.508999 4.7914C0.491908 4.82649 0.499646 4.86862 0.52809 4.89534L6.4376 10.5581C6.47248 10.5915 6.52752 10.5915 6.5624 10.5581L12.4719 4.8957C12.5004 4.86897 12.5081 4.82684 12.491 4.79176L10.3029 0.195667C10.2885 0.164142 10.257 0.143959 10.2223 0.14405H2.77841C2.74357 0.143616 2.71172 0.163692 2.69709 0.195314Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.25945 5.26343C7.21703 5.26661 6.99783 5.27969 6.50888 5.27969C6.11998 5.27969 5.84386 5.26802 5.74699 5.26343C4.24407 5.19732 3.12227 4.93569 3.12227 4.62245C3.12227 4.30921 4.24407 4.04794 5.74699 3.98077V5.00287C5.84527 5.00994 6.1267 5.02655 6.5156 5.02655C6.98228 5.02655 7.21597 5.00711 7.25804 5.00322V3.98147C8.75778 4.04829 9.8771 4.30992 9.8771 4.62245C9.8771 4.93499 8.75813 5.19661 7.25804 5.26308L7.25945 5.26343ZM7.25945 3.87576V2.96114H9.35244V1.56641H3.654V2.96114H5.74663V3.87541C4.04573 3.95354 2.7666 4.29047 2.7666 4.69422C2.7666 5.09797 4.04573 5.43454 5.74663 5.51303V8.44392H7.2591V5.51197C8.95612 5.43384 10.2331 5.09726 10.2331 4.69387C10.2331 4.29047 8.95718 3.9539 7.2591 3.87541L7.25945 3.87576Z"
    fill="white"
  />
</svg>
