<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200
		dark:border-muted-700 rounded-md px-4 md:px-6 py-10">
	<div class="flex items-start justify-between text-center">
		<div class="flex-1">
			<label class="inline-block font-sans text-xs font-semibold tracking-wider
					text-left text-muted-400 dark:text-muted-300 mb-2">Pair</label>
			<bot-pair [bot]="bot" [iconSize]="8"></bot-pair>
		</div>
		<div class="flex-1">
			<label class="inline-block font-sans text-xs font-semibold tracking-wider text-left
					text-muted-400 dark:text-muted-300 mb-2">7D</label>
			<div class="mt-2">
				<span *ngIf="userAccountBotPerformance"
					[ngClass]="formatNumberHelper.getClass(userAccountBotPerformance?.weekly)"
					class="font-sans text-sm font-semibold">{{formatNumberHelper.formatNumber(userAccountBotPerformance?.weekly,
					'$')}}</span>
			</div>
		</div>
		<div class="flex-1">
			<label class="inline-block font-sans text-xs font-semibold tracking-wider text-left
					text-muted-400 dark:text-muted-300 mb-2">30D</label>
			<div class="mt-2">
				<span *ngIf="userAccountBotPerformance"
					[ngClass]="formatNumberHelper.getClass(userAccountBotPerformance?.monthly)"
					class="font-sans text-sm font-semibold">{{formatNumberHelper.formatNumber(userAccountBotPerformance?.monthly,
					'$')}}</span>
			</div>
		</div>
		<div class="flex-1">
			<label class="inline-block font-sans text-xs font-semibold tracking-wider text-left
					text-muted-400 dark:text-muted-300 mb-2">Overall</label>
			<div class="mt-2">
				<span *ngIf="userAccountBotPerformance"
					[ngClass]="formatNumberHelper.getClass(userAccountBotPerformance?.overall)"
					class="font-sans text-sm font-semibold">{{formatNumberHelper.formatNumber(userAccountBotPerformance?.overall,
					'$')}}</span>
			</div>
		</div>
	</div>
</div>
