import { Component } from '@angular/core';
import { IconType } from '@app/core/models/icon-type.enum';
import { IconService } from '@app/core/services/icon.service';
import { Observable } from 'rxjs';

import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'app-wallet-provider-icon',
	templateUrl: './wallet-provider-icon.component.html',
	styleUrls: ['./wallet-provider-icon.component.css'],
})
export class WalletProviderIconComponent extends IconComponent {

	constructor(iconService: IconService) {
		super(iconService);
	}

	override buildIconPath(): Observable<string> {
		return super.buildIconPath(IconType.WALLET_PROVIDERS, this.name, this.addon);
	}
}
