<svg
  width="18"
  height="15"
  viewBox="0 0 18 15"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    d="M17.2585 2.21017L15.1645 0.11017C15.0941 0.0396391 14.9986 0 14.899 0C14.7994 0 14.7038 0.0396391 14.6335 0.11017L5.78948 8.96017C5.71913 9.0307 5.6236 9.07034 5.52398 9.07034C5.42436 9.07034 5.32884 9.0307 5.25848 8.96017L3.16448 6.86017C3.09413 6.78964 2.9986 6.75 2.89898 6.75C2.79936 6.75 2.70384 6.78964 2.63348 6.86017L0.539482 8.96017C0.393089 9.10661 0.393089 9.34398 0.539482 9.49042L5.25848 14.2102C5.32884 14.2807 5.42436 14.3203 5.52398 14.3203C5.6236 14.3203 5.71913 14.2807 5.78948 14.2102L17.2585 2.73517C17.4014 2.58935 17.4014 2.35599 17.2585 2.21017Z"
    fill="currentColor"
  />
</svg>
