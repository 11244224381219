<div *ngIf="showModal" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
	<div @opacity class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

	<div class="fixed inset-0 z-10 overflow-y-auto">
		<div class="modal bg-muted-800/70 fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center px-4">
			<div
				@opacityTranslateY
				class="bg dark:bg-muted-800 relative mx-auto {{
					unstakeModalStep === 1 ? 'w-[48rem]' : 'w-[40rem]'
				}} max-w-full transform overflow-hidden rounded-md rounded bg-white p-6 text-left text-left shadow-xl shadow-xl transition-all sm:my-8 sm:p-6"
			>
				<button
					class="close-modal-icon text-muted-300 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 group inline-flex h-10 w-10 items-center justify-center rounded-full transition-colors duration-300"
					(click)="closeModal()"
				>
					<app-icon-x class="h4 w-4 transition-transform duration-300 group-hover:scale-95"></app-icon-x>
				</button>
				<div class="flex items-center justify-center">
					<h3 class="text-lg font-medium leading-6" id="modal-title">
						{{ unstakeModalStep === 1 ? 'Choose amount to unstake' : 'Confirm Unstake' }}
					</h3>
				</div>

				<!--Separator-->
				<hr class="border-muted-200 dark:border-muted-700 my-6 border-t" />

				<div *ngIf="unstakeModalStep === 1" class="isolate w-full rounded text-center shadow-sm">
					<div class="my-10 flex w-full items-center justify-between">
						<p class="text-l">
							Resultant tier after unstaking: <span class="font-bold text-green-600 text-xl">{{ eligibleTier.name }}</span>
						</p>
						<input
							type="number"
							[(ngModel)]="unstakeInputValue"
							(ngModelChange)="onUnstakeValueInputChange($event)"
							[min]="sliderOptions.floor"
							[max]="maxUnstakeAmount"
							[step]="sliderOptions.step"
							class="max-w-[7rem] text-muted-600 border-muted-300 focus:border-muted-300 focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:outline-muted-300 dark:focus:outline-muted-600 h-10 rounded-lg border bg-white px-3 py-2 font-sans text-sm leading-5 outline-transparent transition-all duration-300 focus:shadow-lg focus:outline-dashed focus:outline-offset-2 focus:ring-0"
						/>
					</div>
					<div class="my-6 flex items-center gap-4">
						<div class="whitespace-nowrap">{{ floor }} BCube</div>
						<ngx-slider
							[(value)]="unstakeSliderValue"
							[options]="sliderOptions"
							(userChange)="handleSliderValueChange()"
							class="tw-mt-4 ngx-slider"
						></ngx-slider>
						<div class="whitespace-nowrap">{{ maxUnstakeAmount }} BCube</div>
					</div>
					<div class="py-6">
						<app-widget-wizard-unstake-tier-comparison [currentTier]="currentTier" [eligibleTier]="eligibleTier" [tierDifference]="tierDifference"></app-widget-wizard-unstake-tier-comparison>
					</div>
					<div class="flex w-full justify-end gap-3">
						<button
							(click)="toggleModal()"
							class="flex h-10 items-center justify-center gap-x-2 rounded-md border-2 border-gray-500 bg-gray-500/10 px-5 text-gray-500 transition-all duration-300 hover:bg-gray-500/90 hover:text-white hover:shadow-md hover:shadow-gray-500/30"
						>
							Cancel
						</button>
						<button
							(click)="updateStep(1)"
							class="flex h-10 items-center justify-center gap-x-2 rounded-md border-2 border-teal-500 bg-teal-500/10 px-5 text-teal-500 transition-all duration-300 hover:bg-teal-500/90 hover:text-white hover:shadow-md hover:shadow-teal-500/30 disabled:cursor-not-allowed disabled:opacity-40"
							[disabled]="unstakeValue === 0"
						>
							<span class="font-sans text-sm">Continue</span>
						</button>
					</div>
				</div>

				<div *ngIf="unstakeModalStep === 2" class="isolate w-full rounded text-center shadow-sm">
					<p class="my-5 text-xl">{{ unstakeValue }} BCube</p>
					<div class="text-amber-600">
						Note: You will be moved to the <span class="font-bold">{{ eligibleTier.name }} Tier</span>
					</div>
					<div class="mt-5 flex w-full items-center justify-between gap-3">
						<button
							(click)="updateStep(-1)"
							class="flex h-10 items-center justify-center gap-x-2 rounded-md border-2 border-gray-500 bg-gray-500/10 px-5 text-gray-500 transition-all duration-300 hover:bg-gray-500/90 hover:text-white hover:shadow-md hover:shadow-gray-500/30"
						>
							<span class="font-sans text-sm">Go Back</span>
						</button>
						<div class="flex gap-3">
							<button
								(click)="toggleModal()"
								class="flex h-10 items-center justify-center gap-x-2 rounded-md border-2 border-gray-500 bg-gray-500/10 px-5 text-gray-500 transition-all duration-300 hover:bg-gray-500/90 hover:text-white hover:shadow-md hover:shadow-gray-500/30"
							>
								<span class="font-sans text-sm">Cancel</span>
							</button>
							<button
								(click)="executeUnstake(eligibleTier, unstakeValue)"
								class="flex h-10 items-center justify-center gap-x-2 rounded-md border-2 border-rose-600 bg-rose-600/10 px-5 text-rose-600 transition-all duration-300 hover:bg-rose-600/90 hover:text-white hover:shadow-md hover:shadow-rose-600/30"
							>
								<span class="font-sans text-sm">Unstake</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
