<svg
  width="91"
  height="21"
  viewBox="0 0 91 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    d="M0.5 17.1963H5.5L10.5 14.1963L15.5 19.6963L20.5 15.6963L25.5 13.6963L30.5 15.1963L35.5 11.1963H40.5L45.5 5.69629L50.5 6.69629L55.5 5.69629L60.5 9.19629L65.5 12.1963L70.5 8.19629L75.5 11.1963L80.5 5.69629L85.5 1.19629L90.5 5.69629"
    stroke="currentColor"
  />
</svg>
