import { Component, Input } from '@angular/core';

@Component({
	selector: 'feature-status',
	templateUrl: './feature-status.component.html',
})
export class FeatureStatusComponent {
	@Input() status: boolean;

	

	getBgColorClasses(): string {
		return this.status ? 'bg-emerald-100 dark:bg-emerald-500/20' : 'bg-rose-100 dark:bg-rose-500/20';
	}

	getTextColorClasses(): string {
		return this.status ? 'text-emerald-500' : 'text-rose-500'
	}
}
