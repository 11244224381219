import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-copy',
  templateUrl: './icon-copy.component.html'
})
export class IconCopyComponent {
  @Input() class = ''

}
