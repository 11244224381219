<div class="relative flex items-center h-[70px] w-full bg-white dark:bg-muted-800 px-4 md:px-6 z-40">
	<!-- ul class="flex items-center gap-4">
    <li><a [routerLink]="'/auth/'">auth</a></li>
    <li><a [routerLink]="'/dashboard/'">dashboard</a></li>
  </ul-->

	<button
		class="group inline-flex xl:hidden items-center justify-center h-10 w-10 mr-4 rounded-full text-muted-300 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 transition-colors duration-300"
		(click)="toggle()">
		<app-icon-dots class="h4 w-4 group-hover:scale-95 transition-transform duration-300"></app-icon-dots>
	</button>
	<button
		class="group hidden xl:inline-flex items-center justify-center h-10 w-10 mr-4 rounded-full text-muted-300 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 transition-colors duration-300"
		*ngIf="isFolded" (click)="toggle()">
		<app-icon-dots class="h4 w-4 group-hover:scale-95 transition-transform duration-300"></app-icon-dots>
	</button>

	<div class="hidden md:flex items-center gap-x-4">
		<a [href]='BUY_BCUBE_URL' target="_blank"
			class="inline-flex items-center justify-between pl-1 pr-4 py-1 border-2 border-brand text-brand hover:text-white font-sans leading-2 text-sm bg-brand/20 hover:bg-brand hover:shadow-md hover:shadow-brand/40 rounded-full cursor-pointer transition-all duration-300">
			<span class="flex items-center justify-center h-6 w-6 rounded-full bg-brand text-white rotate-12">
				<app-logo class="w-4 h-4"></app-logo>
			</span>
			<span class="block font-sans font-medium ml-2">Buy</span>
		</a>

		<!--span
      class="block font-sans text-muted-800 dark:text-muted-100 font-semibold text-sm"
      >B-Cube</span
    -->
		<!--<span class="block font-sans text-muted-400 font-semibold text-xs">BCUBE</span>
		<span class="block font-sans text-muted-800 dark:text-muted-100 font-semibold text-sm">0.1347</span>
		<div class="flex items-center gap-x-2">
			<app-icon name="chevron-up" [svgClass]="'text-green-500'"></app-icon>
			<span class="block font-sans text-green-500 font-semibold text-sm">6.49%</span>
			<app-chart-area-mini></app-chart-area-mini>
		</div>-->
	</div>

	<div class="flex items-center justify-end gap-x-4 ml-auto">
		<app-theme-toggler></app-theme-toggler>
		<div class="flex items-center gap-x-2">
			<!-- Dropdown -->
			<button type="button"
				class="relative flex items-center justify-center h-9 w-9 rounded-full text-muted-400 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 transition-colors duration-300"
				(click)="openPanel($event)">
				<span *ngIf="(notifications$ | async).length > 0" class="flex absolute top-0.5 right-0.5 flex ">
					<span class="animate-ping absolute flex h-full w-full rounded-full bg-violet-500 opacity-75"></span>
					<span
						class="rounded-full h-4 w-4 bg-violet-500 text-3xs flex items-center justify-center font-mono">
						<p>{{(notifications$| async).length}}</p>
					</span>
				</span>
				<app-icon-bell class="w-4 h-4"></app-icon-bell>
			</button>
			<!-- Dropdown -->
			<app-header-wallet [user]="user$ | async"></app-header-wallet>
			<!-- Dropdown -->
			<app-header-account [user]="user$ | async" [initials]="initials$ | async"></app-header-account>
		</div>
		<a [routerLink]="'/marketplace'"
			class="hidden lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300">
			<span class="font-sans text-sm">Marketplace</span>
		</a>
	</div>
</div>
