import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaymentBCubeDetails } from '@b-cube/interfaces/payment';
import { SignedMessage } from '@b-cube/interfaces/signed-message';
import { Staking, Tier } from '@b-cube/interfaces/staking';
import { RestURLBuilder } from 'rest-url-builder';
import { BehaviorSubject, lastValueFrom, map, Observable, of, shareReplay } from 'rxjs';

import {
	BOT_BCUBE_PAYMENT_DETAILS_URL,
	PLAN_BCUBE_PAYMENT_DETAILS_URL,
	STAKING_ADDRESS_URL,
	STAKING_AMOUNT,
	STAKING_TIER_URL,
} from '../constants/api-urls.constants';

@Injectable({
	providedIn: 'root',
})
export class StakingService {
	private readonly tiers$: BehaviorSubject<Tier[]> = new BehaviorSubject<Tier[]>(null);
	private readonly stakedAmount$: BehaviorSubject<string> = new BehaviorSubject<string>('0');
	private readonly stakingWallet$: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor(private http: HttpClient) {
		this.loadTiers();
		this.loadStakedAmount();
		this.loadStakingWallet();
	}

	private loadTiers() {
		this.http
			.get<Tier[]>(STAKING_TIER_URL)
			.pipe(shareReplay())
			.subscribe((plans) => {
				this.tiers$.next(plans);
			});
	}

	public loadStakedAmount() {
		this.http
			.get<string>(STAKING_AMOUNT)
			.pipe(shareReplay())
			.subscribe((amount) => {
				this.stakedAmount$.next(amount);
			});
	}

	public loadStakingWallet() {
		return this.http
			.get<Staking>(STAKING_ADDRESS_URL)
			.pipe(shareReplay())
			.subscribe((wallet) => {
				const stakingWallet = wallet ? wallet.ethereumWalletAddress : '';
				this.stakingWallet$.next(stakingWallet);
			});
	}

	public getStakingWallet(): Observable<string> {
		return this.stakingWallet$.asObservable();
	}

	public getTiers(): Observable<Tier[]> {
		return this.tiers$.asObservable();
	}

	public addStakigEthAddress(signedMessage: SignedMessage): Promise<any> {
		return lastValueFrom(this.http.post(STAKING_ADDRESS_URL, signedMessage));
	}

	public getWallet(): Observable<string> {
		return this.http.get<Staking>(STAKING_ADDRESS_URL).pipe(
			map((staking) => {
				if (!staking) {
					return '';
				}

				return staking.ethereumWalletAddress;
			})
		);
	}

	public getPlanBCubePaymentDetails(planId: string, addons: string[]): Observable<IPaymentBCubeDetails> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(PLAN_BCUBE_PAYMENT_DETAILS_URL);
		urlBuilder.setQueryParameter('planId', String(planId));
		urlBuilder.setQueryParameter('addons', addons.join(','));

		return this.http.get<IPaymentBCubeDetails>(urlBuilder.get());
	}

	public getBotPlanBCubePaymentDetails(planId: string): Observable<IPaymentBCubeDetails> {
		if (!planId) {
			return of(undefined);
		}

		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(BOT_BCUBE_PAYMENT_DETAILS_URL);
		urlBuilder.setQueryParameter('planId', String(planId));

		return this.http.get<IPaymentBCubeDetails>(urlBuilder.get());
	}

	public getStakedAmount() {
		return this.stakedAmount$.asObservable();
	}
}
