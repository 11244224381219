import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { labels } from '@app/core/constants/common.labels';
import { ERROR_OCCURED } from '@app/core/constants/error-messages';
import { CONFLICT } from '@app/core/constants/http-status-code.constants';
import { Routes } from '@app/core/constants/router.constants';
import { SettingsTabs } from '@app/core/constants/settings.constants';
import { DialogService } from '@app/core/services/dialog.service';
import { SecurityService } from '@app/core/services/security.service';
import { UserBotService } from '@app/core/services/user-bot.service';
import { IBot } from '@b-cube/interfaces/bot';
import { IExchangeAccount } from '@b-cube/interfaces/exchange-account';
import { BotPlan } from '@b-cube/interfaces/plan';
import { RestrictedServices, UserInfo } from '@b-cube/interfaces/user';
import { IUserBot } from '@b-cube/interfaces/user-bot';
import { ToastrService } from 'ngx-toastr';
import { catchError, firstValueFrom, of } from 'rxjs';
import { sprintf } from 'sprintf-js';

@Component({
	selector: 'app-widget-marketplace-subscription-bot-bcube',
	templateUrl: './widget-marketplace-subscription-bot-bcube.component.html'
})
export class WidgetMarketplaceSubscriptionBotBcubeComponent implements OnInit {
	@Input() user: UserInfo;
	@Input() bot: IBot;
	@Input() botId: number;
	@Input() exchangeAccounts: IExchangeAccount[];
	@Input() isAlreadyPlugged: boolean;
	@Input() userHasSupportedExchanges: boolean;
	@Output() closeModal = new EventEmitter<IUserBot>();

	public isLoading = true;
	public isModalOpen = false;

	public botInitialPrice: number;
	public botPlan: BotPlan;
	public botPriceWithDiscount: number;

	public Routes = Routes;
	public SettingsTabs = SettingsTabs;

	constructor(
		private userBotService: UserBotService,
		public securityService: SecurityService,
		private toasterService: ToastrService,
		private dialogService: DialogService
	) { }

	ngOnInit(): void {
		setTimeout(() => {
			this.isLoading = false;
		}, 100);
	}

	public canUseService(): boolean{
		return this.securityService.canUseServices(this.user, RestrictedServices.BOT);
	}

	public openModal() {
		if (!this.userHasSupportedExchanges) {
			this.toasterService.warning('No compatible exchange account');

			return;
		}

		this.isModalOpen = true
	}

	public closeAddBot(userBot: IUserBot) {
		this.isModalOpen = false;
		this.closeModal.emit(userBot);
	}

	public async deleteUserBot() {
		const opensTrades = await firstValueFrom(this.userBotService.getBotTradeOpen(this.bot.id));

		if(opensTrades.length > 0){
			if (!await this.dialogService.confirm(sprintf(labels.CONFIRM_BOT_DISCO_WITH_TRADES, {name: this.bot.name}), 'Disconnect')) {
				return;
			}
		} else {
			if (!await this.dialogService.confirm(sprintf(labels.CONFIRM_BOT_DISCO, {name: this.bot.name}), 'Disconnect')) {
				return;
			}
		}

		this.userBotService.deleteUserBot(this.bot.id).pipe(
			catchError(err => {
				if (err.status === CONFLICT) {
					this.toasterService.error(`This agent is already added.`, ERROR_OCCURED);
				} else {
					this.toasterService.error(`The agent '${this.bot.name}' cannot be removed.`, ERROR_OCCURED);
				}

				return of(err)
			})
		).subscribe(err => {
			if (!err) {
				this.toasterService.success(`The agent '${this.bot.name}' is removed.`);
			}
		});
	}
}
