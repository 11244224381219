import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-cardano',
  templateUrl: './icon-cardano.component.html'
})
export class IconCardanoComponent {
  @Input() class = '';

}
