<div class="mb-4 ">
    <div class="relative w-48 max-w-full" (clickOutside)="closeMenu()">
        <button type="button"
            class="flex items-center gap-x-4 w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 px-6 py-2 rounded-lg"
            (click)="toggleMenu()">
            <span class="font-sans  text-muted-800 dark:text-muted-200">
                {{selectedScreen}}
            </span>
            <div class="ml-auto">
                <app-icon name="chevron-down" class="w-3 h-3 text-muted-400 transition-transform duration-300"
                    [svgClass]="isMenuOpen ? 'rotate-180' : ''">
                </app-icon>
            </div>
        </button>
        <div [@openClose]="isMenuOpen ? 'open' : 'closed'" class="opacity-0 absolute top-0 left-0 w-full mt-12 z-20"
            [class]="!isMenuOpen ? 'pointer-events-none' : ''">
            <div
                class="bg-white border border-muted-200 dark:bg-muted-800 dark:border-muted-600 rounded-md shadow-lg shadow-muted-700/10">
                <ul class="p-2 space-y-1">
                    <li *ngFor="let screen of screens">
                        <button type="button" (click)="selectScreen(screen.name)"
                            [disabled]="!hasAccess && !screen.isFree" class="flex items-center disabled:cursor-not-allowed disabled:opacity-40 gap-x-4 text-left w-full py-2 px-4 rounded-md
							hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
                            <span *ngIf="!hasAccess && !screen.isFree" placement="top"
                                [tooltip]="'Upgrade your plan to access this screen'"
                                class="block font-sans text-sm font-medium text-muted-600 dark:text-muted-200">
                                {{screen.name}}
                            </span>
                            <span *ngIf="hasAccess || (!hasAccess && screen.isFree)"
                                class="block font-sans text-sm font-medium text-muted-600 dark:text-muted-200">
                                {{screen.name}}
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
