import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from 'ngx-slider-v2';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-form-bot-settings-slider',
  templateUrl: './form-bot-settings-slider.component.html',
  styleUrls: ['./form-bot-settings-slider.component.css']
})
export class FormBotSettingsSliderComponent implements OnInit {

  @Input() value = 50;
  @Output() valueChanged = new EventEmitter<any>();
  @Input() floor = 0;
  @Input() ceil = 100;

  public options: Options;

  private valueSubject = new Subject();



  ngOnInit(): void {
    this.options = {
      floor: this.floor,
      ceil: this.ceil,
      showSelectionBar: true
    }
    this.valueSubject.pipe(
      debounceTime(40)
    ).subscribe(($event) => {
      this.valueChanged.emit($event);
    })
  }

  sliderValueChanged($event: any): void {
    this.valueSubject.next($event);
  }

}
