<div class="fixed top-0 left-0 w-3/4 md:w-[240px] h-full bg-muted-100 dark:bg-muted-900 border-r border-muted-200 dark:border-muted-800 transition-transform duration-300 z-40"
	[ngClass]="[
		expanded ? 'xl:translate-x-0 !translate-x-0' : '-translate-x-full xl:-translate-x-full'
	]">
	<div class="flex items-center gap-4 px-5 h-[70px] bg-white dark:bg-muted-800">
		<a [routerLink]="'/'" class="flex items-center gap-x-4">
			<app-logo class="text-accent dark:text-brand"></app-logo>
			<app-logo-text class="text-muted-800 dark:text-muted-100 h-6"></app-logo-text>
		</a>
		<button
			class="group inline-flex items-center justify-center h-10 w-10 ml-auto rounded-full text-muted-300 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 transition-colors duration-300"
			(click)="toggle()">
			<app-icon-dots class="h4 w-4 group-hover:scale-95 transition-transform duration-300"></app-icon-dots>
		</button>
	</div>

	<div class="h-[calc(100%_-_140px)] overflow-y-auto px-5 py-6">
		<!-- <ul class="flex flex-col gap-y-6 mt-4 pb-6 mb-6 border-b border-muted-200 dark:border-muted-800"> -->
		<ul class="flex flex-col gap-y-6 mt-4 pb-6 mb-6">
			<li (click)="toogleSideMenu()">
				<a [routerLink]="'/portfolio'"
					class="group relative flex items-center gap-4 text-muted-500 hover:text-muted-600 dark:text-muted-400 dark:hover:text-muted-100 transition-colors">
					<app-icon-pie class="w-6 h-6 text-muted-300 group-hover:text-muted-400 transition-colors">
					</app-icon-pie>
					<span class="block font-sans text-sm leading-8">Portfolio</span>
					<div class="ml-auto">
						<app-icon name="chevron-right"
							svgClass="w-3 h-3 text-muted-400 -translate-x-1 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-300">
						</app-icon>
					</div>

				</a>
			</li>
			<li (click)="toogleSideMenu()">
				<a [routerLink]="'/agents'"
					class="group relative flex items-center gap-4 text-muted-500 hover:text-muted-600 dark:text-muted-400 dark:hover:text-muted-100 transition-colors">
					<app-icon-bot class="w-6 h-6 text-muted-300 group-hover:text-muted-400 transition-colors">
					</app-icon-bot>
					<span class="block font-sans text-sm leading-8">My agents</span>
					<div class="ml-auto">
						<app-icon name="chevron-right"
							svgClass="w-3 h-3 text-muted-400 -translate-x-1 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-300">
						</app-icon>
					</div>
					<!-- <span class="absolute -top-0.5 right-0 inline-block font-sans font-medium text-xs py-1.5 px-3 m-1 rounded-lg bg-brand/20 text-brand
						group-hover:opacity-0 scale-90 pointer-events-none transition-opacity duration-200">
						New
					</span> -->
				</a>
			</li>
			<li (click)="toogleSideMenu()">
				<a [routerLink]="'/marketplace'"
					class="group relative flex items-center gap-4 text-muted-500 hover:text-muted-600 dark:text-muted-400 dark:hover:text-muted-100 transition-colors">
					<app-icon-cart class="w-6 h-6 text-muted-300 group-hover:text-muted-400 transition-colors">
					</app-icon-cart>
					<span class="block font-sans text-sm leading-8">Marketplace</span>
					<div class="ml-auto">
						<app-icon name="chevron-right"
							svgClass="w-3 h-3 text-muted-400 -translate-x-1 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-300">
						</app-icon>
					</div>
				</a>
			</li>
			<li (click)="toogleSideMenu()">
				<a [routerLink]="'/ta-screener'"
					class="group relative flex items-center gap-4 text-muted-500 hover:text-muted-600 dark:text-muted-400 dark:hover:text-muted-100 transition-colors">
					<app-icon-ring class="w-6 h-6 text-muted-300 group-hover:text-muted-400 transition-colors">
					</app-icon-ring>
					<span class="block font-sans text-sm leading-8">TA Screener</span>
					<div class="ml-auto">
						<app-icon name="chevron-right"
							svgClass="w-3 h-3 text-muted-400 -translate-x-1 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-300">
						</app-icon>
					</div>
				</a>
			</li>
			<!-- <li (click)="toogleSideMenu()">
				<a [routerLink]="'/sentiment-analysis'"
					class="group relative flex items-center gap-4 text-muted-500 hover:text-muted-600 dark:text-muted-400 dark:hover:text-muted-100 transition-colors">
					<app-icon-eye class="w-6 h-6 text-muted-300 group-hover:text-muted-400 transition-colors">
					</app-icon-eye>
					<span class="block font-sans text-sm leading-8">Sentiment Analysis</span>
					<div class="ml-auto">
						<app-icon name="chevron-right"
							svgClass="w-3 h-3 text-muted-400 -translate-x-1 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-300">
						</app-icon>
					</div>
				</a>
			</li> -->
		</ul>

		<!-- <ul class="flex flex-col gap-y-2 mt-6">
			<li (click)="toogleSideMenu()">
				<a [routerLink]="'/'"
					class="group flex items-center gap-4 text-muted-500 hover:text-muted-600 dark:text-muted-400 dark:hover:text-muted-100 transition-colors">
					<app-icon-docs class="w-4 h-4 text-muted-300 group-hover:text-muted-400 transition-colors">
					</app-icon-docs>
					<span class="block font-sans text-xs leading-8">Documentation</span>
				</a>
			</li>
			<li (click)="toogleSideMenu()">
				<a [routerLink]="'/'"
					class="group flex items-center gap-4 text-muted-500 hover:text-muted-600 dark:text-muted-400 dark:hover:text-muted-100 transition-colors">
					<app-icon-help class="w-4 h-4 text-muted-300 group-hover:text-muted-400 transition-colors">
					</app-icon-help>
					<span class="block font-sans text-xs leading-8">Help</span>
				</a>
			</li>
		</ul> -->
	</div>

	<!--<div class="flex w-full h-[70px] py-4 divide-x divide-muted-200 dark:divide-muted-800">
		<app-sidebar-currency class="flex-1"></app-sidebar-currency>
		<app-sidebar-language class="flex-1"></app-sidebar-language>
	</div>-->
</div>
