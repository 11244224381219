<div class="modal fixed top-0 left-0 flex items-center justify-center w-full h-full bg-muted-800/70 dark:bg-muted-900/80 p-4 z-50"
	[class.hidden]="! shown"
	>
	<div class="h-full w-full md:h-auto md:w-96 md:max-w-xl mx-auto text-left bg-white dark:bg-muted-800 rounded shadow-xl">
		<!-- Header -->
		<div class="flex items-center justify-between p-2 md:p-4 border-b border-muted-200 dark:border-muted-700">
			<div>
				<h3 class="font-heading text-lg font-medium leading-6 text-muted-900 dark:text-white">
					{{title}}
				</h3>
			</div>

			<button
				class="flex items-center justify-center rounded-full hover:bg-muted-100 dark:hover:bg-muted-700 h-8 w-8 transition-colors duration-300"
				(click)="infirm()">
				<svg class="fill-current h-5 w-5 text-muted-700 dark:text-muted-50" xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="32" height="32"
					preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256">
					<path fill="currentColor"
						d="M205.7 194.3a8.1 8.1 0 0 1 0 11.4a8.2 8.2 0 0 1-11.4 0L128 139.3l-66.3 66.4a8.2 8.2 0 0 1-11.4 0a8.1 8.1 0 0 1 0-11.4l66.4-66.3l-66.4-66.3a8.1 8.1 0 0 1 11.4-11.4l66.3 66.4l66.3-66.4a8.1 8.1 0 0 1 11.4 11.4L139.3 128Z">
					</path>
				</svg>
				<span class="sr-only">Close</span>
			</button>
		</div>
		<!-- Body -->
		<div class="p-4 md:p-6 flex items-center">
			<app-icon name="question" class="w-12 h-12 text-muted-400 transition-transform duration-300">
			</app-icon>
			<h3 class="font-sans text-sm text-muted-500 ml-4">
				{{message}}
			</h3>
		</div>
		<!-- Footer -->
		<div class="p-4 md:p-6">
			<div class="flex justify-end w-full gap-x-2">
				<button type="button"
					class="inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 border border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700 dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300"
					(click)="infirm()">
					<span class="font-sans text-sm">No</span>
				</button>
				<button
					class="hover:bg-muted-100 inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300"
					(click)="confirm()">
					<span class="font-sans text-sm">Yes</span>
				</button>
			</div>
		</div>
	</div>
</div>
