import { Component, OnInit } from '@angular/core';
import { BcubeContractService } from '@app/core/services/bcube-contract.service';
import { StakingService } from '@app/core/services/staking.service';
import { UserService } from '@app/core/services/user.service';
import { TierDifference } from '@app/shared/types/tier-difference';
import { Tier } from '@b-cube/interfaces/staking';
import { UserInfo } from '@b-cube/interfaces/user';
import { catchError, combineLatest, delay, from, map, Observable, of, switchMap } from 'rxjs';

@Component({
	selector: 'app-widget-tier-current',
	templateUrl: './widget-tier-current.component.html',
})
export class WidgetTierCurrentComponent implements OnInit {
	// user
	user$: Observable<UserInfo>;
	userTierProgress$: Observable<any>;
	initials$: Observable<string>;

	// next tier
	tiers$: Observable<Tier[]>;
	nextTier$: Observable<Tier>;
	nextTierAvailable$: Observable<boolean>;
	tierDifference$: Observable<TierDifference>;

	// bcube
	freeBCUBE$: Observable<string>;

	//UI
	loaded$: Observable<boolean>;

	constructor(
		private userService: UserService,
		private stakingService: StakingService,
		private bcubeContractService: BcubeContractService
	) { }

	ngOnInit(): void {
		// user
		this.user$ = this.userService.currentUser;
		this.userTierProgress$ = this.userService.getUserTierProgress();
		this.initials$ = this.userService.getCurrentUserInitials();

		// next plan
		this.tiers$ = this.stakingService.getTiers();
		this.nextTier$ = combineLatest([
			this.user$,
			this.tiers$,
		]).pipe(
			switchMap(([user, tiers]) => {
				return of(this.getNextTier(user?.tier, tiers));
			}),
			catchError(() => {
				return of(null);
			})
		);

		this.nextTierAvailable$ = this.nextTier$.pipe(map(nextPlan => nextPlan !== null));

		this.tierDifference$ = combineLatest([
			this.user$,
			this.nextTier$,
		]).pipe(
			switchMap(([user, nextTier]) => {
				return of(this.computeDifference(user?.tier, nextTier));
			}),
			// retry(),
			catchError(() => {
				return of(null);
			})
		);

		// bcube
		this.freeBCUBE$ = this.stakingService.getWallet().pipe(
			switchMap((wallet: string) => {
				return from(this.bcubeContractService.getOwnedBCubeBalance(wallet));
			})
		);

		// UI
		this.loaded$ = combineLatest([
			this.user$,
			this.tiers$,
		]).pipe(
			switchMap(([user, tiers]) => of(user !== null && tiers!== null)),
			delay(100),
			catchError(() => {
				return of(null);
			})
		);
	}

	private getNextTier(currentTier:Tier, tiers: Tier[]): Tier {
		if (currentTier === undefined || currentTier === null ) {
			return null;
		}

		return tiers.find(tier => tier.tier === (currentTier.tier + 1)) ?? null;
	}

	private computeDifference(currentTier:Tier, nextTier:Tier): TierDifference {
		if (currentTier === null || nextTier === null) {
			return null;
		}

		return <TierDifference>{
			stakedTokens: nextTier.minStakedTokens - currentTier.minStakedTokens,
			planDiscountForCC: nextTier.botDiscountForCC - currentTier.botDiscountForCC,
			planDiscountForBCUBE: nextTier.planDiscountForBCUBE - currentTier.planDiscountForBCUBE,
			botDiscountForCC: nextTier.botDiscountForCC - currentTier.botDiscountForCC,
			botDiscountForBCUBE: nextTier.botDiscountForBCUBE - currentTier.botDiscountForBCUBE,
		};
	}
}
