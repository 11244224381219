import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-sun',
  templateUrl: './icon-sun.component.html',
})
export class IconSunComponent {
  @Input() class = '';

}
