<svg
  width="23"
  height="20"
  viewBox="0 0 23 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M21.7227 7.0125H16.9327L12.5427 0.4425C12.1427 -0.1475 11.2727 -0.1475 10.8827 0.4425L6.49266 7.0125H1.72266C1.17266 7.0125 0.722656 7.4625 0.722656 8.0125C0.722656 8.1025 0.732656 8.1925 0.762656 8.2825L3.30266 17.5525C3.53266 18.3925 4.30266 19.0125 5.22266 19.0125H18.2227C19.1427 19.0125 19.9127 18.3925 20.1527 17.5525L22.6927 8.2825L22.7227 8.0125C22.7227 7.4625 22.2727 7.0125 21.7227 7.0125ZM11.7127 2.8025L14.5227 7.0125H8.90266L11.7127 2.8025ZM11.7227 15.0125C10.6227 15.0125 9.72266 14.1125 9.72266 13.0125C9.72266 11.9125 10.6227 11.0125 11.7227 11.0125C12.8227 11.0125 13.7227 11.9125 13.7227 13.0125C13.7227 14.1125 12.8227 15.0125 11.7227 15.0125Z"
    fill="currentColor"
  />
</svg>
