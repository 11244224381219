import { AfterContentInit, Component, Input } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BABA_BOT_ID } from '@app/core/constants/bots.constants';
import { PerformanceDisplayMode, PerformancePeriod, PerformanceType,PERIOD_ALL } from '@app/core/constants/charts.constants';
import { StrategyType } from '@b-cube/database/types';
import { IBot, MarketType } from '@b-cube/interfaces/bot';
import { IPerformance, PERFORMANCE_TYPE } from '@b-cube/interfaces/performance';

const MAXIMUM_LEVERAGE = 10;
const ALLOCATION_STEP = 10;
const DEFAULT_PERIOD = PERIOD_ALL;

const DEFAULT_FORM_VALUES = {
	period: DEFAULT_PERIOD,
	type: PERFORMANCE_TYPE.LIVE,
	leverage: 1,
	allocation: 100,
	feesEnabled: true
};

enum OrderType {
	MAKER= 'maker',
	TAKER= 'taker'
}

const FEE_RATES = {
	[MarketType.FUTURE] : {
		[OrderType.MAKER]: 0.018,
		[OrderType.TAKER]: 0.057
	},
	[MarketType.SPOT] : {
		[OrderType.MAKER]: 0.075,
		[OrderType.TAKER]: 0.075
	}
}

const FUNDING_RATE = 0.015;

const SLEEPAGE_RATE = 0.1;

@Component({
	selector: 'app-widget-marketplace-bot-chart',
	templateUrl: './widget-marketplace-bot-chart.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: WidgetMarketplaceBotChartComponent,
		}
	]
})
export class WidgetMarketplaceBotChartComponent implements AfterContentInit {
	@Input() bot: IBot;
	@Input() performancePeriods: PerformancePeriod[];
	@Input() performance: IPerformance[];
	@Input() loading: boolean;
	@Input() performanceChartForm: FormGroup;

	private previousPeriod: string;

	public possibleAllocations: number[];
	public possibleLeverages: number[];
	public FUNDING_RATE = FUNDING_RATE;
	public SLEEPAGE_RATE = SLEEPAGE_RATE;

	public get period(): string{
		return this.performanceChartForm.controls['period'].value;
	}

	public get leverage(): number{
		return this.performanceChartForm.controls['leverage'].value;
	}

	public get allocation(): number{
		return this.performanceChartForm.controls['allocation'].value;
	}

	public get feesEnabled(): boolean{
		return this.performanceChartForm.controls['feesEnabled'].value;
	}

	public get type(): string {
		return this.performanceChartForm.controls['type'].value
	}

	constructor() {
		this.possibleAllocations = Array.from({ length: 100 / ALLOCATION_STEP }, (v, i) => (i + 1) * ALLOCATION_STEP).reverse();
		this.possibleLeverages = Array.from({ length: MAXIMUM_LEVERAGE }, (v, i) => i + 1);
	}

	ngAfterContentInit(): void {
		this.performanceChartForm.patchValue(DEFAULT_FORM_VALUES);
		this.previousPeriod = this.period;
	}

	selectPeriod(period: PerformancePeriod) {
		this.performanceChartForm.patchValue({period: period.key})
	}

	selectPerformanceType(type: string) {
		const previousPeriod = this.previousPeriod;
		if(type === PerformanceType.BACKTEST){
			this.previousPeriod =  this.period;
		}

		this.performanceChartForm.patchValue({
			type,
			period: type === PerformanceType.LIVE ? previousPeriod : PERIOD_ALL
		});
	}

	public isPeriodSelected(period: PerformancePeriod): boolean {
		return period.key === this.period;
	}

	public isPerformanceTypeSelected(type: string): boolean {
		return type === this.type;
	}

	public isLive(): boolean{
		return this.type === PERFORMANCE_TYPE.LIVE;
	}

	public isMetaBot(): boolean {
		return this.bot?.strategyType === StrategyType.META_BOT;
	}

	public isBabaBot(): boolean {
		return this.bot?.id === BABA_BOT_ID;
	}

	public getFeeRate(orderType: string): number {
		if(! this.bot){
			return 0;
		}

		return FEE_RATES[<MarketType> this.bot.market][<OrderType><any>orderType];
	}

	getDisplayMode(): PerformanceDisplayMode {
		// Find the period with the matching key
		const period = this.performancePeriods.find(p => p.key === this.period);
		if ( ! period ) {
			// Return the default display mode if the period is not found
			return PerformanceDisplayMode.DAY;
		}

		return period.displayTimeFormat || PerformanceDisplayMode.DAY;
	}
}


