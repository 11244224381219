<div class="grid lg:grid-cols-2 w-full">
  <!--Column-->
  <div class="flex items-center">
    <h2
      class="block font-sans text-4xl font-semibold text-muted-600 dark:text-muted-200 mb-4 lg:mb-0"
    >
      <span>Marketplace</span>
      <span class="text-base px-4 text-muted-400">68 bots</span>
    </h2>
  </div>
  <!--Column-->
  <div
    class="relative bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md px-10 py-16"
  >
    <div class="flex flex-col md:flex-row gap-x-10">
      <!--Profile image-->
      <div class="flex flex-col mb-4 md:mb-0">
        <div class="relative flex justify-center items-center w-24 h-24 mx-auto md:m-0">
          <div
            class="relative flex justify-center items-center w-20 h-20 mask mask-hexed z-20"
          >
            <img
              src="https://vulk.cssninja.io/assets/demo/img/avatars/56.jpg"
              class="object-cover max-w-full dark:border-transparent shadow-sm"
              alt="Avatar image"
            />
          </div>
          <app-hex-progress-track
            class="absolute top-0 left-0 w-24 h-24 text-muted-100 dark:text-muted-700 z-0"
          ></app-hex-progress-track>
          <app-hex-progress
            [progress]="80"
            class="absolute top-0 left-0 w-24 h-24 text-brand z-0"
          ></app-hex-progress>

          <div
            class="absolute bottom-4 -right-0.5 h-7 w-7 flex items-center justify-center border-[3px] border-white dark:border-muted-800 rounded-full bg-brand text-white z-30"
          >
            <span class="block font-sans text-xs font-semibold">4</span>
          </div>
        </div>

        <span
          class="flex w-full justify-center items-center max-w-[110px] mx-auto h-8 px-2 rounded-lg font-sans text-sm leading-none bg-muted-200 dark:bg-muted-700 text-muted-500 dark:text-muted-300 mt-4"
          >Mars</span
        >
      </div>
      <!--Content-->
      <div class="flex-grow">
        <h2
          class="block font-sans text-base font-semibold text-muted-600 dark:text-muted-200 mb-4 text-center md:text-left"
        >
          <span>My active agents</span>
        </h2>
        <!--Settings-->
        <div class="space-y-4">
          <!--Setting line-->
          <div class="flex flex-col md:flex-row items-center">
            <div class="w-[3rem] text-center">
              <span class="font-sans text-brand text-lg font-semibold"
                >3/4</span
              >
            </div>
            <span class="font-sans text-muted-400">B-cube agents</span>
            <div class="mx-auto mt-2 md:mt-0 md:ml-auto">
              <div class="flex items-center gap-x-2">
                <button
                  class="min-w-[90px] inline-flex items-center justify-center gap-x-2 px-4 rounded-md h-8 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300"
                >
                  <span class="font-sans text-sm">Tiers</span>
                </button>
                <a class="font-sans text-sm font-medium text-brand">Manage</a>
              </div>
              <span class="inline-block text-xs text-muted-400 mt-1">
                1 free agent available
              </span>
            </div>
          </div>
          <!--Setting line-->
          <div class="flex flex-col md:flex-row items-center">
            <div class="w-[3rem] text-center">
              <span class="font-sans text-amber-500 text-lg font-semibold"
                >1</span
              >
            </div>

            <span class="font-sans text-muted-400">3rd party agents</span>
            <div class="mx-auto mt-2 md:mt-0 md:ml-auto">
              <div class="flex items-center gap-x-2">
                <button
                  class="min-w-[90px] inline-flex items-center justify-center gap-x-2 px-4 rounded-md h-8 bg-amber-500 hover:bg-amber-500/90 text-white hover:shadow-md hover:shadow-amber-500/30 transition-all duration-300"
                >
                  <span class="font-sans text-sm">Tiers</span>
                </button>
                <a class="font-sans text-sm font-medium text-amber-500"
                  >Manage</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
