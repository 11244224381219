import { Component, EventEmitter, Output } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
	// eslint-disable-next-line
	@Output() onClose = new EventEmitter<boolean>();

	public title: string;
	public message: string;
	public shown = false;

	public async show(message: string, title: string): Promise<boolean> {
		if (title === undefined) {
			title = 'Confirmation';
		}
		this.title = title;
		this.message = message;

		this.shown = true

		return lastValueFrom(this.onClose.asObservable().pipe(take(1)));
	}

	confirm() {
		this.shown = false;
		this.onClose.next(true);
	}

	infirm() {
		this.shown = false;
		this.onClose.next(false);
	}
}
