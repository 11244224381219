<svg
  width="15"
  height="18"
  viewBox="0 0 15 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.8869 6.86719H10.3001V1.86719C10.3001 1.31719 9.85099 0.867188 9.3021 0.867188H5.3102C4.76131 0.867188 4.31222 1.31719 4.31222 1.86719V6.86719H2.72544C1.83724 6.86719 1.38815 7.94719 2.01687 8.57719L6.59759 13.1672C6.9868 13.5572 7.61552 13.5572 8.00473 13.1672L12.5854 8.57719C13.2142 7.94719 12.7751 6.86719 11.8869 6.86719ZM0.320312 16.8672C0.320312 17.4172 0.769402 17.8672 1.31829 17.8672H13.294C13.8429 17.8672 14.292 17.4172 14.292 16.8672C14.292 16.3172 13.8429 15.8672 13.294 15.8672H1.31829C0.769402 15.8672 0.320312 16.3172 0.320312 16.8672Z"
    fill="currentColor"
  />
</svg>
