<svg
  width="21"
  height="21"
  viewBox="0 0 64 64"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="w-6 h-6 text-muted-400 transition-transform duration-300"
>
  <g id="Text-files">
    <path
      d="M53.979 9.143h-3.968a.554.554 0 00-.233.047V5.023C49.778 2.253 47.473 0 44.64 0H10.217c-2.834 0-5.138 2.253-5.138 5.023v46.843c0 2.77 2.304 5.023 5.138 5.023h6.037v2.268c0 2.73 2.216 4.843 4.942 4.843h32.783c2.725 0 4.941-2.173 4.941-4.843V13.986c0-2.67-2.216-4.843-4.941-4.843zM7.111 51.866V5.023c0-1.649 1.394-2.991 3.106-2.991h34.423c1.712 0 3.106 1.342 3.106 2.991v46.843c0 1.649-1.394 2.991-3.106 2.991H10.217c-1.712 0-3.106-1.342-3.106-2.991zM56.889 59.157c0 1.551-1.305 2.812-2.91 2.812H21.195c-1.604 0-2.91-1.261-2.91-2.812v-2.268h26.354c2.833 0 5.138-2.253 5.138-5.023V11.128a.564.564 0 00.233.047h3.968c1.604 0 2.91 1.261 2.91 2.812v45.169z"
      fill="currentColor"
    />
    <path
      d="M38.603 13.206H16.254c-.561 0-1.016.454-1.016 1.016 0 .562.455 1.016 1.016 1.016h22.349c.561 0 1.016-.454 1.016-1.016 0-.562-.455-1.016-1.016-1.016zM38.603 21.333H16.254c-.561 0-1.016.454-1.016 1.016 0 .562.455 1.016 1.016 1.016h22.349c.561 0 1.016-.454 1.016-1.016 0-.562-.455-1.016-1.016-1.016zM38.603 29.46H16.254c-.561 0-1.016.454-1.016 1.016s.455 1.016 1.016 1.016h22.349c.561 0 1.016-.454 1.016-1.016s-.455-1.016-1.016-1.016zM28.444 37.587H16.254c-.561 0-1.016.454-1.016 1.016s.455 1.016 1.016 1.016h12.19c.561 0 1.016-.454 1.016-1.016s-.455-1.016-1.016-1.016z"
      fill="currentColor"
    />
  </g>
</svg>
