import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnInit {
  @Input('tooltip') tooltipTitle!: string;
  @Input() placement?: string;
  @Input() delay?: number | undefined;
  @Input() arrow?: boolean;
  tooltip?: HTMLElement | null;
  offset = 10;

  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe(() => {
      this.hide();
    });
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) { this.show(); }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) { this.hide(); }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    if (this.tooltip) {
      this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
      if (this.delay) {
        window.setTimeout(() => {
          this.renderer.removeChild(document.body, this.tooltip);
          this.tooltip = null;
        }, this.delay);
      } else {
        this.renderer.removeChild(document.body, this.tooltip);
        this.tooltip = null;
      }
    }
  }

  create() {
    this.tooltip = this.renderer.createElement('span');

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle)
    );

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    if (this.arrow) {
      this.renderer.addClass(this.tooltip, 'ng-tooltip-arrow');
    }

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltip?.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;

    if (this.placement === 'top' && tooltipPos !== undefined) {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom' && tooltipPos !== undefined) {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left' && tooltipPos !== undefined) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right' && tooltipPos !== undefined) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
