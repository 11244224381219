import { PercentPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { loadingOptions } from '@app/core/constants/charts.constants';
import { COLOR_PALETTE } from '@core/constants/charts.constants';
import { EChartsOption } from 'echarts';

export interface RingData {
	value: number,
	name: string;
}

export enum PieUnit {
	'percent',
	'dollar'
}

@Component({
	selector: 'app-chart-ring',
	templateUrl: './chart-ring.component.html',
})
export class ChartRingComponent implements OnChanges {
	@Input() data: RingData[];
	@Input() size = 140;
	@Input() unit: PieUnit = PieUnit.dollar;
	@Input() showLabel = false;
	@Input() loading = false;

	options: EChartsOption;
	loadingOptions = loadingOptions;

	constructor(private percentPipe: PercentPipe) {}

	public get isLoading(): boolean {
		return this.loading;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes['data'] || !this.data) {
			return;
		}

		const data = [], colors = [];
		for (const [index, datum] of Object.entries(this.data)) {
			if(datum.value !== 0){
				data.push(datum);
				colors.push(COLOR_PALETTE[Number(index)])
			}
		}

		this.options = <EChartsOption>{
			tooltip: {
				trigger: 'item',
				textStyle: {
					fontSize: 12
				}
			},
			grid: {
				left: 0,
				top: 0,
				right: 0,
				bottom: 0,
			},
			series: [
				{
					name: 'token',
					type: 'pie',
					radius: ['50%', '70%'],
					label: {
						show: this.showLabel,
						color: 'white'

					},
					tooltip: {
						trigger: 'item',
						formatter: ({ name, value, percent }) => {
							if(this.unit ===  PieUnit.percent){
								return `<b>${name}</b><hr>${ this.percentPipe.transform(Number(value), '1.0-1')}`
							}

							const rounded = typeof value === 'number' ? Math.round(value * 100) / 100 : value

							return `<b>${name}</b><hr>${rounded}$ (${percent}%)`;
						}
					},
					labelLine: {
						show: this.showLabel,
					},
					data: data,
					color: colors
				},
			],
			height: this.size,
		};
	}
}
