import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-staking',
  templateUrl: './icon-staking.component.html',
})
export class IconStakingComponent {
  @Input() class = '';

}
