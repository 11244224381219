import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Routes } from '@app/core/constants/router.constants';
import { UserInfo } from '@b-cube/interfaces/user';

import { LOGOUT_URL } from '../../../constants/api-urls.constants';

@Component({
	selector: 'app-header-account',
	templateUrl: './header-account.component.html',
	animations: [
		trigger('openClose', [
			state('open', style({
				transform: 'translateY(0)',
				opacity: 1
			})),
			state('closed', style({
				transform: 'translateY(.25rem)',
				opacity: 0
			})),
			transition('open => closed', [
				animate('0.3s')
			]),
			transition('closed => open', [
				animate('0.2s')
			]),
		]),
	],
})
export class HeaderAccountComponent {
	@Input() user: UserInfo;
	@Input() initials: string;
	isMenuOpen = false;

	public Routes = Routes;

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}

	closeMenu() {
		this.isMenuOpen = false;
	}

	public get getLogoutUrl() {
		return LOGOUT_URL;
	}
}
