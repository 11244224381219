import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Component, EventEmitter,Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ExchangeAccount } from '@app/core/models/exchange-account';

@Component({
	selector: 'app-exchange-account-switch',
	templateUrl: './exchange-account-switch.component.html',
	animations: [
		trigger('openClose', [
			state('open', style({
				transform: 'translateY(0)',
				opacity: 1
			})),
			state('closed', style({
				transform: 'translateY(.25rem)',
				opacity: 0
			})),
			transition('open => closed', [
				animate('0.3s')
			]),
			transition('closed => open', [
				animate('0.2s')
			]),
		]),
	],
})
export class ExchangeAccountSwitchComponent {
	@Input() selectedAccount: ExchangeAccount;
	@Input() exchangeAccounts: ExchangeAccount[];

	@Output() addExchangeAccountEvent = new EventEmitter();

	public isMenuOpen = false;
	public redirectUrl = '/portfolio';

	constructor(private router: Router) { }

	toggleMenu(): void {
		this.isMenuOpen = !this.isMenuOpen;
	}

	closeMenu(): void {
		this.isMenuOpen = false;
	}

	addElement(): void {
		this.addExchangeAccountEvent.emit(null);
	}

	navigateTo(element: ExchangeAccount) {
		let url = this.redirectUrl;
		if (element.id > 0) {
			url += '/' + element.id
		}

		this.router.navigate([url]);
	}

}
