<svg
  width="9"
  height="12"
  viewBox="0 0 9 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    d="M4.68646 0.139648C2.37821 0.14892 0.509272 2.01786 0.5 4.32611C0.5 4.78838 0.574616 5.24755 0.720758 5.68598C0.829813 5.98445 1.15344 6.14516 1.45721 6.05288C1.75082 5.94029 1.90667 5.61931 1.81307 5.31908C1.689 4.97735 1.63381 4.61486 1.65015 4.25193C1.70048 2.58477 3.09259 1.2739 4.75975 1.32424C6.42692 1.37457 7.73778 2.76667 7.68745 4.43384C7.64021 5.9946 6.41102 7.2622 4.85203 7.35712C4.85203 7.35712 4.26437 7.39288 3.97209 7.42865C3.86436 7.4441 3.75751 7.46529 3.65199 7.49222C3.63698 7.50768 3.61269 7.50768 3.59724 7.49267L3.5968 7.49222C3.5862 7.47854 3.5862 7.45911 3.5968 7.44542L3.68775 6.94916L4.23965 4.46695C4.30587 4.15701 4.10852 3.85192 3.79857 3.78569C3.48863 3.71947 3.18354 3.91682 3.11731 4.22677C3.11731 4.22677 1.80468 10.3025 1.80468 10.3577C1.72962 10.653 1.908 10.9533 2.20337 11.0283C2.20646 11.0292 2.20999 11.0301 2.21308 11.0305H2.24355C2.53848 11.1069 2.8396 10.9294 2.91554 10.6345C2.91686 10.6296 2.91819 10.6248 2.91907 10.6195C2.91819 10.6102 2.91819 10.6009 2.91907 10.5921C2.93541 10.5206 3.10097 9.71217 3.10097 9.71217C3.22637 9.10199 3.71821 8.63443 4.33369 8.53994C4.4604 8.52052 4.99287 8.48475 4.99287 8.48475C7.29229 8.25561 8.9705 6.20565 8.74091 3.90623C8.53163 1.80902 6.79426 0.194396 4.68646 0.139648Z"
    fill="currentColor"
  />
</svg>
