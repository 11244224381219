import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Filter, OrderBy } from '@app/core/constants/marketplace.constants';

@Component({
	selector: 'app-widget-marketplace-filters',
	templateUrl: './widget-marketplace-filters.component.html',
})
export class WidgetMarketplaceFiltersComponent implements OnInit {

	@Input() tokens: string[] | null;
	@Input() exchanges: string[];
	@Input() providers: string[] | null;
	@Input() filterForm: FormGroup
	// eslint-disable-next-line
	@Output() onSelectChange: EventEmitter<void> = new EventEmitter();

	filter() {
		this.onSelectChange.emit();
	}

	ngOnInit(): void {
		this.filterForm.get(Filter.MARKET).setValue(Filter.ALL);
		this.filterForm.get(Filter.CRYPTO).setValue(Filter.ALL);
		this.filterForm.get(Filter.EXCHANGE).setValue(Filter.ALL);
		this.filterForm.get(Filter.PROVIDER).setValue(Filter.ALL);
		this.filterForm.get(Filter.ORDER).setValue(OrderBy.OVERALL_PERFORMANCE);
	}

}
