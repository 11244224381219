export const PLAN_SUBSCR_WIZARD_BUTTONS_LABELS = {
	Continue: 'Continue',
	Confirm: 'Confirm',
	Pay: 'Pay',
	Finish: 'Finish'
}

export enum PLAN_SUBSCR_STEP {
	PLAN_SELECTOR = 0,
	PLAN_SUMMARY = 1,
	PAYMENT_METHOD = 2,
	PAYMENT_DETAILS = 3
}
