<svg
  width="21"
  height="21"
  viewBox="0 0 21 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.5 4.13965C0.95 4.13965 0.5 4.58965 0.5 5.13965V18.1396C0.5 19.2396 1.4 20.1396 2.5 20.1396H15.5C16.05 20.1396 16.5 19.6896 16.5 19.1396C16.5 18.5896 16.05 18.1396 15.5 18.1396H3.5C2.95 18.1396 2.5 17.6896 2.5 17.1396V5.13965C2.5 4.58965 2.05 4.13965 1.5 4.13965ZM18.5 0.139648H6.5C5.4 0.139648 4.5 1.03965 4.5 2.13965V14.1396C4.5 15.2396 5.4 16.1396 6.5 16.1396H18.5C19.6 16.1396 20.5 15.2396 20.5 14.1396V2.13965C20.5 1.03965 19.6 0.139648 18.5 0.139648ZM16.5 9.13965H8.5C7.95 9.13965 7.5 8.68965 7.5 8.13965C7.5 7.58965 7.95 7.13965 8.5 7.13965H16.5C17.05 7.13965 17.5 7.58965 17.5 8.13965C17.5 8.68965 17.05 9.13965 16.5 9.13965ZM12.5 13.1396H8.5C7.95 13.1396 7.5 12.6896 7.5 12.1396C7.5 11.5896 7.95 11.1396 8.5 11.1396H12.5C13.05 11.1396 13.5 11.5896 13.5 12.1396C13.5 12.6896 13.05 13.1396 12.5 13.1396ZM16.5 5.13965H8.5C7.95 5.13965 7.5 4.68965 7.5 4.13965C7.5 3.58965 7.95 3.13965 8.5 3.13965H16.5C17.05 3.13965 17.5 3.58965 17.5 4.13965C17.5 4.68965 17.05 5.13965 16.5 5.13965Z"
    fill="currentColor"
  />
</svg>
