import { Component, Input } from '@angular/core';
import { IBot } from '@b-cube/interfaces/bot';

enum BOT_STATUS {
	AVAILABLE = 'AVAILABLE',
	UNAVAILABLE = 'UNAVAILABLE'
}


@Component({
	selector: 'bot-status',
	templateUrl: './bot-status.component.html',
})
export class BotStatusComponent {
	@Input() bot: IBot;

	

	getColorClass(): string {
		if (this.bot.status === BOT_STATUS.UNAVAILABLE) {
			return 'bg-orange-600';
		}

		return 'bg-green-500';
	}
}
