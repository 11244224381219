import { Component } from '@angular/core';
import { IconType } from '@app/core/models/icon-type.enum';
import { IconService } from '@app/core/services/icon.service';
import { Observable } from 'rxjs';

import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'app-cryptocurrency-icon',
	templateUrl: './cryptocurrency-icon.component.html',
	styleUrls: ['./cryptocurrency-icon.component.css'],
})
export class CryptocurrencyIconComponent extends IconComponent {
	constructor(iconService: IconService) {
		super(iconService);
	}

	override buildIconPath(): Observable<string> {
		return super.buildIconPath(IconType.CRYPTOCURRENCY, this.name, this.addon);
	}
}
