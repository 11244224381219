export enum CHRAGEBEE_PLAN {
	FREEMINUM = 'freemium',
	MERCURY = 'staking_mercury',
	MARS = 'staking_mars',
	VENUS = 'staking_venus',
	SATURN = 'staking_saturn',
	JUPITER = 'staking_jupiter',
	BASIC = 'credit-card_basic', 
	BASIC_PLUS = 'credit-card_basic-plus',
	PREMIUM = 'credit-card_premium'
}

export const PLAN_STEP_PERCENTAGE = 25;
export const TIER_STEP_PERCENTAGE = 20;