<div class="relative bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md p-6 font-sans">
	<h3
		class="relative inline-block font-semibold text-lg text-muted-700 dark:text-muted-200 mb-5 after:content-[''] after:absolute after:-bottom-1 after:left-0 after:w-full after:h-0.5 after:bg-brand">
		What Is {{botName}}</h3>
	<div class="space-y-4 text-muted-500 dark:text-muted-400 mb-5">
		<p class="text-sm whitespace-pre-line">
			{{botDescription}}
		</p>
	</div>

	<div *ngIf="!isCompanyProvider && publisherName !== 'GuruBundle'">
		<h3 class="font-semibold text-xs text-muted-400 uppercase">About {{publisherName}}</h3>
		<div class="space-y-4 text-muted-500 dark:text-muted-400">
			<p class="text-sm whitespace-pre-line">
				{{publisherPresentation}}
			</p>
		</div>
	</div>
</div>
