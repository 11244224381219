<div
  class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md px-4 md:px-6 py-8"
>
  <div class="flex items-start justify-between text-center">
    <div class="flex-1">
      <label class="inline-block font-sans text-sm font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">7D Perf</label>
      <div class="mt-2">
        <span
		[class]="!performanceAggregates ? 'button-loading flex justify-center' : formatNumberHelper.getClass(performanceAggregates.weekly)"
		class="font-sans text-lg font-semibold">
		{{formatNumberHelper.formatNumber(performanceAggregates?.weekly, '%')}}
	</span>
      </div>
    </div>
    <div class="flex-1">
      <label class="inline-block font-sans text-sm font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">30D Perf</label>
      <div class="mt-2">
        <span
		[class]="!performanceAggregates ? 'button-loading flex justify-center' : formatNumberHelper.getClass(performanceAggregates?.monthly)"
		class="font-sans text-lg font-semibold">{{formatNumberHelper.formatNumber(performanceAggregates?.monthly, '%')}}</span>
      </div>
    </div>
    <div class="flex-1">
      <label class="inline-block font-sans text-sm font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">3M Perf</label>
      <div class="mt-2">
        <span
		[class]="!performanceAggregates ? 'button-loading flex justify-center' : formatNumberHelper.getClass(performanceAggregates?.quarterly)"
		class="font-sans text-lg font-semibold">{{formatNumberHelper.formatNumber(performanceAggregates?.quarterly, '%')}}</span>
      </div>
    </div>
    <div class="flex-1">
      <label class="inline-block font-sans text-sm font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">Overall</label>
      <div class="mt-2">
        <span
		[class]="!performanceAggregates ? 'button-loading flex justify-center' : formatNumberHelper.getClass(performanceAggregates?.overall)"
		class="font-sans text-lg font-semibold">{{formatNumberHelper.formatNumber(performanceAggregates?.overall, '%')}}</span>
      </div>
    </div>
  </div>
</div>
