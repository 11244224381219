export const SunscriptionWarningMessages = {
	SUBSCRIPTION_NOT_ALLOWED : 'You are not allowed to subscribe to any plan'
}

export const CommonWarningMessages = {
    CANNOT_MODIFY_SUBSCRIPTION: 'You cannot modify a subscription paid with $BCUBE.',
    PLEASE_SELECT_A_PLAN: 'Please select a plan.',
    SELECT_DIFFERENT_PLAN: 'Please select a plan different from your current plan.',
    SUBSCRIBED_TO_SAME_PLAN: 'You already subscribed to same plan and same addon.',
    ENTER_VALID_MONTHS: 'Please enter a valid number of months.',
	ENTER_AMOUNT_OF_MONTH: 'Please enter the number of months'
};
