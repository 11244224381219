<svg
  width="18"
  height="21"
  viewBox="0 0 18 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    d="M16.4713 7.9191H15.9286V7.14638C15.9286 5.90093 14.9213 4.89784 13.6889 4.89784H10.032V3.04285C10.5131 2.76208 10.8354 2.23414 10.8354 1.63181C10.8354 0.729517 10.115 0 9.22621 0C8.33746 0 7.61224 0.729517 7.61224 1.62941C7.61224 2.23414 7.93219 2.75968 8.4133 3.04045V4.89544H4.7635C3.5311 4.89544 2.54281 5.90093 2.54281 7.14398V7.9167H1.98112C1.28909 7.9167 0.722656 8.49503 0.722656 9.19815V11.9434C0.722656 12.6442 1.28909 13.2201 1.98112 13.2201H2.54281V13.9976C2.54281 15.2455 3.5311 16.2413 4.7635 16.2413H5.8063V17.4292C5.8063 18.1323 6.3751 18.6987 7.06477 18.6987H11.3829C12.0773 18.6987 12.6366 18.1299 12.6366 17.4292V16.2413H13.6889C14.9213 16.2413 15.9286 15.2431 15.9286 13.9976V13.2201H16.4713C17.1633 13.2201 17.7226 12.6442 17.7226 11.9434V9.19815C17.725 8.49743 17.1633 7.9191 16.4713 7.9191ZM4.3369 8.7734C4.3369 7.95029 4.99576 7.28077 5.81341 7.28077C6.62632 7.28077 7.28518 7.94789 7.28518 8.7734C7.28518 9.5989 6.62632 10.266 5.81341 10.266C4.99813 10.266 4.3369 9.6013 4.3369 8.7734ZM11.236 14.2184H7.21882C6.55522 14.2184 6.01486 13.6736 6.01486 12.9993C6.01486 12.325 6.55522 11.7827 7.21882 11.7827H11.236C11.8996 11.7827 12.4399 12.325 12.4399 12.9993C12.4399 13.6736 11.8996 14.2184 11.236 14.2184ZM12.639 10.266C11.8261 10.266 11.1649 9.6013 11.1649 8.7734C11.1649 7.95029 11.8237 7.28077 12.639 7.28077C13.4543 7.28077 14.1131 7.94789 14.1131 8.7734C14.1108 9.6013 13.4543 10.266 12.639 10.266ZM11.9138 19.3586H6.57655C6.15232 19.3586 5.8063 19.7065 5.8063 20.1385V20.2201C5.8063 20.6496 6.14995 21 6.57655 21H11.9114C12.3356 21 12.6817 20.652 12.6817 20.2201V20.1385C12.6817 19.7065 12.3356 19.3586 11.9138 19.3586Z"
    fill="currentColor"
  />
</svg>
