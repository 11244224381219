import { AfterContentInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BalancePeriod, DisplayMode } from '@app/core/constants/charts.constants';
import { TOOLTIP_INFO_TXT } from '@app/core/constants/tips.constants';
import { ExchangeAccountService } from '@app/core/services/exchange-account.service';
import { BALANCE_HISTORY_RANGE, PeriodPerformance } from '@b-cube/interfaces/exchange-account';
import { Observable, map } from 'rxjs';

@Component({
	selector: 'app-widget-balance-chart',
	templateUrl: './widget-balance-chart.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: WidgetBalanceChartComponent,
		}
	]
})
export class WidgetBalanceChartComponent implements ControlValueAccessor, AfterContentInit, OnChanges {
	@Input() history: any;
	@Input() usdBalance: number;
	@Input() periods: BalancePeriod[];
	@Input() loading: boolean;
	period: string;
	performance: PeriodPerformance;
	performanceIcon: string;

	public tooltipInfoText = TOOLTIP_INFO_TXT;
	public currentInvestment$: Observable<number>;
	public showDepositeTooltip$: Observable<boolean>;

	// Form control
	disabled = false;
	touched = false;
	// eslint-disable-next-line
	onChange = (period: string) => { };
	// eslint-disable-next-line
	onTouched = () => { };


	constructor(private exchangeAccountService: ExchangeAccountService) {
		this.currentInvestment$ = this.exchangeAccountService.totalInvestment;
		this.showDepositeTooltip$ = this.currentInvestment$.pipe(
			map((investment: number) => investment < 1000)
		);
	}

	ngAfterContentInit(): void {
		this.onChange(this.period);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['history'] === undefined || this.history === null) {
			return;
		}

		this.performance = this.getPerformance();
		this.setPerformanceIcon();
	}

	writeValue(period: string): void {
		this.period = period;
	}

	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}

	markAsTouched(): void {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	selectPeriod(period: BalancePeriod) {
		this.markAsTouched();
		if (!this.disabled) {
			this.period = period.key;
			this.onChange(period.key);
		}
	}

	isSelected(period: BalancePeriod): boolean {
		return period.key === this.period;
	}

	getDisplayMode(): DisplayMode {
		const period = this.periods.find(p => p.key === this.period);
		if (period === null) {
			return DisplayMode.normal;
		}

		return [ BALANCE_HISTORY_RANGE.ONE_WEEK,  BALANCE_HISTORY_RANGE.ONE_DAY].includes(period.range) ?
			DisplayMode.week
				:
			DisplayMode.normal;
	}

	public mustDisplayPerformance(): boolean {
		return this.history !== null && !this.loading;
	}

	public getPerformance(): PeriodPerformance {
		if (this.history === null || this.history.length < 2) {
			return {
				'dollar': NaN,
				'percent': NaN
			};
		}

		const originalBalance = this.history[0].usdQuote;
		const finalBalance = this.history[this.history.length - 1].usdQuote;
		const diffUsdt = finalBalance - originalBalance;

		return {
			'dollar': diffUsdt,
			'percent': originalBalance === 0 ? NaN : diffUsdt / originalBalance
		};
	}

	private setPerformanceIcon(): void {
		let direction: string;

		if (this.performance === null || isNaN(this.performance.percent) || this.performance.percent === 0) {
			direction = 'right';
		} else if (this.performance.percent < 0) {
			direction = 'down';
		} else {
			direction = 'up';
		}

		this.performanceIcon = 'chevron-' + direction
	}

	getPerformanceColor(): string {
		if (this.performance === null || isNaN(this.performance.percent) || this.performance.percent === 0) {
			return 'muted';
		}

		if (this.performance.percent < 0) {
			return 'red';
		}

		return 'green';
	}

	public isNaN(expression: any): boolean {
		return Number.isNaN(expression);
	}
}
