
<div class="show-invalid" *ngIf="isWalletConnected$ | async">
	<div class="space-y-5">
		<!--Input-->
		<form [formGroup]="form">
			<div class="relative">
				<label class="font-alt text-sm text-slate-400">Months</label>
				<div class="group relative">
					<input type="number" formControlName="months" min="1" max="12" step="1" [onlyNumber]
						class="px-2 py-1 h-8 text-sm font-sans w-full rounded-md bg-white text-slate-600 border border-slate-300 focus:border-slate-300 focus:shadow-lg focus:shadow-gray-300/50 dark:focus:shadow-gray-800/50 placeholder:text-slate-300 dark:placeholder:text-slate-500 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600 dark:focus:border-slate-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-gray-300 dark:focus:outline-gray-600 focus:outline-offset-2 transition-all duration-300"
						placeholder="3"
						value = "1"
					/>
				</div>
			</div>
			<div class="relative">
				<label class="font-alt text-sm text-slate-400">Wallet</label>
				<div class="group relative">
					<input type="text" formControlName="wallet"
						class="px-2 py-1 h-8 text-sm font-sans w-full rounded-md bg-white text-slate-600 border border-slate-300 focus:border-slate-300 focus:shadow-lg focus:shadow-gray-300/50 dark:focus:shadow-gray-800/50 placeholder:text-slate-300 dark:placeholder:text-slate-500 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600 dark:focus:border-slate-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-gray-300 dark:focus:outline-gray-600 focus:outline-offset-2 transition-all duration-300"
						placeholder="ex: 3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy"
						[value]="stakingWallet$ | async"
						readonly="readonly"/>
				</div>
			</div>
			<!--Input-->
			<div class="relative">
				<label class="font-alt text-sm text-slate-400">Amount</label>
				<div class="group relative">
					<input type="number"
						class="pl-2 pr-20 py-1 h-8 text-sm font-sans w-full rounded-md bg-white text-muted-600 border border-muted-300 focus:border-muted-300 focus:shadow-lg focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 placeholder:text-muted-300 dark:placeholder:text-muted-500 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-muted-300 dark:focus:outline-muted-600 focus:outline-offset-2 transition-all duration-300"
						placeholder="B-cube amount"
						formControlName="amount"
						readonly="readonly"/>
					<div
						class="absolute top-0 right-0 h-8 w-20 flex items-center justify-center font-sans text-muted-400">
						<span class="text-sm">BCUBE</span>
					</div>
				</div>
				<span class="font-sans text-xs text-secondary-500 cursor-pointer">Available: {{ (ownedBcubeBalance$ | async) | number:'1.0-2' }} BCUBE</span>
			</div>
		</form>
	</div>
</div>
<app-wallet-modal #modal></app-wallet-modal>
<div *ngIf="!(isWalletConnected$ | async)" class="flex justify-center">
	<button (click)="modal.toggleModal()"
	class="min-w-[130px] w-full md:w-auto inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-12 border border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700 dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300">
		<span>Connect</span> <app-icon-wallet class="w-4 h-4"></app-icon-wallet>
	</button>
</div>
