import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-hex-progress',
	templateUrl: './hex-progress.component.html',
})
export class HexProgressComponent implements OnChanges {
	@Input() class = '';
	@Input() max = 450;
	@Input() progress = 0;
	@Input() offset = 450;
	
	initialized = false;

	ngOnChanges(changes: SimpleChanges) {
		if(changes['progress'] === undefined){
			return;
		}

		this.initialized = true;

		let progress = changes['progress'].currentValue;

		if(progress > 100){
			progress = 100;
		} else if(progress < 0){
			progress = 0;
		}

		this.offset = ((100 - progress) / 100) * this.max;
	 }
}
