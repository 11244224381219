import { Component, Input } from '@angular/core';
import { FormatNumberHelper } from '@app/core/services/performance/format-number.helper';
import { IPerformanceAggregates } from '@b-cube/interfaces/performance';

@Component({
	selector: 'app-widget-marketplace-bot-stats',
	templateUrl: './widget-marketplace-bot-stats.component.html'
})
export class WidgetMarketplaceBotStatsComponent {

	@Input() performanceAggregates: IPerformanceAggregates;

	constructor(public formatNumberHelper: FormatNumberHelper) { }

}
