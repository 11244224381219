import { Injectable } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { catchError, map, Observable, of } from 'rxjs';

import { BOT_ICONS_NAMES, DEFAULT_B_CUBE_BOT_ICON, EXCHANGES_ICONS_NAMES, ICONS_FOLDER } from "../constants/icons.constants";
import { IconType } from '../models/icon-type.enum';

@Injectable({
	providedIn: 'root'
})
export class IconService {

	constructor(private iconReg: SvgIconRegistryService) {
	}

	public getIcon(iconType: IconType, name: string, addon = ''): Observable<string> {
		let defaultIconPath = `${ICONS_FOLDER[IconType.DEFAULT]}/cross.svg`;
		const extension = 'svg';

		const folderPath: string = iconType ? ICONS_FOLDER[iconType] : ICONS_FOLDER[IconType.DEFAULT];
		let iconName = name;

		switch (iconType) {
			case IconType.EXCHANGE:
				iconName = EXCHANGES_ICONS_NAMES[name];
				break;
			case IconType.BOT:
				iconName = BOT_ICONS_NAMES[name];
				if (!iconName) {
					iconName = DEFAULT_B_CUBE_BOT_ICON;
					defaultIconPath = `${folderPath}/${DEFAULT_B_CUBE_BOT_ICON}.${extension}`;
				}
				break;
			case IconType.CRYPTOCURRENCY:
				iconName = iconName.toLowerCase();
				defaultIconPath = `${ICONS_FOLDER[IconType.CRYPTOCURRENCY]}/generic.svg`;
				break;
			case IconType.WALLET_PROVIDERS:
				break;
			default:
				break;
		}

		if (iconName === 'all') {
			return of(defaultIconPath);
		}

		iconName += addon ? `_${addon}` : '';
		const url = `${folderPath}/${iconName}.${extension}`;

		return this.iconReg.loadSvg(url)
			.pipe(
				map(() => url),
				catchError(() => of(defaultIconPath))
			)
	}
}
