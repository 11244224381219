<div class="flex-1">
	<!--Profile image-->
	<div class="flex flex-col items-center mb-4 md:mb-0">
		<div class="relative flex justify-center items-center w-24 h-24 mx-auto md:m-0">
			<div class="relative flex justify-center items-center w-20 h-20 mask mask-hexed z-20">
				<!-- <img src="https://vulk.cssninja.io/assets/demo/img/avatars/56.jpg"
					class="object-cover max-w-full dark:border-transparent shadow-sm" alt="Avatar image" /> -->
				<div class="inline-flex items-center justify-center text-4xl text-muted-400 dark:text-white">
					{{initials$ | async}}
				</div>
			</div>
			<app-hex-progress-track class="absolute top-0 left-0 w-24 h-24 text-muted-100 dark:text-muted-700 z-0">
			</app-hex-progress-track>
			<app-hex-progress [progress]="(userTierProgress$ | async)?.progress"
				class="absolute top-0 left-0 w-24 h-24 z-0 text-secondary-500">
			</app-hex-progress>
			<div class="absolute bottom-4 -right-0.5 h-7 w-7 flex items-center justify-center border-[3px] border-white
				dark:border-muted-800 rounded-full text-white z-30 bg-secondary-500">
				<span class="block font-sans text-xs font-semibold">{{(user$ | async)?.plan.tier}}</span>
			</div>
		</div>

		<span
			class="capitalize flex w-full justify-center items-center max-w-[80px] mx-auto h-8 px-2 rounded-lg
			font-sans text-sm leading-none bg-muted-200 dark:bg-muted-700 text-muted-500 dark:text-muted-300 mt-4 mb-2 whitespace-nowrap">
			{{(user$ | async)?.plan.name}}
		</span>

		<p class="font-sans text-xs text-muted-400" *ngIf="(tradingVolume$ | async) === null">
			This is your current plan
		 </p>
		<!--Progress-->
		<div class="flex flex-col py-3" *ngIf="(tradingVolume$ | async) !== null && (user$ | async)?.plan.tradingLimit !== UNLIMITED_TRADING_LIMIT">
			<div class="flex items-center justify-between font-sans">
				<span class="text-sm text-muted-400">{{tradingVolume$ | async}} / {{(user$ |
					async)?.plan.tradingLimit}}$</span>
			</div>
			<app-progress-bar [value]="tradingPourcent$ | async" class="text-secondary-500"></app-progress-bar>
		</div>

		<div class="flex flex-col py-3" *ngIf="(tradingVolume$ | async) !== null && (user$ | async)?.plan.tradingLimit === UNLIMITED_TRADING_LIMIT">
			<div class="flex items-center justify-between font-sans">
				<span class="text-sm text-muted-400">Current investment: {{tradingVolume$ | async | currency:'USD':'symbol':'1.2-2'}}$</span>
			</div>
		</div>
	</div>
</div>