
export enum SPECIAL_VALUES{
	PLUS_INFINITY,
	MINUS_INFINITY
}

export type PlanDifference = {
	tradingLimit: number|SPECIAL_VALUES;
	price?: number;
}
