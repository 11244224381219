import { DecimalPipe, PercentPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormatNumberHelper } from '@app/core/services/performance/format-number.helper';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';
import { SignalStatus } from '@b-cube/database/types';
import { ISignal } from '@b-cube/interfaces/order-history/trade.interface';
import { EChartsOption, TooltipComponentFormatterCallbackParams } from 'echarts';
import { DateTime } from 'luxon';

const RANDOM_PRICE_SHIFT = 10;

@Component({
	selector: 'app-chart-allocations-mini',
	templateUrl: './chart-allocations-mini.component.html',
})
export class ChartAllocationsMiniComponent implements OnInit {
	@Input() metaBotSignal: ISignal;

	options: EChartsOption = {};

	constructor(
		private decimalPipe: DecimalPipe,
		private percentPipe: PercentPipe,
		private datePipe: LocalizedDatePipe
	) { }

	ngOnInit(): void {
		const allocations = this.metaBotSignal.timestamps.reduce((acc, value, index) => [...acc, [value, this.metaBotSignal.allocations[index]]], []);
		const prices = this.metaBotSignal.timestamps.reduce((acc, value, index) => [...acc, [value, this.metaBotSignal.prices[index]]], []);

		// add a final date to display the last period
		if(this.metaBotSignal.status === SignalStatus.CLOSED){
			allocations.push([this.metaBotSignal.dateClose, 0]);
			prices.push([this.metaBotSignal.dateClose, this.metaBotSignal.exitPrice[0]]);
		} else {
			const now = DateTime.now();
			prices.push([now.toISO(), this.metaBotSignal.prices[this.metaBotSignal.prices.length - 1]]);
			allocations.push([now.toISO(), this.metaBotSignal.allocations[this.metaBotSignal.allocations.length - 1]]);
		}

		let maxPrice = Math.max(... this.metaBotSignal.prices);
		let minPrice = Math.min(... this.metaBotSignal.prices);

		if(maxPrice === minPrice){
			maxPrice += RANDOM_PRICE_SHIFT;
			minPrice -= RANDOM_PRICE_SHIFT;
		}

		this.options = <EChartsOption>{
			xAxis: {
				show:false,
				type: 'time',
				boundaryGap:false,
			},
			yAxis: [
				{
					// allocations
					show:false,
					type: 'value',
					min:0,
					max:1
				},
				{
					// prices
					show:false,
					type: 'value',
					min: minPrice,
					max: maxPrice
				}
			],
			grid: {
				left: 0,
				top: 0,
				right: 0,
				bottom: 0,
			},
			tooltip: {
				show: true,
				trigger: 'axis',
				textStyle: {
					fontSize: 12
				},
				appendToBody:true,
				formatter: (params: any[]) => {
					return  `${this.datePipe.transform(params[0].data[0])} <table>`+
					`<tr><td><span style="color:#23cbd5; font-size:20px;">&#9632;</span> ${params[0].seriesName}</td><td style="text-align:right;">${this.percentPipe.transform(params[0].data[1], '1.0-1')}</td></tr>` +
					`<tr><td><span style="color:violet; font-size:20px;">&#9632;</span> ${params[1].seriesName}</td><td style="text-align:right;">${this.decimalPipe.transform(params[1].data[1], '1.0-2')} USDT</td></tr></table>`;
				}
			},
			series: [
				{
					name: 'Allocation',
					data: allocations,
					yAxisIndex: 0,
					showSymbol: false,
					type: 'line',
					step: 'end',
					areaStyle: {
						color: '#23cbd5',
						opacity: 0.5
					},
					lineStyle: {
						width: 1,
						color: '#23cbd5',
						join: 'round',
					},
					itemStyle: {
						normal: {
							color: '#23cbd5',
							lineStyle: {
								width: 1,
								type: 'solid',
								color: '#23cbd5',
							},
						},
					},
				},
				{
					name: 'Price',
					data: prices,
					yAxisIndex: 1,
					showSymbol: false,
					type: 'line',
					lineStyle: {
						width: 1,
						color: 'violet',
						join: 'round',
					},
					itemStyle: {
						normal: {
							color: 'violet',
							lineStyle: {
								width: 1,
								type: 'solid',
								color: 'violet',
							},
						},
					},
				},
			],
			height: 30,
			width: 120,
			backgroundColor: 'rgba(255,255,255,0.1)'
		};
	}
}
