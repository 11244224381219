import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-huobi',
  templateUrl: './icon-huobi.component.html'
})
export class IconHuobiComponent {
  @Input() class = ''

}
