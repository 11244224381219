<div class="w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-lg">
	<div class="w-full p-6 border-b border-muted-200 dark:border-muted-700">
		<span class="font-sans text-base font-semibold text-muted-600 dark:text-muted-200">Agent statistics
		</span>
	</div>
	<div class="w-full">
		<!--Item-->
		<div class="flex items-center justify-between px-6 py-5 border-b border-muted-200 dark:border-muted-700">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Profit factor</span>
				<app-icon-question class="w-3 h-3 text-muted-400" [tooltip]="PROFIT_FACTOR_TOOLTIP" placement="top"
					[delay]="0"></app-icon-question>
			</div>
			<span [class]="loading ? 'loading-row' : formatNumberHelper.getClass(statistics?.profitFactor)"
				class="font-sans text-sm font-semibold">
				{{formatNumberHelper.formatNumber(statistics?.profitFactor)}}
			</span>
		</div>
		<!--Item-->
		<div class="flex items-center justify-between px-6 py-5 border-b border-muted-200 dark:border-muted-700">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Max drawdown</span>
				<app-icon-question class="w-3 h-3 text-muted-400" [tooltip]="MAXDROWDOWN_TOOLTIP" placement="top"
					[delay]="0"></app-icon-question>
			</div>
			<span [class]="loading ? 'loading-row' : formatNumberHelper.getClass(statistics?.maxDrawdown)"
				class="font-sans text-sm font-semibold">
				{{formatNumberHelper.formatNumber(statistics?.maxDrawdown, '%')}} </span>
		</div>
		<!--Item-->
		<!--		<div class="flex items-center justify-between px-6 py-5 border-b border-muted-200 dark:border-muted-700">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Sharpe</span>
				<app-icon-question class="w-3 h-3 text-muted-400" tooltip="This does whatever it needs to do"
					placement="top" [delay]="0"></app-icon-question>
			</div>
			<span class="font-sans text-sm font-semibold">
				N/A
			</span>
		</div>-->
		<!--Item-->
		<!--		<div class="flex items-center justify-between px-6 py-5 border-b border-muted-200 dark:border-muted-700">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Sortino</span>
				<app-icon-question class="w-3 h-3 text-muted-400" tooltip="This does whatever it needs to do"
					placement="top" [delay]="0"></app-icon-question>
			</div>
			<span class="font-sans text-sm font-semibold">
				N/A
			</span>
		</div>-->
		<!--Item-->
		<div class="flex items-center justify-between px-6 py-5 border-b border-muted-200 dark:border-muted-700">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Best trade</span>
			</div>
			<span [class]="loading ? 'loading-row' : formatNumberHelper.getClass(statistics?.bestTrade)"
				class="font-sans text-sm font-semibold">
				{{formatNumberHelper.formatNumber(statistics?.bestTrade, '%')}}
			</span>
		</div>
		<!--Item-->
		<div class="flex items-center justify-between px-6 py-5 border-b border-muted-200 dark:border-muted-700">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Worst trade</span>
			</div>
			<span [class]="loading ? 'loading-row' : formatNumberHelper.getClass(statistics?.worstTrade)"
				class="font-sans text-sm font-semibold">
				{{formatNumberHelper.formatNumber(statistics?.worstTrade, '%')}} </span>
		</div>
		<!--Item-->
		<div class="flex items-center justify-between px-6 py-5 border-b border-muted-200 dark:border-muted-700">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Average trade</span>
			</div>
			<span [class]="loading ? 'loading-row' : formatNumberHelper.getClass(statistics?.averageTrade)"
				class="font-sans text-sm font-semibold">
				{{formatNumberHelper.formatNumber(statistics?.averageTrade, '%')}}
			</span>
		</div>
		<!--Item-->
		<div class="flex items-center justify-between px-6 py-5">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Average monthly returns</span>
			</div>
			<span [class]="loading ? 'loading-row' : formatNumberHelper.getClass(statistics?.averageMonthlyReturns)"
				class="font-sans text-sm font-semibold ">
				{{formatNumberHelper.formatNumber(statistics?.averageMonthlyReturns, '%')}}
			</span>
		</div>

		<!--Item-->
		<div class="flex items-center justify-between px-6 py-5">
			<div class="flex items-center gap-x-2 font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
				<span>Average weekly returns</span>
			</div>
			<span [class]="loading ? 'loading-row' : formatNumberHelper.getClass(statistics?.averageWeeklyReturns)"
				class="font-sans text-sm font-semibold ">
				{{formatNumberHelper.formatNumber(statistics?.averageWeeklyReturns, '%')}}
			</span>
		</div>
	</div>
</div>
