import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ScreenService {
    private minimumDesktopScreenWidth = 1280;

    public isDesktop(): boolean {
        return window.outerWidth > this.minimumDesktopScreenWidth;
    }
}
