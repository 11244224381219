<svg
  width="17"
  height="17"
  viewBox="0 0 17 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.5625 7.42314L4.3925 7.42314L9.2725 2.54314C9.6625 2.15314 9.6625 1.51314 9.2725 1.12314C8.8825 0.733139 8.2525 0.733139 7.8625 1.12314L1.2725 7.71314C0.8825 8.10314 0.8825 8.73314 1.2725 9.12314L7.8525 15.7231C8.03933 15.9104 8.29298 16.0156 8.5575 16.0156C8.82202 16.0156 9.07567 15.9104 9.2625 15.7231C9.6525 15.3331 9.6525 14.7031 9.2625 14.3131L4.3925 9.42314L15.5625 9.42314C16.1125 9.42314 16.5625 8.97314 16.5625 8.42314C16.5625 7.87314 16.1125 7.42314 15.5625 7.42314Z"
    fill="currentColor"
  />
</svg>
