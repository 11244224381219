import {
	BotRisk,
	ExchangeName,
} from '@b-cube/database/types'

export enum PERFORMANCE_HISTORY_FREQUENCY {
	ONE_DAY = '1d',
	ONE_WEEK = '7d',
	ONE_MONTH = '30d',
	THREE_MONTHS = '90d'
}

export enum PERFORMANCE_HISTORY_RANGE {
	ONE_WEEK = '7d',
	ONE_MONTH = '30d',
	THREE_MONTHS = '90d',
	SIX_MONTHS = '180d',
	ONE_YEAR = '365d',
	ALL_HISTORY = 'all'
}

export enum PERFORMANCE_TYPE {
	BACKTEST = 'backtest',
	LIVE = 'live'
}

export interface IPerformance {
	time: string;
	value: number;
}

export interface IBotPerformance {
	performance: IPerformance[];
	performanceAggregates: IPerformanceAggregates;
}

export interface IPerformanceAggregates {
	overall: number;
	quarterly: number;
	monthly: number;
	weekly: number;
}

export interface IBundlePerfAggregate extends IPerformanceAggregates {
	lastYears: number;
	risk: BotRisk;
	exchanges: ExchangeName[];
}

export interface IUserAccountBotPerformance {
	overall: number;
	monthly: number;
	weekly: number;
}


export interface IBotStatistic {
	profitFactor: number,
	//sharpe: number,
	//sortino: number,
	averageTrade: number,
	worstTrade: number,
	bestTrade: number,
	averageMonthlyReturns: number,
	maxDrawdown: number,
	averageTradePerMonth: number,
	averageWeeklyReturns: number,
}
