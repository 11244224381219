import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'priceScreener', pure: true })
export class PriceScreenerPipe implements PipeTransform {
    private priceThreshold = 100;
    private lowPriceThreshold = 8;

    transform(value: number): string {
        if (value >= this.priceThreshold) {
            return value.toFixed(2);
        } else if (this.lowPriceThreshold <= value && value < this.priceThreshold) {
            return value.toFixed(4);
        }
 
            return value.toFixed(6);
        
    }
}
