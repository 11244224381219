import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from '@app/core/constants/router.constants';
import { UserBotSettingsService } from '@app/core/services/user-bot-settings.service';
import { IBot, IBotWithPnls, IBotWithStatus } from '@b-cube/interfaces/bot';
import { IBotSettingsWrapper } from '@b-cube/interfaces/user-bot-settings';
import { ToastrService } from 'ngx-toastr';
import {  Subscription } from 'rxjs';

@Component({
	selector: 'app-widget-bots-active-table',
	templateUrl: './widget-bots-active-table.component.html'
})
export class WidgetBotsActiveTableComponent implements OnDestroy {
	@Input() bots: IBotWithPnls[];
	@Output() updateBotStatus: EventEmitter<number> = new EventEmitter();

	public isBotSettingsOpen = false;
	public botSettingsWrapper: IBotSettingsWrapper = null;
	public selectedBot: IBotWithStatus = null;
	private botSettingsSub = new Subscription();

	constructor(
		private router: Router,
		private botSettingsService: UserBotSettingsService,
		private toasterService: ToastrService
	) { }

	navigateTo(bot: IBot) {
		this.router.navigate([Routes.BOTS + '/' + bot.id]);

		return false;
	}

	ngOnDestroy(): void {
		this.botSettingsSub.unsubscribe();
	}

	public displayBotSettings(bot: IBotWithStatus): void {
		this.selectedBot = bot;
		this.botSettingsSub = this.botSettingsService.getUserBotSettings(bot.id).subscribe((botSettingsWrapper: IBotSettingsWrapper) => {
			if (botSettingsWrapper?.botSettings.length > 0) {
				this.isBotSettingsOpen = true;
				this.botSettingsWrapper = botSettingsWrapper;
			} else {
				this.toasterService.warning('This agent has no particular settings')
			}
		})
	}

	public closeBotSettings(): void {
		this.isBotSettingsOpen = false;
	}

	public updateStatus(botId: number) {
		this.updateBotStatus.emit(botId);
	}

	public getEvolutionIcon(pnl:number): string{
		if(pnl === 0 ){
			return 'chevron-right';
		}

		if(pnl > 0 ){
			return 'chevron-up';
		}

		return 'chevron-down';
	}
}
