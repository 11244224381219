import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-ring',
  templateUrl: './icon-ring.component.html'
})
export class IconRingComponent {
  @Input() class = '';

}
