export const WHITELISTED_IPS =  {
  POL: [
    '45.192.133.135', '45.192.133.174', '45.192.133.115', '45.192.133.105', 
    '45.192.133.175', '45.192.133.207', '45.192.133.241', '45.192.133.100', 
    '45.192.133.242', '45.192.133.62', '45.192.133.205', '45.192.133.79', 
    '45.192.133.61', '45.192.133.95', '45.192.133.251'
  ],
  SRB: [
    '64.137.105.10', '64.137.105.189', '64.137.105.97', '64.137.105.62', 
    '64.137.105.37', '64.137.105.223', '64.137.105.83', '64.137.105.66', 
    '64.137.105.248', '45.192.159.245', '45.192.159.196', '45.192.159.0', 
    '45.192.159.92', '45.192.159.95', '45.192.159.142'
  ],
  DEU: [
    '3.66.134.20', '18.194.250.49', '52.57.253.46'
  ],
  KRAKEN_SPOT: [
    '45.192.133.1/24', '64.137.105.1/24', '45.192.159.1/24'
  ]
};
