import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { COMPANY } from '@core/constants/bots.constants';

@Component({
	selector: 'app-widget-marketplace-tabs',
	templateUrl: './widget-marketplace-tabs.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: WidgetMarketplaceTabsComponent
		}
	]
})


export class WidgetMarketplaceTabsComponent implements ControlValueAccessor {

	source: string = COMPANY;
	touched = false;
	disabled = false;

	@Input() filterForm: FormGroup;
	@Input() toggled = false;

	// eslint-disable-next-line
	@Output() onTabChange: EventEmitter<void> = new EventEmitter();

	// eslint-disable-next-line
	onChange = (source: string) => { };
	// eslint-disable-next-line
	onTouched = () => { };


	writeValue(source: string): void {
		this.source = source;
	}

	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}

	markAsTouched(): void {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	onSourceTabClick(event: Event): void {
		this.markAsTouched();
		if (!this.disabled) {
			this.source = (event.target as HTMLTextAreaElement).id;
			this.onChange(this.source);
		}
		this.toggled = !this.toggled
		this.onTabChange.emit();
	}
}
