<div
	class="relative h-full flex flex-col bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md">

	<!--Header-->
	<div class="flex items-center justify-between px-6 py-8 border-b border-muted-200 dark:border-muted-700">
		<h3 class="font-sans font-semibold text-muted-600 dark:text-muted-100">
			Balance allocated
		</h3>
		<a class="font-sans cursor-pointer text-xs font-semibold text-brand" (click)="openSettings()">Edit
			Allocation</a>

	</div>
	<!--Item-->
	<div class="flex items-center justify-between p-6 border-b border-muted-200 dark:border-muted-700">
		<h4 class="font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
			Exchange account
		</h4>
		<div class="flex items-center gap-x-2 text-left">
			<span class="relative flex justify-center items-center h-7 w-7 rounded-full">
				<app-exchange-icon *ngIf="exchangeAccount" [name]="exchangeAccount.exchangeName" [svgClass]="'w-7 h-7'"
					[addon]="'circle'"></app-exchange-icon>
			</span>
			<span
				class="block font-sans text-sm font-semibold text-muted-600 dark:text-muted-200">{{exchangeAccount?.name}}</span>
		</div>
	</div>
	<!--Item-->
	<div class="flex items-center justify-between p-6 border-b border-muted-200 dark:border-muted-700">
		<h4 class="font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
			Balance allocation (%)
		</h4>
		<span class="font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
			<span *ngIf="!allocLoader; else percentageLoading" [class]="{
				'text-red-400': botAllocationPercentage === 0
			}">
				{{botAllocationPercentage}}%
			</span>
			<ng-template #percentageLoading>
				<span class="dark-circle-loader"></span>
			</ng-template>

		</span>
	</div>
	<!--Item-->
	<div class="flex items-center justify-between p-6">
		<h4 class="font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
			Current balance allocation
		</h4>
		<span class="font-sans text-sm font-semibold text-muted-600 dark:text-muted-100">
			<span *ngIf="!allocLoader; else assetLoading">
				{{assetAllocation| cryptoCurrency:allocationCurrency}} {{allocationCurrency}}
			</span>
			<ng-template #assetLoading>
				<span class="dark-circle-loader"></span>
			</ng-template>

		</span>
	</div>
</div>

<app-widget-balance-allocation-modal *ngIf="isSettingsOpen" (close)="closeBalanceAllocation($event)"
	[selectedAccount]="exchangeAccount" [balanceAllocations]="balanceAllocations" [bot]="bot">
</app-widget-balance-allocation-modal>
