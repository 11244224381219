import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'portfolio',
		pathMatch: 'full'
	},
	{
		path: 'portfolio',
		loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule),
	},
	{
		path: 'agents',
		loadChildren: () => import('./bot/bot.module').then(m => m.BotsModule),
	},
	{
		path: 'marketplace',
		loadChildren: () => import('./marketplace/marketplace.module').then(m => m.MarketplaceModule),
	},
	{
		path: 'plan',
		loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule),
	},
	{
		path: 'staking',
		loadChildren: () => import('./staking/staking.module').then(m => m.StakingModule),
	},
	{
		path: 'exchange-account',
		loadChildren: () => import('./exchange-account/exchange-account.module').then(m => m.ExchangeAccountModule),
	},
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
	},
	{
		path: 'ta-screener',
		loadChildren: () => import('./ta-screener/ta-screener.module').then(m => m.TaScreenerModule),
	},
	// {
	// 	path: 'sentiment-analysis',
	// 	loadChildren: () => import('./sentiment-analysis/sentiment-analysis.module').then(m => m.SentimentAnalysisModule),
	// },
	{
		path: '**',
		redirectTo: 'portfolio',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
