export enum OrderStatus {
  NEW = 'NEW',
  SENT = 'SENT',
  CANCELED = 'CANCELED',
  PARTIALLY_FULLFILLED = 'PARTIALLY_FULLFILLED',
  COMPLETELY_FULLFILLED = 'COMPLETELY_FULLFILLED',
  REPLACED = 'REPLACED'
}


