import { Component } from '@angular/core';
import { IconType } from '@app/core/models/icon-type.enum';
import { IconService } from '@app/core/services/icon.service';
import { Observable } from 'rxjs';

import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'app-bot-icon',
	templateUrl: './bot-icon.component.html',
	styleUrls: ['./bot-icon.component.css'],
})
export class BotIconComponent extends IconComponent {

	constructor(iconService: IconService) {
		super(iconService);
	}

	override buildIconPath(): Observable<string> {
		return super.buildIconPath(IconType.BOT, this.name, this.addon);
	}
}
