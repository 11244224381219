<svg
  width="13"
  height="14"
  viewBox="0 0 13 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.5017 1.21982C12.3274 1.04505 12.0906 0.946833 11.8437 0.946833C11.5969 0.946833 11.3601 1.04505 11.1857 1.21982L6.62175 5.77449L2.05775 1.21049C1.88337 1.03572 1.64663 0.9375 1.39975 0.9375C1.15287 0.9375 0.916126 1.03572 0.74175 1.21049C0.37775 1.57449 0.37775 2.16249 0.74175 2.52649L5.30575 7.09049L0.74175 11.6545C0.37775 12.0185 0.37775 12.6065 0.74175 12.9705C1.10575 13.3345 1.69375 13.3345 2.05775 12.9705L6.62175 8.40649L11.1857 12.9705C11.5497 13.3345 12.1377 13.3345 12.5017 12.9705C12.8657 12.6065 12.8657 12.0185 12.5017 11.6545L7.93775 7.09049L12.5017 2.52649C12.8564 2.17182 12.8564 1.57449 12.5017 1.21982Z"
    fill="currentColor"
  />
</svg>
