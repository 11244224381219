<svg
  width="16"
  height="20"
  viewBox="0 0 16 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.24273 19.6963C9.34273 19.6963 10.2427 18.7963 10.2427 17.6963H6.24273C6.24273 18.7963 7.13273 19.6963 8.24273 19.6963ZM14.2427 13.6963V8.69629C14.2427 5.62629 12.6027 3.05629 9.74273 2.37629V1.69629C9.74273 0.866289 9.07273 0.196289 8.24273 0.196289C7.41273 0.196289 6.74273 0.866289 6.74273 1.69629V2.37629C3.87273 3.05629 2.24273 5.61629 2.24273 8.69629V13.6963L0.952727 14.9863C0.322727 15.6163 0.762727 16.6963 1.65273 16.6963H14.8227C15.7127 16.6963 16.1627 15.6163 15.5327 14.9863L14.2427 13.6963Z"
    fill="currentColor"
  />
</svg>
