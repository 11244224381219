import { Ethereum, InjectedConnectorOptions } from '@wagmi/core';
import { Chain } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

import { Provider } from '../../../constants/provider.constants';


export class BraveConnector extends InjectedConnector {
	override readonly id = Provider.BRAVE;
	override readonly ready = typeof window !== 'undefined' && Boolean(this.findProvider(window.ethereum));

	private provider?: Window['ethereum'];

	constructor({ chains, options }: { chains?: Chain[]; options?: InjectedConnectorOptions } = {}) {
		super({ chains, options: { name: 'Brave Wallet', ...options } });
	}

	override async getProvider() {
		if (typeof window !== 'undefined') {
			this.provider = this.findProvider(window.ethereum);
		}

		return this.provider;
	}

	private getReady(ethereum?: Ethereum) {
		if (!ethereum?.isBraveWallet) {
			return null;
		}

		return ethereum;
	}

	private findProvider(ethereum?: Ethereum) {
		if (ethereum?.providers) return ethereum.providers.find(this.getReady);

		return this.getReady(ethereum);
	}
}
