import { BABA_BOT_ID, COMPANY, GURU_BOT_ID, THIRD_PARTY } from "@app/core/constants/bots.constants";
import { Filter, FilterAndSortParams, FormatedBot, OrderBy } from "@app/core/constants/marketplace.constants";
import { BotRisk } from '@b-cube/interfaces/bot';
import { DateTime } from "luxon";

export class FilterAndSortEngine {
	public bots: FormatedBot[];
	public params: FilterAndSortParams;

	public filter(): FormatedBot[] {
		this.filterByExchange();
		this.filterByCryptoToken();
		// this.filterByMarket();
		this.filterByCategory();
		this.filterByProvider();

		return this.bots;
	}

	public sort(): FormatedBot[] {
		switch (this.params.order) {
			case OrderBy.NAME:
				this.orderByName();
				break;
			case OrderBy.RECENCY:
				this.orderByRecency();
				break;
			case OrderBy.WEEKLY_PERFORMANCE:
			case OrderBy.MONTHLY_PERFORMANCE:
			case OrderBy.QUARTERLY_PERFORMANCE:
			case OrderBy.OVERALL_PERFORMANCE:
				this.orderByPerformance(this.params.order);
				break;
			default:
				this.orderByRecency();
				break;
		}

		return this.bots;
	}

	private filterByExchange(): void {
		if (!this.params.supportedExchanges || this.params.supportedExchanges === Filter.ALL) return;
		this.bots = this.bots.filter((bot: { supportedExchanges: string[] }) => bot.supportedExchanges.includes(this.params.supportedExchanges.toLocaleLowerCase())
		);
	}

	private filterByCryptoToken(): void {
		if (this.params.crypto === Filter.ALL) return;
		this.bots = this.bots.filter((bot: FormatedBot) => {
			if (bot.id === BABA_BOT_ID || bot.id === GURU_BOT_ID) { return true }

			return bot.cryptos.includes(this.params.crypto.toLowerCase())
		});
	}

	private filterByMarket(): void {
		if (this.params.markets === Filter.ALL) return;
		this.bots = this.bots.filter((bot: { market: string }) =>
			bot.market === this.params.markets.toLowerCase()
		);
	}

	private filterByCategory(): void {
		if (!this.params.source) {
			this.params.source = COMPANY;
		};
		this.bots = this.bots.filter((bot: { category: string }) => bot.category.toLowerCase() === this.params.source.toLowerCase());
	}

	private filterByProvider(): void {
		if (this.params.source !== THIRD_PARTY || !this.params.publisherName || this.params.publisherName === Filter.ALL) return;
		this.bots = this.bots.filter((bot: FormatedBot) =>
			bot.publisherName.toLowerCase() === this.params.publisherName.toLowerCase());
	}

	private orderByName(): void {
		this.bots = this.bots.sort((a: { name: string }, b: { name: string }) =>
			a.name.trim() > b.name.trim() ? 1 : -1
		);
	}

	private orderByPerformance(performance: OrderBy): void {
		this.bots = this.bots.sort((bot1: any, bot2: any) =>{
			return (bot2.performance?.performanceAggregates[performance] ?? 0) - (bot1.performance?.performanceAggregates[performance] ?? 0);
		});
	}

	private orderByRecency(): void {
		const botsWithoutLiveDate = this.bots.filter((bot: FormatedBot) => !bot.liveDate);
		this.bots = this.bots.filter((bot: FormatedBot) => bot.liveDate)
			.sort((a, b) => DateTime.fromISO(b.liveDate.toString()).diff(DateTime.fromISO(a.liveDate.toString())).milliseconds);
		this.bots = [...this.bots, ...botsWithoutLiveDate];
	}
}


export function formatBotList(bot: any): FormatedBot {
	return {
		id: bot.id,
		name: bot.name,
		description: bot.description,
		category: bot.category,
		strategyType: bot.strategyType,
		cryptos: bot.cryptos,
		pairing: bot.pairing,
		market: bot.market.toLowerCase(),
		status: bot.status,
		supportedExchanges: bot.supportedExchanges.map((exchange: string) => exchange.split('_')[0].toLowerCase()),
		isCompanySource: bot.category === COMPANY,
		liveDate: bot.liveDate,
		risk: bot.risk,
		performance: bot.performance,
		...(bot.category !== COMPANY && {
			chargebeeAddonUid: bot.chargebeeAddonUid,
			publisherName: bot.publisher.name,
			publisherEmail: bot.publisher.email,
			publisherPresentation: bot.publisher.presentation,
		}),

	};
}
