import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-binance',
  templateUrl: './icon-binance.component.html'
})
export class IconBinanceComponent {
  @Input() class = '';

}
