import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EmptyDataTypeText, loadingOptions,PerformanceDisplayMode } from '@app/core/constants/charts.constants';
import { FormatNumberHelper } from '@app/core/services/performance/format-number.helper';
import { IPerformance } from '@b-cube/interfaces/performance';
import { EChartsOption } from 'echarts';

@Component({
	selector: 'app-chart-line',
	templateUrl: './chart-line.component.html',
})

export class ChartLineComponent implements OnChanges{
	@Input() chartData: IPerformance[];
	@Input() perfDisplayMode: PerformanceDisplayMode;
	@Input() loading = false;
	@Input() isLivePerfMode: boolean;
	@Input() isMetaBot: boolean;

	public loadingOptions = loadingOptions;
	public options: EChartsOption = {};

	constructor(private formatNumberHelper: FormatNumberHelper) { }

	public get isLoading(): boolean {
		return this.loading;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['chartData'] && changes['chartData'].currentValue) {
			this.getChartOptions();
		}
	}

	getChartOptions(): void {
		const isMetaPrecomputedBacktestPerf = this.isMetaBot && !this.isLivePerfMode;
		const data = this.chartData ?? [];
		const datePipe = new DatePipe('en-US');
		const xAxisData = data.map(point => datePipe.transform(point.time, this.perfDisplayMode));
		const yAxisData = data.map(point => isMetaPrecomputedBacktestPerf ? point.value : (point.value - 1) * 100);

		const isEmptyData = xAxisData.length === 0;

		const emptyDataText = this.isLivePerfMode ? EmptyDataTypeText.NO_LIVE_TRADING : EmptyDataTypeText.NO_BACKTEST;

		this.options = <EChartsOption>{
			responsive: true,
			maintainAspectRatio: false,
			title: {
				show: isEmptyData && !this.loading,
				textStyle: {
					color: '#64748B',
					fontSize: 12,
					fontStyle: 'italic'
				},
				text: emptyDataText,
				left: 'center',
				top: 'center'
			},
			tooltip: {
				trigger: "axis",
				show: true,
				textStyle: {
					fontSize: 12
				},
				valueFormatter: (value: number) => this.formatNumberHelper.formatNumber(value, '%'),
			},
			xAxis: {
				show: !isEmptyData,
				type: 'category',
				data: xAxisData,
				axisLabel: {
					color: '#94a3b8',
				},
				axisTick: {
					lineStyle: {
						color: '#cbd5e1',
					},
				},
				axisLine: {
					lineStyle: {
						color: '#cbd5e1',
					},
				},
			},
			yAxis: {
				type: 'value',
				splitNumber: 5,
				axisLabel: {
					formatter: '{value}%',
					color: '#94a3b8',
				},
				axisTick: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
				axisLine: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
				splitLine: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
			},
			grid: {
				left: 6,
				top: 10,
				right: 6,
				bottom: 0,
				containLabel: true
			},
			series: [
				{
					name: 'Bot performance',
					data: yAxisData,
					type: 'line',
					lineStyle: {
						width: 3,
						color: '#23cbd5',
						join: 'round',
					},
					itemStyle: {
						color: '#23cbd5',
						lineStyle: {
							width: 2,
							type: 'solid',
							color: '#23cbd5',
						},
					},
					emphasis: {
						color: 'white',
						lineStyle: {
							width: 2,
							type: 'dotted',
							color: '#23cbd5',
						},
					},
				},
			],
			height: 280
		};
	}
}

