<div *ngIf="!isLoading"
	class="relative bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-lg overflow-hidden">
	<div class="relative w-full min-h-[150px] p-6">
		<div class="flex items-center justify-between mb-4">
			<div class="flex">
				<div class="relative flex justify-center items-center h-10 w-10 rounded-full"
					[ngStyle]="{'background-color': bot.isCompanySource ? 'none' : 'white'}">

					<div *ngIf="bot.isCompanySource; else thirdParty">
						<app-bot-icon name="b-cube-logo-color" class="w-9 h-9"></app-bot-icon>
					</div>

					<ng-template #thirdParty>
						<app-bot-icon [name]="bot['publisherName']"
							[svgClass]="'bg-white rounded-full w-9 h-9'"></app-bot-icon>
					</ng-template>

				</div>
				<div class="flex justify-center items-center ml-2"><span>{{bot.name}}</span></div>
			</div>
			<div class="flex flex-col items-center">
				<span
					class="flex items-center justify-center text-muted-600 dark:text-muted-100 text-xs min-w-[70px] whitespace-nowrap">
					{{BotRiskLabel['' + bot?.risk]}} risk
				</span>
				<bot-pair [bot]="bot" [iconSize]="8"></bot-pair>
			</div>
		</div>
		<div class="flex items-start justify-between text-center">
			<div class="flex-1">
				<label
					class="inline-block font-sans text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">7D
				</label>
				<div>
					<span class="font-sans text-sm font-semibold"
						[class]="getPerformanceClass(PERFORMANCE_HISTORY_RANGE.ONE_WEEK, bot.performance?.performanceAggregates?.weekly)">
						{{formatNumberHelper.formatNumber(bot.performance?.performanceAggregates?.weekly, '%')}}
					</span>
				</div>
			</div>
			<div class="flex-1">
				<label
					class="inline-block font-sans text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">30D
				</label>
				<div>
					<span class="font-sans text-sm font-semibold"
						[class]="getPerformanceClass(PERFORMANCE_HISTORY_RANGE.ONE_MONTH, bot.performance?.performanceAggregates?.monthly)">
						{{formatNumberHelper.formatNumber(bot.performance?.performanceAggregates?.monthly, '%')}}
					</span>
				</div>
			</div>
			<div class="hidden md:block flex-1">
				<label
					class="inline-block font-sans text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">3M
				</label>
				<div>
					<span class="font-sans text-sm font-semibold"
						[class]="getPerformanceClass(PERFORMANCE_HISTORY_RANGE.THREE_MONTHS, bot.performance?.performanceAggregates?.quarterly)">
						{{formatNumberHelper.formatNumber(bot.performance?.performanceAggregates?.quarterly, '%')}}
					</span>
				</div>
			</div>
			<div class="flex-1">
				<label
					class="inline-block font-sans text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">Overall
				</label>
				<div>
					<span class="font-sans text-sm font-semibold"
						[class]="getPerformanceClass(PERFORMANCE_HISTORY_RANGE.ALL_HISTORY, bot.performance?.performanceAggregates?.overall)">
						{{formatNumberHelper.formatNumber(bot.performance?.performanceAggregates?.overall, '%')}}
					</span>
				</div>
			</div>
		</div>
	</div>
	<app-chart-area-card [chartData]="bot.performance?.performance"></app-chart-area-card>
</div>
<div *ngIf="isLoading" role="status"
	class="relative bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-lg overflow-hidden animate-pulse">
	<div class="relative w-full min-h-[144px] p-6">
		<div class="flex items-center rounded-md mb-4 space-x-2">
			<div class="flex-0 w-10 h-10 bg-brand/20 rounded-full dark:bg-brand/20"></div>
			<div class="flex-1 h-6 bg-brand/20 rounded-md dark:bg-brand/20"></div>
		</div>
		<div class="flex items-start justify-around text-center">
			<div *ngFor="let i of [0,1,2,3]" class="flex-0 flex flex-col w-14 rounded-md mt-1 mb-1 gap-y-3">
				<div class="flex-0 h-4 bg-brand/20 rounded-md dark:bg-brand/20"></div>
				<div class="flex-0 h-4 bg-brand/20 rounded-md dark:bg-brand/20"></div>
			</div>
		</div>
	</div>
	<app-chart-area-card [chartData]="FAKE_CARD_PERFORMANCE" [fake]="true"></app-chart-area-card>
</div>
