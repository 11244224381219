import { Component, OnInit } from '@angular/core';
import { UserService } from '@core/services/user.service';
import { environment } from '@env/environment';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TrackerService } from './core/services/tracker.service';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	public title = 'b-cube.ai';
	public isAuthenticated = false;
	public matomoScript: string = null;

	constructor(private userService: UserService, private trackerService: TrackerService, private router: Router) { }

	ngOnInit(): void {
		this.router.events.pipe(
			filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			this.excuteTrackerScript();
		});
		
		this.userService.currentUser.subscribe(() => {
			this.isAuthenticated = true;
		});
	}
	excuteTrackerScript() {
		if (environment.production) {
			const script = document.createElement('script');
			script.innerHTML = this.trackerService.getTrackerInitScript(environment.matomoCDN, environment.matomoContainer);
			document.head.appendChild(script);
		}
	}

}
