import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-toggler',
  templateUrl: './theme-toggler.component.html',
})
export class ThemeTogglerComponent implements OnInit {
  isDark = false;

  ngOnInit(): void {
    this.isDark = document.documentElement.classList.contains('dark')
  }

  toggle(): void {
    this.isDark = !this.isDark;
    if (this.isDark) {
      localStorage.setItem('bcube-color-scheme', 'dark');
      document.documentElement.classList.add('dark');
    } else {
      localStorage.setItem('bcube-color-scheme', 'light');
      document.documentElement.classList.remove('dark');
    }
  }
}
