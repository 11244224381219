<svg
  width="21"
  height="21"
  viewBox="0 0 21 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18.5289 0.639648H2.91901C1.84583 0.639648 0.97753 1.53965 0.97753 2.63965L0.967773 20.6396L4.87025 16.6396H18.5289C19.6021 16.6396 20.4801 15.7396 20.4801 14.6396V2.63965C20.4801 1.53965 19.6021 0.639648 18.5289 0.639648ZM15.602 12.6396H5.84587C5.30928 12.6396 4.87025 12.1896 4.87025 11.6396C4.87025 11.0896 5.30928 10.6396 5.84587 10.6396H15.602C16.1386 10.6396 16.5777 11.0896 16.5777 11.6396C16.5777 12.1896 16.1386 12.6396 15.602 12.6396ZM15.602 9.63965H5.84587C5.30928 9.63965 4.87025 9.18965 4.87025 8.63965C4.87025 8.08965 5.30928 7.63965 5.84587 7.63965H15.602C16.1386 7.63965 16.5777 8.08965 16.5777 8.63965C16.5777 9.18965 16.1386 9.63965 15.602 9.63965ZM15.602 6.63965H5.84587C5.30928 6.63965 4.87025 6.18965 4.87025 5.63965C4.87025 5.08965 5.30928 4.63965 5.84587 4.63965H15.602C16.1386 4.63965 16.5777 5.08965 16.5777 5.63965C16.5777 6.18965 16.1386 6.63965 15.602 6.63965Z"
    fill="currentColor"
  />
</svg>
