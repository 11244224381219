import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-chart-area-mini',
  templateUrl: './chart-area-mini.component.html',
})
export class ChartAreaMiniComponent implements OnInit {
  options: EChartsOption = {};

  ngOnInit(): void {
    this.options = <EChartsOption>{
      xAxis: {
        show:false,
        type: 'category',
        boundaryGap: false,
        data: [
          'Mon',
          '12:00',
          'Tue',
          '12:00',
          'Wed',
          '12:00',
          'Thu',
          '12:00',
          'Fri',
          '12:00',
          'Sat',
          '12:00',
          'Sun',
        ],
        axisLabel: {
          textStyle: {
            color: '#94a3b8',
          },
        },
        axisTick: {
          lineStyle: {
            color: '#cbd5e1',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#cbd5e1',
          },
        },
      },
      yAxis: {
        show: false,
        type: 'value',
      },
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      series: [
        {
          name: 'Balance',
          data: [
            310, 350, 400, 590, 360, 720, 340, 560, 320, 780, 1250, 740, 1024,
          ],
          showSymbol: false,
          type: 'line',
          // smooth: true,
          areaStyle: {
            color: 'transparent'
          },
          lineStyle: {
            width: 2,
            color: '#22C55E',
            join: 'round',
          },
          itemStyle: {
            normal: {
              color: '#22C55E',
              lineStyle: {
                width: 2,
                type: 'solid',
                color: '#22C55E',
              },
            },
          },
        },
      ],
      height: 20,
      width: 90,
    };
  }
}
