import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BOT_SETTINGS_DISPLAY } from '@app/core/constants/bots.constants';
import { ERROR_OCCURED } from '@app/core/constants/error-messages';
import { UserBotSettingsService } from '@app/core/services/user-bot-settings.service';
import { Settings } from '@b-cube/database/types';
import { IBot } from '@b-cube/interfaces/bot';
import { BotSettingsScale, IBotSettings, IBotSettingsWrapper } from '@b-cube/interfaces/user-bot-settings';
import { ToastrService } from 'ngx-toastr';
import { catchError, of } from 'rxjs';

@Component({
	selector: 'app-widget-bot-settings-modal',
	templateUrl: './widget-bot-settings-modal.component.html',
	styleUrls: ['./widget-bot-settings-modal.component.css'],
})
export class WidgetBotSettingsModalComponent implements OnInit {
	@Input() botSettingsWrapper: IBotSettingsWrapper;
	@Input() bot: IBot;
	// eslint-disable-next-line
	@Output() close = new EventEmitter<any>();

	public botSettingsDisplay: { [key in Settings]?: {
		scale: BotSettingsScale, label: string, minValue: number, maxValue: number
	} } = BOT_SETTINGS_DISPLAY;
	public isConfirmLoading = false;
	private initialSettings: {
		botSettings: {
			type: Settings,
			label: string,
			settingValue: number,
			minValue: number,
			maxValue: number,
			scale: BotSettingsScale,
			settingsBoxScaleValue: number
		}[]
	};

	public form: FormGroup = null;

	constructor(private fb: FormBuilder, private toasterService: ToastrService, private userBotSettingsService: UserBotSettingsService) { }

	ngOnInit(): void {
		this.form = this.fb.group({
			botSettings: this.fb.array([])
		});
		this.initBotSettings();
	}

	private initBotSettings() {
		// eslint-disable-next-line
		for (const botSettings of this.botSettingsWrapper?.botSettings) {
			const botSetting = this.fb.group({
				type: [botSettings.type],
				label: [this.botSettingsDisplay[botSettings.type]?.label],
				settingValue: [botSettings.value, Validators.required],
				minValue: [this.botSettingsDisplay[botSettings.type]?.minValue],
				maxValue: [this.botSettingsDisplay[botSettings.type]?.maxValue],
				scale: [this.botSettingsDisplay[botSettings.type]?.scale],
				settingsBoxScaleValue: [botSettings.value, Validators.required]
			})
			this.getBotSettings().push(botSetting);
		}
		this.initialSettings = this.form.value;
	}

	public getBotSettings(): FormArray {
		return this.form.get('botSettings') as FormArray;
	}

	public getBotSettingsAt(index: number) {
		return this.getBotSettings().at(index) as FormGroup;
	}

	public submit(): void {
		const exceededLimit: boolean = this.form.value.botSettings.some((settings: any) => (settings.settingValue > settings.maxValue)
			|| (settings.settingValue < settings.minValue));
		if (exceededLimit) {
			this.toasterService.error(`The values are not in their bounds`)

			return;
		}
		if (!this.form.invalid) {
			this.isConfirmLoading = true;
			const botSettingsWrapper: IBotSettingsWrapper = this.mapToBotSettingsWrapper(this.form.value.botSettings);
			this.userBotSettingsService.updateUserBotSettings(this.bot.id, botSettingsWrapper).pipe(
				catchError(err => {
					this.toasterService.error(`The settings cannot be updated`, ERROR_OCCURED);

					return of(err);
				})
			).subscribe(err => {
				if (!err) {
					this.toasterService.success(`The settings has been updated`)
				}
				this.isConfirmLoading = false;
				this.close.emit(true);
			}
			)
		}
	}

	public setDefaultSettings(): void {
		this.form.reset(this.initialSettings);
	}

	public onInputBoxChange(event: any, index: number): void {
		this.getBotSettingsAt(index).patchValue({
			settingValue: event.target.value,
		})
	}

	public onSliderValueChange(value: number, index: number): void {
		this.getBotSettingsAt(index).patchValue({
			settingValue: value,
			settingsBoxScaleValue: value
		})
		if (this.getBotSettingsAt(index).value.type === Settings.FIRST_ENTRY_WEIGHT) {
			const settings = this.getBotSettings().value.find((setting: any) => setting.type === Settings.SECOND_ENTRY_WEIGHT);
			settings.settingValue = this.getMissingNumberToMax(this.getBotSettingsAt(index).value.settingValue, settings.maxValue);
			settings.settingsBoxScaleValue = this.getMissingNumberToMax(this.getBotSettingsAt(index).value.settingValue, settings.maxValue);
		}
		if (this.getBotSettingsAt(index).value.type === Settings.SECOND_ENTRY_WEIGHT) {
			const settings = this.getBotSettings().value.find((setting: any) => setting.type === Settings.FIRST_ENTRY_WEIGHT);
			settings.settingValue = this.getMissingNumberToMax(this.getBotSettingsAt(index).value.settingValue, settings.maxValue);
			settings.settingsBoxScaleValue = this.getMissingNumberToMax(this.getBotSettingsAt(index).value.settingValue, settings.maxValue);
		}
	}

	private mapToBotSettingsWrapper(botSettingsForm: { type: Settings, settingValue: number }[]): IBotSettingsWrapper {
		const botSettings: IBotSettings[] = [];
		for (const setting of botSettingsForm) {
			botSettings.push({
				type: setting.type,
				value: setting.settingValue
			})
		}

		return { botSettings } as IBotSettingsWrapper;
	}

	private getMissingNumberToMax(value: number, max: number): number {
		return max - value;
	}

	public dismiss(): void {
		this.close.emit(null);
	}
}
