import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StakingService } from '@app/core/services/staking.service';
import { IBotWithStatus } from '@b-cube/interfaces/bot';
import { IPaymentBCubeDetails, PAYMENT_TYPE_TOPUP } from '@b-cube/interfaces/payment';
import { BotPlan, IBotSubscription } from '@b-cube/interfaces/plan';
import { IUserBot } from '@b-cube/interfaces/user-bot';

@Component({
	selector: 'app-widget-bot-controls-third-party',
	templateUrl: './widget-bot-controls-third-party.component.html',
	animations: [
		trigger(
			'insertRemoveTrigger',
			[
				transition(
					':enter',
					[
						style({ height: 0, opacity: 0 }),
						animate('80ms',
							style({ height: 300, opacity: 1 }))
					]
				)
			]
		)
	]
})
export class WidgetBotControlsThirdPartyComponent implements OnInit, OnChanges {
	@Input() isEnabled: boolean;
	@Input() isLoading: boolean;
	@Input() bot: IBotWithStatus;
	@Input() botId: number;
	@Input() botPlan: BotPlan;
	@Input() userBotSubscription: IBotSubscription;

	@Output() updateBotStatus: EventEmitter<number> = new EventEmitter();
	@Output() deleteUserBot: EventEmitter<number> = new EventEmitter();
	// eslint-disable-next-line
	@Output() close = new EventEmitter<IUserBot>();

	@HostBinding('class') classes = 'flex flex-col gap-y-3';

	public isSubscriptionLoading = true;
	public isCreditCard: boolean;
	public isModalOpen = false;
	public bcubePaymentDetails: IPaymentBCubeDetails;
	public paymentMethod: PAYMENT_TYPE_TOPUP;
	public months = 1;

	constructor(
		private stakingService: StakingService,
	) { }

	ngOnInit(): void {
		this.paymentMethod = PAYMENT_TYPE_TOPUP.TOPUP;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes['userBotSubscription'] && this.userBotSubscription) {
			this.isCreditCard = this.userBotSubscription.isCreditCard || false;
			this.isSubscriptionLoading = false;
		}
		if (changes['botPlan'] && this.botPlan) {
			this.getBCUBEPaymentDetails();
		}
	}

	public updateStatus(botId: number) {
		this.updateBotStatus.emit(botId);
	}

	public removeBot(botId: number) {
		this.deleteUserBot.emit(botId);
	}

	public openModal() {
		this.isModalOpen = true;
	}

	public closeAddBot(userBot: IUserBot) {
		this.isModalOpen = false;
		this.close.emit(userBot);
	}

	private getBCUBEPaymentDetails(): void {
		this.stakingService.getBotPlanBCubePaymentDetails(this.botPlan?.id).subscribe(
			(details) => {
				this.bcubePaymentDetails = details;
				this.isLoading = false;
			}
		);
	}

}
