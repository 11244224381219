import { KYCStatus } from '@b-cube/database/types';

import { Plan } from '../plan';
import { Tier } from '../staking';
import { CurrencyIso, LanguageIso } from '.';

export interface UserSecurity{
	twoFactorEnabled: boolean,
	identityVerificationStatus?: KYCStatus,
	isRestricted: boolean,
	kycRequired: boolean
}

export interface UserInfo {
	id: number,
	username?: string,
	email: string;
	emailVerified: boolean;
	preferredLanguageIso: LanguageIso,
	preferredCurrencyIso: CurrencyIso,
	isNewsletter: boolean,
	isSignedTerms: boolean,
	plan: Plan,
	tier: Tier,
	security?: UserSecurity
}

export interface UserFullInfo {
	active: boolean;
	connectorId: string;
	data: {
		isSignedTerms: boolean;
		isNewsletter: boolean;
	};
	email: string;
	firstName: string;
	id: string;
	insertInstant: number;
	lastLoginInstant: number;
	lastName: string;
	lastUpdateInstant: number;
	memberships: any[]; // Update this to the correct type
	passwordChangeRequired: boolean;
	passwordLastUpdateInstant: number;
	preferredLanguages: any[]; // Update this to the correct type
	registrations: { [key: string]: any }[];
	tenantId: string;
	twoFactor: {
		methods: any[]; // Update this to the correct type
		ecoveryCodes: any[]; // Update this to the correct type
	};
	username:string;
	usernameStatus: string;
	verified: boolean;
}
