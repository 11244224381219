<svg
  width="20"
  height="21"
  viewBox="0 0 20 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.5924 11.6035C17.6324 11.2831 17.6624 10.9627 17.6624 10.6223C17.6624 10.2819 17.6324 9.96155 17.5924 9.64117L19.7024 7.98919C19.8924 7.83901 19.9424 7.56868 19.8224 7.34842L17.8224 3.88427C17.7024 3.66401 17.4324 3.58391 17.2124 3.66401L14.7224 4.66521C14.2024 4.26473 13.6424 3.93433 13.0324 3.68403L12.6524 1.03086C12.6224 0.790567 12.4124 0.610352 12.1624 0.610352H8.16244C7.91244 0.610352 7.70244 0.790567 7.67244 1.03086L7.29244 3.68403C6.68244 3.93433 6.12244 4.27474 5.60244 4.66521L3.11244 3.66401C2.88244 3.5739 2.62244 3.66401 2.50244 3.88427L0.502437 7.34842C0.372437 7.56868 0.432437 7.83901 0.622437 7.98919L2.73244 9.64117C2.69244 9.96155 2.66244 10.2919 2.66244 10.6223C2.66244 10.9527 2.69244 11.2831 2.73244 11.6035L0.622437 13.2555C0.432437 13.4057 0.382437 13.676 0.502437 13.8963L2.50244 17.3604C2.62244 17.5807 2.89244 17.6608 3.11244 17.5807L5.60244 16.5795C6.12244 16.98 6.68244 17.3104 7.29244 17.5607L7.67244 20.2138C7.70244 20.4541 7.91244 20.6343 8.16244 20.6343H12.1624C12.4124 20.6343 12.6224 20.4541 12.6524 20.2138L13.0324 17.5607C13.6424 17.3104 14.2024 16.9699 14.7224 16.5795L17.2124 17.5807C17.4424 17.6708 17.7024 17.5807 17.8224 17.3604L19.8224 13.8963C19.9424 13.676 19.8924 13.4057 19.7024 13.2555L17.5924 11.6035ZM10.1624 14.1265C8.23244 14.1265 6.66244 12.5547 6.66244 10.6223C6.66244 8.69003 8.23244 7.11815 10.1624 7.11815C12.0924 7.11815 13.6624 8.69003 13.6624 10.6223C13.6624 12.5547 12.0924 14.1265 10.1624 14.1265Z"
    fill="currentColor"
  />
</svg>
