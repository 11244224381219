import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IAllocation } from '@b-cube/interfaces/exchange-account';
import { COLOR_PALETTE } from '@core/constants/charts.constants';

import { PieUnit, RingData } from '../../chart/chart-ring/chart-ring.component';



@Component({
	selector: 'app-widget-wallet-split',
	templateUrl: './widget-wallet-split.component.html'
})
export class WidgetWalletSplitComponent implements OnChanges{
	@Input() allocation: IAllocation[];
	@Input() usdBalance: number;
	colorPalette = COLOR_PALETTE;
	PieUnit = PieUnit;
	ringData: RingData[];

	isEmptyAccount(): boolean {
		return this.allocation !==null && this.allocation.length === 0;
	}

	ngOnChanges(changes: SimpleChanges){
		if(changes['allocation'] && this.allocation){
			this.ringData = this.allocation.map(({ usdQuote, asset }) => ({ value: usdQuote, name: asset }))
		}

	}
}
