import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { CustomReuseStrategy } from '@core/strategies/route-reuse.strategy';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CoreModule,
		NgxSliderModule,
		HttpClientModule,
		ToastrModule.forRoot()
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
