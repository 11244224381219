import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-help',
  templateUrl: './icon-help.component.html'
})
export class IconHelpComponent {
  @Input() class = '';

}
