import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FINAL_CREDIT_CARD_STEP } from '@app/core/constants/plan.constants';
import { PLAN_SUBSCR_STEP, PLAN_SUBSCR_WIZARD_BUTTONS_LABELS } from '@app/core/constants/plan-pricing-wizard';
import { Routes } from '@app/core/constants/router.constants';
import { SettingsTabs } from '@app/core/constants/settings.constants';
import { TokenContractEvent } from '@app/core/constants/staking.constants';
import { ConfirmMessages } from '@app/core/constants/subscriptions/confirm-messages';
import { PlanSubscriptionErrorCodes, TransactionErrorCodes } from '@app/core/constants/subscriptions/error-codes';
import { CommonErrorMessages, PlanSubscriptionErrorMessages, SubscriptionRetryErrorMessages, TransactionErrorMessages } from '@app/core/constants/subscriptions/error-messages';
import { TransactionSuccessMessages } from '@app/core/constants/subscriptions/success-messages';
import { CommonWarningMessages, SunscriptionWarningMessages } from '@app/core/constants/subscriptions/warning-messages';
import { COUNTRIES_RESTRICTION_TEXT, PLAN_STAKING_HELP_TEXT, PLAN_TIPS_TEXT } from '@app/core/constants/tips.constants';
import { BcubeContractService } from '@app/core/services/bcube-contract.service';
import { CryptoTransactionService } from '@app/core/services/crypto-transaction-service';
import { DialogService } from '@app/core/services/dialog.service';
import { EthereumService } from '@app/core/services/ethereum/ethereum.service';
import { ExchangeAccountService } from '@app/core/services/exchange-account.service';
import { PlanService } from '@app/core/services/plan.service';
import { SecurityService } from '@app/core/services/security.service';
import { StakingService } from '@app/core/services/staking.service';
import { UserService } from '@app/core/services/user.service';
import { TransactionStatus } from '@b-cube/database/types';
import { IPaymentBCubeDetails } from '@b-cube/interfaces/payment';
import { CHARGEBEE_PLAN, ISubscription, Plan, SubscriptionType, TransactionType, UNLIMITED_TRADING_LIMIT } from '@b-cube/interfaces/plan';
import { Tier } from '@b-cube/interfaces/staking';
import { PaymentMethod, RestrictedServices, UserInfo } from '@b-cube/interfaces/user';
import { environment } from '@env/environment';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { PlanDifference, SPECIAL_VALUES } from '@shared/types/plan-diffrence';
import { ToastrService } from 'ngx-toastr';
import { catchError, combineLatest, lastValueFrom, map, mergeMap, Observable, of, retry, Subscription, tap } from 'rxjs';

declare const Chargebee: any;

@Component({
	selector: 'app-widget-pricing-wizard',
	templateUrl: './widget-pricing-wizard.component.html',
})
export class WidgetPricingWizardComponent implements OnInit, OnDestroy {
	@Input() targetPlanId: string = null;
	@Input() currentStep = 0;
	isChecked = true;
	isLoadingBack = false;
	isLoadingNext = false;
	selectedAddons: string[] = [];
	paymentMethod: PaymentMethod = PaymentMethod.CREDIT_CARD;
	bcubePaymentDetails: IPaymentBCubeDetails;

	UNLIMITED_TRADING_LIMIT = UNLIMITED_TRADING_LIMIT;
	PLAN_TIPS_TEXT = PLAN_TIPS_TEXT;
	PLAN_STAKING_HELP_TEXT = PLAN_STAKING_HELP_TEXT;
	COUNTRIES_RESTRICTION_TEXT = COUNTRIES_RESTRICTION_TEXT;

	planSubscription: Subscription;
	subscriptionSubscription: Subscription;
	userSubscription: Subscription;

	// user
	user: UserInfo;
	user$: Observable<UserInfo>;
	userTierProgress$: Observable<any>;
	initials$: Observable<string>;

	// plans
	plans$: Observable<Plan[]>;
	creditCardPlans: Plan[];

	// current plan
	currentPlanPrice$: Observable<number>;
	currentPlan: Plan;
	currentSubscription: ISubscription;
	isCreditCard: boolean;

	currentTier: Tier;

	// trading volume
	tradingVolume$: Observable<number>;
	tradingPourcent$: Observable<number>;

	planDifference$: Observable<PlanDifference>;

	// BCUBE form
	months = 1;

	private paymentDone = false;
	private unwatchTokenContractEvent: any;
	private walletAddressSubscription: Subscription;
	public wallet: string;

	public Routes = Routes;
	public SettingsTabs = SettingsTabs;

	constructor(
		private userService: UserService,
		public securityService: SecurityService,
		private planService: PlanService,
		private stakingService: StakingService,
		private toasterService: ToastrService,
		private bcubeContractService: BcubeContractService,
		private router: Router,
		private exchangeAccountService: ExchangeAccountService,
		private readonly dialogService: DialogService,
		private readonly ethereumService: EthereumService,
		private zone: NgZone,
		private cryptoTxService: CryptoTransactionService,
	) { }

	ngOnInit(): void {
		// user
		this.user$ = this.userService.currentUser;
		this.userSubscription = this.userService.currentUser.subscribe(
			user => this.user = user
		);


		this.userTierProgress$ = this.userService.getUserPlanProgress();
		this.initials$ = this.userService.getCurrentUserInitials();
		this.subscriptionSubscription = this.planService.subscription.subscribe(subscription => {
			this.currentSubscription = subscription;
		});

		// plans
		this.planService.plans.subscribe(plans => {
			this.creditCardPlans = plans;
		});

		// current plan
		this.currentPlanPrice$ = this.userService.currentUser.pipe(
			map(user => {
				if (user === null) {
					return 0;
				}

				return user.plan.price;
			})
		);

		this.planSubscription = this.userService.currentUser.subscribe((user: UserInfo) => {
			if (user === null) {
				return;
			}

			this.currentPlan = user.plan;
			this.currentTier = user.tier;
			this.targetPlanId = user.plan.id;
		});

		// trading volume
		this.tradingVolume$ = this.exchangeAccountService.totalInvestment;
		this.tradingPourcent$ = combineLatest([
			this.userService.currentUser,
			this.tradingVolume$
		]).pipe(
			map(([user, tradingVolume]) => user === null ? 0 : Math.round(100 * tradingVolume / user.plan.tradingLimit)),
			catchError((e: { message: string; }) => {
				// eslint-disable-next-line
				console.error('ERROR: ' + e.message);

				return of(null);
			})
		);

		this.initWallet();
	}

	ngOnDestroy() {
		this.planSubscription.unsubscribe();
		this.subscriptionSubscription.unsubscribe();
		this.userSubscription.unsubscribe();
		this.walletAddressSubscription.unsubscribe();
	}

	public canUseService(): boolean{
		return this.securityService.canUseServices(this.user, RestrictedServices.PLAN);
	}

	private initWallet(): void {
		this.walletAddressSubscription = this.stakingService.getWallet().subscribe(async wallet => {
			this.wallet = wallet;
		});
	}

	async incrementStep() {
		if (this.isLoadingNext) {
			return;
		}

		if(! this.canUseService()){
			this.toasterService.warning(SunscriptionWarningMessages.SUBSCRIPTION_NOT_ALLOWED);

			return;
		}

		if (!this.checkStep()) {
			return;
		}

		if (this.isFinalStep()) {
			if (this.isFreemium(this.getTargetPlan()) && this.selectedAddons.length === 0) {
				this.goFreemium();
			} else if (this.paymentMethod === PaymentMethod.CREDIT_CARD) {
				this.payByCreditCard();
			} else {
				this.payWithBCUBE();
			}

			return;
		}

		this.isLoadingNext = true;
		this.currentStep = this.currentStep + 1;
		await this.initStep();
		this.isLoadingNext = false;
	}

	private async initStep(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (this.currentStep === PLAN_SUBSCR_STEP.PLAN_SUMMARY && this.getTargetPlan().id === this.currentPlan.id) {
				this.selectedAddons = this.getSelectedAddonIdsFromSubscription();
			}

			if (this.currentStep === PLAN_SUBSCR_STEP.PAYMENT_METHOD) {
				this.stakingService.getPlanBCubePaymentDetails(this.targetPlanId, this.selectedAddons).subscribe(details => {
					this.bcubePaymentDetails = details
					resolve(true);
				});
			} else {
				setTimeout(async () => {
					resolve(true);
				}, 500);
			}
		});
	}

	checkStep(): boolean {
		const targetPlan = this.getTargetPlan();
		switch (this.currentStep) {
			case PLAN_SUBSCR_STEP.PLAN_SELECTOR:
				if (this.currentSubscription !== null && !this.currentSubscription.isCreditCard) {
					this.toasterService.warning(CommonWarningMessages.CANNOT_MODIFY_SUBSCRIPTION);

					return false;
				}


				if (targetPlan === undefined) {
					this.toasterService.warning(CommonWarningMessages.PLEASE_SELECT_A_PLAN);

					return false;
				}

				if (targetPlan.id === this.currentPlan.id && targetPlan.addons.length === 0) {
					this.toasterService.warning(CommonWarningMessages.SELECT_DIFFERENT_PLAN);

					return false;
				}

				break;
			case PLAN_SUBSCR_STEP.PLAN_SUMMARY:
				// eslint-disable-next-line
				const initialSelectedAddons = this.getSelectedAddonIdsFromSubscription();
				if (this.getTargetPlan().addons.length > 0 &&
					targetPlan.id === this.currentPlan.id &&
					this.compareAddons(initialSelectedAddons)) {
					this.toasterService.warning(CommonWarningMessages.SUBSCRIBED_TO_SAME_PLAN);

					return false;
				}
				break;
			case PLAN_SUBSCR_STEP.PAYMENT_DETAILS:
				if (this.paymentMethod === PaymentMethod.BCUBE && !this.months) {
					this.toasterService.warning(CommonWarningMessages.ENTER_VALID_MONTHS);

					return false;
				}
				break;
			default:
				break;
		}

		return true;
	}

	isFinalStep(): boolean {
		// Special case freemium
		if (this.isFreemium(this.getTargetPlan()) && this.selectedAddons.length === 0 && this.currentStep === PLAN_SUBSCR_STEP.PLAN_SUMMARY) {
			return true;
		}

		return this.currentStep === FINAL_CREDIT_CARD_STEP;
	}

	private getSelectedAddonIdsFromSubscription(): string[] {
		return this.getTargetPlan().addons.filter(addon => {
			return this.currentSubscription?.subscriptionItems?.find(subItem => subItem.itemPriceId === addon.itemPriceId) !== undefined
		}).map(addon => addon.id);
	}

	private compareAddons(addons: string[]): boolean {
		if (addons.length !== this.selectedAddons.length) {
			return false;
		}

		addons.sort();
		this.selectedAddons.sort();

		return addons.every((addon, key) => addon === this.selectedAddons[key]);
	}

	decrementStep() {
		this.isLoadingBack = true;
		setTimeout(() => {
			if (this.currentStep > 0) {
				this.currentStep = this.currentStep - 1;
				this.isLoadingBack = false;
			}
		}, 500);
	}

	public isFreemium(plan: Plan): boolean {
		return plan?.id === CHARGEBEE_PLAN.FREEMIUM;
	}

	public getCurrentPlan(): Plan {
		if ((this.creditCardPlans ?? null) === null) {
			return null;
		}

		return this.creditCardPlans.find(plan => plan.id === this.targetPlanId);
	}

	public getTargetPlan(): Plan {
		if ((this.creditCardPlans ?? null) === null) {
			return null;
		}

		return this.creditCardPlans.find(plan => plan.id === this.targetPlanId);
	}

	public getSelectedAddonsName(): string[] {
		return this.getTargetPlan().addons
			.filter(addon => this.selectedAddons.includes(addon.id))
			.map(addon => addon.name);
	}

	public getFinalCCPrice(): number {
		return this.getTargetPlan().addons
			.filter(addon => this.selectedAddons.includes(addon.id))
			.reduce((total, addon) => total + addon.price, this.getTargetPlan().price)
	}

	public getFinalCCPriceWithDiscount(): number {
		return this.getFinalCCPrice() * (100 - this.currentTier.planDiscountForCC) / 100;
	}

	public get bcubeFinalPrice(): number {
		const priceDiscount = this.bcubePaymentDetails?.priceDiscount || 0;
		const result = this.months * priceDiscount;

		return Number(result.toFixed(2));
	}

	public getPlanDifference(): PlanDifference {
		const targetPlan = this.getTargetPlan();

		if (targetPlan === undefined || this.currentPlan === null) {
			return null;
		}

		let tradingDiff;
		if (targetPlan.tradingLimit === UNLIMITED_TRADING_LIMIT) {
			tradingDiff = SPECIAL_VALUES.PLUS_INFINITY;
		} else if (this.currentPlan.tradingLimit === UNLIMITED_TRADING_LIMIT) {
			tradingDiff = SPECIAL_VALUES.MINUS_INFINITY;
		} else {
			tradingDiff = (targetPlan.tradingLimit - this.currentPlan.tradingLimit) / 1000;
		}

		let diffPrice = targetPlan.price ?? 0;
		diffPrice -= this.currentPlan.price ?? 0;

		return <PlanDifference>{
			tradingLimit: tradingDiff,
			stakedTokens: 0,
			price: diffPrice
		}
	}

	private getSelectedAddonsPriceItemIds(): string[] {
		return this.getTargetPlan().addons
			.filter(addon => this.selectedAddons.includes(addon.id))
			.map(addon => addon.itemPriceId);
	}

	private payByCreditCard() {
		if(! this.canUseService()){
			this.toasterService.warning(SunscriptionWarningMessages.SUBSCRIPTION_NOT_ALLOWED);

			return;
		}

		this.isLoadingNext = true;

		const chargebeeInstance = Chargebee.init({
			site: environment.chargebeeSite,
			enableRefersionTracking: true
		});

		chargebeeInstance.openCheckout({
			hostedPage: () => {
				return lastValueFrom(this.planService.getHostedPage(this.targetPlanId, this.getSelectedAddonsPriceItemIds()))
			},
			error: (_error: any) => {
				this.toasterService.error(CommonErrorMessages.UNABLE_REACH_PAYMENT_SERVICE);
				this.isLoadingNext = false;
			},
			success: (_hostedPageId: any) => {
				this.userService.updatePlanLocaly(this.getTargetPlan());
				this.paymentDone = true;
			},
			close: () => {
				if (this.paymentDone) {
					this.zone.run(() => {
						this.paymentDone = false;
						this.planService.loadSubscription();
						this.router.navigate([Routes.PLAN]);
					});
					setTimeout(() => {
						this.toasterService.success(TransactionSuccessMessages.TRANSACTION_SUCCESSFUL);
					}, 1000);
				} else {
					this.zone.run(() => {
						this.isLoadingNext = false;
					});
				}
			}
		});
	}

	private async goFreemium(): Promise<void> {
		if (! await this.dialogService.confirm(ConfirmMessages.RESET_AND_BACK_FREEMIUM, 'Reset subscription')) {
			return;
		}

		this.isLoadingNext = true;
		try {
			await this.userService.resetPlan();
		} catch (e) {
			this.toasterService.error(CommonErrorMessages.UNABLE_UPDATE_PLAN);
			this.isLoadingNext = false;

			return;
		}

		this.isLoadingNext = false;

		this.planService.loadSubscription();

		this.router.navigate([`/${Routes.PLAN}`]);
	}

	private async initializeBcubePaymentTransaction(amount: number): Promise<TransactionResponse> {
		try {
			return await this.bcubeContractService.sendBCubeToPaymentWallet(amount);
		} catch (error: any) {
			if (error.reason) {
				throw { code: TransactionErrorCodes.TRANSACTION_FAILED, message: error.reason }
			} else {
				throw { code: TransactionErrorCodes.TRANSACTION_FAILED, message: TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED] }
			}
		}
	}

	private async createCryptoPendingTransaction(tx: TransactionResponse, transactionType: TransactionType, amount: number): Promise<void> {
		try {
			await this.cryptoTxService.createCryptoTransaction({
				planId: this.getTargetPlan().id,
				status: TransactionStatus.PENDING,
				paymentMethod: PaymentMethod.BCUBE,
				price: amount,
				months: this.months,
				ethWalletAddress: tx.from,
				ethTransaction: tx.hash,
				addons: this.getSelectedAddonsPriceItemIds(),
				subscriptionType: SubscriptionType.PLAN,
				transactionType: transactionType,
			});
		} catch (error: any) {
			throw { code: TransactionErrorCodes.TRANSACTION_CREATION_FAILED, message: TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED] }
		}
	}

	private async confirmUserPlanUpdate(): Promise<Plan> {
		const MAX_ATTEMPTS = 8;
		const RETRY_INTERVAL_MS = 5000;
		let currentAttempt = 0;

		return await lastValueFrom(
			this.userService.getUser().pipe(
				mergeMap((user: UserInfo) => {
					if (user && user.plan.id === this.getTargetPlan().id) {
						return of(user.plan);
					} 
						throw new Error(SubscriptionRetryErrorMessages.PENDING_TRANSACTION);
					
				}),
				tap({
					error: _error => {
						if (currentAttempt < MAX_ATTEMPTS) {
							currentAttempt++;
						} else {
							throw new Error(SubscriptionRetryErrorMessages.RETRY_LIMIT_EXCEEDED);
						}
					}
				}),
				retry({ count: MAX_ATTEMPTS, delay: RETRY_INTERVAL_MS }),
				catchError(err => {
					if (err.message === SubscriptionRetryErrorMessages.RETRY_LIMIT_EXCEEDED) {
						this.bcubeContractService.closeLoader();
						throw {
							code: PlanSubscriptionErrorCodes.PLAN_SUBSCRIPTION_PENDING_TRANSACTION,
							message: PlanSubscriptionErrorMessages[PlanSubscriptionErrorCodes.PLAN_TOPUP_PENDING_TRANSACTION]
						};
					}
					throw err;
				})
			)
		);
	}

	private displayPaymentError(error: { code: TransactionErrorCodes, message: string }): void {
		let errorMessage: string;

		switch (error.code) {
			case TransactionErrorCodes.TRANSACTION_CREATION_FAILED:
			case TransactionErrorCodes.TRANSACTION_FAILED:
				errorMessage = error.message;
				break;

			default:
				errorMessage = TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED];
				break;
		}

		this.toasterService.error(errorMessage);
	}

	private async payWithBCUBE(): Promise<void> {
		if(! this.canUseService()){
			this.toasterService.warning(SunscriptionWarningMessages.SUBSCRIPTION_NOT_ALLOWED);

			return;
		}

		this.isLoadingNext = true;
		this.unwatchTokenContractEvent = this.bcubeContractService.watchTokenContractEvent(TokenContractEvent.TRANSFER, this.wallet);

		try {
			const tx = await this.initializeBcubePaymentTransaction(this.bcubeFinalPrice);
			await this.createCryptoPendingTransaction(tx, TransactionType.SUBSCRIPTION, this.bcubeFinalPrice);
			await this.bcubeContractService.waitForBCubeToPaymentTransaction(tx);
		} catch (error: any) {
			this.bcubeContractService.closeLoader();
			this.displayPaymentError(error);
			this.isLoadingNext = false;

			return;
		} finally {
			this.unwatchTokenContractEvent(); // Unsubscribe from the event
		}

		try {
			const plan = await this.confirmUserPlanUpdate();
			this.userService.updatePlanLocaly(plan)
			this.planService.loadSubscription();
			this.bcubeContractService.closeLoader();
			this.isLoadingNext = false;
			this.toasterService.success(TransactionSuccessMessages.TRANSACTION_SUCCESSFUL);
			this.router.navigate([`/${Routes.PLAN}`]);
		} catch (error: any) {
			if (error.code === PlanSubscriptionErrorCodes.PLAN_SUBSCRIPTION_PENDING_TRANSACTION) {
				this.toasterService.error(error.message);
			} else {
				this.toasterService.error(TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_UNEXPECTED_ERROR]);
			}
			this.bcubeContractService.closeLoader();
		}

	}

	public getButtonLabel(): string {
		if (!this.isFinalStep()) {
			return PLAN_SUBSCR_WIZARD_BUTTONS_LABELS.Continue;
		}

		if (this.isFreemium(this.getTargetPlan())) {
			return PLAN_SUBSCR_WIZARD_BUTTONS_LABELS.Confirm;
		}

		return PLAN_SUBSCR_WIZARD_BUTTONS_LABELS.Pay;
	}

	public isButtonDisabled(): boolean {
		if(! this.canUseService()){
			return true;
		}

		if (this.isFinalStep() && this.paymentMethod === PaymentMethod.BCUBE && !this.ethereumService.isWalletConnected$.value) {
			return true;
		}

		return false;
	}

	public isAddonSelected(addonId: string): boolean {
		return this.selectedAddons.includes(addonId);
	}

	public switchAddon(addonId: string): void {
		if (this.isAddonSelected(addonId)) {
			this.selectedAddons = this.selectedAddons.filter(addon => addon !== addonId);
		} else {
			this.selectedAddons.push(addonId)
		}
	}

	public navigateToStakingPage() {
		this.router.navigate([Routes.STAKING_EDIT]);
	}
}
