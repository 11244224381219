<div class="-mx-3 -mt-3">
	<owl-carousel-o [options]="customOptions">
		<!--Slide-->
		<ng-template carouselSlide *ngFor="let bot of bots">
			<a [routerLink]="'bot'" class="block p-6 cursor-pointer">
				<div
					class="relative bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-lg -m-3 overflow-hidden">
					<div class="relative w-full min-h-[150px] p-6">
						<div class="flex items-center justify-between mb-4">
							<div class="relative flex justify-center items-center h-10 w-10 rounded-full bg-brand"
								[ngStyle]="{'background-color': bot.category !== 'COMPANY'? 'white' : 'none'}">
								<div *ngIf="isCompanyProvider">
									<app-logo class="text-white w-6 h-6"></app-logo>
								</div>
								<div *ngIf="!isCompanyProvider">
									<img [src]="getBotIcon(bot['publisherName'])" style="border-radius: 25px;" />
								</div>
							</div>
							<h4 class="font-sans text-2xl text-green-500 font-medium">
								+224.6%
							</h4>
						</div>
						<div class="flex justify-center items-center"><span>{{bot?.name}}</span></div>
						<div class="flex items-start justify-between text-center">
							<div class="flex-1">
								<label
									class="inline-block font-sans text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">{{bot.pairing}}</label>
								<div class="flex justify-center">
									<span
										class="relative flex justify-center items-center h-10 w-10 rounded-full bg-amber-100 border-4 border-white dark:border-muted-800">
										<app-icon-btc class="text-amber-500 w-5 h-5"></app-icon-btc>
									</span>
									<span
										class="relative flex justify-center items-center h-10 w-10 rounded-full bg-amber-500 border-4 border-white dark:border-muted-800 z-10 -ml-4">
										<app-icon-binance class="text-white w-5 h-5"></app-icon-binance>
									</span>
								</div>
							</div>
							<div class="flex-1">
								<label
									class="inline-block font-sans text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">6
									month perf.</label>
								<div class="mt-2">
									<span class="font-sans text-sm font-semibold text-muted-500">+50%</span>
								</div>
							</div>
							<div class="flex-1">
								<label
									class="inline-block font-sans text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">3
									month perf.</label>
								<div class="mt-2">
									<span class="font-sans text-sm font-semibold text-muted-500">+50%</span>
								</div>
							</div>
						</div>
					</div>
					<app-chart-area-card></app-chart-area-card>
				</div>
			</a>
		</ng-template>
		<!--Slide-->

	</owl-carousel-o>
</div>
