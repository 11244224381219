<div class="flex flex-row justify-between relative w-full">
	<div class="flex items-center gap-4 flex-1">
		<a [routerLink]="'/marketplace'"
			class="group flex items-center gap-x-4 w-16 bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-6 rounded-lg hover:border-brand dark:hover:border-brand">
			<app-icon-arrow-left
				class="w-4 h-4 text-muted-300 mr-8 group-hover:text-brand group-hover:-translate-x-1 transition-all duration-300">
			</app-icon-arrow-left>
		</a>
		<div class="flex items-center gap-x-4 flex-1">
			<span *ngIf="isCompanyProvider || publisherName === 'GuruBundle'; else thirdParty"
				class="relative flex justify-center items-center rounded-full bg-white dark:bg-muted-700">
				<app-bot-icon name="b-cube-logo-color" class="w-12 h-12"></app-bot-icon>
			</span>

			<ng-template #thirdParty>
				<span class="relative flex justify-center items-center rounded-full bg-white dark:bg-muted-700">
					<app-bot-icon [name]="publisherName" [svgClass]="'bg-white rounded-full w-12 h-12'"></app-bot-icon>
				</span>
			</ng-template>
			<span class="block font-sans text-lg md:text-xl font-semibold text-muted-600 dark:text-muted-200
			flex-1">
				{{bot?.name }}
			</span>
		</div>
	</div>
	<div class="flex flex-col  max-w-100 items-end">
		<span class="text-sm text-muted-400">
			Risk
		</span>
		<span class="text-muted-600 dark:text-muted-100 text-sm">
			{{BotRiskLabel['' + bot?.risk]}}
		</span>
	</div>
</div>
