import { BALANCE_HISTORY_FREQUENCY, BALANCE_HISTORY_RANGE } from '@b-cube/interfaces/exchange-account';
import { PERFORMANCE_HISTORY_FREQUENCY, PERFORMANCE_HISTORY_RANGE } from '@b-cube/interfaces/performance';

export const COLOR_PALETTE = ['#fbbf24', '#475569', '#34d399', '#f97316', '#f43f5e', '#6366f1', '#8b5cf6', '#23cbd5'];

export const loadingOptions = {
	text: '',
	color: '#23cbd5',
	textColor: '#000',
	maskColor: 'rgba(255, 255, 255, 0.1)',
	zlevel: 0,
};

export const SPLIT_VIEW_MAX_TOKENS = 8;

export enum DisplayMode {
	week,
	normal,
}

export enum PerformanceDisplayMode {
	DAY = 'EEE d',
	MONTH_DAY = 'MMM d',
	MONTH = 'MM',
	MONTH_NAME = 'MMM',
	MONTH_YEAR = 'MMM yy',
	DAY_MONTH_YEAR = 'dd/MM/yyyy',
	FULL_YEAR = 'yyyy',
}

export interface BalancePeriod {
	key: string;
	label: string;
	range: BALANCE_HISTORY_RANGE;
	frequency: BALANCE_HISTORY_FREQUENCY;
}

export interface PerformancePeriod {
	key: string;
	label: string;
	range: PERFORMANCE_HISTORY_RANGE;
	frequency: PERFORMANCE_HISTORY_FREQUENCY;
	displayTimeFormat: PerformanceDisplayMode;
}

export enum PerformanceType {
	LIVE = 'live',
	BACKTEST = 'backtest',
}

export const HISTORY_PERIODS: BalancePeriod[] = [
	{
		key: '1d',
		label: '1D',
		range: BALANCE_HISTORY_RANGE.ONE_DAY,
		frequency: BALANCE_HISTORY_FREQUENCY.FIFTEEN_MINUTES,
	},
	{
		key: '7d',
		label: '7D',
		range: BALANCE_HISTORY_RANGE.ONE_WEEK,
		frequency: BALANCE_HISTORY_FREQUENCY.FIFTEEN_MINUTES,
	},
	{
		key: '1m',
		label: '1M',
		range: BALANCE_HISTORY_RANGE.ONE_MONTH,
		frequency: BALANCE_HISTORY_FREQUENCY.ONE_DAY,
	},
	{
		key: '3m',
		label: '3M',
		range: BALANCE_HISTORY_RANGE.THREE_MONTHES,
		frequency: BALANCE_HISTORY_FREQUENCY.ONE_WEEK,
	},
	{
		key: '6m',
		label: '6M',
		range: BALANCE_HISTORY_RANGE.SIX_MONTHES,
		frequency: BALANCE_HISTORY_FREQUENCY.TWO_WEEKS,
	},
	{
		key: '1y',
		label: '1Y',
		range: BALANCE_HISTORY_RANGE.ONE_YEAR,
		frequency: BALANCE_HISTORY_FREQUENCY.ONE_MONTH,
	},
	{
		key: 'all',
		label: 'All',
		range: BALANCE_HISTORY_RANGE.ALL_HISTORY,
		frequency: BALANCE_HISTORY_FREQUENCY.ONE_MONTH,
	},
];

export const PERIOD_ALL = 'all';

export const PERFORMANCE_HISTORY_PERIODS: PerformancePeriod[] = [
	{
		key: '7d',
		label: '7D',
		range: PERFORMANCE_HISTORY_RANGE.ONE_WEEK,
		frequency: PERFORMANCE_HISTORY_FREQUENCY.ONE_DAY,
		displayTimeFormat: PerformanceDisplayMode.DAY,
	},
	{
		key: '1m',
		label: '1M',
		range: PERFORMANCE_HISTORY_RANGE.ONE_MONTH,
		frequency: PERFORMANCE_HISTORY_FREQUENCY.ONE_DAY,
		displayTimeFormat: PerformanceDisplayMode.MONTH_DAY,
	},
	{
		key: '3m',
		label: '3M',
		range: PERFORMANCE_HISTORY_RANGE.THREE_MONTHS,
		frequency: PERFORMANCE_HISTORY_FREQUENCY.ONE_DAY,
		displayTimeFormat: PerformanceDisplayMode.MONTH_DAY,
	},
	{
		key: '6m',
		label: '6M',
		range: PERFORMANCE_HISTORY_RANGE.SIX_MONTHS,
		frequency: PERFORMANCE_HISTORY_FREQUENCY.ONE_DAY,
		displayTimeFormat: PerformanceDisplayMode.MONTH_DAY,
	},
	{
		key: '1y',
		label: '1Y',
		range: PERFORMANCE_HISTORY_RANGE.ONE_YEAR,
		frequency: PERFORMANCE_HISTORY_FREQUENCY.ONE_DAY,
		displayTimeFormat: PerformanceDisplayMode.DAY_MONTH_YEAR,
	},
	{
		key: PERIOD_ALL,
		label: 'All',
		range: PERFORMANCE_HISTORY_RANGE.ALL_HISTORY,
		frequency: PERFORMANCE_HISTORY_FREQUENCY.ONE_DAY,
		displayTimeFormat: PerformanceDisplayMode.DAY_MONTH_YEAR,
	},
];

export const FAKE_CARD_PERFORMANCE = [
	{ time: '2022-11-03T00:00:00.000Z', value: 1.0 },
	{ time: '2022-11-10T00:00:00.000Z', value: 1.02 },
	{ time: '2022-11-17T00:00:00.000Z', value: 1.013 },
	{ time: '2022-11-24T00:00:00.000Z', value: 1.03 },
	{ time: '2022-12-01T00:00:00.000Z', value: 1.07 },
	{ time: '2022-12-08T00:00:00.000Z', value: 1.09 },
	{ time: '2022-12-15T00:00:00.000Z', value: 1.08 },
	{ time: '2022-12-22T00:00:00.000Z', value: 1.07 },
	{ time: '2022-12-29T00:00:00.000Z', value: 1.08 },
	{ time: '2023-01-05T00:00:00.000Z', value: 1.1 },
	{ time: '2023-01-12T00:00:00.000Z', value: 1.13 },
	{ time: '2023-01-19T00:00:00.000Z', value: 1.17 },
	{ time: '2023-01-26T00:00:00.000Z', value: 1.23 },
];

export enum EmptyDataTypeText {
	NO_LIVE_TRADING = 'No live trading within this period',
	NO_BACKTEST = 'No backtest available',
}

export const EMPTY_CHART_VIEW = {
	responsive: true,
	title: {
		text: 'No data available, try longer period.',
		left: 'center',
		top: 'center',
		textStyle: {
			color: '#64748B',
			fontSize: 12,
			fontStyle: 'italic',
		},
	},
};
