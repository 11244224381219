<div class="relative min-h-screen w-full bg-muted-100 dark:bg-muted-900">

	<app-sidebar (close)="toggle()" [expanded]="!isFolded"></app-sidebar>

	<div class="transition-all duration-300" [class]="isFolded ? 'w-full' : 'xl:w-[calc(100%_-_240px)] xl:ml-[240px]'">
		<app-header [isFolded]="isFolded" (open)="toggle()" (openNotifications)="togglePanel()"></app-header>
		<div class="p-6">
			<div class="pt-4 pb-14 w-full max-w-7xl mx-auto">
				<ng-content></ng-content>
			</div>
		</div>
	</div>

	<app-panel (closePanel)="togglePanel()" [isPanelOpened]="isNotificationPanelOpen"></app-panel>
</div>
