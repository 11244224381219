import { BotRisk } from '@b-cube/database/types';

import { IPublisher } from "./publisher";

export { MarketType } from '@b-cube/database/types';
export { BotRisk } from '@b-cube/database/types';

export const BotRiskLabel = {
	[String(BotRisk.LOW)]: 'Low',
	[String(BotRisk.LOW_MEDIUM)]: 'Low/Medium',
	[String(BotRisk.MEDIUM)]: 'Medium',
	[String(BotRisk.MEDIUM_HIGH)]: 'Medium/High',
	[String(BotRisk.HIGH)]: 'High'
}

export interface IBot {
	id: number
	name: string
	description: string;
	category: string;
	strategyType: number;
	cryptos: string[];
	pairing: string;
	market: string;
	status: string;
	supportedExchanges: string[];
	publisher?: IPublisher;
	chargebeeAddonUid?: string;
	liveDate: Date;
	risk: BotRisk;
}

export interface IBotWithStatus extends IBot {
	enabled: boolean;
}

export interface IBotWithPnls extends IBotWithStatus {
	investment: number,
	overallPnl: number,
	weekPnl: number
}
