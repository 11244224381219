import { UserBotStatus } from "@b-cube/database/types";
import { IUserBot } from "@b-cube/interfaces/user-bot";

import { Bot } from "./bot";
import { ExchangeAccount } from "./exchange-account";

export class UserBot implements IUserBot {
	bot: Bot;
	exchangeAccount: ExchangeAccount;
	enabled: boolean;
	status: UserBotStatus;

	deserialize(input: any): this {
		this.bot = new Bot().deserialize(input.bot);
		this.exchangeAccount = new ExchangeAccount().deserialize(input.exchangeAccount);
		this.enabled = input.enabled;
		this.status = input.status;

		return this;
	}
}
