export const unitName = 18;
export const allowStakingValue = '60000000';

export const STAKING_WARN_MESSAGES = {
	WALLET_SWITCH_CONFIRMATION: {
		title: 'New Wallet Authentication',
		message: 'Switching to a new wallet will disconnect your current wallet {ethAddress} and reset your staking tier. Are you sure you want to proceed?'
	},
	WALLET_SELECTION_CHANGED: {
		title: 'New Wallet Connection',
		message: "The wallet you connected ({connectedAddress}) is not the one you authenticated on our app ({authenticatedAddress}). Please verify before proceeding."
	},
	INSUFFICIENT_ALLOWANCE: {
		title: 'Insufficient Allowance',
		message: `The amount to be staken exceeds the allowance you set on the Smart Contract. Your remaining allowance is {spendingCapAmount}. To proceed, would you like to update your allowance?`
	},
}

export const STAKING_ERROR_MESSAGES = {
	WALLET_DUPLICATION: {
		title: 'New Wallet Authentication',
		message: 'The address you provided is already in use by another user. Please ensure you are using a unique Ethereum wallet address and try again.'
	},
}

export enum SignatureStatus {
	SUCCESS = 'Signature request successfully approved!',
	FAILED = 'Signature request failed',
}

export enum FundsStatus {
	APPROVAL_SUCCESS = 'Funds approval request was successful!',
	APPROVAL_FAILED = 'Funds approval request failed',
}

export enum StakingContractEvent {
	LOAD_BCUBE_STAKING = 'LogBcubeStaking',
	LOAD_BCUBE_UNSTAKING = 'LogBcubeUnstaking',
}

export enum TokenContractEvent {
	TRANSFER = 'Transfer',
}

export enum MetaMaskErrorCodes {
	GAS_LIMIT = 'UNPREDICTABLE_GAS_LIMIT'
}

export const BUY_BCUBE_URL = 'https://app.uniswap.org/#/swap?outputCurrency=0x93C9175E26F57d2888c7Df8B470C9eeA5C0b0A93&use=V2';
