import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, ToRelativeOptions, ToRelativeUnit, Zone } from 'luxon';

@Pipe({ name: 'ldate', pure: true })
export class LuxonDatePipe implements PipeTransform {

	transform(date: DateTime|number): string {
		let luxonDate: DateTime;

		if(Number.isFinite(date)){
			if(! Number.isInteger(date)){
				return 'n/a';
			}

			const timestamp = <number> date;
			luxonDate = DateTime.fromSeconds(timestamp);
		} else {
			luxonDate= <DateTime>date;

			if ( !luxonDate || !luxonDate.isValid){
				return 'n/a';
			}
		}

		return luxonDate.toLocaleString();
	}
}
