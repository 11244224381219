import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-time-rotate',
  templateUrl: './icon-time-rotate.component.html'
})
export class IconTimeRotateComponent {
  @Input() class = ''

}
