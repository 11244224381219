import { Tendency } from "@b-cube/interfaces/ta-screener/tendency.enum";

export const REFRESH_DATA_MS = 7000;
export const RANK_FROM = 1;
export const RANK_TO = 150;
export const COINS_DISPLAY_NUMBER = 25;
export const SCREENS = [
    {
        name: 'Main Screen',
        isFree: true
    },
    {
        name: 'Day Trade',
        isFree: true
    },
    {
        name: 'RSI',
        isFree: false
    },
    {
        name: 'Trend',
        isFree: false
    },
    {
        name: 'MACD',
        isFree: false
    },
    {
        name: 'Long Term',
        isFree: false
    }
];
export const BASELINE_UP = '!text-green-400 dark:!text-green-400';
export const BASELINE_DOWN = '!text-red-400 dark:!text-red-400';
export const HEADER_CLASS = 'bg-white dark:bg-muted-800';
export const CELL_CLASS = 'bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700';
export const NO_DATA_FOUND = `<div class="m-8 dark:text-brand text-muted-500 font-semibold text-sm text-center"><div>No coins found.</div><div>`;
export const LOADING = `<div class="m-8 dark:text-brand text-muted-500 font-semibold text-sm text-center animate-pulse "><div>Loading ...</div><div>`;

//It is 'any' because SortType is not working at the moment
export const DEFAULT_SORT: any = 'multi';

export const TENDENCIES: any = {
    [Tendency.LOWEST_BOUNDARY_LABEL]: {
        order: 0,
        cssClass: '!text-red-500 dark:!text-red-500'
    },
    [Tendency.LOW_BOUNDARY_LABEL]: {
        order: 1,
        cssClass: '!text-red-400 dark:!text-red-400'
    },
    [Tendency.MID_LOW_BOUNDARY_LABEL]: {
        order: 2,
        cssClass: '!text-red-300 dark:!text-red-300'
    },
    [Tendency.MID_BOUNDARY_LABEL]: {
        order: 3,
        cssClass: '!text-orange-400 dark:!text-orange-400'
    },
    [Tendency.MID_HIGH_BOUNDARY_LABEL]: {
        order: 4,
        cssClass: '!text-green-300 dark:!text-green-300'
    },
    [Tendency.HIGH_BOUNDARY_LABEL]: {
        order: 5,
        cssClass: '!text-green-400 dark:!text-green-400'
    },
    [Tendency.HIGHEST_BOUNDARY_LABEL]: {
        order: 6,
        cssClass: '!text-green-500 dark:!text-green-500'
    },
    [Tendency.MACD_NEGATIVE_LABEL]: {
        order: 7,
        cssClass: '!text-red-400 dark:!text-red-400'
    },
    [Tendency.MACD_POSITIVE_LABEL]: {
        order: 8,
        cssClass: '!text-green-500 dark:!text-green-400'
    },
    [Tendency.BELOW]: {
        order: 9,
        cssClass: '!text-red-400 dark:!text-red-400'
    },
    [Tendency.INSIDE]: {
        order: 10,
        cssClass: '!text-orange-400 dark:!text-orange-400'
    },
    [Tendency.ABOVE]: {
        order: 11,
        cssClass: '!text-green-500 dark:!text-green-400'
    }
};


