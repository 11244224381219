import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-x',
  templateUrl: './icon-x.component.html'
})
export class IconXComponent {
  @Input() class = ''

}
