export enum NotificationType {
	EXCHANGE_ERROR = 'EXCHANGE_ERROR',
	USER_TRADE = 'USER_TRADE',
	BLOCKCHAIN_EVENT = 'BLOCKCHAIN_EVENT',
	KYC_COMPLETED = 'KYC_COMPLETED',
	KYC_RETRY = 'KYC_RETRY',
	KYC_REJECTED = 'KYC_REJECTED',
	KYC_APPROVED = 'KYC_APPROVED',
	TOKEN_PAYMENT_SUCCESS = 'TOKEN_PAYMENT_SUCCESS',
	TOKEN_PAYMENT_ERROR = 'TOKEN_PAYMENT_ERROR',
}
