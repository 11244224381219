import { Component, Input } from '@angular/core';
import { BOT_ICONS_NAMES, PATH_TO_ICONS } from '@core/constants/icons.constants';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
	selector: 'app-widget-marketplace-carousel',
	templateUrl: './widget-marketplace-carousel.component.html',
	styleUrls: ['./widget-marketplace-carousel.component.css'],
})
export class WidgetMarketplaceCarouselComponent {

	@Input() bots: any[] | null;
	@Input() isCompanyProvider: boolean | null;

	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		navText: [
			'<img src="https://api.iconify.design/lucide:arrow-left.svg" alt="carousel arrow" />',
			'<img src="https://api.iconify.design/lucide:arrow-right.svg" alt="carousel arrow" />',
		],
		responsive: {
			0: {
				items: 1,
			},
			400: {
				items: 1,
			},
			740: {
				items: 2,
			},
			940: {
				items: 3,
			},
		},
		nav: true,
	};

	getBotIcon(publisher: string | undefined): string {
		return publisher ? `${PATH_TO_ICONS}/${BOT_ICONS_NAMES[publisher]}` : '';
	}
}
