import { Component, Input } from '@angular/core';
import { IBot } from '@b-cube/interfaces/bot';

@Component({
	selector: 'bot-pair',
	templateUrl: './bot-pair.component.html',
})
export class BotPairComponent {
	@Input() bot: IBot;
	@Input() iconSize = 5;
	@Input() maxCryptoNumber = 3;

	getCryptoList(): string[]{
		if(! this.shouldDisplayPlus()){
			return this.bot.cryptos;
		}

		return this.bot.cryptos.slice(0, this.maxCryptoNumber - 1);
	}

	shouldDisplayPlus(): boolean{
		return this.bot.cryptos.length > this.maxCryptoNumber;
	}

	getMissingBotsNumber(): number{
		if(!this.shouldDisplayPlus()){
			return 0;
		}

		return this.bot.cryptos.length - this.maxCryptoNumber + 1;
	}

	getPairsList(): string{
		return this.bot.cryptos.map(coin => coin.toUpperCase()).join(' ');
	}
}
