import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plan } from '@b-cube/interfaces/plan';
import { Tier } from '@b-cube/interfaces/staking';
import { IUserPlanSubscription, UserInfo } from '@b-cube/interfaces/user';
import { RestURLBuilder } from 'rest-url-builder';
import { BehaviorSubject, lastValueFrom, map, Observable, share } from 'rxjs';

import { USER_ADDON_SUBSCRIPTION, USER_SUBSCRIPTION, USER_TOPUP, USER_UPDATE_URL, USER_URL } from '../constants/api-urls.constants';
import { PLAN_STEP_PERCENTAGE, TIER_STEP_PERCENTAGE } from '../constants/chargebee.constants';
import { UserProgress } from '../types/userProgress';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private user$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);

	constructor(private http: HttpClient) {
		this.loadCurrentUser();
	}

	private loadCurrentUser() {
		return this.http.get<UserInfo>(USER_URL).pipe(
			share()
		).subscribe(user => {
			this.user$.next(user);
		});
	}

	// get loaded user
	public get currentUser(): Observable<UserInfo> {
		return this.user$.asObservable();
	}

	// get user
	public getCurrentUser(): Observable<UserInfo> {
		this.loadCurrentUser();

		return this.currentUser;
	}

	public getUser(): Observable<UserInfo> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_URL);

		return this.http.get<UserInfo>(urlBuilder.get());
	}

	public async resetPlan(): Promise<void> {
		await lastValueFrom(this.http.delete(USER_SUBSCRIPTION));
	}

	public async subscribeUser(subscription: IUserPlanSubscription): Promise<void> {
		await lastValueFrom(this.http.post(USER_SUBSCRIPTION, subscription));
	}

	public async topUpSubscription(subscription: IUserPlanSubscription): Promise<void> {
		await lastValueFrom(this.http.post(USER_TOPUP, subscription));
	}

	public async updatePlan(plan: Plan): Promise<void> {
		try {
			await lastValueFrom(this.http.post(USER_UPDATE_URL, {
				chargebeePlanUid: plan.id,
			}));
		} catch (e) {
			console.error('update plan failed');
		}

		this.updatePlanLocaly(plan);
	}

	public updatePlanLocaly(plan: Plan): void {
		const user = this.user$.value;
		user.plan = plan;
		this.user$.next(user);
	}

	public async unsubscribeAddon(itemPriceId: string) {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_ADDON_SUBSCRIPTION);
		urlBuilder.setNamedParameter('itemPriceId', itemPriceId);
		try {
			await lastValueFrom(this.http.delete(urlBuilder.get()));
		} catch (e) {
			console.error('addon unsubscription failed');
		}
	}

	public async updateTier(tier: Tier): Promise<void> {
		await lastValueFrom(this.http.post(USER_UPDATE_URL, { chargebeeTierUid: tier.id }));

		const user = this.user$.value;
		user.tier = tier;
		this.user$.next(user);
	}

	getCurrentUserInitials(): Observable<string> {
		return this.currentUser.pipe(
			map(user => {
				if (!user) {
					return '';
				}

				return user.username.substring(0, 2);
			})
		);
	}

	/*
	* Compute the data to display the progression (tiers)  of the user
	* May be placed in the user service if required elsswhere
	*/
	getUserPlanProgress(): Observable<UserProgress> {
		return this.user$.pipe(
			map(user => {
				let progress = 0;

				if (user !== null) {
					progress = Number(user.plan.tier);
				}

				return <UserProgress>{
					label: String(progress),
					progress: progress * PLAN_STEP_PERCENTAGE,
				};
			})
		);
	}

	/*
	* Compute the data to display the progression (tiers)  of the user
	* May be placed in the user service if required elsswhere
	*/
	getUserTierProgress(): Observable<UserProgress> {
		return this.user$.pipe(
			map(user => {
				let progress = 0;

				if (user !== null) {
					progress = Number(user.tier.tier);
				}

				return <UserProgress>{
					label: String(progress),
					progress: progress * TIER_STEP_PERCENTAGE,
				};
			})
		);
	}
}
