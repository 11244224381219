<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.0925 1.10059V12.2706L2.2125 7.39059C1.8225 7.00059 1.1825 7.00059 0.7925 7.39059C0.4025 7.78059 0.4025 8.41059 0.7925 8.80059L7.3825 15.3906C7.7725 15.7806 8.4025 15.7806 8.7925 15.3906L15.3825 8.80059C15.7725 8.41059 15.7725 7.78059 15.3825 7.39059C15.1957 7.20333 14.942 7.0981 14.6775 7.0981C14.413 7.0981 14.1593 7.20333 13.9725 7.39059L9.0925 12.2706V1.10059C9.0925 0.550586 8.6425 0.100586 8.0925 0.100586C7.5425 0.100586 7.0925 0.550586 7.0925 1.10059Z"
    fill="currentColor"
  />
</svg>
