import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-question',
  templateUrl: './icon-question.component.html'
})
export class IconQuestionComponent {
  @Input() class = ''

}
