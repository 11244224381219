<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 708.1 708.1"
  style="enable-background: new 0 0 708.1 708.1"
  xml:space="preserve"
  [class]="class"
>
  <g>
    <path
      fill="currentColor"
      d="M433.6,217.8c0-101.6-49.5-189.1-87.2-217.5c0,0-2.9-1.6-2.6,2.5c-3.1,196.3-103.4,249.5-158.5,321.1
		c-127.2,165.4-9,346.7,111.6,380.2c67.2,18.8-15.6-33.2-26.3-142.9C257.5,428.8,433.6,327.6,433.6,217.8L433.6,217.8z"
    />
    <path
      fill="currentColor"
      d="M491.4,284.7c-0.8-0.5-1.9-0.9-2.6,0.4c-2.1,24.7-27.3,77.5-59.3,126.1c-108.5,164.6-46.7,244-11.9,286.7
		c20.2,24.7,0,0,50.4-25.3c62.3-37.8,102.7-103.2,108.7-175.8C586.6,379.8,516.4,306.1,491.4,284.7L491.4,284.7z"
    />
  </g>
</svg>
