// PENDING: This value indicates that the bot has been added to the userbots table, but the payment has not yet been confirmed by Chargebee.

// TX_PENDING: This value indicates that the bot has been added to the userbots table, but the transaction has not yet been confirmed by the blockchain.

// ACTIVE: This value indicates that the payment has been confirmed and the bot is active.

// INACTIVE: This value indicates that the bot is inactive, either because the payment failed or the user's subscription has been cancelled.


export enum UserBotStatus {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	TX_PENDING = 'TX_PENDING',
  }
