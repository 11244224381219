import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { BTC } from '@app/core/constants/currency.constants';
import { ExchangeAccount } from '@app/core/models/exchange-account';
import { ScreenService } from '@app/core/services/screen.service';
import { ExchangeName } from '@b-cube/database/types';
import { IBotAllocationSettings } from '@b-cube/interfaces/user-bot-settings';

@Component({
  selector: 'app-widget-balance-allocation',
  templateUrl: './widget-balance-allocation.component.html'
})
export class WidgetBalanceAllocationComponent implements OnChanges {
  @Input() isSettingsOpen = false
  @Input() exchangeAccount: ExchangeAccount;
  @Input() balanceAllocations: IBotAllocationSettings;

  @Output() updateAllocation = new EventEmitter<boolean>();

  constructor(public screenService: ScreenService) { }

  public botsNumber = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['balanceAllocations'] && this.balanceAllocations) {
      this.changePairingByExchange();
      this.botsNumber = this.balanceAllocations?.allocations.reduce((acc, value) => {
        return acc + value.bots.length;
      }, 0)
    }
  }

  openSettings(): void {
    this.isSettingsOpen = true
  }

  public closeBalanceAllocation($event: any) {
    this.isSettingsOpen = false;
    if ($event) {
      this.updateAllocation.emit(true);
    }
  }

  private changePairingByExchange(): void {
    if (this.exchangeAccount.exchangeName === ExchangeName.BITMEX_FUTURES) {
      this.balanceAllocations?.allocations.forEach(alloc => alloc.pairing = BTC)
    }
  }
}
