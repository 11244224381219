import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})

export class FormatNumberHelper {

	public isNegativeNumber(value: number | string): boolean {
		if (typeof value !== "number" && typeof value !== "string") {
			throw new Error(`Expected a number or a string, but got ${typeof value}.`);
		}

		return Number(value) < 0;
	}

	public formatNumber(value: number | string, suffix = ''): string {

		const number = Number(value);

		if (isNaN(number)) {
			throw new Error(`Expected a number, but got ${typeof value}.`);
		}

		return (this.isNegativeNumber(number) ? number.toFixed(2) : `+${number.toFixed(2)}`) + suffix;
	}

	public getClass(value: number | string): string {

		if (this.formatNumber(value) === 'N/A') {
			return '';
		}

		return this.isNegativeNumber(value) ? 'text-rose-500' : 'text-green-500';
	}

}
