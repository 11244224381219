
export interface IAllocation {
	asset: string

	amount: number

	usdQuote: number
}

export interface IAllocationResponse<T extends IAllocation = IAllocation> {
	usdBalance: number

	allocation: T[]
}
