import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-asleep',
  templateUrl: './icon-asleep.component.html'
})
export class IconAsleepComponent {
  @Input() class = '';

}
