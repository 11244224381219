import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAllocation } from "@b-cube/interfaces/exchange-account";
import { UserBotStatusParam } from "@b-cube/interfaces/user-bot";
import { IBotAllocationSettings, IBotSettingsWrapper,IUpdateUserBotSettings } from '@b-cube/interfaces/user-bot-settings';
import { RestURLBuilder } from "rest-url-builder";
import { Observable } from "rxjs";

import { UPDATE_USER_SETTINGS_ALLOCATION_URL, USER_BOT_SETTINGS_ALLOCATION_URL, USER_BOT_SETTINGS_URL } from "../constants/api-urls.constants";
import { BTC } from "../constants/currency.constants";

@Injectable({
	providedIn: 'root'
})
export class UserBotSettingsService {

	constructor(private http: HttpClient) { }

	public getUserBotSettings(botId: number): Observable<IBotSettingsWrapper> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_SETTINGS_URL);
		urlBuilder.setNamedParameter('botId', String(botId));

		return this.http.get<IBotSettingsWrapper>(urlBuilder.get());
	}

	public updateUserBotSettings(botId: number, botSettingsWrapper: IBotSettingsWrapper): Observable<void> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_SETTINGS_URL);
		urlBuilder.setNamedParameter('botId', String(botId));

		return this.http.put<void>(urlBuilder.get(), botSettingsWrapper);
	}

	public getAllocationSettingsByAccount(exchangeAccountId: number, status: UserBotStatusParam): Observable<IBotAllocationSettings> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_SETTINGS_ALLOCATION_URL);
		urlBuilder.setQueryParameter('exchangeAccountId', String(exchangeAccountId));
		urlBuilder.setQueryParameter('status', status);

		return this.http.get<IBotAllocationSettings>(urlBuilder.get());
	}

	public updateBalanceAllocations(userSettings: IUpdateUserBotSettings[]) {
		return this.http.put(UPDATE_USER_SETTINGS_ALLOCATION_URL, userSettings);
	}

	public fillAllocationAmount(balances: IAllocation[], balanceAllocations: IBotAllocationSettings, isBTCbasedExchange = false): IBotAllocationSettings {
		const newBalanceAllocations = { ...balanceAllocations };
		// eslint-disable-next-line
		for (const allocation of balanceAllocations?.allocations) {
			let pairing = allocation.pairing;
			if (isBTCbasedExchange) {
				pairing = BTC;
			}
			const asset = balances?.find(alloc => alloc.asset === pairing);
			if (asset) {
				allocation.amountAvailable = Number(asset.amount);
				for (const bot of allocation.bots) {
					bot.allocatedAsset = bot.allocatedValue * allocation.amountAvailable / 100;
				}
			}
		}

		return newBalanceAllocations;
	}

}
