import { IExchangeAccount } from "@b-cube/interfaces/exchange-account";

export class ExchangeAccount implements IExchangeAccount {
	id: number;
	userId: number;
	name: string;
	exchangeName: string;
	accessKey?: string;
	secretKey?: string;
	password?: string;
	secretId: string;

	deserialize(input: any): this {
		Object.assign(this, input);

		return this;
	}
}
