<svg
  width="139"
  height="140"
  viewBox="0 0 139 140"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    cx="69.5"
    cy="70.1396"
    r="64"
    stroke="#293A6A"
    stroke-width="11"
    stroke-linejoin="round"
  />
  <path
    d="M133.5 70.1396C133.5 34.7934 104.846 6.13965 69.5 6.13965C34.1538 6.13965 5.5 34.7934 5.5 70.1396C5.5 105.486 34.1538 134.14 69.5 134.14C74.4932 134.14 79.3529 133.568 84.0171 132.486"
    stroke="#3B5183"
    stroke-width="11"
    stroke-linejoin="round"
  />
  <path
    d="M5.5 70.1396C5.5 97.7988 23.0458 121.36 47.6142 130.3"
    stroke="#4D699B"
    stroke-width="11"
    stroke-linejoin="round"
  />
  <path
    d="M133.5 70.1396C133.5 34.7934 104.846 6.13965 69.5 6.13965C34.1538 6.13965 5.5 34.7934 5.5 70.1396C5.5 85.632 11.0046 99.8386 20.1647 110.91"
    stroke="#068E97"
    stroke-width="11"
    stroke-linejoin="round"
  />
  <path
    d="M133.5 70.1396C133.5 34.7934 104.846 6.13965 69.5 6.13965C34.1538 6.13965 5.5 34.7934 5.5 70.1396"
    stroke="#068E97"
    stroke-width="11"
    stroke-linejoin="round"
  />
  <path
    d="M133.5 70.1396C133.5 34.7934 104.846 6.13965 69.5 6.13965C50.435 6.13965 33.317 14.4759 21.5923 27.7021"
    stroke="#23CBD5"
    stroke-width="11"
    stroke-linejoin="round"
  />
  <path
    d="M133.5 70.1396C133.5 34.7934 104.846 6.13965 69.5 6.13965"
    stroke="#42DEE7"
    stroke-width="11"
    stroke-linejoin="round"
  />
  <path
    d="M93.9956 129.284C117.187 119.669 133.5 96.809 133.5 70.1395C133.5 49.3821 123.618 30.9327 108.302 19.2393"
    stroke="#172352"
    stroke-width="11"
    stroke-linejoin="round"
  />
</svg>
