<div class="relative flex items-center justify-center">
	<svg
		role="progressbar"
		[attr.aria-valuenow]="progress"
		[attr.aria-valuemax]="max"
		class="block"
		viewBox="0 0 45 45"
		xmlns="http://www.w3.org/2000/svg"
		[class]="class"
	>
		<circle
			class="stroke-current text-muted-200 dark:text-muted-700"
			[attr.stroke-width]="thickness"
			fill="none"
			cx="50%"
			cy="50%"
			r="15.91549431"
		/>
		<circle
			class="stroke-current transition-all duration-[2000ms]"
			[attr.stroke-width]="thickness"
			[attr.stroke-dasharray]="100"
			[attr.stroke-dashoffset]="offset"
			stroke-linecap="round"
			fill="none"
			cx="50%"
			cy="50%"
			r="15.91549431"
		/>
	</svg>
	<div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center z-10">
		<span class="font-sans text-sm font-medium">
			<ng-content></ng-content>
		</span>
	</div>
</div>
