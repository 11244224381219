<div class="w-full py-6 md:py-12" *ngIf="loaded$ | async">
  <div class="w-full max-w-4xl mx-auto">
    <div class="flex items-center justify-between mb-4">
      <h2 class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
        My current Tier
      </h2>
      <a [routerLink]="'edit'" class="font-sans text-sm font-semibold text-brand">Manage tiers</a>
    </div>
    <div
      class="group w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-8 py-12 rounded-lg">
      <div class="flex flex-col md:flex-row items-center gap-6 md:gap-4 py-8">
        <app-widget-wizard-current-tier class="flex-1" [user$]="user$" [userTierProgress$]="userTierProgress$"
          [initials$]="initials$">
        </app-widget-wizard-current-tier>

        <!--Current-->
        <div class="flex-1 h-full px-4">
          <div class="flex items-center justify-center md:justify-start gap-x-2">
            <img src="/assets/img/illustrations/safe-box.png" class="object-contain w-14" alt="Illustration" />
            <div>
              <h4 class="text-[0.65rem] font-semibold text-muted-400 uppercase">
                Current tier
              </h4>
              <h3 class="font-sans font-medium text-2xl text-brand capitalize">
                {{(user$ | async)?.tier.name}}
              </h3>
            </div>
          </div>

          <p *ngIf="nextTierAvailable$ | async; else maxTier" class="font-sans text-sm text-muted-400 py-7 text-center md:text-left">
            You still need to stake tokens in your account to be able to unlock better discounts.
          </p>
          <ng-template #maxTier>
            <p class="font-sans text-sm text-muted-400 py-7 text-center md:text-left">
                This is currently the maximum tier that you can undertake.
            </p>
          </ng-template>
        </div>

        <!--Next -->
        <app-widget-wizard-next-tier [nextTier]="nextTier$ | async" *ngIf="nextTierAvailable$ | async"
          [tierDifference]="tierDifference$ | async" [availableTokens]="freeBCUBE$ | async"></app-widget-wizard-next-tier>

        <!-- No next-->
        <div *ngIf="!(nextTierAvailable$ | async)" class="flex-1 bg-white border-2 border-brand p-10 rounded-lg">
          <div class="flex items-center gap-x-2 mb-4">
            <img src="/assets/img/illustrations/safe-box.png" class="object-contain w-14" alt="Illustration" />
            <div>
              <h4 class="text-[0.65rem] font-semibold text-muted-400 uppercase">
                Scaling
              </h4>
              <h3 class="font-sans font-medium text-2xl text-brand capitalize">
                Contact our team
              </h3>
            </div>
          </div>
          <div class="font-sans">
            <p class="text-sm text-muted-400 py-3">
              You reach the maximum discounts. Explore more investment opportunities by
             contacting <a class="underline" href="mailto:contact@b-cube.ai">our team</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
