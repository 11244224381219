<div class="w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-lg">
	<div class="w-full p-6 text-center border-b border-muted-200 dark:border-muted-700">
		<span class="font-sans text-lg font-semibold text-muted-600 dark:text-muted-200">Subscription</span>
	</div>
		<app-widget-marketplace-subscription-bot-bcube *ngIf="isCompanyProvider"
		[user]="user" [bot]="bot" [isAlreadyPlugged]="isAlreadyPlugged()"
		[userHasSupportedExchanges]="userHasSupportedExchanges()"
		(closeModal)="closeAddBot($event)"
		></app-widget-marketplace-subscription-bot-bcube>

		<app-widget-marketplace-subscription-bot-third-party *ngIf="isCompanyProvider === false"
		[user]="user" [bot]="bot" [isAlreadyPlugged]="isAlreadyPlugged()"
		[userHasSupportedExchanges]="userHasSupportedExchanges()"
		(closeModal)="closeAddBot($event)" [botPlan]="botPlan$ | async"
		[userBotSubscription]="getBotSubscription()"
		></app-widget-marketplace-subscription-bot-third-party>
</div>
