<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200
		dark:border-muted-700 rounded-md px-6 pt-6 pb-12 min-h-[430px]">
	<div class="w-full flex items-center">
		<h4 class="flex font-sans text-sm font-semibold tracking-wider text-left text-muted-700 dark:text-muted-300 mb-5 items-center">
			<app-logo class="w-16 h-16 mx-auto mb-2 text-brand"></app-logo>
			<div class="ml-4 text-2xl  text-brand">Welcome to b-cube.ai</div>
		</h4>
	</div>
	<div class="flex gap-2 flex-wrap text-lg justify-begin">
		Say goodbye to the hassle of managing multiple crypto exchange accounts with b-cube.ai!
	</div>
	<div class="flex gap-2 flex-wrap justify-begin mt-2 text-muted-500 dark:text-muted-400 text-sm">
		<div>Our portfolio management system makes it easy to track all your assets in one place, providing you with a detailed view by exchange account and an overview of all your accounts. Best of all, it's free! Simplify your crypto life and maximize your profits with b-cube.ai today.</div>
	</div>

	<div class="grid lg:grid-cols-3 grid-cols-1 gap-y-2 content-center mt-8" style="max-height:300px">
		<div class="lg:col-span-2" style="max-height:300px">
			<img [src]="getIllustrationPath(Illustrations.BALANCE)" class="object-contain w-[90%] h-[100%] m-auto" alt="Balance history" />
		</div>
		<div class="hidden lg:block" style="max-height:300px">
			<img [src]="getIllustrationPath(Illustrations.ALLOCATION)" class="object-contain h-[100%]" alt="Assets allocation" />
		</div>
		<div class="lg:col-span-2 text-xs text-center text-muted-500 dark:text-muted-400 italic">
			Follow your balance history
		</div>
		<div class="hidden lg:block text-xs text-center text-muted-500 dark:text-muted-400 italic">
			Get the assets allocation
		</div>
	</div>

	<div class="flex flex-col gap-2 text-sm lg-test-base flex-wrap justify-center mt-8 items-center">
		<!-- <div>Let's connect your first exchange account!</div> -->
		<button (click)="createExchangeAccount()"
			class="ml-4 items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand
			hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all
			duration-300 disabled:bg-muted-300">
			<span class="font-sans text-sm">Connect your first exchange account!</span>
		</button>
	</div>

	<!-- <div class="flex flex-col gap-2 text-sm lg-test-base flex-wrap justify-center mt-8 items-center">
		<div>Let's connect your first exchange account!</div>
		<button (click)="createExchangeAccount()" class="ml-4 items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300">
			<span class="font-sans text-sm">Start</span>
		</button>
	</div> -->

	<div class="flex justify-center mt-8 text-xs items-center">
		Not convinced yet. Let's visit our marketplace to find out which agents will suit your needs,&nbsp;
		<button (click)="goToMarketplace()" class="justify-center font-sans text-sm font-medium underline text-brand">
			<span class="font-sans text-xs">it's here</span>
		</button>
	</div>
</div>
