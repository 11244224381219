<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 708.1 708.1"
  style="enable-background: new 0 0 708.1 708.1"
  xml:space="preserve"
  [class]="class"
>
  <g>
    <path fill="currentColor" d="M365.2,498.1H201.4v164h163.8V498.1z" />
    <path fill="currentColor" d="M510.2,272.3H201.4v163.4H566V272.3H510.2z" />
    <path fill="currentColor" d="M708.1,46.1H201.4v164h506.7V46.1z" />
    <path fill="currentColor" d="M163.8,272.3H0v164h163.8V272.3z" />
  </g>
</svg>
