<svg
  width="19"
  height="19"
  viewBox="0 0 19 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.07 0.100586H6.43C6.17 0.100586 5.91 0.210586 5.73 0.390586L1.04 5.08059C0.86 5.26059 0.75 5.52059 0.75 5.78059V12.4106C0.75 12.6806 0.86 12.9306 1.04 13.1206L5.72 17.8006C5.91 17.9906 6.17 18.1006 6.43 18.1006H13.06C13.33 18.1006 13.58 17.9906 13.77 17.8106L18.45 13.1306C18.64 12.9406 18.74 12.6906 18.74 12.4206V5.78059C18.74 5.51059 18.63 5.26059 18.45 5.07059L13.77 0.390586C13.59 0.210586 13.33 0.100586 13.07 0.100586ZM9.75 14.4006C9.03 14.4006 8.45 13.8206 8.45 13.1006C8.45 12.3806 9.03 11.8006 9.75 11.8006C10.47 11.8006 11.05 12.3806 11.05 13.1006C11.05 13.8206 10.47 14.4006 9.75 14.4006ZM9.75 10.1006C9.2 10.1006 8.75 9.65059 8.75 9.10059V5.10059C8.75 4.55059 9.2 4.10059 9.75 4.10059C10.3 4.10059 10.75 4.55059 10.75 5.10059V9.10059C10.75 9.65059 10.3 10.1006 9.75 10.1006Z"
    fill="currentColor"
  />
</svg>
