<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700
	rounded-md p-6 md:min-h-[430px]">
	<div class="flex flex-col lg:flex-row justify-between">
		<div class="mb-5 lg:mb-0">
			<div class="flex items-center mb-5">
				<span class="font-sans text-xl font-semibold text-muted-600 dark:text-muted-200">
					Monthly performances
				</span>
			</div>
		</div>
	</div>

	<div class="mt-10 w-full">
		<app-chart-bar [chartData]="performance" [loading]="loading"></app-chart-bar>
	</div>
</div>
