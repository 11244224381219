export enum Tendency {
	LOWEST_BOUNDARY_LABEL = 'Very Bearish',
	LOW_BOUNDARY_LABEL = 'Bearish',
	MID_LOW_BOUNDARY_LABEL = 'Slightly Bearish',
	MID_BOUNDARY_LABEL = 'Neutral',
	MID_HIGH_BOUNDARY_LABEL = 'Slightly Bullish',
	HIGH_BOUNDARY_LABEL = 'Bullish',
	HIGHEST_BOUNDARY_LABEL = 'Very Bullish',
	NO_VALUE = 'No Value',
	MACD_POSITIVE_LABEL = 'Positive',
	MACD_NEGATIVE_LABEL = 'Negative',
	INSIDE = 'Inside',
	BELOW = 'Below',
	ABOVE = 'Above'
}
