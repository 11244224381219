<svg
  class="relative block w-16 h-16 rounded-full stroke-2 stroke-current animate-scale"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
  viewBox="0 0 52 52"
  stroke-miterlimit="10"
>
  <circle
    class="stroke-2 stroke-current animate-circle"
    cx="26"
    cy="26"
    r="25"
    fill="none"
    stroke-dasharray="166"
    stroke-dashoffset="166"
    stroke-miterlimit="10"
  />
  <path
    class="animate-check stroke-2 stroke-current"
    fill="none"
    d="M14.1 27.2l7.1 7.2 16.7-16.8"
    stroke-dasharray="48"
    stroke-dashoffset="48"
  />
</svg>
