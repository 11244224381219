<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200
		dark:border-muted-700 rounded-md px-6 pt-6 pb-12 min-h-[430px]" >
	<div class="w-full flex items-center">
		<h4 class="flex font-sans text-sm font-semibold tracking-wider text-left text-muted-700 dark:text-muted-300 mb-5 items-center">
			<app-logo class="w-16 h-16 mx-auto mb-2 text-brand"></app-logo>
			<div class="ml-4 text-2xl  text-brand">Welcome to b-cube.ai</div>
		</h4>
	</div>
	<div class="flex gap-2 flex-wrap text-lg justify-begin">
		Simplify your agent management!
	</div>
	<div class="flex gap-2 flex-wrap justify-begin mt-2 text-muted-500 dark:text-muted-400 text-sm">
		<div>We show the accurate performance of the agents available on our marketplace: manual transactions and transactions made with agents from other platforms are filtered out.</div>
		<div>Get all the details of trades made by the agents from our marketplace, including the history of all the trades along with the PnL of our agents which makes it easy to track their efficiency.</div>
	</div>

	<div class="grid lg:grid-cols-2 grid-cols-1 gap-y-2 content-center mt-8" style="max-height:300px">
		<div style="max-height:300px">
			<img [src]="getIllustrationPath(Illustrations.PNL)" class="object-contain w-[90%] h-[100%] m-auto" alt="Balance history" />
		</div>
		<div class="hidden lg:block" style="max-height:300px">
			<img [src]="getIllustrationPath(Illustrations.LIST)" class="object-contain h-[100%]" alt="Assets allocation" />
		</div>
		<div class="text-xs text-center text-muted-500 dark:text-muted-400 italic">
			Follow your PnL history
		</div>
		<div class="hidden lg:block text-xs text-center text-muted-500 dark:text-muted-400 italic">
			Get the details of all the trades
		</div>
	</div>

	<div *ngIf="!hasExchangeAccount" class="flex flex-col gap-2 text-sm lg-test-base flex-wrap justify-center mt-8 items-center">
		<div>To take advantage of our strategies, you'll need to connect an exchange account first</div>
		<button (click)="createExchangeAccount()" class="ml-4 items-center justify-center
			gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all
			duration-300 disabled:bg-muted-300">
			<span class="font-sans text-sm">Connect your first exchange account!</span>
		</button>
	</div>

	<div class="flex justify-center mt-8 text-xs items-center"  *ngIf="!hasExchangeAccount">
		Then let's visit our Marketplace and pick up the strategies that best fit your needs&nbsp;
		<button (click)="goToMarketplace()" class="justify-center font-sans text-sm font-medium underline text-brand">
			<span class="font-sans text-xs">it's here</span>
		</button>
	</div>

	<div *ngIf="hasExchangeAccount" class="flex flex-col gap-2 text-sm lg-test-base flex-wrap justify-center mt-8 items-center">
		<div>Let's visit our Marketplace and pick up the strategies that best fit your needs</div>
		<button (click)="goToMarketplace()" class="ml-4 items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300">
			<span class="font-sans text-sm">Visit</span>
		</button>
	</div>

	<div *ngIf="hasExchangeAccount" class="flex justify-center mt-8 text-xs items-center">
		You have the {{user?.plan.name}} plan. Your invesmtent limit is {{user?.plan.tradingLimit | currency:'USD':'symbol':'1.0-2'}}. To increase it, you can purchase a higher plan&nbsp;
		<button (click)="goToPlan()" class="justify-center font-sans text-sm font-medium underline text-brand">
			<span class="font-sans text-xs">it's here</span>
		</button>
	</div>
</div>
