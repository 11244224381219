import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from '@app/core/constants/router.constants';
import { IAccountBalance } from '@b-cube/interfaces/balance'

@Component({
	selector: 'app-widget-table-accounts',
	templateUrl: './widget-table-accounts.component.html'
})
export class WidgetTableAccountsComponent implements OnChanges {
	@Input() overviewAccountBalance: IAccountBalance[];
	@Output() addExchangeAccountEvent = new EventEmitter();

	public accountsNumber = 0;

	constructor(private router: Router) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes['overviewAccountBalance'] && this.overviewAccountBalance) {
			this.accountsNumber = this.overviewAccountBalance.length;
		}
	}

	goToAccount(exchangeAccountId: number): void {
		this.router.navigate([Routes.PORTFOLIO + '/', exchangeAccountId]);
	}

	addAccount(): void {
		this.addExchangeAccountEvent.emit(null);
	}
}
