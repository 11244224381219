import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cryptoCurrency', pure: true })
export class CryptoCurrencyPipe implements PipeTransform {
    private stableCoinPairings = ['USDT', 'BUSD', 'USDC'];
    private stableCoinDecimals = 3;
    private otherCoinDecimals = 8;

    

    transform(value: number, pairing: string): number {
        let decimalsNeeded = 0;
        if (!value) {
            return 0;
        }
        if (this.stableCoinPairings.includes(pairing)) {
            decimalsNeeded = this.stableCoinDecimals;
        } else {
            decimalsNeeded = this.otherCoinDecimals;
        }

        return Number(this.toFixed(value, decimalsNeeded));

    }

    /**
     *  This method is to not round up decimals. For example: 14.778 will be 14.77 and not 14.78
     */
    private toFixed(value: number, numberOfDecimals: number) {
		
		// eslint-disable-next-line
        const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (numberOfDecimals || -1) + '})?');

        return value.toString().match(re)[0];
    }
}
