import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { HeaderComponent } from './components/header/header.component';
import { HeaderAccountComponent } from './components/header/header-account/header-account.component';
import { HeaderNotificationsComponent } from './components/header/header-notifications/header-notifications.component';
import { HeaderWalletComponent } from './components/header/header-wallet/header-wallet.component';
import { LayoutDashboardComponent } from './components/layout/layout-dashboard/layout-dashboard.component';
import { PanelComponent } from './components/panel/panel.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarCurrencyComponent } from './components/sidebar/sidebar-currency/sidebar-currency.component';
import { SidebarLanguageComponent } from './components/sidebar/sidebar-language/sidebar-language.component';
import { DialogService } from './services/dialog.service';

@NgModule({
	declarations: [
		HeaderComponent,
		LayoutDashboardComponent,
		SidebarComponent,
		HeaderNotificationsComponent,
		HeaderWalletComponent,
		HeaderAccountComponent,
		SidebarCurrencyComponent,
		SidebarLanguageComponent,
		PanelComponent,
		ConfirmDialogComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		AngularSvgIconModule
	],
	providers: [
		DialogService
	],
	exports: [
		LayoutDashboardComponent
	]
})
export class CoreModule { }
