<div class="dark:bg-muted-800 border-muted-200 dark:border-muted-700 relative rounded-md border bg-white p-4">
	<app-video-player-modal #modal></app-video-player-modal>
	<div class="flex w-full flex-col items-center gap-2 text-center">
		<div class="justify-begin flex flex-wrap gap-2 text-lg">Get started with b-cube.ai!</div>
		<div class="justify-begin text-muted-500 dark:text-muted-400 mt-2 flex flex-wrap gap-2 text-sm">
			<div>Click the button below to watch and learn more about setting up your account.</div>
		</div>
		<button
			(click)="modal.toggleModal()"
			class="border-brand text-brand leading-2 bg-brand/20 hover:bg-brand hover:shadow-brand/40 inline-flex cursor-pointer items-center justify-between rounded-full border-2 py-1 pl-1 pr-4 font-sans text-sm transition-all duration-300 hover:text-white hover:shadow-md"
		>
			<span class="ml-2 block font-sans font-medium">Get Started</span>
		</button>
	</div>
</div>
