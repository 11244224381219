import { Injectable, NgZone } from '@angular/core';
import { appName } from '@core/constants/common.constants';
import { environment } from '@env/environment';
import { chain, configureChains, connect, createClient, signMessage, watchAccount, watchNetwork } from '@wagmi/core';
import { BehaviorSubject } from 'rxjs';
import { Chain } from 'wagmi';
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { publicProvider } from 'wagmi/providers/public';

import { Provider } from '../../constants/provider.constants';
import { Web3Provider } from '../../types/web3-provider';
import { BcubeContractService } from '../bcube-contract.service';
import { BraveConnector } from './connectors/BraveWalletConnector';


@Injectable({
	providedIn: 'root',
})
export class EthereumService {
	public isWalletConnected$ = new BehaviorSubject<boolean>(false);
	public isBraveWallet$ = new BehaviorSubject<boolean>(false);
	public currentEthAddress$ = new BehaviorSubject<string>('');
	public currentChainId$ = new BehaviorSubject<number>(null);
	public shouldOpenWallet$ = new BehaviorSubject<boolean>(false);

	private chains: Chain[] = [
		chain.mainnet,
		...environment.enablesTestnets ? [chain.goerli] : [],
	];

	private connectors = [
		new CoinbaseWalletConnector({
			chains: this.chains,
			options: {
				appName,
			},
		}),
		new MetaMaskConnector({
			chains: this.chains,
		}),
		new BraveConnector({
			chains: this.chains,
		}),
	];

	constructor(public bcubeContractService: BcubeContractService, private ngZone: NgZone) {
		const { provider } = configureChains(this.chains, [
			publicProvider()
		]);
		createClient({
			autoConnect: true,
			connectors: this.connectors,
			provider,
		});
		this.setupWatchers();
		this.isBraveWallet$.next(Boolean(window.ethereum?.isBraveWallet));
	}

	public async connect(provider: Web3Provider): Promise<void> {
		switch (provider) {
			case Provider.METAMASK:
				await this.connectMetaMask();
				break;
			case Provider.COINBASE:
				await this.connectCoinBase();
				break;
			case Provider.BRAVE:
				await this.connectBrave();
				break;
			default:
				break;
		}
		this.shouldOpenWallet$.next(true);
	}

	private async connectMetaMask(): Promise<void> {
		await connect({ connector: this.connectors[1] })
	}

	private async connectBrave(): Promise<void> {
		if (window.ethereum) {
			await connect({ connector: this.connectors[2] })
		} else {
			// TODO: handle case when brave is not avalable
			// eslint-disable-next-line
			console.log("No wallet");
		}
	}

	private async connectCoinBase(): Promise<void> {
		await connect({ connector: this.connectors[0] })
	}

	public async signMessage(message: string): Promise<string> {
		return await signMessage({ message });
	}

	private updateConnectedStatus(isConnected: boolean, address?: string) {
		this.ngZone.run(() => {
			this.isWalletConnected$.next(isConnected);
			if (isConnected) {
				this.currentEthAddress$.next(address);
			} else {
				this.shouldOpenWallet$.next(false);
			}
		});
	}

	private setupWatchers(): void {
		watchAccount((accountData) => {
			if (accountData.isConnected) {
				this.updateConnectedStatus(true, accountData.address);
			} else {
				this.updateConnectedStatus(false);
			}
		});

		watchNetwork((networkData) => {
			if (networkData.chain) {
				this.currentChainId$.next(networkData.chain.id);
			}
		});
	}
}
