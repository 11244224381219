import {
	animate,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Routes } from '@app/core/constants/router.constants';
import { SettingsTabs } from '@app/core/constants/settings.constants';
import { DialogService } from '@app/core/services/dialog.service';
import { PlanService } from '@app/core/services/plan.service';
import { SecurityService } from '@app/core/services/security.service';
import { StakingService } from '@app/core/services/staking.service';
import { UserBotService } from '@app/core/services/user-bot.service';
import { IBot } from '@b-cube/interfaces/bot';
import { IExchangeAccount } from '@b-cube/interfaces/exchange-account';
import { IPaymentBCubeDetails, IPaymentCreditCardDetails, PAYMENT_METHOD_EVENT } from '@b-cube/interfaces/payment';
import { BotPlan, GURU_BOT_SPECIAL_CASE, IBotSubscription } from '@b-cube/interfaces/plan';
import { PaymentMethod, RestrictedServices, UserInfo } from '@b-cube/interfaces/user';
import { IUserBot } from '@b-cube/interfaces/user-bot';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

@Component({
	selector: 'app-widget-marketplace-subscription-bot-third-party',
	templateUrl: './widget-marketplace-subscription-bot-third-party.component.html',
	animations: [
		trigger(
			'insertRemoveTrigger',
			[
				transition(
					':enter',
					[
						style({ height: 0, opacity: 0 }),
						animate('80ms',
							style({ height: 300, opacity: 1 }))
					]
				)
			]
		)
	]
})
export class WidgetMarketplaceSubscriptionBotThirdPartyComponent implements OnChanges {
	@Input() user: UserInfo;
	@Input() bot: IBot;
	@Input() exchangeAccounts: IExchangeAccount[];
	@Input() userBotSubscription: IBotSubscription;
	@Input() isAlreadyPlugged: boolean;
	@Input() userHasSupportedExchanges: boolean;
	@Input() botPlan: BotPlan;
	// eslint-disable-next-line
	@Output() closeModal = new EventEmitter<IUserBot>();

	public isLoading = true;
	public isButtonLoading = false;
	public isSubscriptionLoading = true;
	public isModalOpen = false;
	public isSpecialCaseBot: boolean;
	public isCreditCard: boolean;
	public paymentMethod: PAYMENT_METHOD_EVENT;

	public ccPaymentDetails: IPaymentCreditCardDetails;
	public bcubePaymentDetails: IPaymentBCubeDetails;

	public Routes = Routes;
	public SettingsTabs = SettingsTabs;

	constructor(
		public securityService: SecurityService,
		private userBotService: UserBotService,
		private toasterService: ToastrService,
		private planService: PlanService,
		private stakingService: StakingService,
		private dialogService: DialogService
	) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes['botPlan']) {
			this.isSpecialCaseBot = this.botPlan?.id === GURU_BOT_SPECIAL_CASE.planId;
		}

		if (changes['userBotSubscription'] && this.userBotSubscription) {
			this.isCreditCard = this.userBotSubscription.isCreditCard || false;
			this.isSubscriptionLoading = false;
		}
		this.getBCUBEPaymentDetails();
	}

	public canUseService(): boolean{
		return this.securityService.canUseServices(this.user, RestrictedServices.BOT);
	}

	private getBCUBEPaymentDetails(): void {
		this.stakingService.getBotPlanBCubePaymentDetails(this.botPlan?.id).subscribe(
			(details) => {
				this.bcubePaymentDetails = details;
				this.isLoading = false;
			}
		);
	}

	// getters
	public get paymentDetails(): IPaymentBCubeDetails | IPaymentCreditCardDetails {
		if (this.paymentMethod === PaymentMethod.CREDIT_CARD) {
			return this.creditCardPaymentDetails;
		}

		return this.bCUBEPaymentDetails;

	}

	public get creditCardPaymentDetails(): IPaymentCreditCardDetails {
		return {
			initialPrice: this.botPlan?.price,
			tierName: this.user?.tier.name,
			discount: this.user?.tier.botDiscountForCC,
			priceDiscount: this.creditCardPriceWithDiscount,
		}
	}

	public get bCUBEPaymentDetails(): IPaymentBCubeDetails {
		return this.bcubePaymentDetails;
	}

	public get creditCardPriceWithDiscount(): number {
		return this.botPlan?.price * (100 - this.user?.tier.botDiscountForCC) / 100;
	}

	public openModal(event: Event) {
		if (!this.userHasSupportedExchanges) {
			this.toasterService.warning('No compatible exchange account');

			return;
		}

		this.paymentMethod = (event.target as HTMLTextAreaElement).value as PAYMENT_METHOD_EVENT;
		this.isModalOpen = true
	}

	public closeAddBot(userBot: IUserBot) {
		this.isModalOpen = false;
		this.closeModal.emit(userBot);
	}

	public async cancelCreditCardSubscription() {
		if (!await this.dialogService.confirm(`Are you sure you want to unsubscribe from the ${this.bot.name}?`, 'Unsubscribe')) {
			return;
		}
		this.isButtonLoading = true;
		try {
			await lastValueFrom(this.planService.cancelCreditCardSubscription(this.bot.id));

			this.userBotService.deleteUserBotLocally(this.bot.id);

			this.toasterService.success(`The subscription '${this.bot.name}' cancelled.`);
		} catch (e) {
			this.toasterService.error(`The subscription couldn't be cancelled.`)
		} finally {
			this.isButtonLoading = false;
		}
	}
}
