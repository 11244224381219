import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-eth',
  templateUrl: './icon-eth.component.html'
})
export class IconEthComponent {
  @Input() class = ''

}
