
import { Injectable } from '@angular/core';
import { KYCStatus } from '@b-cube/database/types';
import { RestrictedServices, UserInfo } from '@b-cube/interfaces/user';

import {
	COUNTRIES_RESTRICTION_TEXT,
	COUNTRIES_RESTRICTION_TYPE_TEXT,
	KYC_MFA_RESTRICTION_TEXT,
	KYC_MFA_RESTRICTION_TYPE_TEXT,
	KYC_RESTRICTION_TEXT,
	KYC_RESTRICTION_TYPE_TEXT,
	MFA_RESTRICTION_TEXT,
	MFA_RESTRICTION_TYPE_TEXT } from '../constants/tips.constants';

@Injectable({
	providedIn: 'root',
})
export class SecurityService {

	public canUseServices(user: UserInfo, service: RestrictedServices): boolean {
		// french users can't use any service
		if ( ! user || ! user.security
			|| user.security.isRestricted) {
				return false;
			}

		// only french people are restricted from pluggin exchage accounts
		if (service === RestrictedServices.EXCHANGE_ACCOUNT) {
			return true;
		}

		// bot, plan : need 2FA and KYC
		return user.security.twoFactorEnabled
			&& ( ! user.security.kycRequired
				||
				user.security.identityVerificationStatus === KYCStatus.APPROVED
		);
	}

	public getRestrictionTypeLabel(user: UserInfo): string {
		if (! user || ! user.security) {
			return '';
		}

		if (user.security.isRestricted) {
			return COUNTRIES_RESTRICTION_TYPE_TEXT;
		}

		if (! user.security.twoFactorEnabled) {
			if (user.security.identityVerificationStatus !== KYCStatus.APPROVED) {
				return KYC_MFA_RESTRICTION_TYPE_TEXT;
			}

			return MFA_RESTRICTION_TYPE_TEXT;
		}

		if (user.security.identityVerificationStatus !== KYCStatus.APPROVED) {
			return KYC_RESTRICTION_TYPE_TEXT;
		}

		return '';
	}

	public getRestrictionDescriptionLabel(user: UserInfo): string {
		if (! user || ! user.security) {
			return '';
		}

		if (user.security.isRestricted) {
			return COUNTRIES_RESTRICTION_TEXT;
		}

		if (! user.security.twoFactorEnabled) {
			if (user.security.identityVerificationStatus !== KYCStatus.APPROVED) {
				return KYC_MFA_RESTRICTION_TEXT;
			}

			return MFA_RESTRICTION_TEXT;
		}

		if (user.security.identityVerificationStatus !== KYCStatus.APPROVED) {
			return KYC_RESTRICTION_TEXT;
		}

		return '';
	}

	public shouldRedirectToSecurity(user: UserInfo): boolean {
		if (! user || ! user.security) {
			return false;
		}

		if (user.security.isRestricted) {
			return false;
		}

		return true;
	}
}
