import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-plus',
  templateUrl: './icon-plus.component.html'
})
export class IconPlusComponent {
  @Input() class = '';

}
