import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-widget-marketplace-header-simple',
	templateUrl: './widget-marketplace-header-simple.component.html'
})
export class WidgetMarketplaceHeaderSimpleComponent {

	@Input() botsCount?: number;

}
