import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { loadingOptions, PerformanceDisplayMode } from '@app/core/constants/charts.constants';
import { IPerformance } from '@b-cube/interfaces/performance';
import { EChartsOption, graphic } from 'echarts';

@Component({
	selector: 'app-chart-area-card',
	templateUrl: './chart-area-card.component.html'
})
export class ChartAreaCardComponent implements OnChanges{

	@Input() chartData: IPerformance[];
	@Input() isLoading: boolean;
	@Input() fake = false;

	public options: EChartsOption = {};
	public loadingOptions = loadingOptions;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['chartData'] && changes['chartData'].currentValue) {
			this.getChartOptions();
		}
	}

	private getChartOptions(): void {
		const axisColor = this.fake ? 'rgb(35 203 213 / 0.2)' : '#cbd5e1';
		const labelColor = this.fake ?'rgb(35 203 213 / 0.2)' : '#94a3b8';
		const mainColor =  this.fake ? 'rgb(35 203 213 / 0.2)' : '#23cbd5';
		const secondaryColor =  this.fake ?'rgb(35 203 213 / 0.2)' :'#E6FEFF';

		const data = this.chartData ?? [];
		const datePipe = new DatePipe('en-US');
		const xAxisData = data.map(point => datePipe.transform(point.time, PerformanceDisplayMode.MONTH_YEAR));
		const yAxisData = data.map(point => (point.value - 1) * 100);
		const isEmptyData = xAxisData.length === 0;


		this.options = <EChartsOption>{
			title: {
				show: (!this.chartData || this.chartData.length === 0) && !this.isLoading,
				textStyle: {
					color: '#64748B',
					fontSize: 12,
					fontStyle: 'italic'
				},
				text: 'No live trading history yet',
				left: 'center',
				top: '20'
			},
			animation: ! this.fake,
			responsive: true,
			maintainAspectRatio: false,
			xAxis: {
				show: false,
				type: 'category',
				boundaryGap: false,
				data: xAxisData,
				axisLabel: {
					color: labelColor,
				},
				axisTick: {
					lineStyle: {
						color: axisColor,
					},
				},
				axisLine: {
					lineStyle: {
						color: axisColor,
					},
				},
			},
			yAxis: {
				show: false,
				type: 'value',
			},
			grid: {
				left: 0,
				top: 0,
				right: 0,
				bottom: 0,
			},
			series: [
				{
					name: 'Performance',
					data: yAxisData,
					type: 'line',
					// smooth: true,
					showSymbol: false, //! this.fake,
					areaStyle: this.fake ? null : {
						color:  new graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0,
								color: mainColor,
							},
							{
								offset: 1,
								color:secondaryColor
							},
						]),
						opacity: 0.4
					},
					lineStyle: {
						width: 2,
						color: mainColor,
						join: 'round',
					},
					itemStyle: {
						color: mainColor,
						lineStyle: {
							width: 2,
							type: 'solid',
							color: mainColor,
						},
					},
					emphasis: {
						color: 'white',
						lineStyle: {
							width: 2,
							type: 'dotted',
							color: mainColor,
						},
					},
				},
			],
			height: 112,
			width: '100%',
		};
	}
}
