import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ExchangeAccount } from '@app/core/models/exchange-account';
import { IBot } from '@b-cube/interfaces/bot';

@Component({
	selector: 'app-bot-switch',
	templateUrl: './bot-switch.component.html',
	animations: [
		trigger('openClose', [
			state('open', style({
				transform: 'translateY(0)',
				opacity: 1
			})),
			state('closed', style({
				transform: 'translateY(.25rem)',
				opacity: 0
			})),
			transition('open => closed', [
				animate('0.3s')
			]),
			transition('closed => open', [
				animate('0.2s')
			]),
		]),
	],
})
export class BotSwitchComponent {
	@Input() selectedBot: IBot;
	@Input() bots: IBot[];

	public isMenuOpen = false;
	public redirectUrl = '/agents';

	constructor(private router: Router) { }

	toggleMenu(): void {
		this.isMenuOpen = !this.isMenuOpen;
	}

	closeMenu(): void {
		this.isMenuOpen = false;
	}

	addBot(): void {
		this.router.navigate(['marketplace']);
	}

	navigateTo(element: ExchangeAccount) {
		let url = this.redirectUrl;
		if (element.id > 0) {
			url += '/' + element.id
		}

		this.router.navigate([url]);
	}

}
