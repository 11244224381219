export const StakingAbi = [
	{
	  inputs: [
		{
		  internalType: "contract IERC20",
		  name: "_bcube",
		  type: "address",
		},
	  ],
	  payable: false,
	  stateMutability: "nonpayable",
	  type: "constructor",
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: "address",
		  name: "staker",
		  type: "address",
		},
		{
		  indexed: false,
		  internalType: "uint256",
		  name: "bcubeAmount",
		  type: "uint256",
		},
	  ],
	  name: "LogBcubeStaking",
	  type: "event",
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: "address",
		  name: "unstaker",
		  type: "address",
		},
		{
		  indexed: false,
		  internalType: "uint256",
		  name: "bcubeAmount",
		  type: "uint256",
		},
	  ],
	  name: "LogBcubeUnstaking",
	  type: "event",
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: "address",
		  name: "sender",
		  type: "address",
		},
		{
		  indexed: false,
		  internalType: "uint256",
		  name: "weiReceived",
		  type: "uint256",
		},
	  ],
	  name: "LogEtherReceived",
	  type: "event",
	},
	{
	  payable: true,
	  stateMutability: "payable",
	  type: "fallback",
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: "address",
		  name: "",
		  type: "address",
		},
	  ],
	  name: "bcubeStakeRegistry",
	  outputs: [
		{
		  internalType: "uint256",
		  name: "",
		  type: "uint256",
		},
	  ],
	  payable: false,
	  stateMutability: "view",
	  type: "function",
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: "uint256",
		  name: "_bcubeAmount",
		  type: "uint256",
		},
	  ],
	  name: "stake",
	  outputs: [],
	  payable: false,
	  stateMutability: "nonpayable",
	  type: "function",
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: "uint256",
		  name: "_bcubeAmount",
		  type: "uint256",
		},
	  ],
	  name: "unstake",
	  outputs: [],
	  payable: false,
	  stateMutability: "nonpayable",
	  type: "function",
	},
  ];
