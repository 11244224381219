import { Component, Input } from '@angular/core';
import { IBot } from '@b-cube/interfaces/bot';

@Component({
	selector: 'app-widget-marketplace-trades',
	templateUrl: './widget-marketplace-trades.component.html'
})
export class WidgetMarketplaceTradesComponent {
	@Input() bot: IBot;
	@Input() averageTradePerMonth: number;

	checkValue(value: any) {
		return typeof value === 'number';
	}
}
