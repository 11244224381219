import { Component, Input } from '@angular/core';
import { UserInfo } from '@b-cube/interfaces/user';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-widget-wizard-current-tier',
	templateUrl: './widget-wizard-current-tier.component.html'
})
export class WidgetWizardCurrentTierComponent {
	@Input() user$: Observable<UserInfo>;
	@Input() userTierProgress$: Observable<any>;
	@Input() initials$: Observable<string>;
}
