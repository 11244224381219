
export enum BALANCE_HISTORY_FREQUENCY {
	FIFTEEN_MINUTES = '15m',
	ONE_HOUR = '1h',
	ONE_DAY = '1d',
	ONE_WEEK = '7d',
	TWO_WEEKS = '14d',
	ONE_MONTH = '30d',
	THREE_MONTHES = '90d'
}

export enum BALANCE_HISTORY_RANGE {
	ONE_DAY = '1d',
	ONE_WEEK = '7d',
	ONE_MONTH = '30d',
	THREE_MONTHES = '90d',
	SIX_MONTHES = '180d',
	ONE_YEAR = '365d',
	ALL_HISTORY = 'all'
}


export interface BalanceQueryInput{
	range: BALANCE_HISTORY_RANGE;
	frequency: BALANCE_HISTORY_FREQUENCY;
}

export interface Balance {
	time: string;
	usdQuote: number;
}
