import { Component, Input } from '@angular/core';
import { TierDifference } from '@app/shared/types/tier-difference';
import { Tier } from '@b-cube/interfaces/staking';


@Component({
	selector: 'app-widget-wizard-unstake-tier-comparison',
	templateUrl: './widget-wizard-unstake-tier-comparison.component.html'
})
export class WidgetWizardUnstakeTierComparisonComponent {
	@Input() eligibleTier: Tier;
	@Input() currentTier: Tier;
	@Input() tierDifference: TierDifference;
}
