import { AfterContentInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PERFORMANCE_HISTORY_PERIODS, PerformanceDisplayMode, PerformancePeriod } from '@app/core/constants/charts.constants';
import { IBot } from '@b-cube/interfaces/bot';
import { IPerformance } from '@b-cube/interfaces/performance';

const DEFAULT_FORM_VALUES = {
	period: PERFORMANCE_HISTORY_PERIODS[0].key
};

interface PnL {
	value: number,
	abs: number,
	sign: string,
	cssClass: string
}

@Component({
	selector: 'app-widget-bot-performance',
	templateUrl: './widget-bot-performance.component.html'
})
export class WidgetBotPerformanceComponent implements AfterContentInit, OnChanges {
	@Input() bot: IBot;
	@Input() performancePeriods: PerformancePeriod[];
	@Input() performance: IPerformance[];
	@Input() loading: boolean;
	@Input() performanceChartForm: FormGroup;

	public pnl: PnL = {
		value: 0,
		abs: 0,
		sign: '',
		cssClass: ''
	};

	public get period(): string {
		return this.performanceChartForm.controls['period'].value;
	}

	ngAfterContentInit(): void {
		this.performanceChartForm.patchValue(DEFAULT_FORM_VALUES);
	}

	ngOnChanges(changes: SimpleChanges): void {

		if (!changes['performance']) {
			return;
		}

		if (!this.performance || this.performance.length === 0) {
			this.pnl.value = 0;
			this.pnl.value = 0;
			this.pnl.sign = '';
			this.pnl.cssClass = '';
		} else {
			this.pnl.value = this.performance.at(-1).value;
			this.pnl.abs = Math.abs(this.pnl.value);
			this.pnl.sign = this.pnl.value > 0 ? '+' : '-';
			this.pnl.cssClass = this.pnl.value > 0 ? 'text-green-500' : 'text-rose-500';
		}
	}

	selectPeriod(period: PerformancePeriod) {
		this.performanceChartForm.patchValue({ period: period.key })
	}

	public isPeriodSelected(period: PerformancePeriod): boolean {
		return period.key === this.period;
	}

	public getDisplayMode(): PerformanceDisplayMode {
		// Find the period with the matching key
		const period = this.performancePeriods.find(p => p.key === this.period);
		if (!period) {
			// Return the default display mode if the period is not found
			return PerformanceDisplayMode.DAY;
		}

		return period.displayTimeFormat || PerformanceDisplayMode.DAY;
	}
}
