import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-check-animated',
  templateUrl: './icon-check-animated.component.html',
  styleUrls: ['./icon-check-animated.component.css']
})
export class IconCheckAnimatedComponent {
  @Input() class = ''

}
