export enum ExecutionErrorType {
	INVALID_API_KEYS = 'INVALID_API_KEYS',
	INVALID_PAIR = 'INVALID_PAIR',
	INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
	CANNOT_SEND_ENTRY = 'CANNOT_SEND_ENTRY',
	CANNOT_SEND_EXIT_ORDER = 'CANNOT_SEND_EXIT_ORDER',
	ORDER_WITH_NO_CHILDREN = 'ORDER_WITH_NO_CHILDREN',
	CHILD_ORDER_CANCELLED = 'CHILD_ORDER_CANCELLED',
	INITIAL_ORDER_CANCELLED = 'INITIAL_ORDER_CANCELLED',
	MARKET_ORDER_NOT_FULFILLED = 'MARKET_ORDER_NOT_FULFILLED',
	LIMIT_ORDER_NOT_FULFILLED = 'LIMIT_ORDER_NOT_FULFILLED',
	LIMIT_ORDER_CANCELED = 'LIMIT_ORDER_CANCELED',
	MISSING_ORDER = 'MISSING_ORDER',
	ONE_POSITION_ALREADY_EXISTS = 'ONE_POSITION_ALREADY_EXISTS',
	UNKNOWN_EXCHANGE = 'UNKNOWN_EXCHANGE',
	UNKNOWN = 'UNKNOWN',
	NONE = 'NONE',
	EMPTY = '',
	LIMIT_ORDER_NOT_CANCELED = 'LIMIT_ORDER_NOT_CANCELED',
	MISSING_SETTING = 'MISSING_SETTING',
	INVALID_REQUEST = 'INVALID_REQUEST',
	EXCHANGE_API_TIMEOUT = 'EXCHANGE_API_TIMEOUT',
	NETWORK_ERROR = 'NETWORK_ERROR'
}

export const executionErrorLabels = {
	[ExecutionErrorType.INVALID_API_KEYS]: 'Invalid API keys',
	[ExecutionErrorType.INVALID_PAIR]: 'Pair not supported by the exchange',
	[ExecutionErrorType.INSUFFICIENT_BALANCE]: 'Not enough balance to fulfill the trade',
	[ExecutionErrorType.CANNOT_SEND_ENTRY]: 'Unable to place the entry order',
	[ExecutionErrorType.CANNOT_SEND_EXIT_ORDER]: 'Unable to place the exit order',
	[ExecutionErrorType.ORDER_WITH_NO_CHILDREN]: 'The OCO order could not be placed',
	[ExecutionErrorType.INITIAL_ORDER_CANCELLED]: 'One entry order was externally canceled',
	[ExecutionErrorType.MARKET_ORDER_NOT_FULFILLED]: 'The market order was not fulfilled',
	[ExecutionErrorType.LIMIT_ORDER_NOT_FULFILLED]: 'The limit order was not fulfilled',
	[ExecutionErrorType.LIMIT_ORDER_CANCELED]: 'The limit order has been canceled',
	[ExecutionErrorType.MISSING_ORDER]: 'Execution error',
	[ExecutionErrorType.ONE_POSITION_ALREADY_EXISTS]: 'One position already exists on the same exchange account and the same pair',
	[ExecutionErrorType.UNKNOWN_EXCHANGE]: 'Execution error',
	[ExecutionErrorType.UNKNOWN]: 'Execution error',
	[ExecutionErrorType.NONE]: '',
	[ExecutionErrorType.EMPTY]: '',
	[ExecutionErrorType.LIMIT_ORDER_NOT_CANCELED]: 'Unable to modify the entry order',
	[ExecutionErrorType.MISSING_SETTING]: 'Your agent is not correctly set up',
};
