import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserPlanSubscription } from '@b-cube/interfaces/user';
import { lastValueFrom } from 'rxjs';

import { CRYPTO_TRANSACTION_URL } from '../constants/api-urls.constants';

@Injectable({
	providedIn: 'root'
})
export class CryptoTransactionService {

	constructor(private http: HttpClient) { }

	public createCryptoTransaction(subscription: IUserPlanSubscription): Promise<void> {
		return lastValueFrom(this.http.post<void>(CRYPTO_TRANSACTION_URL, subscription));
	}
}
