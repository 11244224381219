<svg
  width="10"
  height="14"
  viewBox="0 0 10 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.90995 6.049C10.109 4.71807 9.09573 4.00264 7.71015 3.52538L8.15964 1.72252L7.06218 1.44905L6.62461 3.20445C6.3361 3.13249 6.03981 3.06469 5.74533 2.99747L6.18607 1.23049L5.08929 0.957031L4.63956 2.75931C4.4008 2.70495 4.1663 2.65122 3.9388 2.59462L3.94007 2.58895L2.42665 2.21102L2.13471 3.38317C2.13471 3.38317 2.94893 3.56982 2.93177 3.5813C3.37618 3.69222 3.45655 3.98641 3.4432 4.21959L2.93119 6.27348C2.96179 6.28125 3.00148 6.29249 3.04528 6.31009L3.01336 6.30212C2.98629 6.29535 2.95816 6.28831 2.92928 6.2814L2.2116 9.1586C2.15729 9.29362 2.01944 9.49624 1.70873 9.4193C1.71973 9.43524 0.911079 9.22024 0.911079 9.22024L0.366211 10.4765L1.79437 10.8325C1.95073 10.8717 2.10524 10.912 2.25818 10.9519L2.25834 10.9519C2.36523 10.9798 2.47136 11.0074 2.57682 11.0344L2.12268 12.8579L3.21887 13.1314L3.66861 11.3272C3.96808 11.4085 4.2587 11.4835 4.54321 11.5542L4.09498 13.3499L5.19249 13.6233L5.64658 11.8032C7.51798 12.1574 8.92512 12.0146 9.51741 10.3219C9.99467 8.9591 9.49365 8.17303 8.50912 7.66043C9.2262 7.49505 9.76633 7.02342 9.91034 6.04915L9.91 6.0489L9.90995 6.049ZM7.40251 9.56507C7.09135 10.8154 5.13424 10.2985 4.23879 10.062C4.15824 10.0407 4.08628 10.0217 4.02488 10.0065L4.62753 7.59057C4.70236 7.60926 4.7938 7.62978 4.89739 7.65302C5.82362 7.86089 7.72133 8.28677 7.40256 9.56507H7.40251ZM5.08317 6.53177C5.82971 6.73101 7.45829 7.16564 7.74192 6.02925H7.74202C8.03164 4.867 6.44894 4.51659 5.67594 4.34545C5.58898 4.3262 5.51227 4.30922 5.44962 4.2936L4.90324 6.48466C4.95483 6.49752 5.01536 6.51367 5.08317 6.53177Z"
    fill="currentColor"
  />
</svg>
