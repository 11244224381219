<div class="group flex items-center gap-x-4 w-full bg-white dark:bg-muted-800 border border-muted-200
		dark:border-muted-700 px-6 py-14 rounded-lg">
	<div class="w-full">
		<div class="w-full flex items-center justify-between text-center mb-5">
			<div class="flex-1">
				<label class="inline-block font-sans text-sm font-semibold tracking-wider text-left
						text-muted-400 dark:text-muted-300 mb-2">
					Pair
				</label>
				<bot-pair [bot]="bot" [iconSize]="9" [maxCryptoNumber]="5"></bot-pair>
			</div>
			<div class="flex-1">
				<label class="inline-block font-sans text-sm font-semibold tracking-wider
						text-left text-muted-400 dark:text-muted-300 mb-2">
					Exchanges
				</label>
				<div class="flex justify-center">
					<span *ngFor="let exchange of bot?.supportedExchanges" class="relative flex justify-center items-center h-11 w-11 rounded-full
								bg-indigo-100 border-4 border-white dark:border-muted-800 z-10 -ml-4">
						<app-exchange-icon [name]="exchange" class="text-indigo-500" [svgClass]="'w-8 h-8'" >
						</app-exchange-icon>
					</span>
				</div>
			</div>
		</div>
		<div class="w-full text-center">
			<p
				class="inline-block font-sans text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300 mb-2">
				Trades/month
			</p>
			<span 
			[class]="!checkValue(averageTradePerMonth) ? 'button-loading flex justify-center' : ''"
			class="block font-sans text-muted-600 dark:text-muted-100 font-semibold">
				{{ averageTradePerMonth }} trades
			</span>
		</div>
	</div>
</div>
