<div class="w-full min-h-screen">
	<!--Main wizard-->
	<div *ngIf="currentStep <= 3" class="w-full">
		<div class="mb-5">
			<div class="flex items-center justify-between mb-4">
				<h2 *ngIf="currentStep === 0" class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
					Pickup a plan
				</h2>
				<h2 *ngIf="currentStep === 1" class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
					Your active plan
				</h2>
				<h2 *ngIf="currentStep === 2"
					class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
					Payment method
				</h2>
				<h2 *ngIf="currentStep === 3"
					class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
					Payment details
				</h2>

				<a [routerLink]="'/plan'" class="font-sans text-sm font-semibold text-brand">Cancel</a>
			</div>
		</div>

		<div class="w-full flex flex-col md:flex-row gap-6">
			<!--Content-->
			<div class="w-full lg:w-3/4">
				<div
					class="w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-8 py-12 rounded-lg">

					<!--Step 1-->
					<div *ngIf="currentStep === 0" class="grid md:grid-cols-2 gap-8 place-items-center">
						<div class="w-full">
							<div class="w-full max-w-xs space-y-4">
								<!--plan-->
								<div *ngFor="let plan of creditCardPlans" class="relative group">
									<input [(ngModel)]="targetPlanId" type="radio" name="plan_selection"
										class="peer absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-20"
										[value]="plan.id" />
									<div class="absolute top-1/2 right-5 -translate-y-1/2 flex items-center justify-center h-6 w-6 rounded-full border-2 transition-colors duration-300"
										[class]="targetPlanId === plan.id ? 'text-secondary-500 border-secondary-500' : 'text-transparent border-muted-200 dark:border-muted-700'">
										<span class="block h-2 w-2 rounded-full bg-current"></span>
									</div>
									<div class="flex items-center w-full bg-white dark:bg-muted-800 border p-3 rounded-lg transition-colors duration-300"
										[class]=" targetPlanId  === plan.id ? 'border-secondary-500' : 'border-muted-200 dark:border-muted-700'">
										<div>
											<h3 class="font-sans font-medium text-xl text-secondary-500">
												{{plan.name}}
											</h3>
										</div>
										<div class="ml-auto pr-12 w-[150px]">
											<h3 class="font-sans font-semibold text-xl text-muted-500">
												{{isFreemium(plan) ? 'Free' : plan.price}}
											</h3>
											<p class="font-sans text-xs text-muted-400">
												{{isFreemium(plan) ? 'No credit card' : 'EUR - monthly'}}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!--plan details-->
						<div>
							<div class="mb-8">
								<h4 class="font-semibold text-muted-700 dark:text-muted-100">
									Plan details
								</h4>
								<p class="text-sm text-muted-400">
									Compare features between plans
								</p>
							</div>

							<div class="space-y-3 font-sans">
								<!--Item-->
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Number of agents</span>
									<span *ngIf="getTargetPlan() !== undefined"
										class="flex items-center h-8 font-semibold text-muted-600 dark:text-muted-100 text-xl pr-2">&#8734;</span>
								</div>
								<!--Item-->
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Maximum investment</span>
									<span *ngIf="getTargetPlan() !== undefined"
										class="h-8 font-semibold text-muted-600 dark:text-muted-100 min-w-[68px] text-right"
										[ngClass]="[getTargetPlan()?.tradingLimit === UNLIMITED_TRADING_LIMIT ? 'text-xl pr-2' : '']">
										{{getTargetPlan()?.tradingLimit === UNLIMITED_TRADING_LIMIT ? '&#8734;' : (getTargetPlan()?.tradingLimit | currency:'USD':'symbol':'1.0-2')}}
									</span>
								</div>
								<!--Item-->
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Switching between agents</span>
									<feature-status *ngIf="getTargetPlan() !== undefined"
										[status]="getTargetPlan()?.switchingBetweenBots"></feature-status>
								</div>
								<!--Item-->
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Technical Screener</span>
									<feature-status *ngIf="getTargetPlan() !== undefined"
										[status]="getTargetPlan()?.technicalScreener"></feature-status>
								</div>
							</div>
						</div>
					</div>

					<!--Step 2-->
					<div *ngIf="currentStep === 1" class="flex flex-col gap-6 md:gap-4 py-8">
						<div class="w-full flex items-center flex-row md:flex-row">
							<!--Profile-->
							<app-widget-wizard-profile class="flex-1" [user$]="user$"
								[userTierProgress$]="userTierProgress$" [initials$]="initials$">
							</app-widget-wizard-profile>

							<!--Current-->
							<div class="flex-1 h-full px-4">
								<div class="flex items-center justify-center md:justify-start gap-x-2">
									<img src="/assets/img/illustrations/credit-card.png" class="object-contain w-14"
										alt="Illustration" />
									<div>
										<h4 class="text-[0.65rem] font-semibold text-muted-400 uppercase">
											Current plan
										</h4>
										<h3 class="font-sans font-medium text-2xl text-secondary-500 capitalize">
											{{user?.plan.name}}
										</h3>
									</div>
								</div>

								<p class="font-sans text-4xl text-secondary-500 py-5 text-center md:text-left">
									<span>{{ (currentPlanPrice$ | async) | currency:'EUR':'symbol':'1.2-2'}}</span>
									<span class="text-sm text-muted-400"> / month</span>
								</p>

								 <!--Progress-->
								 <div class="flex flex-col py-3" *ngIf="user?.plan.tradingLimit !== UNLIMITED_TRADING_LIMIT">
									<div class="flex items-center justify-between font-sans">
										<span class="text-sm text-muted-400">{{tradingVolume$ | async | currency:'USD':'symbol':'1.0-0'}} / {{user?.plan.tradingLimit}}</span>
									</div>
									<app-progress-bar [value]="tradingPourcent$ | async" class="text-secondary-500"></app-progress-bar>
								  </div>

								  <div class="flex flex-col py-3" *ngIf="user?.plan.tradingLimit === UNLIMITED_TRADING_LIMIT">
									<div class="flex items-center justify-between font-sans">
									  <span class="text-sm text-muted-400">Current investment: {{tradingVolume$ | async | currency:'USD':'symbol':'1.0-0'}}</span>
									</div>
								  </div>
							</div>

							<!--Next -->
							<app-widget-wizard-next-plan [nextPlan]="getTargetPlan()" [planDifference]="getPlanDifference()"></app-widget-wizard-next-plan>
						</div>

						<div  class="w-full flex flex-row justify-end gap-x-2 mt-8">
							<div *ngFor="let addon of getTargetPlan().addons"
									class="flex flex-col items-end">
								<div class="relative ">
									<input type="checkbox" [checked]="isAddonSelected(addon.id)"
										class="peer absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-20"
										[value]="addon.id" (change)="switchAddon(addon.id)"/>
									<div class="absolute top-1/2 right-5 -translate-y-1/2 flex items-center justify-center h-5 w-5 rounded border-2 transition-colors duration-300 text-transparent
									text-transparent"
										[class]="isAddonSelected(addon.id) ? 'border-secondary-500' : 'border-muted-200 dark:border-muted-700'">
										<app-icon *ngIf="isAddonSelected(addon.id)" name="check" class="h-2 w-2 text-secondary-500"></app-icon>
									</div>
									<div class="flex items-center w-full bg-white dark:bg-muted-800 border p-3 pr-12 transition-colors duration-300"
										[class]=" isAddonSelected(addon.id) ? 'border-secondary-500' : 'border-muted-200 dark:border-muted-700'">
										<div>
											<h3 class="font-sans font-medium text-secondary-500">
												{{addon.name}} <span class="font-sans text-xs text-muted-400">( +{{addon.price}} {{addon.currency}} monthly)</span>
											</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!--Step 3-->
					<div *ngIf="currentStep === 2" class="flex items-center flex-col md:flex-row gap-6 md:gap-4 py-6">
						<!--Current-->
						<app-widget-wizard-profile class="flex-1" [user$]="user$"
							[userTierProgress$]="userTierProgress$" [initials$]="initials$">
						</app-widget-wizard-profile>

						<!--Fiat-->
						<div class="flex-1">
							<div class="group relative">
								<input
									type="radio"
									[(ngModel)]="paymentMethod"
									value="CREDIT_CARD"
									class="peer absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-20" />
								<div class="text-center px-5 py-8 rounded-lg border-2 text-muted-400 transition-colors duration-300"
									[class]=" paymentMethod === 'CREDIT_CARD' ?
										'text-secondary-500 bg-secondary-500/10 border-secondary-500'
											:
										'border-transparent group-hover:bg-muted-50 dark:group-hover:bg-muted-700 group-hover:border-dashed group-hover:border-muted-200 dark:group-hover:border-muted-600'
								">
									<div class="relative h-28 w-28 rounded-full bg-secondary-500/20 mx-auto mb-4">
										<img src="/assets/img/illustrations/credit-card.png"
											class="absolute top-0 right-0 object-contain" alt="Illustration" />
									</div>

									<h4 class="font-sans font-semibold text-lg text-secondary-500">
										Credit Card
									</h4>
									<p class="font-text text-xs">
										Pay {{getFinalCCPriceWithDiscount() | currency:'EUR':'symbol':'1.0-2'}}
									</p>
								</div>
							</div>
						</div>

						<!--BCube-->
						<div class="flex-1">
							<div class="group relative">
								<input
									type="radio"
									[(ngModel)]="paymentMethod"
									value="BCUBE"
									class="peer absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-20" />
								<div class="text-center px-5 py-8 rounded-lg border-2 text-muted-400 transition-colors duration-300"
									[class]="paymentMethod === 'BCUBE' ?
										'text-brand bg-brand/10 border-brand'
											:
										'border-transparent group-hover:bg-muted-50 dark:group-hover:bg-muted-700 group-hover:border-dashed group-hover:border-muted-200 dark:group-hover:border-muted-600'
								">
									<div class="relative h-28 w-28 rounded-full bg-brand/20 mx-auto mb-4">
										<img src="/assets/img/illustrations/safe-box.png"
											class="absolute top-0 right-0 object-contain" alt="Illustration" />
									</div>

									<h4 class="font-sans font-semibold text-lg text-brand">
										$BCUBE
									</h4>
									<p class="font-text text-xs">
										Pay {{bcubePaymentDetails?.priceDiscount | currency:'$BCUBE ':'symbol':'1.0-2'}}
									</p>
								</div>
							</div>
						</div>
					</div>

					<!--payment details-->
					<div *ngIf="currentStep === 3" class="flex items-center flex-col md:flex-row gap-6 md:gap-4 py-6">
						<!--Current-->
						<app-widget-wizard-profile class="flex-0 min-w-[220px]" [user$]="user$"
							[userTierProgress$]="userTierProgress$" [initials$]="initials$">
						</app-widget-wizard-profile>

						<!--BCUBE form-->
						<app-bcube-payment-form [(months)]="months" [amount]="bcubePaymentDetails?.priceDiscount" *ngIf="paymentMethod === 'BCUBE'">
						</app-bcube-payment-form>

						<div class="flex-1 flex justify-center">
							<div class="max-w-[400px]">
								<div class="mb-8">
									<h4 class="font-semibold text-muted-700 dark:text-muted-100">
										Payment details
									</h4>
								</div>

								<div class="space-y-3 font-sans">
									<div class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 flex-0 pr-2">Items</span>
										<span class="flex flex-col items-end text-muted-600 dark:text-muted-100 pr-2 text-sm flex-1">
											<span class="text-right">{{getTargetPlan()?.name}}</span>
											<span class="text-right" *ngFor="let addonName of getSelectedAddonsName()">{{addonName}}</span>
										</span>
									</div>
									<div *ngIf="paymentMethod === 'CREDIT_CARD'" class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 pr-20">Initial price</span>
										<span  class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
											{{getFinalCCPrice() | currency:'EUR':'symbol':'1.0-2'}}
										</span>
									</div>
									<div *ngIf="paymentMethod === 'CREDIT_CARD'" class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 pr-20">Discount ({{user.tier.name }} advantage)</span>
										<span  class="h-8 text-muted-600 dark:text-muted-100 text-right">
											{{currentTier?.planDiscountForCC / 100 | percent:'1.0-0'}}
										</span>
									</div>
									<div *ngIf="paymentMethod === 'CREDIT_CARD'" class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 pr-20">Final price</span>
										<span  class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
											{{getFinalCCPriceWithDiscount() | currency:'EUR':'symbol':'1.0-2'}}
										</span>
									</div>
									<div *ngIf="paymentMethod === 'BCUBE'" class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 pr-20">Price in EUR</span>
										<span  class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
											{{months * getFinalCCPrice() | currency:'EUR':'symbol':'1.0-2'}}
										</span>
									</div>
									<div *ngIf="paymentMethod === 'BCUBE'" class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 pr-20">$BCUBE/EUR</span>
										<span  class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
											{{bcubePaymentDetails?.conversionRate}}
										</span>
									</div>
									<div *ngIf="paymentMethod === 'BCUBE'" class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 pr-20">Price in $BCUBE</span>
										<span  class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
											{{months * bcubePaymentDetails?.priceBCUBE | number:'1.0-2'}}
										</span>
									</div>
									<div *ngIf="paymentMethod === 'BCUBE'" class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 pr-20">Discount ({{ user?.tier.name }})</span>
										<span  class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
											{{bcubePaymentDetails?.discount  / 100 | percent:'1.0-0'}}
										</span>
									</div>
									<div *ngIf="paymentMethod === 'BCUBE'" class="flex items-center justify-between">
										<span class="block text-sm text-muted-400 pr-20">Final price in $BCUBE</span>
										<span  class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
											{{months * bcubePaymentDetails?.priceDiscount | number:'1.0-2'}}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Footer -->
				<div class="py-4 md:py-6">
					<div
						class="fixed z-30 inset-x-0 bottom-5 p-4 w-[calc(100%_-_2rem)] mx-[1rem] bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md md:z-0 md:rounded-none md:border-0 md:w-full md:bg-transparent dark:md:bg-transparent md:p-0 md:static md:mx-0 flex justify-end gap-x-2">
						<button *ngIf="currentStep > 0"
							class="min-w-[130px] w-full md:w-auto inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-12 border border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700 dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300"
							[ngClass]="[isLoadingBack ? 'button-loading' : '']" (click)="decrementStep()">
							<span class="font-sans text-sm">Go Back</span>
						</button>
						<button
							class="min-w-[130px] w-full md:w-auto  inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-12 text-white hover:shadow-md transition-all duration-300
									bg-secondary-500 hover:bg-secondary-500/90 hover:shadow-secondary-500/30 disabled:cursor-not-allowed disabled:opacity-40"
							[ngClass]="[isLoadingNext ? 'button-loading' : '']" (click)="incrementStep()"
							[disabled]="isButtonDisabled()">
							<span class="font-sans text-sm">{{getButtonLabel()}}</span>
						</button>
					</div>
				</div>
			</div>

			<!--Help Area-->
			<div class="w-full md:w-1/4 hidden lg:block">
				<div class="flex flex-col gap-y-6">
					<!--Tips box-->
					<div *ngIf="canUseService()"
						class="w-full bg-muted-50 dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-6 lg:p-8 rounded-lg">
						<div class="flex items-center gap-x-2 mb-4">
							<div
								class="flex items-center justify-center h-10 w-10 rounded-full bg-secondary-100 dark:bg-secondary-500/20">
								<app-icon-lightbulb class="w-4 h-4 text-secondary-500"></app-icon-lightbulb>
							</div>
							<div class="font-sans">
								<h4 class="font-semibold text-muted-700 dark:text-muted-100">
									Tips
								</h4>
								<p class="text-xs lg:text-sm text-muted-400">Good to know</p>
							</div>
						</div>

						<p class="font-sans text-xs mb-4 text-muted-500 dark:text-muted-400" [innerText]="PLAN_TIPS_TEXT"></p>

						<button (click)="navigateToStakingPage()"
						class="inline-flex items-center justify-between pl-1 pr-4 py-1 border-2 border-brand text-brand hover:text-white font-sans leading-2 text-sm bg-brand/20 hover:bg-brand hover:shadow-md hover:shadow-brand/40 rounded-full cursor-pointer transition-all duration-300">
						<span
							class="flex items-center justify-center h-6 w-6 rounded-full bg-brand text-white rotate-12">
							<app-logo class="w-4 h-4"></app-logo>
						</span>
						<span class="block font-sans font-medium ml-2">Stake</span>
					</button>
					</div>

					<div *ngIf="! canUseService()"
						class="w-full bg-muted-50 dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-6 lg:p-8 rounded-lg">
						<div class="flex items-center gap-x-2 mb-4">
							<div
								class="flex items-center justify-center h-10 w-10 rounded-full">
								<app-icon-warning class="w-8 h-8 text-orange-500"></app-icon-warning>
							</div>
							<div class="font-sans">
								<h4 class="font-semibold text-muted-700 dark:text-muted-100">
									Restriction
								</h4>
								<p class="text-xs lg:text-sm text-muted-400">{{securityService.getRestrictionTypeLabel(this.user)}}</p>
							</div>
						</div>

						<p class="font-sans text-xs text-muted-500 dark:text-muted-400" [innerText]="securityService.getRestrictionDescriptionLabel(this.user)"></p>
						<div class="flex justify-end mt-4" *ngIf="securityService.shouldRedirectToSecurity(user)" >
							<a [routerLink]="[Routes.SETTINGS]" [fragment]="SettingsTabs.SECURITY"
								class="flex-0 flex w-full max-w-[90px] items-center justify-center gap-x-2 px-2 rounded-md h-10 text-white hover:shadow-md transition-all duration-300 bg-brand hover:bg-brand/90 hover:shadow-brand/30">
								<span class="font-sans text-sm">Settings</span>
							</a>
						</div>
					</div>

					<!--Support box-->
					<div
						class="w-full bg-muted-50 dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-6 lg:p-8 rounded-lg">
						<div class="flex items-center gap-x-2 mb-4">
							<div
								class="flex items-center justify-center h-10 w-10 rounded-full bg-emerald-100 dark:bg-emerald-500/20">
								<app-icon-help class="w-4 h-4 text-emerald-500"></app-icon-help>
							</div>
							<div class="font-sans">
								<h4 class="font-semibold text-muted-700 dark:text-muted-100">
									Help
								</h4>
								<p class="text-xs lg:text-sm text-muted-400">Contact our support team</p>
							</div>
						</div>

						<p class="font-sans text-xs text-muted-500 dark:text-muted-400" [innerText]="PLAN_STAKING_HELP_TEXT"></p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--Success step-->
	<div *ngIf="currentStep > 3" class="py-12">
		<div class="w-full max-w-lg mx-auto">
			<!--Success card-->
			<div class="w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-8 rounded-lg">
				<div class="w-20 mx-auto mb-4">
					<app-icon-check-animated class="text-secondary-500"></app-icon-check-animated>
				</div>
				<div class="text-center mb-4">
					<h2 class="font-sans text-xl font-bold text-muted-600 dark:text-white mb-2">
						Your changes were saved!
					</h2>
					<p class="text-sm text-muted-400 max-w-xs mx-auto">
						Amazing, we saved all the changes you've made to your billing
						settings. You can come back any time to manage your plans.
					</p>
				</div>

				<a [routerLink]="'/plan'" class="flex w-full max-w-[200px] mx-auto items-center justify-center gap-x-2 px-5 rounded-md h-12 text-white
						hover:shadow-md transition-all duration-300 bg-secondary-500 hover:bg-secondary-500/90 hover:shadow-secondary-500/30">
					<span class="font-sans text-sm">Back to plan settings</span>
				</a>
			</div>
		</div>
	</div>
</div>
