import { DatePipe } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { DisplayMode, loadingOptions } from '@app/core/constants/charts.constants';
import { EChartsOption,graphic } from 'echarts';

@Component({
	selector: 'app-chart-area',
	templateUrl: './chart-area.component.html',
})
export class ChartAreaComponent {
	@Input() chartData: any[];
	@Input() displayMode: DisplayMode;
	@Input() loading = false;

	public options: EChartsOption = {};
	public loadingOptions = loadingOptions;

	public isLoading(): boolean {
		return this.loading;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['chartData'] && changes['chartData'].currentValue) {
			this.getOptions();
		}
	}
	getOptions(): void {
		const data = this.chartData ?? [];

		const datePipe = new DatePipe('en-US');
		const xAxisData = [];
		const yAxisData = [];

		if (!this.loading) {
			for (const point of data) {
				xAxisData.push(datePipe.transform(point.time, this.getDateFormat()));
				yAxisData.push(Number(point.usdQuote));
			}
		}

		const isEmptyData = xAxisData.length === 0;

		this.options = <EChartsOption>{
			title: {
				show: isEmptyData && !this.loading,
				textStyle: {
					color: '#64748B',
					fontSize: 12,
					fontStyle: 'italic'
				},
				text: 'No data yet. Please wait...',
				left: 'center',
				top: 'center'
			},
			xAxis: {
				show: !isEmptyData,
				type: 'category',
				data: xAxisData,
				axisLabel: {
					color: '#94a3b8',
					formatter: ((value: string) => value.replace(/(\d{1,2}):\d{2}/, '$1'))
				},
				axisTick: {
					lineStyle: {
						color: '#cbd5e1',
					},
				},
				axisLine: {
					lineStyle: {
						color: '#cbd5e1',
					},
				},
			},
			yAxis: {
				show: false,
				type: 'value',
			},
			grid: {
				containLabel: true,
				left: 0,
				top: 0,
				right: 24,
				bottom: 0,
			},
			tooltip: {
				show: true,
				trigger: 'axis',
				textStyle: {
					fontSize: 12
				},
				valueFormatter: (value: number) => '$' + value.toFixed(2)
			},
			series: [
				{
					name: 'Balance',
					data: yAxisData,
					type: 'line',
					smooth: true,
					areaStyle: {
						color: new graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0,
								color: '#23cbd5',
							},
							{
								offset: 1,
								color: '#E6FEFF',
							},
						]),
						opacity: 0.4
					},
					lineStyle: {
						width: 3,
						color: '#10949D',
						join: 'round',
					},
					itemStyle: {
						color: '#10949D',
						lineStyle: {
							width: 2,
							type: 'solid',
							color: '#10949D',
						},
					},
					emphasis: {
						color: 'white',
						lineStyle: {
							width: 2,
							type: 'dotted',
							color: '#10949D',
						},
					},
				},
			],
			height: 245
		};
	}

	private getDateFormat(): string {
		return this.displayMode === DisplayMode.week ?
			'EEE h:mma'
			:
			'MMM d';
	}
}
