<div class="group relative" (clickOutside)="closeMenu()">
  <button
    type="button"
    class="relative flex items-center justify-center h-9 w-9 rounded-full text-muted-400 group-hover:text-muted-500 group-hover:bg-muted-100 dark:group-hover:text-muted-100 dark:group-hover:bg-muted-700 transition-colors duration-300"
    (click)="toggleMenu()"
  >
    <span class="absolute top-0.5 right-0.5 flex h-2 w-2">
      <span
        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand opacity-75"
      ></span>
      <span class="relative inline-flex rounded-full h-2 w-2 bg-brand"></span>
    </span>
    <app-icon-bell class="w-4 h-4"></app-icon-bell>
  </button>

  <div
    [@openClose]="isMenuOpen ? 'open' : 'closed'"
    class="absolute top-0 right-0 w-64 mt-14"
    [class]="!isMenuOpen ? 'pointer-events-none' : ''"
  >
    <div
      class="bg-white border border-muted-200 dark:bg-muted-800 dark:border-muted-600 rounded-md"
    >
      <!--Header-->
      <div class="flex items-center p-4">
        <h3
          class="font-sans text-xs uppercase text-muted-500 dark:text-muted-200"
        >
          Notifications
        </h3>
        <a
          href="#"
          class="font-sans text-sm font-medium text-muted-400 hover:text-brand ml-auto transition-colors duration-300"
          >View All</a
        >
      </div>
      <!--body-->
      <ul class="px-3 pb-3 space-y-1">
        <li>
          <a
            href="#"
            class="flex gap-x-2 p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/50 transition-colors duration-300"
          >
            <div class="relative h-9 w-9 min-w-[2.25rem]">
              <div
                class="flex items-center justify-center w-full h-full object-cover mask mask-hexed bg-muted-100 dark:bg-muted-700/50"
              >
                <app-icon-cart
                  class="w-4 h-4 text-muted-300 group-hover:text-muted-400 transition-colors"
                ></app-icon-cart>
              </div>
            </div>
            <div class="shrink">
              <p
                class="font-sans text-xs text-muted-500 dark:text-muted-200 leading-2"
              >
                You purchased 0.75 BCube coins from your wallet
              </p>
              <span class="font-sans text-xs text-muted-400 leading-2"
                >10 min ago</span
              >
            </div>
          </a>
        </li>
        <li>
          <a
            href="#"
            class="flex gap-x-2 p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/50 transition-colors duration-300"
          >
            <div class="relative h-9 w-9 min-w-[2.25rem]">
              <div
                class="flex items-center justify-center w-full h-full object-cover mask mask-hexed bg-muted-100 dark:bg-muted-700/50"
              >
                <app-icon-pie
                  class="w-4 h-4 text-muted-300 group-hover:text-muted-400 transition-colors"
                ></app-icon-pie>
              </div>
            </div>
            <div class="shrink">
              <p
                class="font-sans text-xs text-muted-500 dark:text-muted-200 leading-2"
              >
                Your monthly report has been sent to your email inbox
              </p>
              <span class="font-sans text-xs text-muted-400 leading-2"
                >2 hours ago</span
              >
            </div>
          </a>
        </li>
        <li>
          <a
            href="#"
            class="flex gap-x-2 p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/50 transition-colors duration-300"
          >
            <div class="relative h-9 w-9 min-w-[2.25rem]">
              <div
                class="flex items-center justify-center w-full h-full object-cover mask mask-hexed bg-muted-100 dark:bg-muted-700/50"
              >
                <app-icon-cart
                  class="w-4 h-4 text-muted-300 group-hover:text-muted-400 transition-colors"
                ></app-icon-cart>
              </div>
            </div>
            <div class="shrink">
              <p
                class="font-sans text-xs text-muted-500 dark:text-muted-200 leading-2"
              >
              You purchased 1.25 BCube coins from your wallet
              </p>
              <span class="font-sans text-xs text-muted-400 leading-2"
                >5 hours ago</span
              >
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
