import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-fake-chart-main',
	templateUrl: './fake-chart-main.component.html'
})
export class FakeChartMainComponent {

	@Input() class = '';

}
