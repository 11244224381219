<div
	class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md p-6">
	<h4 class="font-sans text-sm font-semibold tracking-wider text-left text-muted-700 dark:text-muted-300 mb-5">
		Wallet splitview
	</h4>

	<div class="grid grid-cols-2 mb-8">
		<div class="space-y-4">
			<!-- <div class="mt-3">
        <h5 class="font-sans text-sm text-slate-500">Account balance</h5>
        <p class="font-sans text-lg text-muted-700 dark:text-white font-bold">7.9500000 BTC</p>
      </div> -->
			<div>
				<h5 class="font-sans text-sm text-slate-500">Estimated value</h5>
				<p class="font-sans text-lg text-muted-700 dark:text-white font-bold">{{usdBalance |
					currency:'USD':'symbol':'1.2-2'}}</p>
			</div>
		</div>
		<div>
			<!--app-fake-ring></app-fake-ring-->
			<app-chart-ring [data]="ringData" [unit]="PieUnit.dollar"></app-chart-ring>
		</div>
	</div>

	<div *ngIf="allocation?.length === 0" class="text-center italic font-sans text-sm text-slate-500">
		This exchange account is empty
	</div>
	<div class="grid md:grid-cols-2 gap-x-3 gap-y-6 pb-4 mt-auto">
		<div *ngFor="let token of allocation; index as i">
			<div class="flex items-center gap-x-2 pr-2">
				<div class="inline-flex justify-center items-center relative w-2 h-2 rounded-full bg-amber-500 min-w-[0.5rem]"
					[style.background-color]="colorPalette[i % 8]"></div>
				<span class="relative flex justify-center items-center h-6 w-6 rounded-full">
					<app-cryptocurrency-icon [name]="token.asset" [svgClass]="'w-4 h-4'">
					</app-cryptocurrency-icon>
				</span>
				<span class="block font-sans text-xs text-muted-500 dark:text-muted-100">{{token.asset}}</span>
				<span class="block font-sans font-semibold text-xs text-muted-500 ml-auto">
					{{token.amount | number:'1.0-9'}}
				</span>
			</div>
		</div>
	</div>
</div>
