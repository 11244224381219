<svg
  width="23"
  height="16"
  viewBox="0 0 23 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.5 0.139648C6.5 0.139648 2.23 3.24965 0.5 7.63965C2.23 12.0296 6.5 15.1396 11.5 15.1396C16.5 15.1396 20.77 12.0296 22.5 7.63965C20.77 3.24965 16.5 0.139648 11.5 0.139648ZM11.5 12.6396C8.74 12.6396 6.5 10.3996 6.5 7.63965C6.5 4.87965 8.74 2.63965 11.5 2.63965C14.26 2.63965 16.5 4.87965 16.5 7.63965C16.5 10.3996 14.26 12.6396 11.5 12.6396ZM11.5 4.63965C9.84 4.63965 8.5 5.97965 8.5 7.63965C8.5 9.29965 9.84 10.6396 11.5 10.6396C13.16 10.6396 14.5 9.29965 14.5 7.63965C14.5 5.97965 13.16 4.63965 11.5 4.63965Z"
    fill="currentColor"
  />
</svg>
