import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-download',
  templateUrl: './icon-download.component.html'
})
export class IconDownloadComponent {
  @Input() class = ''

}
