<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700
		rounded-md p-6 md:p-10">
	<div class="flex items-center gap-x-4">
		<span class="relative flex justify-center items-center h-16 w-16 rounded-full bg-muted-100 dark:bg-muted-700">
			<app-bot-icon [name]="bot?.publisher?.name ? bot.publisher.name : bot?.name"
				[svgClass]="'rounded-full h-16 w-16'"></app-bot-icon>
		</span>
		<span class="block font-sans text-lg md:text-xl max-w-[240px] font-semibold text-muted-600 dark:text-muted-200">
			{{ bot?.name }}
		</span>
	</div>
</div>
