/**
 * Enum representing the possible statuses of an ExchangeAccount
 */
export enum ExchangeAccountStatus {
    /**
     * The exchange account is active and can be used for trading and other operations
     */
    ACTIVE = 'ACTIVE',

    /**
     * The exchange account is deleted, meaning it's no longer accessible or usable for trading and other operations
	 * This status is used to soft delete an exchange account
     */
    DELETED = 'DELETED',
}
