import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BTC } from '@app/core/constants/currency.constants';
import { ExchangeName } from '@b-cube/database/types';
import { IBot } from '@b-cube/interfaces/bot';
import { IAllocation, IExchangeAccount } from '@b-cube/interfaces/exchange-account';
import { IAllocationSettings, IBotAllocation, IBotAllocationSettings } from '@b-cube/interfaces/user-bot-settings';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-widget-bot-balance-allocation',
	templateUrl: './widget-bot-balance-allocation.component.html'
})
export class WidgetBotBalanceAllocationComponent implements OnInit, OnDestroy, OnChanges {
	@Input() exchangeAccount: IExchangeAccount;
	@Input() allocations: IAllocation[];
	@Input() balanceAllocations: IBotAllocationSettings;
	@Input() bot: IBot;

	@Output() updateAllocation = new EventEmitter<boolean>();

	public isSettingsOpen = false;
	public allocLoader = false;

	public allocationSettings: IBotAllocationSettings;
	public botAllocationPercentage = 0;
	public assetAllocation = 0;
	public allocationCurrency = '';
	private allocationSettingsSub = new Subscription();

	constructor(
		private toasterService: ToastrService
	) { }

	ngOnInit(): void {
		this.allocLoader = true;
	}

	ngOnDestroy(): void {
		this.allocationSettingsSub.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['balanceAllocations'] && this.balanceAllocations) {
			this.allocLoader = false;
			if (this.bot) {
				this.fillAllocationBlock();
				this.checkAllocationNotNull()
			}
		}
	}

	private computeAssetAllocation(botAllocationPercentage: number, amount: number): number {
		return botAllocationPercentage * amount / 100;
	}

	public openSettings(): void {
		this.isSettingsOpen = true
	}

	private fillAllocationBlock(): void {
		this.allocationCurrency = this.bot.pairing;
		if (this.exchangeAccount.exchangeName === ExchangeName.BITMEX_FUTURES) {
			this.allocationCurrency = BTC;
		}

		const alloc: IAllocationSettings = this.balanceAllocations?.allocations.find(alloc => alloc.pairing === this.bot.pairing);
		const bot: IBotAllocation = alloc?.bots.find(bot => bot.id === this.bot.id);
		if (!bot) {
			this.botAllocationPercentage = 0;
			this.assetAllocation = 0;

			return;
		}
		this.botAllocationPercentage = bot.allocatedValue;
		this.assetAllocation = this.computeAssetAllocation(this.botAllocationPercentage,
			alloc.amountAvailable);
	}

	private checkAllocationNotNull() {
		if (this.botAllocationPercentage === 0) {
			this.toasterService.warning(`This agent won't be active until you set an allocation.`)
		}
	}

	public closeBalanceAllocation($event: any) {
		this.isSettingsOpen = false;
		if ($event) {
			this.allocLoader = true;
			this.updateAllocation.emit(true);
		}
	}


}
