export interface IFAUser {
	applicationId: string,
	email: string,
	email_verified: boolean,
	family_name: string,
	given_name: string,
	phone_number: string,
	roles: any[],
	sid: string,
	sub: string,
	tid: string,
}
