import { BotRisk, IBot, IPublisher } from "@b-cube/interfaces/bot";

export class Bot implements IBot{
    id: number
    name: string
    description: string;
    category: string;
    strategyType: number;
    cryptos: string[];
    pairing: string;
    market: string;
    status: string;
    supportedExchanges: string[];
    publisher?: IPublisher;
	liveDate: Date;
    risk: BotRisk;

    deserialize(input: any): this {
		Object.assign(this, input);

		return this;
	}
}
