import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-circle-progress',
	templateUrl: './circle-progress.component.html'
})
export class CircleProgressComponent implements OnChanges {
	@Input() class = '';
	@Input() thickness = 4;
	@Input() max = 100;
	@Input() progress = 0;
	@Input() offset = 100;

	ngOnChanges(changes: SimpleChanges) {
		if(changes['progress'] === undefined){
			return;
		}

		let progress = changes['progress'].currentValue;

		if(progress > 100){
			progress = 100;
		} else if(progress < 0){
			progress = 0;
		}

		this.offset = ((100 - progress) / 100) * this.max;
	 }
}
