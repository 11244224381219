import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormatedBot } from '@app/core/constants/marketplace.constants';
import { IBot } from '@b-cube/interfaces/bot';
import { IBotPerformance,IBotStatistic, IPerformance, IPerformanceAggregates, PERFORMANCE_HISTORY_FREQUENCY, PERFORMANCE_HISTORY_RANGE } from '@b-cube/interfaces/performance';
import { RestURLBuilder } from 'rest-url-builder';
import { catchError, combineLatest, from, map, mergeMap, Observable, of, shareReplay, switchMap, toArray } from 'rxjs';

import { BACKTEST_DATA_BOT_PERFORMANCE_HISTORY_URL,BOT_STATISTICS_URL, BOT_URL, LIVE_DATA_AGGREGATES_HISTORY_PERFORMANCE_URL, LIVE_DATA_BOT_PERFORMANCE_HISTORY_URL, LIVE_DATA_PERFORMANCE_SUMMARY_URL, MONTHLY_BOT_PERFORMANCE_HISTORY_URL } from '../../constants/api-urls.constants';
import { formatBotList } from '../marketplace/filer-sort-bots.service';

interface PerformanceObservablesList {
	[key: number]: Observable<IBotPerformance>
}

@Injectable({
	providedIn: 'root'
})
export class PerformanceService {

	botPerformancesSummary$: PerformanceObservablesList = {};

	constructor(private http: HttpClient) { }

	public getLivePerformanceHistoryForBotByRange(
		botId: number,
		range: PERFORMANCE_HISTORY_RANGE,
		frequency: PERFORMANCE_HISTORY_FREQUENCY,
		leverage = 1,
		allocation= 100,
		feesEnabled = true
	): Observable<IPerformance[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(LIVE_DATA_BOT_PERFORMANCE_HISTORY_URL);
		urlBuilder.setNamedParameter('botId', String(botId));
		urlBuilder.setQueryParameter('range', range);
		urlBuilder.setQueryParameter('frequency', frequency);
		urlBuilder.setQueryParameter('leverage', String(leverage));
		urlBuilder.setQueryParameter('allocation', String(allocation));
		urlBuilder.setQueryParameter('feesEnabled', feesEnabled ? '1' : '0');
		const url = urlBuilder.get();

		return this.http.get<IPerformance[]>(url)
			.pipe(
				map(performance => performance),
				catchError(() => of([]))
			);
	}

	public getAllBacktestPerformanceHistoryForBot(
		botId: number,
		leverage = 1,
		allocation= 100,
		feesEnabled = true
	): Observable<IPerformance[]> {
		const urlBuilder = new RestURLBuilder();

		urlBuilder.buildRestURL(BACKTEST_DATA_BOT_PERFORMANCE_HISTORY_URL);
		urlBuilder.setNamedParameter('botId', String(botId));
		urlBuilder.setQueryParameter('leverage', String(leverage));
		urlBuilder.setQueryParameter('allocation', String(allocation));
		urlBuilder.setQueryParameter('feesEnabled', feesEnabled ? '1' : '0');
		urlBuilder.setQueryParameter('frequency', PERFORMANCE_HISTORY_FREQUENCY.ONE_WEEK);
		const url = urlBuilder.get();

		return this.http.get<IPerformance[]>(url)
			.pipe(
				map(performance => performance),
				catchError(() => of([]))
			);
	}

	public getPerformanceHistoryForBotInRange(
		botId: number,
		range: PERFORMANCE_HISTORY_RANGE,
		frequency: PERFORMANCE_HISTORY_FREQUENCY
	): Observable<IPerformance[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(MONTHLY_BOT_PERFORMANCE_HISTORY_URL);
		urlBuilder.setNamedParameter('botId', String(botId));
		urlBuilder.setQueryParameter('range', range);
		urlBuilder.setQueryParameter('frequency', frequency);
		const url = urlBuilder.get();

		return this.http.get<IPerformance[]>(url)
			.pipe(
				map(performance => performance),
				catchError(() => of([]))
			);
	}

	public getBotPerformanceList(): Observable<FormatedBot[]> {
		return this.http.get<IBot[]>(BOT_URL).pipe(
			switchMap(botList => from(botList)),
			mergeMap(bot => combineLatest(
				[
					of(bot),
					this.getLivePerformanceHistoryForBotByRange(
						bot.id,
						PERFORMANCE_HISTORY_RANGE.THREE_MONTHS,
						PERFORMANCE_HISTORY_FREQUENCY.ONE_WEEK
					),
					this.getBotPerformanceAggregates(bot.id)
				]
			)),
			map(([bot, performance, aggregates]) => formatBotList(
				{ ...bot, performance: { performance, performanceAggregates: aggregates } })),
			toArray(),
			catchError(() => of([]))
		);
	}

	public getBotPerformanceSummary(
		bot: IBot,
		range: PERFORMANCE_HISTORY_RANGE,
		frequency: PERFORMANCE_HISTORY_FREQUENCY
	): Observable<IBotPerformance> {
		if(this.botPerformancesSummary$[bot.id]){
			return this.botPerformancesSummary$[bot.id];
		}

		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(LIVE_DATA_PERFORMANCE_SUMMARY_URL);
		urlBuilder.setNamedParameter('botId', String(bot.id));
		urlBuilder.setQueryParameter('range', range);
		urlBuilder.setQueryParameter('frequency', frequency);
		const url = urlBuilder.get();

		return this.http.get<IBotPerformance>(url).pipe(
			shareReplay(),
			catchError(() => of(null))
		);
	}

	public getBotPerformanceAggregates(botId: number): Observable<IPerformanceAggregates> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(LIVE_DATA_AGGREGATES_HISTORY_PERFORMANCE_URL);
		urlBuilder.setNamedParameter('botId', String(botId));
		const url = urlBuilder.get();

		return this.http.get<IPerformanceAggregates>(url).pipe(
			map(performance => performance),
			catchError(() => of(null))
		);
	}

	public getBotStatistics(botId: number): Observable<Partial<IBotStatistic>> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(BOT_STATISTICS_URL);
		urlBuilder.setNamedParameter('botId', String(botId));
		const url = urlBuilder.get();

		return this.http.get<Partial<IBotStatistic>>(url).pipe(
			map(statistic => statistic),
			catchError(() => of(null))
		);
	}
}
