<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700
	rounded-md p-6 md:min-h-[430px]">
	<div class="flex flex-col lg:flex-row justify-between">
		<div class="mb-5 lg:mb-0">
			<div class="flex items-center mb-5">
				<span class="font-sans text-xl font-semibold text-muted-600 dark:text-muted-200">
					Current allocation
				</span>
			</div>
		</div>
	</div>

	<div class="flex-row flex items-center min-h-[180px]">
		<app-chart-ring [data]="allocations" [size]="180" class="w-full" [loading]="loading"
			[unit]="PieUnit.percent" [showLabel]="true" ></app-chart-ring>
	</div>

	<div class="grid md:grid-cols-2 gap-x-3 gap-y-6 pb-4 mt-auto">
		<div *ngFor="let token of allocations; index as i">
			<div class="flex items-center gap-x-2 pr-2">
				<div class="inline-flex justify-center items-center relative w-2 h-2 rounded-full bg-amber-500 min-w-[0.5rem]"
					[style.background-color]="colorPalette[i % 8]"></div>
				<span class="relative flex justify-center items-center h-6 w-6 rounded-full">
					<app-cryptocurrency-icon [name]="token.name" [svgClass]="'w-4 h-4'">
					</app-cryptocurrency-icon>
				</span>
				<span class="block font-sans text-xs text-muted-500 dark:text-muted-100">{{token.name}}</span>
				<span class="block font-sans font-semibold text-xs text-muted-500 ml-auto">
					{{token.value | percent:'1.0-1'}}
				</span>
			</div>
		</div>
	</div>
</div>
