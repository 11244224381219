import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-gear',
  templateUrl: './icon-gear.component.html'
})
export class IconGearComponent {
  @Input() class = '';

}
