<svg
  viewBox="0 0 291 86"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.2"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.429688 46.5817L12.0297 39.6164H23.6297L35.2297 28.472L46.8297 20.1136L58.4297 25.6859L70.0297 20.1136L82.2097 28.472L93.2297 25.6859L104.83 17.3275L116.43 14.5414L128.03 15.9345L139.63 20.1136L151.23 32.6511L162.83 39.6164L174.43 46.5817L186.03 39.6164L198.79 32.6511H209.23L220.83 39.6164L232.43 32.6511L244.03 27.0789L255.63 22.8997H267.23L278.83 8.96918L290.43 2.00391V85.5872H0.429688V46.5817Z"
    fill="url(#paint0_linear_1318_27313)"
  />
  <path
    d="M0.429688 45.9193L12.0297 38.8359H23.6297L35.2297 27.5026L46.8297 19.0026L58.4297 24.6693L70.0297 19.0026L82.2097 27.5026L93.2297 24.6693L104.83 16.1693L116.43 13.3359L128.03 14.7526L139.63 19.0026L151.23 31.7526L162.83 38.8359L174.43 45.9193L186.03 38.8359L198.79 31.7526H209.23L220.83 38.8359L232.43 31.7526L244.03 26.0859L255.63 21.8359L267.23 22.5443L278.83 7.66927L290.43 0.585938"
    stroke="#23CBD5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <defs>
    <linearGradient
      id="paint0_linear_1318_27313"
      x1="0.429688"
      y1="2.00391"
      x2="0.429688"
      y2="85.5872"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#42DEE7" />
      <stop offset="1" stop-color="#23CBD5" />
    </linearGradient>
  </defs>
</svg>
