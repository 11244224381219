export class Order {
	date: Date;
	symbol: string;
	side: string;
	price: number;
	quantity: number;
	type: string;

	deserialize(input: any): this {
		Object.assign(this, input);
		this.date = new Date();
		this.date.setTime(input.date);

		return this;
	}
}
