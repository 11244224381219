<div class="relative flex items-center h-full px-4" (clickOutside)="closeMenu()">
	<button class="flex items-center text-left w-full" (click)="toggleMenu()">
		<span class="block">
			<span class="block text-[0.6rem] font-sans font-medium text-muted-400 uppercase">Currency</span>
			<span class="block font-sans text-xs font-medium text-muted-500 dark:text-muted-100">USD</span>
		</span>
		<div class="ml-auto">
			<app-icon name="chevron-down" class="w-3 h-3 text-muted-400 transition-transform duration-300"
				[svgClass]="isMenuOpen ? 'rotate-180' : ''">
			</app-icon>
		</div>
	</button>

	<div [@openClose]="isMenuOpen ? 'open' : 'closed'" class="absolute -top-[170px] left-0 w-full h-[170px] p-4"
		[class]="!isMenuOpen ? 'pointer-events-none' : ''">
		<div
			class="w-full h-full bg-white border border-muted-200 dark:bg-muted-800 dark:border-muted-600 rounded-md overflow-y-auto slimscroll">
			<ul class="p-2 space-y-1">
				<li>
					<a
						class="inline-flex items-center justify-between w-full font-sans text-xs p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
						<span class="text-sm font-medium text-slate-800 dark:text-muted-100">$</span>
						<span class="font-sembibold text-slate-500">USD</span>
					</a>
				</li>
				<li>
					<a
						class="inline-flex items-center justify-between w-full font-sans text-xs p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
						<span class="text-sm font-medium text-slate-800 dark:text-muted-100">€</span>
						<span class="font-sembibold text-slate-500">EUR</span>
					</a>
				</li>
				<li>
					<a
						class="inline-flex items-center justify-between w-full font-sans text-xs p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
						<span class="text-sm font-medium text-slate-800 dark:text-muted-100">£</span>
						<span class="font-sembibold text-slate-500">GBP</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>