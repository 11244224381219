<div class="w-full bg-white dark:bg-muted-800">
	<div class="px-6 py-4" *ngIf="canUseService() && userHasSupportedExchanges">
		<button *ngIf="!isSpecialCaseBot && !isAlreadyPlugged" type="button"
			[ngClass]="isLoading ? 'button-loading' : ''" [disabled]="isLoading" value="CREDIT_CARD"
			class="w-full flex items-center justify-center gap-x-2 px-5 mb-2 rounded-md h-12 bg-secondary-500/10 hover:bg-secondary-500/30 text-white border-2 border-secondary-500/50 transition-all duration-300"
			(click)="openModal($event)">
			<app-icon [ngClass]="isLoading ? 'hidden' : ''" name="add" class="h-4 w-4 pointer-events-none"></app-icon>
			<h4 [ngClass]="isLoading ? 'hidden' : ''"
				class="font-sans font-semibold text-lg text-secondary-500 pointer-events-none">
				Credit Card
			</h4>
			<p [ngClass]="isLoading ? 'hidden' : ''" class="font-text text-xs pointer-events-none">
				Pay {{creditCardPriceWithDiscount | currency:'EUR':'symbol':'1.0-2'}}
			</p>
		</button>
		<button *ngIf="!isAlreadyPlugged" type="button" [ngClass]="isLoading ? 'button-loading' : ''"
			[disabled]="isLoading" value="BCUBE"
			class="w-full flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-brand/10 border-2 border-brand/50 hover:bg-brand/30  transition-all duration-300"
			(click)="openModal($event)">
			<app-icon [ngClass]="isLoading ? 'hidden' : ''" name="add" class="h-4 w-4 pointer-events-none"></app-icon>
			<h4 [ngClass]="isLoading ? 'hidden' : ''"
				class="font-sans font-semibold text-lg text-brand pointer-events-none">
				$BCUBE
			</h4>
			<p [ngClass]="isLoading ? 'hidden' : ''" class="font-text text-xs pointer-events-none">
				Pay {{bcubePaymentDetails?.priceDiscount | currency:'':'':'1.0-2'}}
			</p>
		</button>
		<button *ngIf="isAlreadyPlugged && isCreditCard" type="button"
			[ngClass]="isLoading ? 'button-loading bg-transparent hover:bg-transparent hover:shadow-transparent' : ''"
			[disabled]="isLoading"
			class="w-full flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-danger-500 hover:bg-danger-500/90 text-white hover:shadow-md hover:shadow-danger-500/30 transition-all duration-300"
			(click)="cancelCreditCardSubscription()">
			<app-icon name="minus" class="h-4 w-4"></app-icon>
			<span>Unsubscribe</span>
		</button>
		<button *ngIf="isAlreadyPlugged && !isCreditCard" type="button" value="TOPUP" [disabled]="isSubscriptionLoading"
			[ngClass]="isSubscriptionLoading ? 'button-loading bg-transparent hover:bg-transparent hover:shadow-transparent' : ''"
			class="w-full flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand-500/30 transition-all duration-300"
			(click)="openModal($event)">
			<h4 class="font-sans font-semibold text-lg pointer-events-none">
				TopUp </h4>
		</button>
	</div>
	<div *ngIf="isAlreadyPlugged" class="flex xs:flex-col xs:justify-center md:flex-col justify-between py-4 px-6
		bg-white dark:bg-muted-800  dark:border-muted-700 rounded-md">
		<div class="flex items-center justify-between">
			<span class="text-sm text-muted-400 mr-2">Method:</span>
			<span class="text-muted-600 dark:text-muted-100 text-sm">{{isCreditCard ? 'Credit Card' : '$BCUBE'}}</span>
		</div>
		<div class="flex items-center justify-between" *ngIf="!isSpecialCaseBot && isCreditCard">
			<span class="text-sm text-muted-400 mr-2">Next billing:</span>
			<span class="text-muted-600 dark:text-muted-100 text-sm">{{userBotSubscription?.nextBillingDate | ldate}}
			</span>
		</div>
		<div class="flex items-center justify-between" *ngIf="!isCreditCard">
			<span class="text-sm text-muted-400 mr-2">Expiration:</span>
			<span class="text-muted-600 dark:text-muted-100 text-sm">{{userBotSubscription?.currentTermEnd | ldate}}
			</span>
		</div>
	</div>
	<div class="px-6 py-4" *ngIf="! canUseService()">
		<div class="flex items-center gap-x-2 mb-4">
			<div class="flex items-center justify-center h-10 w-10 rounded-full">
				<app-icon-warning class="w-8 h-8 text-orange-500"></app-icon-warning>
			</div>
			<div class="font-sans">
				<h4 class="font-semibold text-muted-700 dark:text-muted-100">
					Restriction
				</h4>
				<p class="text-xs lg:text-sm text-muted-400">{{securityService.getRestrictionTypeLabel(user)}}</p>
			</div>
		</div>

		<p class="font-sans text-sm text-muted-500 dark:text-muted-400">
			{{securityService.getRestrictionDescriptionLabel(user)}}
		</p>
		<div class="flex justify-end mt-4" *ngIf="securityService.shouldRedirectToSecurity(user)" >
			<a [routerLink]="[Routes.SETTINGS]" [fragment]="SettingsTabs.SECURITY"
				class="flex-0 flex w-full max-w-[90px] items-center justify-center gap-x-2 px-2 rounded-md h-10 text-white hover:shadow-md transition-all duration-300 bg-brand hover:bg-brand/90 hover:shadow-brand/30">
				<span class="font-sans text-sm">Settings</span>
			</a>
		</div>
	</div>

	<div class="px-6 py-4" *ngIf="canUseService() && !userHasSupportedExchanges">
		<div class="flex items-center justify-center gap-x-2 mb-4">
			<div class="flex items-center justify-center h-10 w-10 rounded-full">
				<app-icon-warning class="w-8 h-8 text-orange-500"></app-icon-warning>
			</div>
			<div class="flex font-sans">
				<h4 class="font-semibold text-muted-700 dark:text-muted-100">
					No compatible exchange account
				</h4>
			</div>
		</div>

		<p class="px-6 font-sans text-sm text-muted-500 dark:text-muted-400">
			You need to <a class="font-sans text-sm font-semibold text-brand" [routerLink]="'/exchange-account'">plug a
				compatible exchange account</a> to use this bot.
		</p>
	</div>
	<!--Modal-->
	<app-widget-marketplace-subscription-modal [@insertRemoveTrigger] [isOpen]="isModalOpen" [bot]="bot"
		[isCompanyProvider]="false" (closeModal)="closeAddBot($event)" [botPlan]="botPlan" [paymentMethod]="paymentMethod"
		[paymentDetails]="paymentDetails" [user]="user" [isSpecialCaseBot]="isSpecialCaseBot" [userBotSubscription]="userBotSubscription">
	</app-widget-marketplace-subscription-modal>
</div>
