<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200
		dark:border-muted-700 rounded-md px-6 pt-6 pb-12 min-h-[430px]">
	<div class="flex flex-col lg:flex-row justify-between">
		<div class="mb-5 lg:mb-0">
			<div class="flex items-center gap-x-4 mb-5">
				<h4 class="font-sans text-sm font-semibold tracking-wider text-left text-muted-700 dark:text-muted-300 mb-5">
					Balance Performance
				</h4>
			</div>
			<div class="min-w-[260px] space-y-2">
				<div class="flex items-center">
					<h4 class="font-sans text-xl text-muted-700 dark:text-white font-bold">
						{{usdBalance | currency:'USD':'symbol':'1.2-2'}}
					</h4>
					<app-icon-alert
						class="ml-2 width-5 height-5text-muted-400 cursor-pointer"
						[tooltip]="tooltipInfoText"
						[ngStyle]="{'display': (showDepositeTooltip$ | async) ? 'block' : 'none'}"
						placement="top"
						[arrow]="true"
					></app-icon-alert>
					<div class="ml-auto">
						<div class="flex items-center gap-x-2" *ngIf="mustDisplayPerformance()"
							[ngClass]="'text-' + getPerformanceColor() + '-500'">
							<app-icon [name]="performanceIcon"></app-icon>
							<span class="block font-sans font-semibold text-xs">
								{{isNaN(performance.percent) ? 'n/a' : (performance.percent | percent:'1.2-2')}}
							</span>
							<span class="block font-sans font-semibold text-xs ml-2">
								{{isNaN(performance.dollar) ? '' : (performance.dollar |
								currency:'USD':'symbol':'1.2-2')}}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex gap-2 flex-wrap justify-end">
			<button *ngFor="let period of periods" (click)="selectPeriod(period)"
				class="font-sans text-xs font-semibold flex-1 h-8 px-3 rounded-md  transition-colors duration-300"
				[ngClass]="{
				'bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30': isSelected(period),
				'text-brand bg-muted-100 hover:bg-muted-200 dark:bg-muted-700 dark:hover:bg-muted-600': !isSelected(period)
			}">
				{{period.label}}
			</button>
		</div>
	</div>

	<div class="mt-auto w-full">
		<!--app-fake-chart-main class="w-full h-auto"></app-fake-chart-main-->
		<app-chart-area [chartData]="history" [displayMode]="getDisplayMode()" [loading]="loading"></app-chart-area>
	</div>
</div>