import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserBotStatus } from '@b-cube/database/types';
import { IPerformance, IUserAccountBotPerformance, PERFORMANCE_HISTORY_FREQUENCY, PERFORMANCE_HISTORY_RANGE } from '@b-cube/interfaces/performance';
import { IActiveBotPnl, ICreateUserBot, IOpenTrade, ITrade, IUserBot } from '@b-cube/interfaces/user-bot';
import { RestURLBuilder } from 'rest-url-builder'
import { BehaviorSubject, catchError, map, Observable, of, shareReplay, Subscription } from 'rxjs';

import { DELETE_USER_BOTS_URL, USER_ACCOUNT_BOT_PERFORMANCE, USER_BOT_ACTIVE_BOTS_PNL, USER_BOT_CLOSE_TRADE, USER_BOT_FROM_EXCHANGE_ACCOUNT, USER_BOT_OVERVIEW_PERFORMANCE_HISTORY, USER_BOT_OVERVIEW_TRADE_HISTORY, USER_BOT_OVERVIEW_TRADE_OPEN, USER_BOT_PERFORMANCE_HISTORY, USER_BOT_TRADE_HISTORY, USER_BOT_TRADE_OPEN, USER_BOT_URL, USER_BOTS_URL } from '../constants/api-urls.constants';
import { COMPANY } from '../constants/bots.constants';
import { UserBot } from '../models/user-bot';

@Injectable({
	providedIn: 'root'
})
export class UserBotService {

	public userBots$ = new BehaviorSubject<IUserBot[]>(null);

	constructor(private http: HttpClient) {
		this.loadUserBotList();
	}

	private loadUserBotList(): Subscription {
		return this.http.get<IUserBot[]>(USER_BOTS_URL).pipe(
			shareReplay(),
			map((userBots: IUserBot[]) => {
				return userBots.map((userBot) => (new UserBot()).deserialize(userBot));
			})
		).subscribe((userBots: IUserBot[]) => {
			this.userBots$.next(userBots);
		});
	}

	public get getUserBots(): Observable<IUserBot[]> {
		return this.userBots$.asObservable();
	}

	public getUserBotById(botId: number): Observable<IUserBot> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_URL);
		urlBuilder.setNamedParameter('botId', String(botId));

		return this.http.get<IUserBot>(urlBuilder.get()).pipe(
			map((userBot: IUserBot) => {
				return new UserBot().deserialize(userBot);
			})
		)
	}

	public addUserBot(data: ICreateUserBot): Observable<IUserBot> {
		return this.http.post(USER_BOTS_URL, data).pipe(
			map(createdUserBot => {
				const userBot = new UserBot().deserialize(createdUserBot);
				if (userBot.bot.category !== COMPANY) {
					userBot.status = UserBotStatus.ACTIVE;
				}

				return userBot;
			})
		);
	}

	public addUserBotLocally(userBot: IUserBot): void {
		this.userBots$.value.push(userBot);
		this.userBots$.next(this.userBots$.value);
	}

	public updateUserBot(botId: number, newFields: any): Observable<IUserBot> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_URL);
		urlBuilder.setNamedParameter('botId', String(botId));

		return this.http.put(urlBuilder.get(), newFields).pipe(
			map(updatedUserBot => {
				const userBot = new UserBot().deserialize(updatedUserBot);
				const index = this.userBots$.value.findIndex(userBot => userBot.bot.id === botId);
				this.userBots$.value[index] = userBot;
				this.userBots$.next(this.userBots$.value);

				return userBot;
			})
		);
	}

	public deleteUserBot(botId: number): Observable<void> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(DELETE_USER_BOTS_URL);
		urlBuilder.setNamedParameter('botId', String(botId));

		return this.http.delete<void>(urlBuilder.get()).pipe(
			map(() => {
				this.userBots$.next(this.userBots$.value.filter(userBot => userBot.bot.id !== botId));
			})
		);
	}

	public deleteUserBotLocally(botId: number): void {
		this.userBots$.next(this.userBots$.value.filter(userBot => userBot.bot.id !== botId));
	}

	public getBotsByExchangeAccount(exchangeAccountId: number): Observable<IUserBot[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_FROM_EXCHANGE_ACCOUNT);
		urlBuilder.setNamedParameter('exchangeAccountId', String(exchangeAccountId));

		return this.http.get<IUserBot[]>(urlBuilder.get());
	}

	public getBotPerformanceHistory(
		botId: number,
		range: PERFORMANCE_HISTORY_RANGE,
		frequency: PERFORMANCE_HISTORY_FREQUENCY
	): Observable<IPerformance[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_PERFORMANCE_HISTORY);
		urlBuilder.setNamedParameter('botId', String(botId));
		urlBuilder.setQueryParameter('range', range);
		urlBuilder.setQueryParameter('frequency', frequency);

		return this.http.get<IPerformance[]>(urlBuilder.get());
	}

	public getOverviewPerformanceHistory(
		range: PERFORMANCE_HISTORY_RANGE,
		frequency: PERFORMANCE_HISTORY_FREQUENCY
	): Observable<IPerformance[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_OVERVIEW_PERFORMANCE_HISTORY);
		urlBuilder.setQueryParameter('range', range);
		urlBuilder.setQueryParameter('frequency', frequency);

		return this.http.get<IPerformance[]>(urlBuilder.get());
	}

	public getBotTradeHistory(botId: number): Observable<ITrade[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_TRADE_HISTORY);
		urlBuilder.setNamedParameter('botId', String(botId));

		return this.http.get<ITrade[]>(urlBuilder.get());
	}

	public getOverviewTradeHistory(): Observable<ITrade[]> {
		return this.http.get<ITrade[]>(USER_BOT_OVERVIEW_TRADE_HISTORY);
	}

	public getBotTradeOpen(botId: number): Observable<IOpenTrade[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_TRADE_OPEN);
		urlBuilder.setNamedParameter('botId', String(botId));

		return this.http.get<IOpenTrade[]>(urlBuilder.get());
	}

	public getOverviewTradeOpen(): Observable<IOpenTrade[]> {
		return this.http.get<IOpenTrade[]>(USER_BOT_OVERVIEW_TRADE_OPEN);
	}

	public getActiveBotsPnl(botIds: number[]): Observable<IActiveBotPnl[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_ACTIVE_BOTS_PNL);
		urlBuilder.setQueryParameter('botIds', botIds.join(','));

		return this.http.get<IActiveBotPnl[]>(urlBuilder.get());
	}

	public getUserAccountBotPerformance(botId: number): Observable<IUserAccountBotPerformance> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_ACCOUNT_BOT_PERFORMANCE);
		urlBuilder.setNamedParameter('botId', String(botId));
		const url = urlBuilder.get();

		return this.http.get<IUserAccountBotPerformance>(url).pipe(
			map(performance => performance),
			catchError(() => of(null))
		);
	}

	public closeTrade(trade: IOpenTrade): Observable<boolean> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(USER_BOT_CLOSE_TRADE);
		urlBuilder.setNamedParameter('executionId', String(trade.executionId));
		const url = urlBuilder.get();

		return this.http.put(url, null).pipe(
			map(res => true),
			catchError(() => of(false))
		);
	}

}
