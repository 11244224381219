export enum Settings {
	ALLOCATION = 'ALLOCATION',
	LEVERAGE = 'LEVERAGE',

	EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION',
	GURU_FUTURES_USDT = 'GURU_FUTURES_USDT',
	GURU_SPOT_USDT = 'GURU_SPOT_USDT',

	FIRST_ENTRY_WEIGHT = 'FIRST_ENTRY_WEIGHT', // old value : HIGH_ENTRY_LONG_WEIGHT
	SECOND_ENTRY_WEIGHT = 'SECOND_ENTRY_WEIGHT', // old value : LOW_ENTRY_LONG_WEIGHT

	PER_TRADE_ALLOCATION = 'PER_TRADE_ALLOCATION',

	SPOT_LOW_RISK_ALLOCATION = 'SPOT_LOW_RISK_ALLOCATION', // old value : LOW_RISK_BET
	SPOT_MEDIUM_RISK_ALLOCATION = 'SPOT_MEDIUM_RISK_ALLOCATION', // old value : MEDIUM_RISK_BET
	SPOT_HIGH_RISK_ALLOCATION = 'SPOT_HIGH_RISK_ALLOCATION',  // old value : HIGH_RISK_BET
}
