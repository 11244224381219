<div class="mb-6">
	<div class="relative w-96 max-w-full" (clickOutside)="closeMenu()">
		<button type="button"
			class="flex items-center gap-x-4 w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 px-6 py-4 rounded-lg"
			(click)="toggleMenu()">
			<app-bot-icon [name]="selectedBot?.name" [svgClass]="'w-6 h-6'"></app-bot-icon>

			<span class="font-sans font-semibold text-muted-800 dark:text-muted-200">
				{{selectedBot?.name}}
			</span>
			<div class="ml-auto">
				<app-icon name="chevron-down" class="w-3 h-3 text-muted-400 transition-transform duration-300"
					[svgClass]="isMenuOpen ? 'rotate-180' : ''">
				</app-icon>
			</div>
		</button>

		<div [@openClose]="isMenuOpen ? 'open' : 'closed'" class="opacity-0 absolute top-0 left-0 w-full mt-16 z-20"
			[class]="!isMenuOpen ? 'pointer-events-none' : ''">
			<div
				class="bg-white border border-muted-200 dark:bg-muted-800 dark:border-muted-600 rounded-md shadow-lg shadow-muted-700/10">
				<ul class="p-2 space-y-1">
					<li *ngFor="let element of bots">
						<a [routerLink]="redirectUrl + (element.id > 0 ? ('/' + element.id) : '')" (click)="closeMenu()"
							class="flex items-center gap-x-4 text-left w-full py-2 px-4 rounded-md
							hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
							<span class="relative flex justify-center items-center h-7 w-7 rounded-full">
								<app-bot-icon [name]="element?.publisher?.name ? element.publisher.name : element.name"
									[svgClass]="element?.publisher ? 'bg-white rounded-full h-7 w-7' : 'rounded-full h-7 w-7'">
								</app-bot-icon>

							</span>

							<span class="block font-sans text-sm font-medium text-muted-600 dark:text-muted-200">
								{{element.name}}
							</span>
						</a>
					</li>
				</ul>
				<ul class="p-2 border-t border-muted-200 dark:border-muted-600">
					<li>
						<button
							class="flex items-center gap-x-4 text-left w-full py-2 px-4 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer"
							(click)="addBot()">
							<app-icon name="add" [svgClass]="'h-7 w-7 text-brand'"></app-icon>
							<span class="block font-sans text-sm font-semibold text-brand">Add Agent</span>
						</button>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
