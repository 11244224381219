export interface ISubscription {
	id: string;
	planId: string;
	customerId: string;
	planAmount?: number;
	status: string;
	nextBillingDate: number;
	currentTermEnd: number;
	cancelledAt: number;
	subscriptionItems?: Array<ISubscriptionItem>;
	discounts?: Array<IDiscount>;
	isCreditCard: boolean;
}

export interface ISubscriptionItem {
	itemPriceId: string;
	itemType: string;
	quantity?: number;
	unitPrice?: number;
	amount?: number;
	objectName?: string;
	objectId?: number;
}

export interface IDiscount {
	id: string;
	percentage?: number;
	amount?: number;
	period?: number;
	periodUnit?: string;
	applyOn: string;
	itemPriceId?: string;
	createdAt: number;
}

export enum SubscriptionPaymentMethod {
	CREDIT_CARD = 'credit-card',
	BCUBE = 'bcube',
}

export enum BcubePaymentType {
	BOT
}

export enum RecurringPaymentType {
	PLAN,
	ADDON,
	BOT
}

export interface RecurringPayment {
	itemPriceId: string
	objectName: string,
	type: RecurringPaymentType,
	price: number,
	objectId?: number
}

export interface IBotSubscription extends
	Partial<Pick<ISubscription, "nextBillingDate" | "currentTermEnd">>,
	Pick<ISubscriptionItem, "itemPriceId" | "objectName" | "objectId"> {
	nextBillingDate?: number;
	currentTermEnd?: number;
	isCreditCard: boolean;
	price: number;
};

export const GURU_BOT_SPECIAL_CASE = {
	planId: 'bot-8',
	discount: 0,
	priceBCUBE: 1750
}

export enum SubscriptionType {
	PLAN = 'PLAN',
	BOT = 'BOT'
}

export enum TransactionType {
    SUBSCRIPTION = 'subscription',
    TOPUP = 'topup',
}

export interface SubscriptionDetails {
	subscriptionType: SubscriptionType,
	transactionType: TransactionType,
	planId: string,
	addons: string[],
	months: number,
	email: string,
	firstName: string,
	lastName: string,
}
