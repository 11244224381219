import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-switch',
  templateUrl: './form-switch.component.html'
})
export class FormSwitchComponent {
  @Input() checked = false
  @Input() label: any = undefined

}
