import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanService } from '@app/core/services/plan.service';
import { UserBotStatus } from '@b-cube/database/types';
import { IBot } from '@b-cube/interfaces/bot';
import { IExchangeAccount } from '@b-cube/interfaces/exchange-account';
import { BotPlan,IBotSubscription } from '@b-cube/interfaces/plan';
import { UserInfo } from '@b-cube/interfaces/user';
import { IUserBot } from '@b-cube/interfaces/user-bot';
import { map, Observable } from 'rxjs';

@Component({
	selector: 'app-widget-marketplace-subscription',
	templateUrl: './widget-marketplace-subscription.component.html'
})
export class WidgetMarketplaceSubscriptionComponent implements OnInit{
	@Input() user: UserInfo;
	@Input() bot: IBot;
	@Input() exchangeAccounts: IExchangeAccount[];
	@Input() userBots: IUserBot[];
	@Input() userBotSubscriptions: IBotSubscription[] = [];
	@Input() isCompanyProvider: boolean;
	// eslint-disable-next-line
	@Output() close = new EventEmitter<IUserBot>();

	public botPlan$: Observable<BotPlan>;

	public botInitialPrice: number;
	public botPlan: BotPlan;
	public botPriceWithDiscount: number;

	constructor(
		private planService: PlanService,
	) { }

	ngOnInit() {
		this.getBotPlanDetails();
	}

	private getBotPlanDetails(): void {
		this.botPlan$ = this.planService.botPlans.pipe(
			map(plans => {
				return plans?.find(plan => plan.botId === this.bot.id);
			})
		)
	}

	public closeAddBot(userBot: IUserBot) {
		this.close.emit(userBot);
	}

	public isAlreadyPlugged(): boolean {
		return Boolean(this.userBots?.some(userBot => {
			return userBot?.bot.id === this.bot?.id && userBot?.status === UserBotStatus.ACTIVE;
		}))
	}

	public getBotSubscription(): IBotSubscription {
		return this.userBotSubscriptions?.find((sub) => {
			return sub?.objectId === this.bot?.id;
		});
	}

	public userHasSupportedExchanges(): boolean {
		return Boolean(this.exchangeAccounts?.some(exchangeAccount => {
			return this.bot?.supportedExchanges.includes(exchangeAccount.exchangeName);
		}));
	}
}
