export enum StrategyType {
	BABA = 1,

	/**
	 * self-managed futures - execution engine manage the exit - entry = market price
	 */
	BCUBE_1 = 2,

	/**
	 * bcube's trailing stoploss strategy - execution engine rely on exit signal
	 */
	EXTERNAL = 3,

	/**
	 * self managed futures - execution engine manage the exit - entry = limit price
	 */
	BCUBE_2 = 4,

	/**
	 * to entry order
	 */
	GURU_BOT = 5,

	SWING_BOT = 6,

	META_BOT = 7,

	SCALPERS_BOT = 8
}
