import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-pause',
  templateUrl: './icon-pause.component.html'
})
export class IconPauseComponent {
  @Input() class = ''

}
