import { Settings } from "@b-cube/database/types";
import { BotSettingsScale } from "@b-cube/interfaces/user-bot-settings";

export const COMPANY = 'COMPANY';
export const THIRD_PARTY = 'THIRD_PARTY';

export enum BotCategory {
	COMPANY = 'COMPANY',
	THIRD_PARTY = 'THIRD_PARTY'
}

export const PROVIDER_NAMES = ['Moonman', 'Skyital', 'Overhead Gains', 'GuruBot'];

export const GURU_BOT_ID = 8;
export const BABA_BOT_ID = 5;

export const BOT_SETTINGS_DISPLAY = {
	[Settings.ALLOCATION]: {
		scale: BotSettingsScale.PERCENTAGE,
		label: 'Allocation',
		minValue: 0,
		maxValue: 100
	},
	[Settings.LEVERAGE]: {
		scale: BotSettingsScale.MULTIPLIER,
		label: 'Leverage',
		minValue: 1,
		maxValue: 50
	},
	[Settings.FIRST_ENTRY_WEIGHT]: {
		scale: BotSettingsScale.PERCENTAGE,
		label: 'First entry weight',
		minValue: 0,
		maxValue: 100
	},
	[Settings.SECOND_ENTRY_WEIGHT]: {
		scale: BotSettingsScale.PERCENTAGE,
		label: 'Second entry weight',
		minValue: 0,
		maxValue: 100
	},
	[Settings.PER_TRADE_ALLOCATION]: {
		scale: BotSettingsScale.PERCENTAGE,
		label: 'Allocation per trade',
		minValue: 0,
		maxValue: 100
	},
	[Settings.SPOT_LOW_RISK_ALLOCATION]: {
		scale: BotSettingsScale.PERCENTAGE,
		label: 'Low risk allocation',
		minValue: 0,
		maxValue: 100
	},
	[Settings.SPOT_MEDIUM_RISK_ALLOCATION]: {
		scale: BotSettingsScale.PERCENTAGE,
		label: 'Medium risk allocation',
		minValue: 0,
		maxValue: 100
	},
	[Settings.SPOT_HIGH_RISK_ALLOCATION]: {
		scale: BotSettingsScale.PERCENTAGE,
		label: 'High risk allocation',
		minValue: 0,
		maxValue: 100
	}
}

export enum BotStatus {
	ACTIVATED = 'activated',
	PAUSED = 'paused',
}
