import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BTC } from '@app/core/constants/currency.constants';
import { ERROR_OCCURED } from '@app/core/constants/error-messages';
import { ExchangeAccountService } from '@app/core/services/exchange-account.service';
import { UserBotSettingsService } from '@app/core/services/user-bot-settings.service';
import { ExchangeName, Settings } from '@b-cube/database/types';
import { IBot } from '@b-cube/interfaces/bot';
import { IExchangeAccount } from '@b-cube/interfaces/exchange-account';
import { IBotAllocationSettings, IUpdateUserBotSettings } from '@b-cube/interfaces/user-bot-settings';
import { ToastrService } from 'ngx-toastr';
import { catchError, of, Subscription } from 'rxjs';

@Component({
	selector: 'app-widget-balance-allocation-modal',
	templateUrl: './widget-balance-allocation-modal.component.html',
})
export class WidgetBalanceAllocationModalComponent implements OnChanges, OnDestroy {
	// eslint-disable-next-line
	@Output() close = new EventEmitter<any>();
	@Input() selectedAccount: IExchangeAccount;
	@Input() bot?: IBot;
	@Input() balanceAllocations: IBotAllocationSettings;

	public sliderFloor = 0;
	public sliderCeil = 100;
	public isConfirmLoading = false;
	private updateSubscription = new Subscription();
	private initialSettings: any;
	public form: FormGroup = null;

	constructor(
		private fb: FormBuilder,
		private toasterService: ToastrService,
		private userSettingsService: UserBotSettingsService,
		private exchangeAccountService: ExchangeAccountService
	) {
		if (this.form === null) {
			this.form = this.fb.group({
				settings: this.fb.array([])
			});
		}
	}

	ngOnChanges(): void {
		if (this.form === null) {
			this.form = this.fb.group({
				settings: this.fb.array([])
			});
		}
		this.changePairingByExchange();
		this.initBalanceAllocations();
	}

	ngOnDestroy(): void {
		this.updateSubscription.unsubscribe()
	}

	private initBalanceAllocations(): void {
		if (!this.balanceAllocations?.allocations) {
			return;
		}

		let allocations;

		if (this.bot) {
			// keep only the pairing of the current bot
			allocations = this.balanceAllocations.allocations.filter(allocation => allocation.pairing === this.bot.pairing);
			// put the current bot at first place
			const botIndex = allocations[0].bots.findIndex(bot => bot.id === this.bot.id);
			const currentBot = allocations[0].bots.splice(botIndex, 1)[0];
			allocations[0].bots.unshift(currentBot);
		} else {
			allocations = this.balanceAllocations.allocations;
		}
		for (const [index, allocation] of Object.entries(allocations)) {
			if (this.bot && this.bot.pairing !== allocation.pairing) {
				continue;
			}

			this.getSettings().push(this.newBots(allocation.pairing, allocation.amountAvailable, 0));
			for (const bot of allocation.bots) {
				const allocationPercentage = this.computeAllocatedPercentage(bot.allocatedValue, this.sliderCeil);
				const botGroup = new FormGroup({
					id: new FormControl(bot.id),
					name: new FormControl(bot.name),
					allocation: new FormControl(bot.allocatedValue, Validators.required),
					allocationPercentage: new FormControl(allocationPercentage, Validators.required),
					allocationAsset: new FormControl(this.computeAssetAllocation(allocationPercentage, this.getAmountAvailable(Number(index))))
				});
				this.getBots(Number(index)).push(botGroup);
			}

			this.getSettingsAt(Number(index)).patchValue({
				totalAllocation: this.computeTotalAllocation(Number(index))
			})
		}
		this.initialSettings = this.form.value;
	}

	public getSettings(): FormArray {
		return this.form.get('settings') as FormArray;
	}

	private getSettingsAt(index: number): FormGroup {
		return this.getSettings().at(index) as FormGroup;
	}

	public getBots(index: number): FormArray {
		return this.getSettingsAt(index).get('bots') as FormArray;
	}

	private getAmountAvailable(index: number): number {
		return this.getSettingsAt(index).get('amountAvailable').value;
	}

	private newBots(pairing: string, amountAvailable: number, totalAllocation: number): FormGroup {
		return this.fb.group({
			pairing: pairing,
			amountAvailable: amountAvailable,
			totalAllocation: totalAllocation,
			bots: this.fb.array([])
		})
	}

	public onSliderValueChange(value: number, index: number, botIndex: number): void {
		const allocationPercentage = this.computeAllocatedPercentage(value, this.sliderCeil);
		this.getBots(index).at(botIndex).patchValue({
			allocation: value,
			allocationPercentage: this.computeAllocatedPercentage(value, this.sliderCeil),
			allocationAsset: this.computeAssetAllocation(allocationPercentage, this.getAmountAvailable(index))
		});
		this.getSettingsAt(index).patchValue({
			totalAllocation: this.computeTotalAllocation(index)
		})
	}

	public onAllocationPercentageChange(event: any, index: number, botIndex: number): void {
		let percentage: number = event.target.value;
		if (percentage > this.sliderCeil) {
			percentage = this.sliderCeil;
		}
		this.getBots(index).at(botIndex).patchValue({
			allocation: this.compouteAllocationValue(percentage, this.sliderCeil),
			allocationPercentage: percentage,
			allocationAsset: this.computeAssetAllocation(percentage, this.getAmountAvailable(index))
		});
	}

	public setDefaultSettings(): void {
		this.form.reset(this.initialSettings);
	}

	private computeTotalAllocation(index: number): number {
		return this.getSettingsAt(index).value.bots?.reduce((acc: number, bot: any) => {
			return acc + bot.allocationPercentage
		}, 0)
	}

	private computeAllocatedPercentage(value: number, max: number): number {
		return Math.floor(value / max * 100);
	}

	private computeAssetAllocation(botAllocationPercentage: number, amount: number): number {
		return botAllocationPercentage * amount / 100;
	}

	private compouteAllocationValue(percentage: number, max: number): number {
		return percentage * max / 100;
	}

	public submit(): void {
		if (this.form.value.settings.some((settings: any) => settings.totalAllocation > this.sliderCeil)) {
			this.toasterService.error(`Your total allocation cannot be above than ${this.sliderCeil}`)

			return;
		}
		if (!this.form.invalid) {
			this.isConfirmLoading = true;
			const userSettings: IUpdateUserBotSettings[] = []
			for (const setting of this.form.value.settings) {
				for (const bot of setting.bots) {
					userSettings.push(this.buildUpdateUserSettings(bot.id, Settings.ALLOCATION, bot.allocation));
				}
			}

			this.updateSubscription = this.userSettingsService.updateBalanceAllocations(userSettings).pipe(
				catchError(err => {
					this.toasterService.error(`The allocations cannot be updated`, ERROR_OCCURED);

					return of(err);
				})
			).subscribe((err) => {
				if (!err) {
					this.toasterService.success(`The allocations has been updated`)
				}
				this.isConfirmLoading = false;
				this.close.emit(true);
				this.exchangeAccountService.loadTotalInvestment();
			});

		}
	}

	private buildUpdateUserSettings(botId: number, settings: Settings, allocationValue: number): IUpdateUserBotSettings {
		return {
			botId, settings, allocationValue
		} as IUpdateUserBotSettings
	}

	private changePairingByExchange(): void {
		if (this.selectedAccount.exchangeName === ExchangeName.BITMEX_FUTURES) {
			this.balanceAllocations?.allocations.forEach(alloc => {
				alloc.pairing = BTC;
			})
		}
	}

	public dismiss(): void {
		this.close.emit(null);
	}

}
