<div *ngIf="showModal" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
	<div @opacity class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

	<div class="fixed inset-0 z-10 overflow-y-auto">
		<div class="modal bg-muted-800/70 fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center px-4">
			<div
				@opacityTranslateY
				class="bg dark:bg-muted-800 relative mx-auto w-[45rem] transform overflow-hidden rounded-md rounded bg-white p-6 text-left text-left shadow-xl shadow-xl transition-all sm:my-8 sm:max-w-[45rem] sm:p-6 md:max-w-[45rem]"
			>
				<div class="mb-2 flex items-center justify-between">
					<div>
						<h3 class="text-lg font-medium leading-6" id="modal-title">Get Started</h3>
					</div>
					<button
						class="text-muted-300 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 group inline-flex h-10 w-10 items-center justify-center rounded-full transition-colors duration-300"
						(click)="closeModal()"
					>
						<app-icon-x class="h4 w-4 transition-transform duration-300 group-hover:scale-95"></app-icon-x>
					</button>
				</div>
				<span class="isolate w-full rounded text-center shadow-sm">
					<iframe class="aspect-video w-full" [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
				</span>
			</div>
		</div>
	</div>
</div>
