<div class="relative bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md p-4">
	<span class="absolute top-4 left-4 inline-flex justify-center items-center h-7 px-3 rounded-lg font-sans
		text-sm leading-none bg-muted-200 dark:bg-muted-700 text-muted-500 dark:text-muted-300 cursor-pointer"
		[routerLink]="['/plan']">
		{{(user$ | async)?.plan.name}}
	</span>
	<!-- <div class="absolute top-5 right-4 font-sans text-xs text-muted-400 text-right">
		Credit Card
	</div> -->
	<!-- <div class="absolute top-5 right-4 font-sans text-xs text-muted-400 text-right">
		<div>BCUBE</div>
		<div>30/11/2023</div>
		<button>Top Up</button>
	</div> -->
	<div class="relative flex justify-center items-center w-28 h-28 mx-auto mt-16 mb-6">
		<div class="relative flex justify-center items-center w-24 h-24 mask mask-hexed z-20">
			<div class="inline-flex items-center justify-center text-5xl text-muted-400 dark:text-white">
				{{initials$ | async}}
			</div>
			<!-- <img
				src="https://vulk.cssninja.io/assets/demo/img/avatars/56.jpg"
				class="object-cover max-w-full dark:border-transparent shadow-sm"
				alt="Avatar image"
			/> -->
		</div>
		<app-hex-progress-track class="absolute top-0 left-0 w-28 h-28 text-muted-100 dark:text-muted-700 z-0">
		</app-hex-progress-track>
		<app-hex-progress [progress]="(getUserProgress() | async)?.progress"
			class="absolute top-0 left-0 w-28 h-28 z-0 text-secondary-500">
		</app-hex-progress>
		<div class="absolute bottom-4 -right-0.5 h-7 w-7 flex items-center justify-center border-[3px] border-white
			dark:border-muted-800 rounded-full text-white z-30 bg-secondary-500"
			[class.invisible]="!(isInitialized$ | async)"
		>
			<span class="block font-sans text-xs font-semibold">{{(getUserProgress() | async)?.label}}</span>
		</div>
	</div>
	<div class="relative pb-2">
		<div class="flex w-full ">
			<div class="flex items-center justify-center flex-1 font-sans h-full">
				<div>
					<span class="block text-secondary-500 text-5xl text-center min-h-[80px] leading-[5rem]"  [class.invisible]="!(isInitialized$ | async)">
						{{getCurrentNumberOfBots() | async}}
					</span>
					<div class="text-center">
						<h6 class="invisible font-sans text-xs font-medium" *ngIf="! (unlimitedInvestment$ | async)">&nbsp;</h6>
						<p class="font-sans text-xs text-muted-400">Agents allocated</p>
					</div>
				</div>
			</div>
			<div class="flex items-center justify-center flex-1  h-full">
				<div>
					<app-circle-progress *ngIf="! (unlimitedInvestment$ | async)"
						[progress]="getInvestmentProportion() | async"
						[thickness]="2"
						class="w-20 h-20"
						[class]="getInvestmentProgressColor() | async"
					>
						<span class="block" [class.invisible]="!(isInitialized$ | async)"
							[class]="getInvestmentProgressColor() | async">
							{{getInvestmentProportion() | async}}%
						</span>
					</app-circle-progress>
					<span *ngIf="(unlimitedInvestment$ | async)" class="block text-secondary-500 text-xl text-center min-h-[80px] leading-[5rem]"
						[class.invisible]="!(isInitialized$ | async)">
						{{currentInvestment$ | async | currency:'USD':'symbol':'1.0-0'}}
					</span>

					<div class="text-center">
						<h6 *ngIf="! (unlimitedInvestment$ | async)"
							class="font-sans text-xs font-medium text-secondary-500"
							[class.invisible]="!(isInitialized$ | async)">
							{{currentInvestment$ | async | currency:'USD':'symbol':'1.0-0' }} / {{(user$ | async)?.plan.tradingLimit  | currency:'USD':'symbol':'1.0-0'}}
						</h6>
						<p class="font-sans text-xs text-muted-400">Investment</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="text-center mt-6 mb-12 border-t border-muted-200 dark:border-muted-600">
		<p class="absolute bottom-5 left-4 inline-flex font-sans text-xs text-muted-400">{{stakedAmount$ | async | number:'1.0-4'}} BCUBE staked</p>
		<span class="absolute bottom-4 right-4 inline-flex justify-center items-center h-7 px-3 rounded-lg font-sans
			text-sm leading-none bg-muted-200 dark:bg-muted-700 text-muted-500 dark:text-muted-300 cursor-pointer"
			[routerLink]="['/staking']">
			{{(user$ | async)?.tier.name}}
		</span>
	</div>
</div>
