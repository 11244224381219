<div [class.hidden]="! shown"
	class="modal fixed top-0 left-0 flex items-center justify-center w-full h-full bg-muted-800/70 dark:bg-muted-900/80 px-4 z-50">
	<div class="h-auto w-full md:max-w-xl mx-auto text-left bg-white dark:bg-muted-800 rounded shadow-xl">
		<!-- Header -->
		<div class="flex items-center justify-between p-4 md:p-6 border-b border-muted-200 dark:border-muted-700">
			<div>
				<h3 class="font-heading text-lg font-medium leading-6 text-muted-900 dark:text-white">
					Add Exchange Account
				</h3>
			</div>

			<button
				class="flex items-center justify-center rounded-full hover:bg-muted-100 dark:hover:bg-muted-700 h-8 w-8 transition-colors duration-300"
				(click)="dismiss()">
				<svg class="fill-current h-5 w-5 text-muted-700 dark:text-muted-50" xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="32" height="32"
					preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256">
					<path fill="currentColor"
						d="M205.7 194.3a8.1 8.1 0 0 1 0 11.4a8.2 8.2 0 0 1-11.4 0L128 139.3l-66.3 66.4a8.2 8.2 0 0 1-11.4 0a8.1 8.1 0 0 1 0-11.4l66.4-66.3l-66.4-66.3a8.1 8.1 0 0 1 11.4-11.4l66.3 66.4l66.3-66.4a8.1 8.1 0 0 1 11.4 11.4L139.3 128Z">
					</path>
				</svg>
				<span class="sr-only">Close</span>
			</button>
		</div>
		<!-- Body -->
		<form [formGroup]="exchangeAccountForm" (ngSubmit)="submit()">
			<div class="max-h-[440px] overflow-y-auto slimscroll p-4 md:p-6">
				<!--Select exchange-->
				<h3 class="font-sans text-sm text-muted-500 mb-2">
					Select an Exchange to connect
				</h3>
				<div class="grid grid-cols-3 gap-3">
					<!--Item-->
					<div class="relative" *ngFor="let exchange of exchangeList">
						<input type="radio" (click)="handleExchangeChange(exchange)" name="exchangeName"
							formControlName="exchangeName" [value]="exchange"
							class="peer absolute top-0 left-0 z-10 w-full h-full opacity-0 cursor-pointer" checked />
						<div
							class="w-full flex items-center gap-2 p-3 rounded-md bg-white dark:bg-muted-700 border border-muted-200 dark:border-muted-600 text-muted-400 peer-checked:border-brand peer-checked:text-brand">
							<!--<app-exchange-icon [name]="exchange" [svgClass]="'w-8 h-8'">
							</app-exchange-icon>-->
							<app-exchange-icon [name]="exchange"
								[ngClass]="screenService.isDesktop() ? 'w-8 h-8' : 'w-6 h-6'">
							</app-exchange-icon>
							<div>
								<div class="font-sans font-semibold text-xs text-muted-800 dark:text-white">
									{{ exchangesLabels[exchange] }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--Separator-->
				<hr class="my-4 border-t border-muted-200 dark:border-muted-800" />

				<div *ngIf="ipAddresses !== ''" class="p-4 bg-white rounded-xl dark:bg-muted-800 border border-muted-200 dark:border-muted-700">
					<div>
							<div class="flex items-center justify-between mb-4">
									<div class="flex flex-col gap-1">
											<h4 class="font-sans font-semibold text-xs uppercase text-muted-400 dark:text-muted-400">
													Whitelist IPs
											</h4>
											<div class="text-xs text-muted-400 dark:text-muted-400">
													Copy & paste these IP addresses to whitelist b-cube on your exchange
											</div>
									</div>
									<div>
											<app-icon-copy class="w-3 h-3 text-muted-400 cursor-pointer" [tooltip]="tooltipText" placement="top" (click)="copyIpAddresses()"></app-icon-copy>
									</div>
							</div>
							<div class="w-full flex items-center gap-2 p-2 rounded bg-white dark:bg-muted-700 border border-muted-200 dark:border-muted-600">
									<div class="max-h-16 overflow-y-auto slimscroll">
											<span>{{ ipAddresses }}</span>
									</div>
							</div>
					</div>
			</div>

				<!--Separator-->
				<hr class="my-4 border-t border-muted-200 dark:border-muted-800" />

				<div class="space-y-4">
					<!--Grid-->
					<div class="grid md:grid-cols-2 gap-x-6 gap-y-4">
						<!--Field-->
						<div class="group z-full relative">
							<label class="font-sans text-sm inline-block text-muted-500 dark:text-muted-400">Exchange	Account	Name*</label>
							<div class="relative">
								<input type="text"
									class="px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded-lg bg-white text-muted-600 border border-muted-300 focus:border-muted-300 focus:shadow-lg focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 placeholder:text-muted-300 dark:placeholder:text-muted-500 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-muted-300 dark:focus:outline-muted-600 focus:outline-offset-2 transition-all duration-300"
									placeholder="Exchange Account Name" formControlName="name" />
								<div *ngIf="(exchangeAccountForm.get('name')?.invalid &&
								exchangeAccountForm.get('name')?.errors &&
                    (exchangeAccountForm.get('name')?.dirty || exchangeAccountForm.get('name')?.touched))
					|| exchangeAccountForm.get('name')?.invalid && isSubmited" class="alert">
									<small class="text-red-700"
										*ngIf="exchangeAccountForm.get('name')?.hasError('required') && exchangeAccountForm.get('name')?.touched">
										This field is required.
									</small>
								</div>
							</div>
						</div>
						<!--Field-->
						<div class="group z-full relative">
							<label class="font-sans text-sm inline-block text-muted-500 dark:text-muted-400">API Public
								key*</label>
							<div class="relative">
								<input type="text"
									class="px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded-lg bg-white text-muted-600 border border-muted-300 focus:border-muted-300 focus:shadow-lg focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 placeholder:text-muted-300 dark:placeholder:text-muted-500 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-muted-300 dark:focus:outline-muted-600 focus:outline-offset-2 transition-all duration-300"
									placeholder="API Public key" formControlName="accessKey" />
								<div *ngIf="(exchangeAccountForm.get('accessKey')?.invalid &&
								exchangeAccountForm.get('accessKey')?.errors &&
											(exchangeAccountForm.get('accessKey')?.dirty || exchangeAccountForm.get('accessKey')?.touched))
											|| exchangeAccountForm.get('accessKey')?.invalid && isSubmited" class="alert">
									<small class="text-red-700"
										*ngIf="exchangeAccountForm.get('accessKey')?.hasError('required') && exchangeAccountForm.get('accessKey')?.touched">
										This field is required.
									</small>
								</div>
							</div>
						</div>
						<!--Field-->
						<div class="group z-full relative">
							<label class="font-sans text-sm inline-block text-muted-500 dark:text-muted-400">API Secret
								key*</label>
							<div class="relative">
								<input type="password"
									class="px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded-lg bg-white text-muted-600 border border-muted-300 focus:border-muted-300 focus:shadow-lg focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 placeholder:text-muted-300 dark:placeholder:text-muted-500 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-muted-300 dark:focus:outline-muted-600 focus:outline-offset-2 transition-all duration-300"
									placeholder="API Secret key" formControlName="secretKey" />
								<div *ngIf="(exchangeAccountForm.get('secretKey')?.invalid &&
								exchangeAccountForm.get('secretKey')?.errors &&
											(exchangeAccountForm.get('secretKey')?.dirty || exchangeAccountForm.get('secretKey')?.touched))
											|| exchangeAccountForm.get('secretKey')?.invalid && isSubmited" class="alert">
									<small class="text-red-700"
										*ngIf="exchangeAccountForm.get('secretKey')?.hasError('required') && exchangeAccountForm.get('secretKey')?.touched">
										This field is required.
									</small>
								</div>
							</div>
						</div>
						<!--Field-->
						<div *ngIf="exchangeAccountForm.get('password')" class="group z-full relative">
							<label
								class="font-sans text-sm inline-block text-muted-500 dark:text-muted-400">Passphrase*</label>
							<div class="relative">
								<input type="password"
									class="px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded-lg bg-white text-muted-600 border border-muted-300 focus:border-muted-300 focus:shadow-lg focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 placeholder:text-muted-300 dark:placeholder:text-muted-500 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-muted-300 dark:focus:outline-muted-600 focus:outline-offset-2 transition-all duration-300"
									placeholder="Passphrase" formControlName="password" />
								<div *ngIf="(exchangeAccountForm.get('password')?.invalid &&
								exchangeAccountForm.get('password')?.errors &&
											(exchangeAccountForm.get('password')?.dirty || exchangeAccountForm.get('password')?.touched))
											|| exchangeAccountForm.get('password')?.invalid && isSubmited" class="alert">
									<small class="text-red-700"
										*ngIf="exchangeAccountForm.get('password')?.hasError('required') && exchangeAccountForm.get('password')?.touched">
										This field is required.
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Footer -->
			<div class="p-4 md:p-6">
				<div class="flex justify-end w-full gap-x-2">
					<button type="button"
						class="lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 border border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700 dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300"
						(click)="dismiss()">
						<span class="font-sans text-sm">Cancel</span>
					</button>
					<button [ngClass]="[isTestConnectionLoading ? 'button-loading' : '']"
						class="hover:bg-muted-100 w-24 lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300"
						(click)="testConnection($event)">
						<span class="font-sans text-sm">Test</span>
					</button>
					<button [ngClass]="[isConfirmLoading ? 'button-loading' : '']"
						[class]="
					isAddAccountDisabled
						? 'text-muted-200 border border-muted-200 bg-white dark:bg-muted-700 dark:border-muted-600 dark:text-muted-600'
						: 'hover:bg-muted-100 hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300 bg-brand'"
						class="w-24 lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 "
						type="submit" [disabled]="isAddAccountDisabled">
						<span class="font-sans text-sm">Add</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</div>
