import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LOGIN_URL } from '../constants/api-urls.constants';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private router: Router) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// send cookie info in all requests
		request = request.clone({
			withCredentials: true
		});

		return next.handle(request).pipe(
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					window.location.replace(`${LOGIN_URL}?state=${decodeURI(this.router.url)}`);

					return EMPTY;
				}
				
					return throwError(() => error);
				
			}
			));
	}
}