import { Component, Input } from '@angular/core';
import { UNLIMITED_TRADING_LIMIT } from '@b-cube/interfaces/plan/index';
import { UserInfo } from '@b-cube/interfaces/user';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-widget-wizard-profile',
	templateUrl: './widget-wizard-profile.component.html'
})
export class WidgetWizardProfileComponent  {
	@Input() user$: Observable<UserInfo>;
	@Input() userTierProgress$: Observable<any>;
	@Input() initials$: Observable<string>;
	@Input() tradingVolume$: Observable<number>;
	@Input() tradingPourcent$: Observable<number>;

	UNLIMITED_TRADING_LIMIT = UNLIMITED_TRADING_LIMIT;
}
