<svg
  width="11"
  height="14"
  viewBox="0 0 11 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.0625 1.86859V12.2286C0.0625 13.0186 0.9325 13.4986 1.6025 13.0686L9.7425 7.88859C10.3625 7.49859 10.3625 6.59859 9.7425 6.19859L1.6025 1.02859C0.9325 0.598592 0.0625 1.07859 0.0625 1.86859Z"
    fill="currentColor"
  />
</svg>
