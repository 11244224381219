import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAccountBalance } from '@b-cube/interfaces/balance'
import { ExchangeConfig } from '@b-cube/interfaces/exchange/exchange-config';
import { Balance, BALANCE_HISTORY_FREQUENCY, BALANCE_HISTORY_RANGE, IAllocationResponse } from '@b-cube/interfaces/exchange-account';
import { Order } from '@core/models/order';
import { RestURLBuilder } from 'rest-url-builder'
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';

import { DELETE_EXCHANGE_ACCOUNT_URL, EXCHANGE_ACCOUNT_ALLOCATION_URL, EXCHANGE_ACCOUNT_BALANCE_HISTORY_URL, EXCHANGE_ACCOUNT_ORDER_URL, EXCHANGE_ACCOUNT_OVERVIEW_ALLOCATION_URL, EXCHANGE_ACCOUNT_OVERVIEW_BALANCE_HISTORY_URL, EXCHANGE_ACCOUNT_URL, EXCHANGE_ACCOUNT_WITH_KEYS_URL, OVERVIEW_BALANCE_ACCOUNT_URL, TEST_EXCHANGE_CONNECTION_URL } from '../constants/api-urls.constants';
import { EXCHANGES_ICONS_NAMES } from '../constants/icons.constants';
import { ExchangeAccount } from '../models/exchange-account';

export type InvestmentByBot = { [key: number]: number };
interface investmentData {
	total: number,
	byBot: InvestmentByBot
}


@Injectable({
	providedIn: 'root'
})
export class ExchangeAccountService {

	public readonly accounts$ = new BehaviorSubject<ExchangeAccount[]>(null);
	private readonly totalInvestment$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private readonly investmentByBot$: BehaviorSubject<InvestmentByBot> = new BehaviorSubject<InvestmentByBot>({});
	private totalInvestmentLoaded = false;

	private overviewAccount: ExchangeAccount = new ExchangeAccount().deserialize({
		id: '0',
		exchangeName: 'overview',
		name: 'Portfolio overview',
		icon: EXCHANGES_ICONS_NAMES['overview']
	});

	constructor(private http: HttpClient) {
		this.loadAccounts();
	}

	private loadAccounts() {
		this.http.get<ExchangeAccount[]>(EXCHANGE_ACCOUNT_URL).pipe(
			shareReplay(),
			map((exchangeAccounts: ExchangeAccount[]) => {
				return exchangeAccounts.map((exchangeAccount) => new ExchangeAccount().deserialize(exchangeAccount));
			})
		).subscribe((exchangeAccounts: ExchangeAccount[]) => {
			this.accounts$.next(exchangeAccounts);
		});
	}

	public loadTotalInvestment(): void {
		this.getOverviewBalanceAccounts().pipe(
			shareReplay(),
			map((accounts: IAccountBalance[]) => {
				let totalInvestment = 0;
				const investmentByBot: InvestmentByBot = {}

				for (const account of accounts) {
					for (const pairingAllocation of account.pairingsAllocation) {
						for (const botAllocation of pairingAllocation.botsAllocation) {
							const investment = botAllocation.allocation * pairingAllocation.usdQuote / 100;
							investmentByBot[botAllocation.botId] = investment;
							if (!botAllocation.isThirdParty) {
								totalInvestment += investment;
							}
						}
					}
				}

				return {
					total: Number(totalInvestment.toFixed(2)),
					byBot: investmentByBot
				};
			})
		).subscribe((data: investmentData) => {
			this.totalInvestment$.next(data.total);
			this.investmentByBot$.next(data.byBot);
		});
	}

	public get totalInvestment(): Observable<number> {
		if (!this.totalInvestmentLoaded) {
			this.loadTotalInvestment();
		}

		return this.totalInvestment$.asObservable();
	}

	public get investmentByBot(): Observable<InvestmentByBot> {
		if (!this.totalInvestmentLoaded) {
			this.loadTotalInvestment();
		}

		return this.investmentByBot$.asObservable();
	}


	public getExchangeAccounts(): Observable<ExchangeAccount[]> {
		return this.accounts$.asObservable();
	}

	public getOverviewAccount(): ExchangeAccount {
		return this.overviewAccount;
	}

	public getExchangeAccountsWithKeys(): Observable<ExchangeAccount[]> {
		return this.http.get<ExchangeAccount[]>(EXCHANGE_ACCOUNT_WITH_KEYS_URL);
	}

	public addExchangeAccount(data: ExchangeAccount): Observable<ExchangeAccount> {
		return this.http.post(EXCHANGE_ACCOUNT_URL, data).pipe(
			map(createdAccount => {
				const exchangeAccount = new ExchangeAccount().deserialize(createdAccount);
				this.accounts$.value.push(exchangeAccount);
				this.accounts$.next(this.accounts$.value);

				return exchangeAccount;
			})
		);
	}

	public testExchangeKeysConnection(exchangeConfig: ExchangeConfig): Observable<any> {
		return this.http.post(TEST_EXCHANGE_CONNECTION_URL, exchangeConfig);
	}

	public updateExchangeAccount(exchangeAccount: ExchangeAccount): Observable<void> {
		return this.http.put(EXCHANGE_ACCOUNT_URL, exchangeAccount).pipe(
			map(() => {
				for (const [index] of Object.entries(Object.entries(this.accounts$.value))) {
					if (this.accounts$.value[Number(index)].id === exchangeAccount.id) {
						this.accounts$.value[Number(index)] = exchangeAccount;
					}
				}
				this.accounts$.next(this.accounts$.value);
			})
		);
	}

	public deleteExchangeAccount(exchangeAccountId: number): Observable<void> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(DELETE_EXCHANGE_ACCOUNT_URL);
		urlBuilder.setNamedParameter('exchangeAccountId', String(exchangeAccountId));

		return this.http.delete<void>(urlBuilder.get()).pipe(
			map(() => {
				const newArray = this.accounts$.value.filter(exchangeAccount => exchangeAccount.id !== exchangeAccountId);
				this.accounts$.next(newArray);
			})
		);
	}

	public getExchangeAccountBalanceHistory(exchangeAccountId: number, range: BALANCE_HISTORY_RANGE, frequency: BALANCE_HISTORY_FREQUENCY): Observable<Balance[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(EXCHANGE_ACCOUNT_BALANCE_HISTORY_URL);
		urlBuilder.setNamedParameter('exchangeAccountId', String(exchangeAccountId));
		urlBuilder.setQueryParameter('range', range);
		urlBuilder.setQueryParameter('frequency', frequency);
		const url = urlBuilder.get();

		return this.http.get<Balance[]>(url);
	}

	public getOverviewBalanceHistory(range: BALANCE_HISTORY_RANGE, frequency: BALANCE_HISTORY_FREQUENCY): Observable<Balance[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(EXCHANGE_ACCOUNT_OVERVIEW_BALANCE_HISTORY_URL);
		urlBuilder.setQueryParameter('range', range);
		urlBuilder.setQueryParameter('frequency', frequency);

		return this.http.get<Balance[]>(urlBuilder.get());
	}

	public getExchangeAccountOrders(exchangeAccountId: number): Observable<Array<Order>> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(EXCHANGE_ACCOUNT_ORDER_URL);
		urlBuilder.setNamedParameter('exchangeAccountId', String(exchangeAccountId));

		return this.http.get<Order[]>(urlBuilder.get()).pipe(
			map((remoteOrders: any) => {
				const orders: Order[] = [];

				for (const remoteOrder of remoteOrders) {
					orders.push(new Order().deserialize(remoteOrder));
				};

				return orders;
			})
		);
	}

	getExchangeAccountAllocation(exchangeAccountId: number, limit: number): Observable<IAllocationResponse> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(EXCHANGE_ACCOUNT_ALLOCATION_URL);
		urlBuilder.setNamedParameter('exchangeAccountId', String(exchangeAccountId));
		urlBuilder.setQueryParameter('limit', String(limit));

		return this.http.get<IAllocationResponse>(urlBuilder.get());
	}

	getOverviewAllocation(limit: number): Observable<IAllocationResponse> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(EXCHANGE_ACCOUNT_OVERVIEW_ALLOCATION_URL);
		urlBuilder.setQueryParameter('limit', String(limit));

		return this.http.get<IAllocationResponse>(urlBuilder.get());
	}

	public getOverviewBalanceAccounts(): Observable<IAccountBalance[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(OVERVIEW_BALANCE_ACCOUNT_URL);

		return this.http.get<IAccountBalance[]>(urlBuilder.get());
	}

	// public getUserCurrenInvestment(): Observable<number> {
	// 	return this.getOverviewBalanceAccounts().pipe(
	// 		shareReplay(),
	// 		map((accounts: IAccountBalance[]) => {
	// 			let totalInvestment = 0;

	// 			for(const account of accounts){
	// 				for(const pairingAllocation of account.pairingsAllocation){
	// 					totalInvestment += (pairingAllocation.allocatedValue * pairingAllocation.usdQuote / 100);
	// 				}
	// 			}

	// 			return totalInvestment;
	// 		})
	// 	)
	// }

	public truncateKey(initialKey: string): string {
		const numberOfDigitsWeWant = 8;

		return initialKey?.substring(initialKey.length - numberOfDigitsWeWant, initialKey.length);
	}
}
