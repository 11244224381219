import { Component, Input } from '@angular/core';
import { Options } from 'ngx-slider-v2';

@Component({
	selector: 'app-form-balance-allocation-slider',
	templateUrl: './form-balance-allocation-slider.component.html',
	styleUrls: ['./form-balance-allocation-slider.component.css'],
})
export class FormBalanceAllocationSliderComponent {
	@Input() value = 5;
	options: Options = {
		showTicksValues: true,
		stepsArray: [
			{ value: 0 },
			{ value: 25 },
			{ value: 50 },
			{ value: 75 },
			{ value: 100 },
		],
		showSelectionBar: true
	};
}
