<div class="modal fixed top-0 left-0 flex items-center justify-center w-full h-full bg-muted-800/70 dark:bg-muted-900/80 px-4 z-50"
	[ngClass]="{'hidden': !isOpen}">
	<div class="h-auto w-full md:max-w-lg mx-auto text-left bg-white dark:bg-muted-800 rounded shadow-xl">
		<!-- Header -->
		<div class="flex items-center justify-between p-4 md:p-6 border-b border-muted-200 dark:border-muted-700">
			<div>
				<h3 class="font-heading text-lg font-medium leading-6 text-muted-900 dark:text-white">
					{{modalTitle}} {{bot?.name}}
				</h3>
			</div>

			<button
				class="flex items-center justify-center rounded-full hover:bg-muted-100 dark:hover:bg-muted-700 h-8 w-8 transition-colors duration-300"
				(click)="close()">
				<app-icon name="close" class="fill-current h-5 w-5 text-muted-700 dark:text-muted-50"></app-icon>
				<span class="sr-only">Close</span>
			</button>
		</div>
		<div *ngIf="currentStep === 1 && bcubePaymentState" class="md:pt-6 md:px-6 pt-4 px-4">
			<app-bcube-payment-form [(months)]="months" [amount]="bcubePaymentDetails?.priceDiscount">
			</app-bcube-payment-form>
		</div>
		<form [formGroup]="addBotForm" (ngSubmit)="submit()" class="mt-4">
			<!-- Body -->
			<div class="max-h-[380px] overflow-y-auto slimscroll md:px-6 px-4">
				<!--Accounts-->
				<div *ngIf="currentStep === 0" class="w-full px-2">
					<h3 class="font-sans text-muted-600 dark:text-muted-200 mb-6">Pick the exchange account
					</h3>
					<div class="space-y-6 pl-6 py-4">
						<!--Account-->
						<div @insertRemoveTrigger *ngFor="let exchangeAccount of exchangeAccounts$ | async"
							class="flex items-center">
							<!--Radio-->
							<div class="relative flex items-center mr-4">
								<div
									class="group relative overflow-hidden text-brand w-5 h-5 rounded flex flex-shrink-0 justify-center items-center mr-2 outline-1 focus-within:ring-0 outline-transparent focus-within:outline-dashed focus-within:outline-gray-300 dark:focus-within:outline-gray-600 focus-within:outline-offset-2 cursor-pointer transition-all duration-300">
									<input type="radio" class="peer cursor-pointer opacity-0 absolute h-5 w-5 z-20"
										formControlName="exchangeAccountId" [(ngModel)]="selectedAccountId"
										[value]="exchangeAccount.id" />
									<div
										class="absolute top-0 left-0 h-full w-full rounded bg-white dark:bg-slate-700 dark:border-slate-700 border-2 border-slate-400 z-0 peer-checked:border-brand peer-checked:dark:border-brand">
									</div>
									<svg class="relative fill-current opacity-0 w-2.5 h-2.5 pointer-events-none transition duration-300 translate-y-6 peer-checked:opacity-100 peer-checked:translate-y-0 z-10"
										version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
										<g fill="none" fill-rule="evenodd">
											<g transform="translate(-9 -11)" fill="currentColor" fill-rule="nonzero">
												<path
													d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
											</g>
										</g>
									</svg>
								</div>
							</div>
							<!--Logo-->
							<div class="flex items-center gap-x-2">
								<span class="relative flex justify-center items-center h-8 w-8 rounded-full">
									<app-exchange-icon [name]="exchangeAccount.exchangeName" [svgClass]="'w-8 h-8'"
										addon="circle"></app-exchange-icon>
								</span>
								<span
									class="block font-sans font-semibold text-sm text-muted-500 dark:text-muted-100">{{exchangeAccount.name}}</span>
							</div>
						</div>
					</div>
				</div>


				<!--payment details-->
				<div *ngIf="currentStep === 1">
					<!--BCUBE form-->
					<div @insertRemoveTrigger *ngIf="paymentMethod === 'BCUBE' && !isSpecialCaseBot">
						<div class="flex-1 flex justify-center">
							<div class="max-w-[400px]">
								<div class="mb-4 mt-4">
									<h4 class="font-semibold text-muted-700 dark:text-muted-100 text-center">
										Payment details
									</h4>
								</div>
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Price in EUR</span>
									<span class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
										{{months * bcubePaymentDetails?.priceEUR | currency:'EUR':'symbol':'1.0-2'}}
									</span>
								</div>
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">$BCUBE/EUR</span>
									<span class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
										{{bcubePaymentDetails?.conversionRate}}
									</span>
								</div>
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Price in $BCUBE</span>
									<span class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
										{{months * bcubePaymentDetails?.priceBCUBE | number:'1.0-2'}}
									</span>
								</div>
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Discount ({{ user?.tier.name
										}})</span>
									<span class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
										{{bcubePaymentDetails?.discount / 100 | percent:'1.0-0'}}
									</span>
								</div>
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Final price in $BCUBE</span>
									<span class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
										{{months * bcubePaymentDetails?.priceDiscount | number:'1.0-2'}}
									</span>
								</div>
							</div>
						</div>
					</div>

					<!--Credit Card form-->
					<div @insertRemoveTrigger *ngIf="paymentMethod === 'CREDIT_CARD'" class="space-y-3 font-sans">
						<div class="flex justify-center">
							<div class="flex-1 flex justify-center">
								<div class="max-w-[400px]">
									<div class="mb-6">
										<h4 class="font-semibold text-muted-700 dark:text-muted-100 text-center">
											Payment details
										</h4>
									</div>
									<div class="space-y-3 font-sans">
										<div class="flex items-center justify-between">
											<span class="block text-sm text-muted-400 pr-20">Initial price</span>
											<span [ngClass]="[isLoading ? 'button-loading' : '']"
												class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
												{{ccPaymentDetails?.initialPrice | currency:'EUR':'symbol':'1.0-2'}}
											</span>
										</div>
										<div class="flex items-center justify-between">
											<span class="block text-sm text-muted-400 pr-20">Discount ({{
												ccPaymentDetails?.tierName }}
												advantage)</span>
											<span [ngClass]="[isLoading ? 'button-loading' : '']"
												class="h-8 text-muted-600 dark:text-muted-100 text-right">
												{{ccPaymentDetails?.discount / 100 | percent:'1.0-0'}}
											</span>
										</div>
										<div class="flex items-center justify-between">
											<span class="block text-sm text-muted-400 pr-20">Final price</span>
											<span [ngClass]="[isLoading ? 'button-loading' : '']"
												class="h-8 text-muted-600 dark:text-muted-100 min-w-[68px] text-right">
												{{ccPaymentDetails?.priceDiscount | currency:'EUR':'symbol':'1.0-2'}}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--Help text-->
				<div class="py-6 px-2">
					<p class="font-sans text-muted-500 dark:text-muted-400 text-xs">
						Please consider that trading is at your own risk. The risk of loss in
						trading cryptocurrencies, even when done via an automated trading
						system, can be substantial. By confirming, you agree with our term and
						conditions
					</p>
				</div>
			</div>
			<!-- Footer -->
			<div class="p-4 md:p-6">
				<div class="flex justify-end w-full gap-x-2">
					<button type="reset" class="lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 border
						border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700
						dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300" (click)="dismiss()">
						<span class="font-sans text-sm">{{getCancelButtonLabel()}}</span>
					</button>
					<button type="submit" [disabled]="isSubmitDisabled()"
						[class]="
						isLoading ? 'button-loading' : isSubmitDisabled()
						? 'text-muted-200 bg-white dark:bg-muted-700 dark:text-muted-600'
						: 'hover:bg-muted-100 hover:bg-brand/90 bg-brand text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300'"
						class="lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10">
						<span class="font-sans text-sm">{{getAddButtonLabel()}}</span>
					</button>

				</div>
			</div>

		</form>
	</div>
</div>
