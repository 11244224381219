<svg
  width="21"
  height="20"
  viewBox="0 0 21 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.72266 1.00442V18.6444C9.72266 19.2844 9.13266 19.7644 8.51266 19.6244C4.04266 18.6244 0.722656 14.6144 0.722656 9.82442C0.722656 5.03442 4.04266 1.02442 8.51266 0.0244172C9.13266 -0.115583 9.72266 0.364417 9.72266 1.00442ZM11.7527 1.00442V7.81442C11.7527 8.36442 12.2027 8.81442 12.7527 8.81442H19.5427C20.1827 8.81442 20.6627 8.22442 20.5227 7.59442C19.6727 3.83442 16.7227 0.874417 12.9727 0.0244172C12.3427 -0.115583 11.7527 0.364417 11.7527 1.00442ZM11.7527 11.8344V18.6444C11.7527 19.2844 12.3427 19.7644 12.9727 19.6244C16.7327 18.7744 19.6827 15.8044 20.5327 12.0444C20.6727 11.4244 20.1827 10.8244 19.5527 10.8244H12.7627C12.2027 10.8344 11.7527 11.2844 11.7527 11.8344Z"
    fill="currentColor"
  />
</svg>
