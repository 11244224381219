import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxEchartsModule } from 'ngx-echarts';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSliderModule } from 'ngx-slider-v2';

import { BotPairComponent } from './components/bot-pair/bot-pair';
import { BotStatusComponent } from './components/bot-status/bot-status';
import { BotSwitchComponent } from './components/bot-switch/bot-switch.component';
import { ChartAllocationsMiniComponent } from './components/chart/chart-area/chart-allocations-mini/chart-allocations-mini.component';
import { ChartAreaComponent } from './components/chart/chart-area/chart-area.component';
import { ChartAreaBigComponent } from './components/chart/chart-area/chart-area-big/chart-area-big.component';
import { ChartAreaCardComponent } from './components/chart/chart-area/chart-area-card/chart-area-card.component';
import { ChartAreaMiniComponent } from './components/chart/chart-area/chart-area-mini/chart-area-mini.component';
import { ChartBarComponent } from './components/chart/chart-bar/chart-bar.component';
import { ChartLineComponent } from './components/chart/chart-line/chart-line.component';
import { ChartRingComponent } from './components/chart/chart-ring/chart-ring.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { ExchangeAccountSwitchComponent } from './components/exchange-account-switch/exchange-account-switch.component';
import { FakeChartComponent } from './components/fake-chart/fake-chart.component';
import { FakeChartBarComponent } from './components/fake-chart/fake-chart-bar/fake-chart-bar.component';
import { FakeChartBotComponent } from './components/fake-chart/fake-chart-bot/fake-chart-bot.component';
import { FakeChartMainComponent } from './components/fake-chart/fake-chart-main/fake-chart-main.component';
import { FakeChartMarketplaceComponent } from './components/fake-chart/fake-chart-marketplace/fake-chart-marketplace.component';
import { FakeRingComponent } from './components/fake-ring/fake-ring.component';
import { FeatureStatusComponent } from './components/feature-status/feature-status';
import { FormBalanceAllocationSliderComponent } from './components/form/form-balance-allocation-slider/form-balance-allocation-slider.component';
import { FormBotSettingsSliderComponent } from './components/form/form-bot-settings-slider/form-bot-settings-slider.component';
import { FormSwitchComponent } from './components/form/form-switch/form-switch.component';
import { HexProgressComponent } from './components/hex-progress/hex-progress.component';
import { HexProgressTrackComponent } from './components/hex-progress/hex-progress-track/hex-progress-track.component';
import { IconAlertComponent } from './components/icon/icon-alert/icon-alert.component';
import { IconArrowDownComponent } from './components/icon/icon-arrow-down/icon-arrow-down.component';
import { IconArrowLeftComponent } from './components/icon/icon-arrow-left/icon-arrow-left.component';
import { IconAsleepComponent } from './components/icon/icon-asleep/icon-asleep.component';
import { IconBellComponent } from './components/icon/icon-bell/icon-bell.component';
import { IconBinanceComponent } from './components/icon/icon-binance/icon-binance.component';
import { IconBotComponent } from './components/icon/icon-bot/icon-bot.component';
import { IconBtcComponent } from './components/icon/icon-btc/icon-btc.component';
import { IconCardanoComponent } from './components/icon/icon-cardano/icon-cardano.component';
import { IconCartComponent } from './components/icon/icon-cart/icon-cart.component';
import { IconCheckComponent } from './components/icon/icon-check/icon-check.component';
import { IconCheckAnimatedComponent } from './components/icon/icon-check-animated/icon-check-animated.component';
import { IconCopyComponent } from './components/icon/icon-copy/icon-copy.component';
import { IconDocsComponent } from './components/icon/icon-docs/icon-docs.component';
import { IconDogeComponent } from './components/icon/icon-doge/icon-doge.component';
import { IconDotComponent } from './components/icon/icon-dot/icon-dot.component';
import { IconDotsComponent } from './components/icon/icon-dots/icon-dots.component';
import { IconDownloadComponent } from './components/icon/icon-download/icon-download.component';
import { IconEthComponent } from './components/icon/icon-eth/icon-eth.component';
import { IconEyeComponent } from './components/icon/icon-eye/icon-eye.component';
import { IconFtxComponent } from './components/icon/icon-ftx/icon-ftx.component';
import { IconGearComponent } from './components/icon/icon-gear/icon-gear.component';
import { IconHelpComponent } from './components/icon/icon-help/icon-help.component';
import { IconHuobiComponent } from './components/icon/icon-huobi/icon-huobi.component';
import { IconLightbulbComponent } from './components/icon/icon-lightbulb/icon-lightbulb.component';
import { IconMoonComponent } from './components/icon/icon-moon/icon-moon.component';
import { IconPauseComponent } from './components/icon/icon-pause/icon-pause.component';
import { IconPenComponent } from './components/icon/icon-pen/icon-pen.component';
import { IconPieComponent } from './components/icon/icon-pie/icon-pie.component';
import { IconPlayComponent } from './components/icon/icon-play/icon-play.component';
import { IconPlusComponent } from './components/icon/icon-plus/icon-plus.component';
import { IconQuestionComponent } from './components/icon/icon-question/icon-question.component';
import { IconRingComponent } from './components/icon/icon-ring/icon-ring.component';
import { IconStakingComponent } from './components/icon/icon-staking/icon-staking.component';
import { IconSunComponent } from './components/icon/icon-sun/icon-sun.component';
import { IconTestComponent } from './components/icon/icon-test/icon-test.component';
import { IconTimeRotateComponent } from './components/icon/icon-time-rotate/icon-time-rotate.component';
import { IconTrashComponent } from './components/icon/icon-trash/icon-trash.component';
import { IconTusdtComponent } from './components/icon/icon-tusdt/icon-tusdt.component';
import { IconWalletComponent } from './components/icon/icon-wallet/icon-wallet.component';
import { IconWarningComponent } from './components/icon/icon-warning/icon-warning.component';
import { IconXComponent } from './components/icon/icon-x/icon-x.component';
import { BotIconComponent } from './components/icons/bot-icon/bot-icon.component';
import { CryptocurrencyIconComponent } from './components/icons/cryptocurrency-icon/cryptocurrency-icon.component';
import { ExchangeIconComponent } from './components/icons/exchange-icon/exchange-icon.component';
import { IconComponent } from './components/icons/icon/icon.component';
import { WalletProviderIconComponent } from './components/icons/wallet-provider-icon/wallet-provider-icon.component';
import { LogoComponent } from './components/logo/logo.component';
import { LogoTextComponent } from './components/logo/logo-text/logo-text.component';
import { UnstakeConformationModalComponent } from './components/modals/unstake-confirmation-modal/unstake-confirmation-modal.component'
import { VideoPlayerModalComponent } from './components/modals/video-player-modal/video-player-modal.component';
import { WalletModalComponent } from './components/modals/wallet-modal/wallet-modal.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ScreenLoaderComponent } from './components/screen-loader/screen-loader.component';
import { SettingsTabsAccountsExchangeModalComponent } from './components/settings/settings-tabs-accounts-exchange-modal/settings-tabs-accounts-exchange-modal.component';
import { TaScreenerSwitchComponent } from './components/ta-screener-switch/ta-screener-switch.component';
import { ThemeTogglerComponent } from './components/theme-toggler/theme-toggler.component';
import { BcubePaymentFormComponent } from './components/widget/bcube-payment-form/bcube-payment-form.component'
import { WidgetBalanceAllocationComponent } from './components/widget/widget-balance/widget-balance-allocation/widget-balance-allocation.component';
import { WidgetBalanceAllocationModalComponent } from './components/widget/widget-balance/widget-balance-allocation-modal/widget-balance-allocation-modal.component';
import { WidgetBalanceChartComponent } from './components/widget/widget-balance/widget-balance-chart/widget-balance-chart.component';
import { WidgetMyBotsWelcomeComponent } from './components/widget/widget-balance/widget-mybots-welcome/widget-mybots-welcome.component';
import { WidgetPortfolioWelcomeComponent } from './components/widget/widget-balance/widget-portfolio-welcome/widget-portfolio-welcome.component';
import { WidgetBotBalanceAllocationComponent } from './components/widget/widget-bot/widget-bot-balance-allocation/widget-bot-balance-allocation.component';
import { WidgetBotControlsComponent } from './components/widget/widget-bot/widget-bot-controls/widget-bot-controls.component';
import { WidgetBotControlsThirdPartyComponent } from './components/widget/widget-bot/widget-bot-controls-third-party/widget-bot-controls-third-party.component';
import { WidgetBotNameComponent } from './components/widget/widget-bot/widget-bot-name/widget-bot-name.component';
import { WidgetBotPerformanceComponent } from './components/widget/widget-bot/widget-bot-performance/widget-bot-performance.component';
import { WidgetBotSettingsComponent } from './components/widget/widget-bot/widget-bot-settings/widget-bot-settings.component';
import { WidgetBotSettingsModalComponent } from './components/widget/widget-bot/widget-bot-settings-modal/widget-bot-settings-modal.component';
import { WidgetBotStatsComponent } from './components/widget/widget-bot/widget-bot-stats/widget-bot-stats.component';
import { WidgetBotsActiveTableComponent } from './components/widget/widget-bots/widget-bots-active-table/widget-bots-active-table.component';
import { WidgetGetStartedComponent } from './components/widget/widget-get-started/widget-get-started.component';
import { WidgetListToolbarComponent } from './components/widget/widget-list-toolbar/widget-list-toolbar.component';
import { WidgetMarketplaceAboutComponent } from './components/widget/widget-marketplace/widget-marketplace-about/widget-marketplace-about.component';
import { WidgetMarketplaceAllocationsPieComponent } from './components/widget/widget-marketplace/widget-marketplace-allocation-pie/widget-marketplace-allocation-pie.component';
import { WidgetMarketplaceBarChartComponent } from './components/widget/widget-marketplace/widget-marketplace-bar-chart/widget-marketplace-bar-chart.component';
import { WidgetMarketplaceBotCardComponent } from './components/widget/widget-marketplace/widget-marketplace-bot-card/widget-marketplace-bot-card.component';
import { WidgetMarketplaceBotChartComponent } from './components/widget/widget-marketplace/widget-marketplace-bot-chart/widget-marketplace-bot-chart.component';
import { WidgetMarketplaceBotStatsComponent } from './components/widget/widget-marketplace/widget-marketplace-bot-stats/widget-marketplace-bot-stats.component';
import { WidgetMarketplaceBotStatsDetailsComponent } from './components/widget/widget-marketplace/widget-marketplace-bot-stats-details/widget-marketplace-bot-stats-details.component';
import { WidgetMarketplaceCarouselComponent } from './components/widget/widget-marketplace/widget-marketplace-carousel/widget-marketplace-carousel.component';
import { WidgetMarketplaceFiltersComponent } from './components/widget/widget-marketplace/widget-marketplace-filters/widget-marketplace-filters.component';
import { WidgetMarketplaceHeaderComponent } from './components/widget/widget-marketplace/widget-marketplace-header/widget-marketplace-header.component';
import { WidgetMarketplaceHeaderSimpleComponent } from './components/widget/widget-marketplace/widget-marketplace-header-simple/widget-marketplace-header-simple.component';
import { WidgetMarketplaceLinkComponent } from './components/widget/widget-marketplace/widget-marketplace-link/widget-marketplace-link.component';
import { WidgetMarketplaceSubscriptionComponent } from './components/widget/widget-marketplace/widget-marketplace-subscription/widget-marketplace-subscription.component';
import { WidgetMarketplaceSubscriptionBotBcubeComponent } from './components/widget/widget-marketplace/widget-marketplace-subscription-bot-bcube/widget-marketplace-subscription-bot-bcube.component';
import { WidgetMarketplaceSubscriptionBotThirdPartyComponent } from './components/widget/widget-marketplace/widget-marketplace-subscription-bot-third-party/widget-marketplace-subscription-bot-third-party.component';
import { WidgetMarketplaceSubscriptionModalComponent } from './components/widget/widget-marketplace/widget-marketplace-subscription-modal/widget-marketplace-subscription-modal.component';
import { WidgetMarketplaceTabsComponent } from './components/widget/widget-marketplace/widget-marketplace-tabs/widget-marketplace-tabs.component';
import { WidgetMarketplaceTradesComponent } from './components/widget/widget-marketplace/widget-marketplace-trades/widget-marketplace-trades.component';
import { WidgetPlanCurrentComponent } from './components/widget/widget-plan-current/widget-plan-current.component';
import { WidgetPricingWizardComponent } from './components/widget/widget-pricing-wizard/widget-pricing-wizard.component';
import { WidgetProfileComponent } from './components/widget/widget-profile/widget-profile.component';
import { WidgetStakingWizardComponent } from './components/widget/widget-staking-wizard/widget-staking-wizard.component';
import { WidgetTableAccountsComponent } from './components/widget/widget-table/widget-table-accounts/widget-table-accounts.component';
import { WidgetTierCurrentComponent } from './components/widget/widget-tier-current/widget-tier-current.component';
import { WidgetWalletSplitComponent } from './components/widget/widget-wallet-split/widget-wallet-split.component';
import { WidgetWizardCurrentTierComponent } from './components/widget/widget-wizard-current-tier/widget-wizard-current-tier.component';
import { WidgetWizardProfileComponent } from './components/widget/widget-wizard-profile/widget-wizard-profile.component';
import { WidgetWizardNextPlanComponent } from './components/widget/widget-wizard-profile-next-plan/widget-wizard-next-plan.component';
import { WidgetWizardNextTierComponent } from './components/widget/widget-wizard-profile-next-tier/widget-wizard-next-tier.component';
import { WidgetWizardUnstakeTierComparisonComponent } from './components/widget/widget-wizard-unstake-tier-comparison/widget-wizard-unstake-tier-comparison.component';
import { BlinkClassDirective } from './directives/blink-class.directive';
import { ClickoutsideDirective } from './directives/clickoutside.directive';
import { OnlyNumberDirective } from './directives/only-number';
import { TooltipDirective } from './directives/tooltip.directive';
import { CryptoCurrencyPipe } from './pipes/crypto-currency.pipe';
import { LuxonDatePipe } from './pipes/date.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { PriceScreenerPipe } from './pipes/price-screener.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

@NgModule({
	declarations: [
		IconPlusComponent,
		IconAsleepComponent,
		LogoComponent,
		ThemeTogglerComponent,
		IconMoonComponent,
		IconSunComponent,
		LogoTextComponent,
		IconDotsComponent,
		IconPieComponent,
		IconBotComponent,
		IconCartComponent,
		IconRingComponent,
		IconStakingComponent,
		FakeChartComponent,
		IconWalletComponent,
		IconBellComponent,
		IconHelpComponent,
		IconDocsComponent,
		ClickoutsideDirective,
		OnlyNumberDirective,
		IconGearComponent,
		HexProgressComponent,
		IconTestComponent,
		HexProgressTrackComponent,
		CircleProgressComponent,
		WidgetProfileComponent,
		WidgetGetStartedComponent,
		VideoPlayerModalComponent,
		UnstakeConformationModalComponent,
		WidgetBalanceChartComponent,
		WidgetPortfolioWelcomeComponent,
		WidgetMyBotsWelcomeComponent,
		FakeChartMainComponent,
		IconBinanceComponent,
		WidgetTableAccountsComponent,
		IconEyeComponent,
		WidgetWalletSplitComponent,
		FakeRingComponent,
		IconBtcComponent,
		IconEthComponent,
		IconDotComponent,
		IconCardanoComponent,
		IconTusdtComponent,
		IconDogeComponent,
		IconAlertComponent,
		IconArrowDownComponent,
		WidgetBalanceAllocationComponent,
		WidgetBalanceAllocationModalComponent,
		IconWarningComponent,
		IconTimeRotateComponent,
		FormBalanceAllocationSliderComponent,
		WidgetBotsActiveTableComponent,
		IconPauseComponent,
		IconPlayComponent,
		IconDownloadComponent,
		WidgetBotNameComponent,
		WidgetBotStatsComponent,
		WidgetBotBalanceAllocationComponent,
		WidgetBotPerformanceComponent,
		FakeChartBotComponent,
		WidgetBotControlsComponent,
		WidgetBotControlsThirdPartyComponent,
		IconXComponent,
		WidgetBotSettingsComponent,
		WidgetBotSettingsModalComponent,
		IconQuestionComponent,
		IconCopyComponent,
		FormSwitchComponent,
		FormBotSettingsSliderComponent,
		WidgetMarketplaceHeaderComponent,
		WidgetMarketplaceTabsComponent,
		WidgetMarketplaceFiltersComponent,
		WidgetMarketplaceCarouselComponent,
		WidgetListToolbarComponent,
		FakeChartMarketplaceComponent,
		WidgetMarketplaceBotCardComponent,
		IconArrowLeftComponent,
		WidgetMarketplaceLinkComponent,
		WidgetMarketplaceAboutComponent,
		WidgetMarketplaceBotChartComponent,
		WidgetMarketplaceBotStatsComponent,
		WidgetMarketplaceBarChartComponent,
		WidgetMarketplaceAllocationsPieComponent,
		FakeChartBarComponent,
		WidgetMarketplaceTradesComponent,
		WidgetMarketplaceSubscriptionComponent,
		WidgetMarketplaceSubscriptionBotBcubeComponent,
		WidgetMarketplaceSubscriptionBotThirdPartyComponent,
		WidgetMarketplaceBotStatsDetailsComponent,
		WidgetMarketplaceSubscriptionModalComponent,
		WidgetPricingWizardComponent,
		IconLightbulbComponent,
		IconCheckComponent,
		ProgressBarComponent,
		IconCheckAnimatedComponent,
		WidgetPlanCurrentComponent,
		WidgetWizardProfileComponent,
		WidgetWizardNextPlanComponent,
		WidgetMarketplaceHeaderSimpleComponent,
		TooltipDirective,
		IconTrashComponent,
		IconHuobiComponent,
		IconFtxComponent,
		ChartAllocationsMiniComponent,
		ChartAreaComponent,
		ChartRingComponent,
		ChartAreaMiniComponent,
		ChartLineComponent,
		ChartAreaCardComponent,
		ChartBarComponent,
		ChartAreaBigComponent,
		IconPenComponent,
		FeatureStatusComponent,
		BotStatusComponent,
		BotPairComponent,
		WalletModalComponent,
		ScreenLoaderComponent,
		CryptoCurrencyPipe,
		TimeAgoPipe,
		LuxonDatePipe,
		IconComponent,
		BotSwitchComponent,
		ExchangeAccountSwitchComponent,
		BotIconComponent,
		ExchangeIconComponent,
		CryptocurrencyIconComponent,
		WalletProviderIconComponent,
		PriceScreenerPipe,
		BlinkClassDirective,
		TaScreenerSwitchComponent,
		WidgetStakingWizardComponent,
		WidgetTierCurrentComponent,
		WidgetWizardNextTierComponent,
		WidgetWizardUnstakeTierComparisonComponent,
		WidgetWizardCurrentTierComponent,
		BcubePaymentFormComponent,
		LocalizedDatePipe,
		SettingsTabsAccountsExchangeModalComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule.withConfig({
			callSetDisabledState: 'whenDisabledForLegacyCode',
		}),
		NgxSliderModule,
		CarouselModule,
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts'),
		}),
		AngularSvgIconModule.forRoot(),
		NgxSkeletonLoaderModule.forRoot({
			theme: {
				// Enabling theme combination
				extendsFromRoot: true,
				'animation-duration': '1s',
				'background': 'rgba(0, 0, 0, 0)'
			},
		}),
	],
	exports: [
		IconPlusComponent,
		IconAsleepComponent,
		IconSunComponent,
		IconMoonComponent,
		IconDotsComponent,
		IconPieComponent,
		IconBotComponent,
		IconCartComponent,
		IconRingComponent,
		IconStakingComponent,
		IconBellComponent,
		IconWalletComponent,
		IconHelpComponent,
		IconDocsComponent,
		IconGearComponent,
		IconBinanceComponent,
		IconHuobiComponent,
		IconFtxComponent,
		IconEyeComponent,
		IconAlertComponent,
		IconArrowDownComponent,
		IconWarningComponent,
		IconTimeRotateComponent,
		IconPauseComponent,
		IconPlayComponent,
		IconDownloadComponent,
		IconXComponent,
		IconQuestionComponent,
		IconCopyComponent,
		IconArrowLeftComponent,
		IconLightbulbComponent,
		IconCheckComponent,
		IconTrashComponent,
		IconCheckAnimatedComponent,
		LogoComponent,
		LogoTextComponent,
		ThemeTogglerComponent,
		FakeChartComponent,
		FakeChartMainComponent,
		FakeChartBotComponent,
		FakeChartMarketplaceComponent,
		FakeChartBarComponent,
		FakeRingComponent,
		ChartAllocationsMiniComponent,
		ChartAreaComponent,
		ChartAreaBigComponent,
		ChartAreaMiniComponent,
		ChartAreaCardComponent,
		ChartLineComponent,
		ChartBarComponent,
		ChartRingComponent,
		HexProgressComponent,
		HexProgressTrackComponent,
		CircleProgressComponent,
		ProgressBarComponent,
		WidgetProfileComponent,
		WidgetGetStartedComponent,
		VideoPlayerModalComponent,
		UnstakeConformationModalComponent,
		WidgetBalanceChartComponent,
		WidgetPortfolioWelcomeComponent,
		WidgetMyBotsWelcomeComponent,
		WidgetTableAccountsComponent,
		WidgetWalletSplitComponent,
		WidgetBalanceAllocationComponent,
		WidgetBalanceAllocationModalComponent,
		WidgetBotsActiveTableComponent,
		WidgetBotNameComponent,
		WidgetBotBalanceAllocationComponent,
		WidgetBotStatsComponent,
		WidgetBotPerformanceComponent,
		WidgetBotControlsComponent,
		WidgetBotControlsThirdPartyComponent,
		WidgetBotSettingsComponent,
		WidgetBotSettingsModalComponent,
		WidgetMarketplaceHeaderComponent,
		WidgetMarketplaceHeaderSimpleComponent,
		WidgetMarketplaceTabsComponent,
		WidgetMarketplaceFiltersComponent,
		WidgetMarketplaceCarouselComponent,
		WidgetListToolbarComponent,
		WidgetMarketplaceBotCardComponent,
		WidgetMarketplaceLinkComponent,
		WidgetMarketplaceAboutComponent,
		WidgetMarketplaceBotChartComponent,
		WidgetMarketplaceBotStatsComponent,
		WidgetMarketplaceBarChartComponent,
		WidgetMarketplaceAllocationsPieComponent,
		WidgetMarketplaceTradesComponent,
		WidgetMarketplaceSubscriptionComponent,
		WidgetMarketplaceSubscriptionBotBcubeComponent,
		WidgetMarketplaceSubscriptionBotThirdPartyComponent,
		WidgetMarketplaceSubscriptionModalComponent,
		WidgetMarketplaceBotStatsDetailsComponent,
		WidgetPricingWizardComponent,
		WidgetPlanCurrentComponent,
		WidgetWizardProfileComponent,
		WidgetWizardNextPlanComponent,
		FormBalanceAllocationSliderComponent,
		FormSwitchComponent,
		ClickoutsideDirective,
		OnlyNumberDirective,
		TooltipDirective,
		IconPenComponent,
		FeatureStatusComponent,
		BotStatusComponent,
		BotPairComponent,
		WalletModalComponent,
		ScreenLoaderComponent,
		CryptoCurrencyPipe,
		TimeAgoPipe,
		LuxonDatePipe,
		IconComponent,
		BotSwitchComponent,
		ExchangeAccountSwitchComponent,
		BotIconComponent,
		ExchangeIconComponent,
		CryptocurrencyIconComponent,
		PriceScreenerPipe,
		BlinkClassDirective,
		TaScreenerSwitchComponent,
		WidgetStakingWizardComponent,
		WidgetTierCurrentComponent,
		WidgetWizardNextTierComponent,
		WidgetWizardUnstakeTierComparisonComponent,
		WidgetWizardCurrentTierComponent,
		BcubePaymentFormComponent,
		LocalizedDatePipe,
		ReactiveFormsModule,
		ExchangeIconComponent,
		SettingsTabsAccountsExchangeModalComponent,
		NgxSkeletonLoaderModule
	]
})
export class SharedModule { }
