<app-wallet-modal #modal></app-wallet-modal>
<app-screen-loader [showScreenLoader]="showScreenLoader$ | async"></app-screen-loader>
<div class="group relative" (clickOutside)="closeMenu($event)">

	<button *ngIf="!(isWalletLoaded$ | async) && (isWalletConnected$ | async)"
		class="relative flex items-center justify-center h-9 w-9 rounded-full text-muted-400 group-hover:text-muted-500 group-hover:bg-muted-100 dark:group-hover:text-muted-100 dark:group-hover:bg-muted-700 transition-colors duration-300">
		<span class="circle-loader"></span>
	</button>

	<button *ngIf="!(isWalletConnected$ | async)" (click)="modal.toggleModal()"
		class="relative flex items-center justify-center h-9 w-9 text-brand hover:text-white font-sans leading-2 text-sm hover:shadow-md  rounded-full cursor-pointer transition-all duration-300">
		<app-icon-wallet class="w-4 h-4"></app-icon-wallet>
	</button>

	<button *ngIf="(isWalletConnected$ | async) && (stakingWallet$ | async) !== null" type="button"
		class="relative flex items-center justify-center h-9 w-9 rounded-full text-muted-400 group-hover:text-muted-500 group-hover:bg-muted-100 dark:group-hover:text-muted-100 dark:group-hover:bg-muted-700 transition-colors duration-300"
		(click)="toggleMenu()">
		<app-icon-wallet class="w-4 h-4"></app-icon-wallet>
	</button>

	<div [@openClose]="isMenuOpen ? 'open' : 'closed'" class="absolute top-0 right-0 w-64 mt-14"
		[class]="!isMenuOpen ? 'pointer-events-none' : ''">
		<div
			class="bg-gradient-to-t from-cyan-700 to-brand border border-muted-200 dark:from-muted-800 dark:to-muted-800 dark:border-muted-600 rounded-md">
			<!--Header-->
			<div class="flex items-center px-4 pt-4 pb-0">
				<!-- <a href="#"
					class="font-sans text-sm font-medium text-white hover:opacity-75 ml-auto transition-colors duration-300">
					<app-icon-gear class="w-4 h-4"></app-icon-gear>
				</a> -->
			</div>
			<!--body-->
			<div class="relative px-4 pb-4">
				<div class="relative z-10 text-center">
					<app-logo class="w-12 h-12 mx-auto mb-2 text-white dark:text-brand"></app-logo>
					<h3 class="font-sans font-semibold text-xs uppercase text-white dark:text-muted-200">
						Wallet
					</h3>
					<p class="font-san text-sm text-white opacity-50">{{
						formatEthWallet((currentConnectedWallet$ |
						async)) }}</p>
				</div>
				<ng-container
					*ngIf="{shouldChange: shouldChangeNetwork$ | async, shouldAuthenticate: shoudAuthenticate$ | async, isFundsApproved: isFundsApproved$ | async, walletsMatchStatus: walletsMatchStatus$ | async } as states">
					<div *ngIf="!states.shouldChange">
						<div *ngIf="!states.shouldAuthenticate && states.isFundsApproved"
							class="relative z-10 text-center pt-4 space-y-4">
							<!-- Owned and Staked blocks -->
							<div>
								<span
									class="block font-sans font-medium text-xs uppercase text-white opacity-50 dark:text-muted-200">Owned</span>
								<p class="font-sans font-semibold text-sm text-white dark:text-white">
									{{ ownedBcubeBalance$ | async | number:'1.0-2' }} <span
										class="text-white font-normal opacity-50">BCUBE</span>
								</p>
							</div>
							<div>
								<span
									class="block font-sans font-medium text-xs uppercase text-white opacity-50 dark:text-muted-200">Staked</span>
								<p class="font-sans font-semibold text-sm text-white dark:text-white">
									{{ stakedBcubeBalance$ | async | number:'1.0-2'}} <span
										class="text-white font-normal opacity-50">BCUBE</span>
								</p>
							</div>
						</div>

						<div *ngIf="states.shouldAuthenticate" class="relative z-10 mt-5 text-center space-y-2">
							<!-- Authenticate button -->
							<button type="button"
								class="font-sans text-sm w-full h-10 rounded-md border-2 border-white text-white hover:bg-white hover:text-muted-500 dark:border-brand dark:text-brand dark:hover:bg-brand dark:hover:white transition-colors duration-300"
								(click)="authenticateBySigning()">
								<span class="block font-sans font-medium ml-2">Authenticate</span>
							</button>
						</div>

						<div *ngIf="!states.shouldAuthenticate && !states.isFundsApproved && !states.shouldChange"
							class="relative z-10 mt-5 text-center space-y-2">
							<!-- Approve button -->
							<button type="button"
								class="font-sans text-sm w-full h-10 rounded-md border-2 border-white text-white hover:bg-white hover:text-muted-500 dark:border-brand dark:text-brand dark:hover:bg-brand dark:hover:white transition-colors duration-300"
								(click)="approveFunds()">
								<span class="block font-sans font-medium ml-2">Approve</span>
							</button>
						</div>
					</div>

					<div *ngIf="states.shouldChange"
						class="relative z-10 mt-5 flex justify-center items-center border border-orange-500 rounded-md p-5">
						<!-- Network warning block -->
						<app-icon-warning class="w-8 h-8 text-orange-500"></app-icon-warning>
						<span class="block font-sans font-normal ml-2 text-muted-300 text-sm">Please connect to the {{
							network }} network</span>
					</div>

					<div *ngIf="!states.walletsMatchStatus"
						class="relative z-10 mt-5 flex justify-center items-center border border-orange-500 rounded-md p-5">
						<!-- Wallet did not match warning block -->
						<app-icon-warning class="w-8 h-8 text-orange-500"></app-icon-warning>
						<span class="block font-sans font-normal ml-2 text-muted-300 text-sm">{{ walletSelectionWarning }}</span>
					</div>
	
				</ng-container>

				<!-- DO NOT DELETE. WILL BE USED LATER -->
				<!-- <div class="relative z-10 mt-5 text-center space-y-2">
					<button
						class="font-sans text-sm w-full h-10 rounded-md border-2 border-white text-white hover:bg-white hover:text-muted-500 dark:border-brand dark:text-brand dark:hover:bg-brand dark:hover:white transition-colors duration-300">
						Buy
					</button>
					<button class="font-sans text-sm w-full h-10 rounded-md bg-accent dark:bg-slate-900 text-white">
						Stake
					</button>
					<button class="font-sans text-sm w-full h-10 rounded-md bg-accent dark:bg-slate-900 text-white">
						Unstake
					</button>
				</div> -->
				<app-logo
					class="absolute bottom-0 inset-x-0 z-0 w-full h-full text-white opacity-30 dark:opacity-20 dark:text-white scale-75">
				</app-logo>
			</div>
		</div>


	</div>
</div>
