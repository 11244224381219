import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sidebar-currency',
	templateUrl: './sidebar-currency.component.html',
	animations: [
		trigger('openClose', [
			state('open', style({
				transform: 'translateY(0)',
				opacity: 1
			})),
			state('closed', style({
				transform: 'translateY(0.25rem)',
				opacity: 0
			})),
			transition('open => closed', [
				animate('0.3s')
			]),
			transition('closed => open', [
				animate('0.2s')
			]),
		]),
	],
})
export class SidebarCurrencyComponent {
	
	isMenuOpen = false;

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}

	closeMenu() {
		this.isMenuOpen = false;
	}
}
