
export interface AssetBalance {
	asset: string;
	amount: number;
	usdValue: number;
}

export interface ExchangeAccountBalance {
	exchangeAccountId: number;
	usdBalance: number;
	btcBalance: number;
	assetBalances: Array<AssetBalance>;
}
