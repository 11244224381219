export enum Routes {
	MARKETPLACE = '/marketplace',
	PORTFOLIO = '/portfolio',
	BOTS = '/agents',
	PLAN = '/plan',
	SETTINGS = '/settings',
	KYC = '/settings/kyc',
	STAKING = '/staking',
	STAKING_EDIT = '/staking/edit',
	EXCHANGE_ACCOUNT = '/exchange-account',
	SENTIMENT_ANALYSIS = '/sentiment-analysis',
	TA_SCEENER = '/ta-screener',
	EDIT_PLAN= '/plan/edit',
}

export enum ReuseRoutesParams {
	SHOULDREUSE = 'shouldReuse',
	KEY = 'key',
	REUSEROUTESFROM = 'reuseRoutesFrom',
}
