import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from "luxon";

@Pipe({
    name: 'localizedDate',
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {

    transform(value: Date | string): string {
        const date = DateTime.fromISO(value.toString())
        const humanReadable = date.toLocaleString(DateTime.DATETIME_SHORT);

        return humanReadable;
    }
}
