import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { loadingOptions, PerformanceDisplayMode } from '@app/core/constants/charts.constants';
import { IPerformance } from '@b-cube/interfaces/performance';
import { EChartsOption, graphic } from 'echarts';

@Component({
	selector: 'app-chart-area-big',
	templateUrl: './chart-area-big.component.html'
})
export class ChartAreaBigComponent implements OnChanges {
	@Input() chartData: IPerformance[];
	@Input() perfDisplayMode: PerformanceDisplayMode;
	@Input() loading = false;

	public loadingOptions = loadingOptions;
	public options: EChartsOption = {};

	public get isLoading(): boolean {
		return this.loading;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ((changes['chartData'] || changes['loading']) && this.chartData) {
			this.getChartOptions();
		}
	}

	getChartOptions(): void {
		const data = this.chartData ?? [];
		const datePipe = new DatePipe('en-US');
		const xAxisData = data.map(point => datePipe.transform(point.time, this.perfDisplayMode));
		const yAxisData = Object.values(data);

		const isEmptyData = xAxisData.length === 0;

		this.options = <EChartsOption>{
			responsive: true,
			maintainAspectRatio: false,
			title: {
				show: isEmptyData && !this.isLoading,
				textStyle: {
					color: '#64748B',
					fontSize: 12,
					fontStyle: 'italic'
				},
				text: 'No trade within this period',
				left: 'center',
				top: 'center'
			},
			xAxis: {
				show: !isEmptyData,
				type: 'category',
				boundaryGap: true,
				data: xAxisData,
				axisLabel: {
					color: '#94a3b8',
				},
				axisTick: {
					lineStyle: {
						color: '#cbd5e1',
					},
				},
				axisLine: {
					lineStyle: {
						color: '#cbd5e1',
					},
				},
			},
			yAxis: {
				type: 'value',
				splitNumber: 5,
				axisLabel: {
					formatter: '{value}$',
					color: '#94a3b8',
				},
				axisTick: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
				axisLine: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
				splitLine: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
			},
			tooltip: {
				show: true,
				textStyle: {
					fontSize: 12
				},
				valueFormatter: (value: number) => '$' + value.toFixed(2)
			},
			grid: {
				left: 0,
				top: 0,
				right: 0,
				bottom: 0,
				containLabel: true
			},
			series: [
				{
					name: 'Balance',
					data: yAxisData,
					type: 'line',
					areaStyle: {
						color: new graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0,
								color: '#23cbd5',
							},
							{
								offset: 1,
								color: '#E6FEFF',
							},
						]),
						opacity: 0.4
					},
					lineStyle: {
						width: 3,
						color: '#10949D',
						join: 'round',
					},
					itemStyle: {
						normal: {
							color: '#10949D',
							lineStyle: {
								width: 2,
								type: 'solid',
								color: '#10949D',
							},
						},
						emphasis: {
							color: 'white',
							lineStyle: {
								width: 2,
								type: 'dotted',
								color: '#10949D',
							},
						},
					},
				},
			],
			height: 325
		};
	}
}
