<svg
  width="21"
  height="19"
  viewBox="0 0 21 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.7156 0.321904C6.6256 0.180064 2.4556 4.31369 2.4556 9.44019H0.665597C0.215597 9.44019 -0.00440308 9.98729 0.315597 10.3014L3.1056 13.1382C3.3056 13.3408 3.6156 13.3408 3.8156 13.1382L6.6056 10.3014C6.9256 9.98729 6.6956 9.44019 6.2556 9.44019H4.4556C4.4556 5.48893 7.6356 2.29753 11.5556 2.34819C15.2756 2.39885 18.4056 5.56999 18.4556 9.33888C18.5056 13.3003 15.3556 16.5322 11.4556 16.5322C9.8456 16.5322 8.3556 15.975 7.1756 15.0327C6.7756 14.7187 6.2156 14.7491 5.8556 15.1138C5.4356 15.5494 5.4656 16.2586 5.9356 16.6335C7.4556 17.8392 9.3756 18.5585 11.4556 18.5585C16.5056 18.5585 20.5956 14.3337 20.4556 9.17678C20.3256 4.42513 16.4056 0.453612 11.7156 0.321904V0.321904ZM11.2056 5.38762C10.7956 5.38762 10.4556 5.73209 10.4556 6.14748V9.87585C10.4556 10.2304 10.6456 10.5648 10.9456 10.7472L14.0656 12.6215C14.4256 12.8342 14.8856 12.7126 15.0956 12.358C15.3056 11.9933 15.1856 11.5273 14.8356 11.3145L11.9556 9.58204V6.13735C11.9556 5.73209 11.6256 5.38762 11.2056 5.38762V5.38762Z"
    fill="currentColor"
  />
</svg>
