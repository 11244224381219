import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-fake-chart',
	templateUrl: './fake-chart.component.html'
})
export class FakeChartComponent {

	@Input() class = '';

}
