import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ERROR_OCCURED } from '@app/core/constants/error-messages';
import { WHITELISTED_IPS } from '@app/core/constants/ips.constants';
import { COPIED_TO_CLIPBOARD,COPY_TO_CLIPBOARD } from '@app/core/constants/tips.constants';
import { ADD_EXCHANGE_AC_SUCCESS } from '@app/core/constants/pixel-tracker.constants';
import { ExchangeAccount } from '@app/core/models/exchange-account';
import { ExchangeService } from '@app/core/services/exchange.service';
import { ScreenService } from '@app/core/services/screen.service';
import { ExchangeName } from '@b-cube/database/types';
import { IExchangeAccount } from '@b-cube/interfaces/exchange-account';
import { ExchangeAccountService } from '@core/services/exchange-account.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, lastValueFrom, of, Subscription, take, Observable } from 'rxjs';

enum FormControls {
	Name = 'name',
	AccessKey = 'accessKey',
	SecretKey = 'secretKey',
	Password = 'password',
	ExchangeName = 'exchangeName'
}

enum ResetFormMode {
	Default,
	WithExchangeName
}

@Component({
	selector: 'app-settings-tabs-accounts-exchange-modal',
	templateUrl: './settings-tabs-accounts-exchange-modal.component.html',
})
export class SettingsTabsAccountsExchangeModalComponent implements OnInit, OnDestroy {
	// eslint-disable-next-line
	private exchangeAccountCreated = new EventEmitter<ExchangeAccount>;

	public shown = false;
	public isTestConnectionLoading = false;
	public isConfirmLoading = false;
	public isAddAccountDisabled = true;
	public isSubmited = false;

	public exchangeList: string[];
	public exchangesLabels: { [key in string]?: string };
	public exchangeAccountForm: FormGroup;

	private addExchangeSubscription = new Subscription();
	private testConnectionSub = new Subscription();

	public tooltipText = COPY_TO_CLIPBOARD;
	public ipAddresses = '';
	public accounts$: Observable<ExchangeAccount[]>;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private exchangeAccountService: ExchangeAccountService,
		private toasterService: ToastrService,
		private exchangeService: ExchangeService,
		public screenService: ScreenService
	) { }


	ngOnInit(): void {
		this.exchangeAccountForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			exchangeName: [ExchangeName.BINANCE_SPOT, [Validators.required]],
			accessKey: ['', [Validators.required]],
			secretKey: ['', [Validators.required]]
		});
		this.exchangeList = Object.keys(this.getExchangesLabels());
		this.exchangesLabels = this.getExchangesLabels();
		this.ipAddresses = this.getIPAddresses(ExchangeName.BINANCE_SPOT)
		this.initExchangeAccountsList();
	}

	ngOnDestroy(): void {
		this.addExchangeSubscription.unsubscribe();
		this.testConnectionSub.unsubscribe();
	}

	public async show(): Promise<IExchangeAccount> {
		this.resetForm(ResetFormMode.WithExchangeName);
		this.shown = true;

		return lastValueFrom(this.exchangeAccountCreated.asObservable().pipe(take(1)));
	}

	dismiss(): void {
		this.exchangeAccountCreated.emit(null);
		this.shown = false;
	}

	private resetForm(mode: ResetFormMode = ResetFormMode.Default): void {
		this.isAddAccountDisabled = true;
		const controlKeys = Object.values(FormControls).filter(controlKey => controlKey !== FormControls.ExchangeName);

		for (const controlKey of controlKeys) {
			if (this.exchangeAccountForm.contains(controlKey)) {
				this.exchangeAccountForm.controls[controlKey].reset('');
			}
		}

		if (mode === ResetFormMode.WithExchangeName) {
			this.exchangeAccountForm.controls[FormControls.ExchangeName].reset(ExchangeName.BINANCE_SPOT);
		}
	}

	getExchangesLabels(): { [key in string]?: string } {
		return this.exchangeService.getExchangesLabels();
	}

	handleExchangeChange(exchange: string): void {
		this.resetForm();
		if (exchange === ExchangeName.BITGET_FUTURES || exchange === ExchangeName.KUCOIN_SPOT || exchange === ExchangeName.KUCOIN_FUTURES) {
			this.exchangeAccountForm.addControl('password', this.formBuilder.control('', Validators.required));
		} else {
			this.exchangeAccountForm.removeControl('password');
		}
		this.ipAddresses = this.getIPAddresses(exchange)
	}

	public testConnection(event: Event): void {
		event.preventDefault();

		if (this.exchangeAccountForm.valid) {

			this.isTestConnectionLoading = true;

			const exchangeConfig = (({ name, ...formValues }) => formValues)(this.exchangeAccountForm.value)

			this.testConnectionSub = this.exchangeAccountService.testExchangeKeysConnection(exchangeConfig).pipe(
				catchError(err => {
					this.toasterService.error("Connection error", ERROR_OCCURED);
					this.isTestConnectionLoading = false;

					return of(err);
				})
			).subscribe((err) => {
				if (!err) {
					this.toasterService.success("Connection successful.");
					this.isAddAccountDisabled = false;
				}
				this.isTestConnectionLoading = false;
			});
		} else {
			this.toasterService.warning("Please fill all the fields.");
		}
	}

	submit(): void {
		this.isSubmited = true;
		if (this.exchangeAccountForm.valid) {
			this.isConfirmLoading = true;
			this.isAddAccountDisabled = true;
			this.addExchangeSubscription =
				this.exchangeAccountService.addExchangeAccount(this.exchangeAccountForm.value).
					pipe(
						catchError(err => {
							if (err.status === 400) {
								this.toasterService.error(`The exchange account ${this.exchangeAccountForm.value.name} already exists or you provided invalid keys`, ERROR_OCCURED)
							} else {
								this.toasterService.error(`The exchange account ${this.exchangeAccountForm.value.name} cannot be created`, ERROR_OCCURED)
							}

							return of(err)
						})
					)
					.subscribe((createdAccount: any) => {
						if (!createdAccount.error) {
							this.isAddAccountDisabled = false;
							const exchangeAccount = new ExchangeAccount().deserialize(createdAccount);
							this.exchangeAccountCreated.emit(exchangeAccount);
							this.toasterService.success(`The exchange account ${exchangeAccount.name} has been created`)
							this.accounts$.subscribe(accounts => {
								if (accounts.length === 1) {
									const urlTree = this.router.createUrlTree([], {
										relativeTo: this.route,
										fragment: ADD_EXCHANGE_AC_SUCCESS
									});
									this.router.navigateByUrl(urlTree);
								}
							});
						}
						this.isConfirmLoading = false;
						this.shown = false;
					})
		}
	}

	copyIpAddresses(): void {
    navigator.clipboard.writeText(this.ipAddresses).then(() => {
			this.tooltipText = COPIED_TO_CLIPBOARD
    }).catch(err => {
      console.error('Could not copy IPs: ', err)
    })
  }

	private joinIPAddresses(countries: (keyof typeof WHITELISTED_IPS)[], separator: string): string {
		return countries.map(country => WHITELISTED_IPS[country].join(separator)).join(separator);
	}

	private getIPAddresses(exchangeName: string): string {
		switch (exchangeName) {
			case ExchangeName.BINANCE_SPOT:
			case ExchangeName.BINANCE_FUTURES:
			case ExchangeName.MOCK_BINANCE_FUTURES:
				return this.joinIPAddresses(['POL', 'SRB', 'DEU'], ' ');
				
			case ExchangeName.BITGET_SPOT:
			case ExchangeName.BITGET_FUTURES:
				return this.joinIPAddresses(['POL', 'SRB'], ',');
				
			case ExchangeName.BYBIT_SPOT:
			case ExchangeName.BYBIT_FUTURES:
				return this.joinIPAddresses(['POL', 'SRB', 'DEU'], ',');
				
			case ExchangeName.KRAKEN_SPOT:
				return [
					WHITELISTED_IPS.KRAKEN_SPOT.join('\n'), 
					WHITELISTED_IPS.DEU.join('\n')
				].join('\n');
				
			case ExchangeName.KUCOIN_SPOT:
			case ExchangeName.KUCOIN_FUTURES:
				return '';
				
			default:
				return '';
		}
	}

	private initExchangeAccountsList(): void {
		this.accounts$ = this.exchangeAccountService.getExchangeAccounts();
	}
}
