<div class="flex-1 bg-gradient-to-t border px-5 py-8 rounded-lg from-cyan-700 to-brand">
	<div class="flex items-center gap-x-2 mb-4">
		<img src="/assets/img/illustrations/safe-box.png" class="object-contain w-14" alt="Illustration" />
		<div>
			<h4 class="text-[0.65rem] font-semibold text-white opacity-50 uppercase">
				Next tier
			</h4>
			<h3 class="font-sans font-medium text-2xl text-white capitalize">
				{{nextTier.name}}
			</h3>
		</div>
	</div>
	<div class="flex divide-x divide-brand/50">
		<div class="font-sans px-4">
			<p class="text-white opacity-90 text-xs whitespace-nowrap pb-2">
				Discount
			</p>
			<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
				<span>Plan/CC</span>
				<span>{{displaySign(tierDifference.planDiscountForCC)}}{{tierDifference.planDiscountForCC / 100 | percent:'1.0-0'}}</span>
			</p>
			<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
				<span>Plan/BCUBE</span>
				<span>{{displaySign(tierDifference.planDiscountForBCUBE)}}{{tierDifference.planDiscountForBCUBE / 100 | percent:'1.0-0'}}</span>
			</p>
			<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
				<span>Agent/CC</span>
				<span>{{displaySign(tierDifference.botDiscountForCC)}}{{tierDifference.botDiscountForCC  / 100| percent:'1.0-0'}}</span>
			</p>
			<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
				<span>Agent/BCUBE</span>
				<span>{{displaySign(tierDifference.botDiscountForBCUBE)}}{{tierDifference.botDiscountForBCUBE / 100 | percent:'1.0-0'}}</span>
			</p>
		</div>
		<div class="font-sans px-4">
			<p class="text-white opacity-90 text-xs whitespace-nowrap pb-2">
				Staking
			</p>
			<p class="text-white opacity-90 text-xs whitespace-nowrap" *ngIf="areTokensDifferent() && availableTokens === '-1'">
				{{getTokensDifferenceLabel()}} $BCUBE
			</p>
			<p class="text-white opacity-90 text-xs" *ngIf="areTokensDifferent() && isAvailableTokensGteZero()">
				Required: {{ tierDifference?.stakedTokens }} $BCUBE
			</p>
			<p class="text-white opacity-90 text-xs" *ngIf="areTokensDifferent() && isAvailableTokensGteZero()">
				Available: {{ availableTokens | number:'1.0-2' }} $BCUBE
			</p>
		</div>
	</div>
</div>
