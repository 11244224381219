<svg
  width="21"
  height="20"
  viewBox="0 0 21 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.7166 0C5.19656 0 0.726562 4.48 0.726562 10C0.726562 15.52 5.19656 20 10.7166 20C16.2466 20 20.7266 15.52 20.7266 10C20.7266 4.48 16.2466 0 10.7166 0ZM13.9466 15.39L10.7266 13.45L7.50656 15.39C7.12656 15.62 6.65656 15.28 6.75656 14.85L7.60656 11.19L4.77656 8.74C4.44656 8.45 4.62656 7.9 5.06656 7.86L8.80656 7.54L10.2666 4.09C10.4366 3.68 11.0166 3.68 11.1866 4.09L12.6466 7.53L16.3866 7.85C16.8266 7.89 17.0066 8.44 16.6666 8.73L13.8366 11.18L14.6866 14.85C14.7866 15.28 14.3266 15.62 13.9466 15.39Z"
    fill="currentColor"
  />
</svg>
