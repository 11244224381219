<svg
  width="60"
  height="11"
  viewBox="0 0 60 11"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    d="M1.80602 4.25532C2.29372 3.52163 3.05699 3.15426 4.09582 3.15426C5.12529 3.15426 5.93744 3.51229 6.53016 4.22834C7.12289 4.94439 7.42029 5.83478 7.42029 6.89952C7.42029 7.96426 7.12393 8.85257 6.53016 9.56343C5.93744 10.2743 5.12529 10.6302 4.09582 10.6302C2.98316 10.6302 2.1783 10.2359 1.68124 9.44617V10.4652H0.164062V0.140625H1.80602V4.25532ZM1.75091 6.89848C1.75091 7.55953 1.93289 8.10539 2.2958 8.5371C2.65872 8.9688 3.1485 9.18466 3.76514 9.18466C4.37243 9.18466 4.85909 8.96673 5.2272 8.53087C5.59532 8.09501 5.77938 7.55123 5.77938 6.89952C5.77938 6.23017 5.59532 5.67912 5.2272 5.24742C4.85909 4.81571 4.37243 4.59986 3.76514 4.59986C3.1485 4.59986 2.65872 4.81571 2.2958 5.24742C1.93289 5.67809 1.75091 6.22809 1.75091 6.89848Z"
    fill="currentColor"
  />
  <path d="M11.3521 6.12695V7.65557H8.50391V6.12695H11.3521Z" fill="currentColor" />
  <path
    d="M12.4844 6.89851C12.4844 5.84312 12.8203 4.9548 13.492 4.2346C14.1627 3.5144 15.0185 3.1543 16.0574 3.1543C17.0411 3.1543 17.8252 3.43242 18.4096 3.98761C18.994 4.54281 19.3403 5.18311 19.4515 5.9085H17.769C17.6681 5.51415 17.4633 5.19764 17.1555 4.95895C16.8477 4.72027 16.4775 4.60093 16.0449 4.60093C15.4657 4.60093 15.0009 4.81471 14.6515 5.24122C14.3021 5.66774 14.1274 6.22086 14.1274 6.89955C14.1274 7.57825 14.3021 8.12929 14.6515 8.55166C15.0009 8.97403 15.4657 9.18469 16.0449 9.18469C16.4775 9.18469 16.8466 9.06535 17.1555 8.82666C17.4633 8.58798 17.6681 8.27147 17.769 7.87712H19.4515C19.3413 8.60251 18.994 9.2428 18.4096 9.798C17.8252 10.3532 17.0411 10.6313 16.0574 10.6313C15.0185 10.6313 14.1627 10.2712 13.492 9.55102C12.8192 8.8277 12.4844 7.94457 12.4844 6.89851Z"
    fill="currentColor"
  />
  <path
    d="M25.4643 7.43605V3.32031H27.1052V10.4653H25.588V9.54269C25.4227 9.85506 25.1534 10.1135 24.7811 10.321C24.4088 10.5275 23.9513 10.6303 23.4085 10.6303C22.562 10.6303 21.9121 10.376 21.4566 9.86647C21.0012 9.35693 20.7734 8.67513 20.7734 7.82209V3.32031H22.4154V7.60209C22.4154 8.6855 22.8802 9.22618 23.8088 9.22618C24.3236 9.22618 24.7281 9.07259 25.0224 8.76438C25.3166 8.4572 25.4643 8.01408 25.4643 7.43605Z"
    fill="currentColor"
  />
  <path
    d="M30.7318 4.25532C31.2195 3.52163 31.9828 3.15426 33.0216 3.15426C34.0511 3.15426 34.8632 3.51229 35.4559 4.22834C36.0487 4.94439 36.3461 5.83478 36.3461 6.89952C36.3461 7.96426 36.0497 8.85257 35.4559 9.56343C34.8632 10.2743 34.0511 10.6302 33.0216 10.6302C31.9089 10.6302 31.1041 10.2359 30.607 9.44617V10.4652H29.0898V0.140625H30.7318V4.25532ZM30.6767 6.89848C30.6767 7.55953 30.8576 8.10539 31.2216 8.5371C31.5845 8.9688 32.0743 9.18466 32.6909 9.18466C33.2982 9.18466 33.7849 8.96673 34.153 8.53087C34.5211 8.09501 34.7052 7.55123 34.7052 6.89952C34.7052 6.23017 34.5211 5.67912 34.153 5.24742C33.7849 4.81571 33.2982 4.59986 32.6909 4.59986C32.0743 4.59986 31.5845 4.81571 31.2216 5.24742C30.8576 5.67809 30.6767 6.22809 30.6767 6.89848Z"
    fill="currentColor"
  />
  <path
    d="M44.4602 7.36758H39.0113C39.0758 7.93627 39.2827 8.38873 39.6321 8.72393C39.9815 9.05912 40.4411 9.2262 41.011 9.2262C41.3884 9.2262 41.7285 9.13488 42.0321 8.9512C42.3358 8.76751 42.552 8.51949 42.681 8.20816H44.3365C44.116 8.93355 43.7042 9.51781 43.1021 9.96301C42.5001 10.4082 41.7898 10.6303 40.9704 10.6303C39.9035 10.6303 39.0414 10.2774 38.3842 9.5697C37.726 8.86299 37.3984 7.96844 37.3984 6.88503C37.3984 5.82963 37.7291 4.94442 38.3915 4.22837C39.0539 3.51232 39.9087 3.1543 40.9569 3.1543C42.0051 3.1543 42.8536 3.50506 43.5025 4.20762C44.1514 4.90914 44.4748 5.79331 44.4748 6.85701L44.4602 7.36758ZM40.9569 4.48989C40.4422 4.48989 40.0148 4.6414 39.6737 4.94442C39.3336 5.24745 39.1215 5.65114 39.0394 6.15548H42.8463C42.7725 5.65114 42.5708 5.24641 42.239 4.94442C41.9084 4.6414 41.481 4.48989 40.9569 4.48989Z"
    fill="currentColor"
  />
  <path
    d="M47.4943 10.2645C47.2832 10.471 47.0118 10.5737 46.6801 10.5737C46.3483 10.5737 46.0748 10.4699 45.8596 10.2645C45.6433 10.0579 45.5352 9.78916 45.5352 9.45916C45.5352 9.13849 45.6433 8.86971 45.8596 8.65386C46.0759 8.438 46.3494 8.33008 46.6801 8.33008C47.0024 8.33008 47.2707 8.438 47.487 8.65386C47.7033 8.86971 47.8114 9.13849 47.8114 9.45916C47.8125 9.7902 47.7064 10.0579 47.4943 10.2645Z"
    fill="currentColor"
  />
  <path
    d="M51.4673 10.6302C50.6208 10.6302 49.9917 10.433 49.5778 10.0386C49.164 9.64429 48.957 9.12126 48.957 8.46955C48.9581 7.22943 49.8742 6.4781 51.7065 6.21659L53.7706 5.9229V5.8129C53.7706 4.94119 53.26 4.50533 52.2399 4.50533C51.3477 4.50533 50.8423 4.85921 50.7227 5.56592H49.1224C49.1962 4.86855 49.4884 4.29259 49.9979 3.83805C50.5085 3.38352 51.2697 3.15625 52.2815 3.15625C53.3016 3.15625 54.0722 3.39078 54.5921 3.85881C55.111 4.32684 55.372 4.9827 55.372 5.82743V9.11815C55.372 9.69618 55.4406 10.1455 55.5789 10.4672H54.0202C54.0108 10.4672 53.9994 10.433 53.9859 10.3635C53.9723 10.295 53.9578 10.1818 53.9443 10.0262C53.9307 9.87052 53.9235 9.70448 53.9235 9.53014C53.4358 10.2628 52.6174 10.6302 51.4673 10.6302ZM53.7716 7.11736L51.8708 7.41104C50.9775 7.54803 50.5304 7.88426 50.5304 8.42182C50.5304 9.04032 50.9536 9.3506 51.799 9.3506C52.4333 9.3506 52.921 9.18145 53.2611 8.84418C53.6011 8.50691 53.7716 8.08143 53.7716 7.57086V7.11736Z"
    fill="currentColor"
  />
  <path
    d="M58.144 2.1211C57.8404 2.1211 57.5919 2.02666 57.3995 1.83883C57.2061 1.65099 57.1094 1.41024 57.1094 1.11655C57.1094 0.813527 57.2061 0.565503 57.3995 0.373519C57.5929 0.180496 57.8404 0.0839844 58.144 0.0839844C58.4383 0.0839844 58.6817 0.180496 58.8751 0.373519C59.0685 0.566541 59.1652 0.813527 59.1652 1.11655C59.1652 1.41024 59.0685 1.65099 58.8751 1.83883C58.6817 2.0277 58.4383 2.1211 58.144 2.1211ZM57.3163 10.4636V3.31866H58.9583V10.4636H57.3163Z"
    fill="currentColor"
  />
</svg>
