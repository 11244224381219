<div class="relative flex items-center h-full px-4" (clickOutside)="closeMenu()">
	<button class="flex items-center gap-x-2 text-left w-full" (click)="toggleMenu()">
		<span class="relative flex items-center h-6 w-6">
			<img src="/assets/img/flags/united-states-of-america.svg" class="w-full h-full object-cover rounded-full"
				alt="Flag" />
		</span>
		<span class="block font-sans text-sm font-medium text-muted-500 dark:text-muted-100">En</span>
		<div class="ml-auto">
			<app-icon name="chevron-down" class="w-3 h-3 text-muted-400 transition-transform duration-300"
				[svgClass]="isMenuOpen ? 'rotate-180' : ''">
			</app-icon>
		</div>
	</button>

	<div [@openClose]="isMenuOpen ? 'open' : 'closed'" class="absolute -top-[190px] left-0 w-full h-[190px] py-4 px-1"
		[class]="!isMenuOpen ? 'pointer-events-none' : ''">
		<div
			class="w-full h-full bg-white border border-muted-200 dark:bg-muted-800 dark:border-muted-600 rounded-md overflow-y-auto slimscroll">
			<ul class="p-2 space-y-1">
				<li>
					<a
						class="flex items-center gap-x-2 text-left w-full p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
						<span class="relative flex items-center h-4 w-4">
							<img src="/assets/img/flags/united-states-of-america.svg"
								class="w-full h-full object-cover rounded-full" alt="Flag" />
						</span>
						<span
							class="block font-sans text-xs font-medium text-muted-500 dark:text-muted-100">English</span>
					</a>
				</li>
				<li>
					<a
						class="flex items-center gap-x-2 text-left w-full p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
						<span class="relative flex items-center h-4 w-4">
							<img src="/assets/img/flags/france.svg" class="w-full h-full object-cover rounded-full"
								alt="Flag" />
						</span>
						<span
							class="block font-sans text-xs font-medium text-muted-500 dark:text-muted-100">French</span>
					</a>
				</li>
				<li>
					<a
						class="flex items-center gap-x-2 text-left w-full p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
						<span class="relative flex items-center h-4 w-4">
							<img src="/assets/img/flags/spain.svg" class="w-full h-full object-cover rounded-full"
								alt="Flag" />
						</span>
						<span
							class="block font-sans text-xs font-medium text-muted-500 dark:text-muted-100">Spanish</span>
					</a>
				</li>
				<li>
					<a
						class="flex items-center gap-x-2 text-left w-full p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer">
						<span class="relative flex items-center h-4 w-4">
							<img src="/assets/img/flags/china.svg" class="w-full h-full object-cover rounded-full"
								alt="Flag" />
						</span>
						<span
							class="block font-sans text-xs font-medium text-muted-500 dark:text-muted-100">Chinese</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>