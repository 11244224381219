import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ExchangeName } from "@b-cube/database/types";
import { ExchangeCredentials } from '@b-cube/interfaces/exchange'
import { RestURLBuilder } from "rest-url-builder";
import { Observable } from "rxjs";

import { EXCHANGE_SETTINGS_URL } from "../constants/api-urls.constants";

@Injectable({
	providedIn: 'root'
})
export class ExchangeService {
	constructor(private http: HttpClient) { }

	getExchangeCredentials(exchange: string): Observable<ExchangeCredentials[]> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(EXCHANGE_SETTINGS_URL);
		urlBuilder.setQueryParameter('exchange', exchange);
		const url = urlBuilder.get();

		return this.http.get<ExchangeCredentials[]>(url);
	}

	getExchangesLabels(): { [key in ExchangeName]?: string } {
		const labels = {
			[ExchangeName.BINANCE_SPOT]: 'Binance Spot',
			[ExchangeName.BINANCE_FUTURES]: 'Binance Futures',
			[ExchangeName.BITGET_FUTURES]: 'Bitget Futures',
			[ExchangeName.BYBIT_SPOT]: 'Bybit Spot',
			[ExchangeName.BYBIT_FUTURES]: 'Bybit Futures',
			//[ExchangeName.COINBASE_SPOT]: 'Coinbase Spot',
			[ExchangeName.KRAKEN_SPOT]: 'Kraken Spot',
			[ExchangeName.KUCOIN_SPOT]: 'Kucoin Spot',
			[ExchangeName.KUCOIN_FUTURES]: 'Kucoin Futures',
			//[Exchange.BITMEX_FUTURES]: 'Bitmex Futures',
			//[ExchangeName.HUOBI_SPOT]: 'HTX/Huobi Spot',
			//[ExchangeName.HUOBI_FUTURES]: 'HTX/Huobi Futures'

		}

		return labels;
	}

}
