import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BUY_BCUBE_URL } from '@app/core/constants/staking.constants';
import { NotificationService } from '@app/core/services/notification.service';
import { IUserNotification } from '@b-cube/interfaces/notification';
import { UserInfo } from '@b-cube/interfaces/user';
import { Observable } from 'rxjs';

import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

	user$: Observable<UserInfo>;
	initials$: Observable<string>;
	notifications$: Observable<IUserNotification[]>;

	@Input() isFolded = false;
	// eslint-disable-next-line
	@Output() close = new EventEmitter<any>();
	// eslint-disable-next-line
	@Output() open = new EventEmitter<any>();
	@Output() openNotifications = new EventEmitter<any>();

	public BUY_BCUBE_URL = BUY_BCUBE_URL;

	constructor(
		private userService: UserService,
		private notificationService: NotificationService
	) { }

	ngOnInit(): void {
		this.user$ = this.userService.currentUser;
		this.initials$ = this.userService.getCurrentUserInitials();
		this.notifications$ = this.notificationService.getNotifications();
	}

	toggle() {
		this.isFolded = true;
		this.open.emit(null);
	}

	openPanel(e: Event) {
		e.stopPropagation();
		this.openNotifications.emit(null);

		return false;
	}
}
