import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-arrow-down',
  templateUrl: './icon-arrow-down.component.html'
})
export class IconArrowDownComponent {
  @Input() class = ''

}
