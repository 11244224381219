import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-alert',
  templateUrl: './icon-alert.component.html'
})
export class IconAlertComponent {
  @Input() class = ''

}
