export interface Staking {
	userId: number
	ethereumWalletAddress: string
}

export enum TierLevel {
	TIER_0 = 0,
	TIER_1 = 1,
	TIER_2 = 2,
	TIER_3 = 3,
	TIER_4 = 4,
	TIER_5 = 5
}

export interface Tier {
	id: string;
	name: string;
	tier: TierLevel;
	minStakedTokens: number;
	planDiscountForCC: number;
	planDiscountForBCUBE: number;
	botDiscountForCC: number;
	botDiscountForBCUBE: number;
}

export enum TierName {
	EARTH = 'earth',
	MERCURY = 'mercury',
	MARS = 'mars',
	VENUS = 'venus',
	SATURN = 'saturn',
	JUPITER = 'jupiter',
}
