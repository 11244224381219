<label for="switch-thin-1" class="relative flex items-center cursor-pointer">
  <span class="block relative h-4">
    <input
      id="switch-thin-1"
      type="checkbox"
      class="peer cursor-pointer opacity-0 absolute h-full w-full z-20"
      [checked]="checked"
    />
    <span
      class="absolute flex items-center justify-center w-6 h-6 border bg-white dark:bg-muted-700 dark:border-muted-600 rounded-full shadow -left-1 top-1/2 -translate-y-1/2 transition peer-checked:-translate-y-1/2 peer-checked:translate-x-full"
    ></span>
    <span
      class="block w-10 h-4 bg-muted-300 dark:bg-muted-600 rounded-full shadow-inner peer-checked:bg-brand peer-focus:ring-0 outline-1 outline-transparent peer-focus:outline-dashed peer-focus:outline-gray-300 dark:peer-focus:outline-gray-600 peer-focus:outline-offset-2 transition-all duration-300"
    ></span>
  </span>
  <span
    *ngIf="label !== undefined || ''"
    class="relative cursor-pointer select-none ml-2 text-sm text-muted-400 font-alt"
    >Switch label</span
  >
</label>
