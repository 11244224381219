<div class="mt-8 md:mt-0">
  <div class="flex gap-x-6 font-sans">
    <a
	id="COMPANY"
	  (click)="onSourceTabClick($event)"
      class="pb-3 select-none border-b-2 cursor-pointer transition-colors duration-300"
      [class]="
	  	!toggled
          ? 'text-brand border-brand'
          : 'text-muted-300 hover:text-muted-400 dark:text-muted-500 dark:hover:text-muted-400 border-transparent'
      "
      >B-cube agents</a
    >
    <a
	id="THIRD_PARTY"
	(click)="onSourceTabClick($event)"
      class="pb-3 select-none border-b-2 cursor-pointer transition-colors duration-300"
      [class]="
        toggled
          ? 'text-brand border-brand'
          : 'text-muted-300 hover:text-muted-400 dark:text-muted-500 dark:hover:text-muted-400 border-transparent'
      "
      >3rd party agents</a
    >
  </div>

  <div class="mt-6">
	<div>
		<ng-content select="[common]"></ng-content>
	  </div>
	  <div [hidden]="!toggled">
		<ng-content select="[bcube]"></ng-content>
	  </div>
	  <div [hidden]="toggled">
		<ng-content select="[external]"></ng-content>
	  </div>
  </div>
</div>

