<svg
  width="15"
  height="13"
  viewBox="0 0 15 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.33602 2.14514L12.607 11.3718H2.06502L7.33602 2.14514V2.14514ZM0.854022 10.6626C0.315022 11.6059 0.987022 12.7902 2.06502 12.7902H12.607C13.685 12.7902 14.357 11.6059 13.818 10.6626L8.54702 1.43594C8.00802 0.492706 6.66402 0.492706 6.12502 1.43594L0.854022 10.6626ZM6.63602 5.69824V7.11664C6.63602 7.5067 6.95102 7.82584 7.33602 7.82584C7.72102 7.82584 8.03602 7.5067 8.03602 7.11664V5.69824C8.03602 5.30818 7.72102 4.98904 7.33602 4.98904C6.95102 4.98904 6.63602 5.30818 6.63602 5.69824ZM6.63602 9.24424H8.03602V10.6626H6.63602V9.24424Z"
    fill="currentColor"
  />
</svg>
