<div class="flex flex-col">
	<div class="overflow-x-auto rounded border border-muted-200 dark:border-muted-700">
		<div class="inline-block min-w-full align-middle">
			<div class="overflow-hidden">
				<table class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-muted-700 min-h-[444px]">
					<!--Head-->
					<thead class="bg-muted-50 dark:bg-muted-800">
						<!-- Row -->
						<tr class="h-24">
							<!--Header-->
							<th scope="col"
								class="font-sans p-6 text-sm font-semibold tracking-wider text-left text-muted-700 dark:text-muted-300">
								Accounts plugged (USDT)
							</th>
							<!--Header-->
							<th scope="col"
								class="font-sans p-6 text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300">
								Total balance
							</th>
							<!--Header-->
							<th scope="col"
								class="font-sans p-6 text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300">
								Available for trades
							</th>
							<!--Header-->
							<th scope="col"
								class="font-sans p-6 text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300">
								% allocated
							</th>
							<!--Header-->
							<th scope="col"
								class="font-sans p-6 text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300">
								Action
							</th>
						</tr>
					</thead>
					<tbody *ngIf="!overviewAccountBalance">
						<tr class="hover:bg-muted-50 dark:hover:bg-muted-900 transition-colors duration-300">
							<!--Cell-->
							<td colspan="5"
								class="content-center font-sans py-4 px-6 text-sm font-semibold text-muted-600 whitespace-nowrap dark:text-white">
								<div class="flex items-center gap-x-4 flex-col">
									<div class="flex items-center justify-center">
										<span class="brand-circle-loader"></span>
									</div>
								</div>
							</td>
					</tbody>
					<!--Body-->
					<tbody *ngIf="overviewAccountBalance && accountsNumber === 0">
						<tr class="hover:bg-muted-50 dark:hover:bg-muted-900 transition-colors duration-300">
							<!--Cell-->
							<td colspan="5"
								class="content-center font-sans py-4 px-6 text-sm font-semibold text-muted-600 whitespace-nowrap dark:text-white">
								<div class="flex items-center gap-x-4 flex-col">
									<div class="inline-flex justify-center items-center relative h-8">
										No exchange account connected
									</div>
									<div class="w-60">
										<button (click)="addAccount()"
											class="flex items-center gap-x-4 text-left w-full py-2 px-4 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/40 cursor-pointer ng-tns-c96-4">
											<app-icon name="add" class="h-7 w-7 text-brand"></app-icon>
											<span class="block font-sans text-sm font-semibold text-brand">
												Add Exchange Account
											</span>
										</button>
									</div>
								</div>
							</td>
					</tbody>
					<tbody *ngIf="accountsNumber > 0"
						class="bg-white divide-y divide-gray-200 dark:bg-muted-800 dark:divide-gray-700">
						<!--Row-->
						<tr *ngFor="let accountBalance of overviewAccountBalance"
							class="h-24 hover:bg-muted-50 dark:hover:bg-muted-900 transition-colors duration-300">
							<!--Cell-->
							<td
								class="font-sans py-4 px-6 text-sm font-semibold text-muted-600 whitespace-nowrap dark:text-white">
								<div class="flex items-center gap-x-4">
									<div
										class="inline-flex justify-center items-center relative w-4 h-4 rounded-full bg-green-500">
									</div>
									<div class="flex items-center justify-center ">
										<span class=" relative flex justify-center items-center h-6 w-6 rounded-full">
											<app-exchange-icon [name]="accountBalance.exchange" addon="circle"
												[svgClass]="'w-6 h-6'"></app-exchange-icon>
										</span>
									</div>
									<span>{{accountBalance.name}}</span>
								</div>
							</td>
							<!--Cell-->
							<td
								class="font-sans py-4 px-6 text-sm text-muted-500 whitespace-nowrap dark:text-muted-400">
								<div class="flex items-center">
									<span>{{accountBalance.totalBalance | cryptoCurrency:'USDT'}} USDT</span>
								</div>
							</td>
							<!--Cell-->
							<td
								class="font-sans py-4 px-6 text-sm text-muted-500 whitespace-nowrap dark:text-muted-400">
								<div class="space-y-2">
									<span *ngFor="let balance of accountBalance.pairingsAllocation" class="block">
										{{balance.amountAvailable | cryptoCurrency:balance.pairing }}
										{{balance.pairing}}
									</span>
									<span *ngIf="accountBalance.pairingsAllocation.length === 0" class="block">
										N/A
									</span>
								</div>
							</td>
							<!--Cell-->
							<td
								class="font-sans py-4 px-6 text-sm text-muted-500 whitespace-nowrap dark:text-muted-400">
								<div class="space-y-2">
									<span *ngFor="let balance of accountBalance.pairingsAllocation" class="block">
										{{balance.allocatedValue}}%
									</span>
									<span *ngIf="accountBalance.pairingsAllocation.length === 0" class="block">
										N/A
									</span>
								</div>
							</td>
							<!--Cell-->
							<td class="py-4 px-6 text-sm font-semibold text-right whitespace-nowrap">
								<a
									class="flex items-center justify-center w-10 h-10 rounded-full text-muted-400 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 transition-colors duration-300 cursor-pointer">
									<app-icon-eye (click)="goToAccount(accountBalance.id)" class="w-5 h-5">
									</app-icon-eye>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
