import { Component, Input } from '@angular/core';
import { IBot } from '@b-cube/interfaces/bot';

@Component({
	selector: 'app-widget-bot-name',
	templateUrl: './widget-bot-name.component.html'
})
export class WidgetBotNameComponent {
	@Input() bot: IBot;

	
}
