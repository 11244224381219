<div class="flex justify-between gap-x-4 py-3">
	<button (click)="updateStatus(botId)"
	[ngClass]="[isLoading ? 'button-loading' : '']"
	[class]="
		isEnabled
		? 'text-amber-500  hover:border-amber-500'
		: 'text-green-500  hover:border-green-500'"
		class="flex-1 flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-white dark:bg-muted-800  border-2 border-transparent transition-all duration-300">
		<div class="flex items-center justify-center gap-x-2" *ngIf="!isLoading && isEnabled; else play">
			<span class="font-sans text-sm">Pause</span>
			<app-icon-pause class="w-3 h-3"></app-icon-pause>
		</div>
		<ng-template #play>
			<span class="font-sans text-sm">Play</span>
			<app-icon-play></app-icon-play>
		</ng-template>
	</button>
	<button
		[ngClass]="[isLoading ? 'button-loading' : '']"
		(click)="removeBot(botId)"
		class="flex-1 flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-white dark:bg-muted-800 text-rose-500 border-2 border-transparent hover:border-rose-500 transition-all duration-300">
		<span class="font-sans text-sm">Disconnect</span>
		<app-icon-x class="w-3 h-3"></app-icon-x>
	</button>
</div>
