import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-ftx',
  templateUrl: './icon-ftx.component.html'
})
export class IconFtxComponent {
  @Input() class = ''

}
