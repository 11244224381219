export const PLAN_TIPS_TEXT = `Discover the benefits of staking $BCUBE tokens and unlock exclusive discounts on our plans! Seize the opportunity to save while enjoying our premium features. Conveniently pay for any of our plans with a credit card or $BCUBE. Explore our plans now to find the perfect fit for your needs!`;
export const STAKING_TIPS_TEXT = `Ready to stake $BCUBE tokens? Easily acquire them on Uniswap by clicking the "Buy" button in the top left corner of our screen. This will take you directly to the Uniswap exchange, where you can purchase $BCUBE using ETH. Don't miss out on the chance to invest in $BCUBE - click "Buy" now to get started!`;
export const PLAN_STAKING_HELP_TEXT = `Need assistance with our product? Our dedicated support team is here to help! Connect with our knowledgeable support agents via LiveChat or explore our comprehensive documentation for detailed guides and helpful tips on maximizing our product. Don't hesitate to reach out - we're always here to assist you!`;
export const COUNTRIES_RESTRICTION_TEXT = `Due to AMF regulations, French citizens & residents are currently unable to subscribe to our services. We are in the process of DASP registration, and once successful, all our services will be available for you very soon!`;
export const MFA_RESTRICTION_TEXT = `Activate two-factor authentication to access this service and enhance your security`;
export const KYC_RESTRICTION_TEXT = `Verify your identity to gain access to this service`;
export const KYC_MFA_RESTRICTION_TEXT = `For added security, activate two-factor authentication and complete identity verification to access this service`;
export const COUNTRIES_RESTRICTION_TYPE_TEXT = `for French residents`;
export const MFA_RESTRICTION_TYPE_TEXT = `Two-Factor Authentication Required`;
export const KYC_RESTRICTION_TYPE_TEXT = `Identity Verification Required`;
export const KYC_MFA_RESTRICTION_TYPE_TEXT = `Additional Security Measures Required`;

export const MAXDROWDOWN_TOOLTIP = `Drawdown describes the peak-to-trough decline of an investment's value over a specific time period. It is the difference between the highest value of an investment and its subsequent lowest value before it recovers.`;
export const PROFIT_FACTOR_TOOLTIP = `Profit Factor is the sum of the winnings divided by the sum of the losses.`;
export const SIGNAL_HISTORY_TOOLTIP = `All the performances in the Signal History are computed without taking into account the exchange fees.`;
export const META_BOT_ALLOCATION_HISTORY_TOOLTIP = `AI Crypto Agents consistently rebalance the allocation percentage for each signal. Discover the chart below to trace the evolution of this allocation over the signal’s duration.`;

export const COPY_TO_CLIPBOARD = `Copy to Clipboard`;
export const COPIED_TO_CLIPBOARD = `Copied`;
export const TOOLTIP_INFO_TXT = `B-cube recommends keeping the wallet deposits above 1000$ for better performance.`;
