import { Component, OnInit } from '@angular/core';
import { ExchangeAccountService } from '@app/core/services/exchange-account.service';
import { StakingService } from '@app/core/services/staking.service';
import { UserBotService } from '@app/core/services/user-bot.service';
import { UNLIMITED_TRADING_LIMIT } from '@b-cube/interfaces/plan';
import { UserInfo } from '@b-cube/interfaces/user';
import { UserService } from '@core/services/user.service';
import { BigNumber } from 'bignumber.js';
import { combineLatest, map, Observable } from 'rxjs';

enum INVESTMENT_THRESHOLD {
	warning = 95,
	error = 105
}

@Component({
	selector: 'app-widget-profile',
	templateUrl: './widget-profile.component.html'
})
export class WidgetProfileComponent implements OnInit {
	user$: Observable<UserInfo>;
	initials$: Observable<string>;
	stakedAmount$: Observable<string>;
	unlimitedInvestment$: Observable<boolean>;
	isInitialized$: Observable<boolean>;
	currentInvestment$: Observable<number>

	constructor(
		private userService: UserService,
		private userBotService: UserBotService,
		private stakingService: StakingService,
		private exchangeAccountService: ExchangeAccountService,
	) { }

	ngOnInit(): void {
		this.user$ = this.userService.currentUser;
		this.initials$ = this.userService.getCurrentUserInitials();
		this.stakedAmount$ = this.stakingService.getStakedAmount().pipe(
			map(amount => new BigNumber(amount).toFixed(4, BigNumber.ROUND_DOWN))
		);
		this.unlimitedInvestment$ = this.user$.pipe(
			map(user => user?.plan?.tradingLimit === UNLIMITED_TRADING_LIMIT)
		);
		this.isInitialized$ = this.user$.pipe(
			map(user => user !== null)
		);
		this.currentInvestment$ = this.exchangeAccountService.totalInvestment;
	}

	public getUserProgress(): Observable<any> {
		return this.userService.getUserPlanProgress();
	}

	public getCurrentNumberOfBots(): Observable<number> {
		return this.userBotService.getUserBots.pipe(
			map(userBots => userBots?.length)
		);
	}

	public getInvestmentProportion(): Observable<number> {
		return combineLatest([this.currentInvestment$, this.user$]).pipe(
			map(([current, user]) => user === null ? 0 : Math.round(100 * current / user.plan.tradingLimit))
		);
	}
	public getInvestmentProgressColor(): Observable<string> {
		return this.getInvestmentProportion().pipe(
			map(proportion => {
				if (proportion < INVESTMENT_THRESHOLD.warning) {
					return 'text-secondary-500';
				}
				if (proportion < INVESTMENT_THRESHOLD.error) {
					return 'text-orange-400';
				}

				return 'text-red-700';
			})
		);
	}
}
