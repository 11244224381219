import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from '@app/core/constants/router.constants';
import { DialogService } from '@app/core/services/dialog.service';
import {  SecurityService } from '@app/core/services/security.service';
import { UserService } from '@app/core/services/user.service';
import { RestrictedServices, UserInfo } from '@b-cube/interfaces/user';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';


enum Illustrations {
	ALLOCATION= 'assets-allocation',
	BALANCE= 'balance-history'
}

@Component({
	selector: 'app-widget-portfolio-welcome',
	templateUrl: './widget-portfolio-welcome.component.html',
})
export class WidgetPortfolioWelcomeComponent implements OnInit, OnDestroy {
	Illustrations = Illustrations;

	public userSubscription = new Subscription();

	public user: UserInfo;

	constructor(
		private router: Router,
		private userService: UserService,
		public securityService: SecurityService,
		private toastrService: ToastrService,
		private dialogService: DialogService
	) {}

	ngOnInit(): void {
		this.userSubscription = this.userService.currentUser.subscribe(
			user => {
				this.user = user;
			}
		);
	}

	ngOnDestroy(): void {
		this.userSubscription.unsubscribe();
	}

	private isDarkMode(): boolean{
		return document.documentElement.classList.contains('dark')
	}

	public getIllustrationPath(illustration: Illustrations){
		return `/assets/img/illustrations/${String(illustration)}-${this.isDarkMode() ? 'light' : 'dark'}.png`;
	}

	public async createExchangeAccount(): Promise<void>{
		if(! this.securityService.canUseServices(this.user, RestrictedServices.EXCHANGE_ACCOUNT)){
			this.toastrService.warning(this.securityService.getRestrictionDescriptionLabel(this.user));

			return;
		}

		const exchangeAccount = await this.dialogService.showAddExchangeAccountModal();

		if(! exchangeAccount){
			return;
		}

		this.router.navigate([Routes.PORTFOLIO + '/', exchangeAccount.id]);
	}

	public goToMarketplace(): void{
		this.router.navigate([Routes.MARKETPLACE]);
	}
}
