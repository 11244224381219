import { Component, Input } from '@angular/core';
import { TierDifference } from '@app/shared/types/tier-difference';
import { Tier } from '@b-cube/interfaces/staking';
import { BigNumber } from 'bignumber.js';


@Component({
	selector: 'app-widget-wizard-next-tier',
	templateUrl: './widget-wizard-next-tier.component.html'
})
export class WidgetWizardNextTierComponent {
	@Input() nextTier: Tier;
	@Input() tierDifference: TierDifference;
	@Input() availableTokens = '-1';

	public displaySign(pourcent: number): string {
		return pourcent >= 0 ? '+' : '';
	}

	public areTokensDifferent(): boolean {
		return (this.tierDifference?.stakedTokens ?? 0) !== 0;
	}

	public getTokensDifferenceLabel(): string {
		let label = '';

		if (this.tierDifference?.stakedTokens > 0) {
			label += '+';
		}

		label += String((this.tierDifference?.stakedTokens ?? 0) / 1000) + 'K';

		return label;
	}

	public isAvailableTokensGteZero(): boolean {
		try {
			const availableTokensDecimal = new BigNumber(this.availableTokens);

			return availableTokensDecimal.gte(0);
		} catch (error) {
			return false;
		}
	}
}
