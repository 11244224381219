<svg
  width="17"
  height="16"
  viewBox="0 0 17 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.164062 4H4.16406V0H0.164062V4ZM6.16406 16H10.1641V12H6.16406V16ZM0.164062 16H4.16406V12H0.164062V16ZM0.164062 10H4.16406V6H0.164062V10ZM6.16406 10H10.1641V6H6.16406V10ZM12.1641 0V4H16.1641V0H12.1641ZM6.16406 4H10.1641V0H6.16406V4ZM12.1641 10H16.1641V6H12.1641V10ZM12.1641 16H16.1641V12H12.1641V16Z"
    fill="currentColor"
  />
</svg>
