// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiUrl: '/api',
	stakingContract: '0x1BD6B045Ae822093c9CCfAb4F786c419CcD165b7',
	tokenContract: '0xDaE0524F938ad5f83bE3e91cF55c935f56Ac556A',
	paymentWallet: '0x33424aFc2B42D3Bd279d2914894ED47349451a82',
	chainId: 5,
	networkName: 'goerli',
	enablesTestnets: true,
	chargebeeSite: 'b-cube-test',
	matomoUrl: 'https://bcubeai.matomo.cloud/',
	matomoSiteId: 4,
	matomoCDN: 'https://cdn.matomo.cloud/bcubeai.matomo.cloud',
	matomoContainer: 'mnD1X3as',
	landingPageUrl: '//b-cube.ai',
};

/*
 * matomoContainer: 'mnD1X3as' for DASHBOARD DEV
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
