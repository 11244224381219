import { PAYMENT_TYPE_TOPUP } from "@b-cube/interfaces/payment";
import { PaymentMethod } from "@b-cube/interfaces/user";

export const DEFAULT_PAYMENT_METHOD = 'DEFAULT';

export enum BotSubscriptionWizardButtonLabels {
	CANCEL = 'Cancel',
	GO_BACK = 'Go Back',
	CONNECT = 'Connect',
	CONTINUE = 'Continue',
	PAY = 'Pay',
	TOPUP = 'Top Up',
}

export enum BotSubscriptionStep {
	EXCHANGE_SELECTOR = 0,
	BOT_PAYMENT_DETAILS = 1
}

export enum ModalDescription {
	TOPUP = `You are about to topup {botname} subscription`,
	PAY = `You are about to connect {botname}`
}

export const PAYMENT_TEMPLATES: { [key: string]: { title: string, description: string, currentStep?: number, isSubmitDisabled?: boolean } } = {
	[PaymentMethod.CREDIT_CARD]: {
		title: BotSubscriptionWizardButtonLabels.CONNECT,
		description: ModalDescription.PAY,
		currentStep: BotSubscriptionStep.EXCHANGE_SELECTOR,
		isSubmitDisabled: true
	},
	[PAYMENT_TYPE_TOPUP.TOPUP]: {
		title: BotSubscriptionWizardButtonLabels.TOPUP,
		description: ModalDescription.TOPUP,
		currentStep: BotSubscriptionStep.BOT_PAYMENT_DETAILS,
		isSubmitDisabled: false
	},
	[PaymentMethod.BCUBE]: {
		title: BotSubscriptionWizardButtonLabels.CONNECT,
		description: ModalDescription.PAY,
		currentStep: BotSubscriptionStep.EXCHANGE_SELECTOR,
		isSubmitDisabled: true

	},
	[DEFAULT_PAYMENT_METHOD]: {
		title: BotSubscriptionWizardButtonLabels.CONNECT,
		description: ModalDescription.PAY,
		currentStep: BotSubscriptionStep.EXCHANGE_SELECTOR,
		isSubmitDisabled: true
	}
};
