<svg
  width="12"
  height="12"
  viewBox="0 0 12 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.0001 3.67994C9.98881 1.06831 7.48876 0.584473 7.48876 0.584473H0.356064L0.383584 3.24463H1.80406V9.18479H0.355957V11.8402H7.29132C8.96278 11.8402 10.3026 10.1605 10.3026 10.1605C12.4482 7.11525 11 3.67994 11 3.67994H11.0001ZM7.32854 8.37082C7.32854 8.37082 6.78454 9.15567 6.19265 9.15567H5.00865L4.9806 3.25114H6.50006C6.50006 3.25114 7.20406 3.39834 7.6959 4.79151C7.6959 4.79151 8.34796 6.78618 7.32854 8.37082Z"
    fill="currentColor"
  />
</svg>
