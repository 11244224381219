<svg
  width="13"
  height="13"
  viewBox="0 0 13 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.33789 2.81259L4.00739 5.14308L4.00744 5.14299L2.65149 3.78699L6.33789 0.100586L10.0255 3.78809L8.66949 5.14409L6.33789 2.81259ZM1.69394 4.74435L0.337891 6.10059L1.69384 7.45635L3.04989 6.1003L1.69394 4.74435ZM4.00739 7.05823L6.33789 9.38859L8.66944 7.05713L10.0262 8.41241L10.0255 8.41313L6.33789 12.1006L2.65149 8.41419L2.64957 8.41227L4.00739 7.05823ZM10.9819 4.74511L9.62589 6.10116L10.9819 7.45711L12.3379 6.10107L10.9819 4.74511Z"
    fill="currentColor"
  />
  <path
    d="M7.71317 6.0996H7.71374L6.33787 4.72363L5.32089 5.74032L5.20406 5.8572L4.9631 6.09821L4.96118 6.10008L4.9631 6.10205L6.33787 7.47701L7.71384 6.10104L7.71451 6.10027L7.71326 6.0996"
    fill="currentColor"
  />
</svg>
