import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FAKE_CARD_PERFORMANCE, PERFORMANCE_HISTORY_PERIODS } from '@app/core/constants/charts.constants';
import { FormatedBot } from '@app/core/constants/marketplace.constants';
import { PerformanceService } from '@app/core/services/performance/bot-performance.service';
import { FormatNumberHelper } from '@app/core/services/performance/format-number.helper';
import { BotRiskLabel } from '@b-cube/interfaces/bot';
import { PERFORMANCE_HISTORY_FREQUENCY, PERFORMANCE_HISTORY_RANGE } from '@b-cube/interfaces/performance';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-widget-marketplace-bot-card',
	templateUrl: './widget-marketplace-bot-card.component.html',
})
export class WidgetMarketplaceBotCardComponent implements OnChanges, OnDestroy {
	@Input() bot: FormatedBot;
	@Input() chartPeriod: PERFORMANCE_HISTORY_RANGE;
	@Output() performanceLoaded = new EventEmitter<boolean>();

	public performanceSubscription: Subscription;
	public isLoading = true;
	public FAKE_CARD_PERFORMANCE = FAKE_CARD_PERFORMANCE;
	public PERFORMANCE_HISTORY_RANGE = PERFORMANCE_HISTORY_RANGE;
	public BotRiskLabel = BotRiskLabel;

	constructor(
		private performanceService: PerformanceService,
		public formatNumberHelper: FormatNumberHelper
	) { }

	public ngOnChanges() {
		if (!this.bot || !this.chartPeriod) {
			return;
		}

		this.isLoading = true;

		this.performanceSubscription = this.performanceService.getBotPerformanceSummary(
			this.bot,
			this.chartPeriod,
			this.getPeriodFrequency()
		).subscribe(performanceSummary => {
			this.bot.performance = performanceSummary;
			this.isLoading = false;
			this.performanceLoaded.next(true);
		});
	}

	public ngOnDestroy() {
		this.performanceSubscription.unsubscribe();
	}

	private getPeriodFrequency(): PERFORMANCE_HISTORY_FREQUENCY {
		return PERFORMANCE_HISTORY_PERIODS.find(period => period.range === this.chartPeriod)?.frequency;
	}

	public getPerformanceClass(period: PERFORMANCE_HISTORY_RANGE, performance: number): string {
		if (period !== this.chartPeriod) {
			return 'text-muted-500';
		}

		return this.formatNumberHelper.getClass(performance);
	}
}
