import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-fake-chart-bar',
	templateUrl: './fake-chart-bar.component.html'
})
export class FakeChartBarComponent {

	@Input() class = ''

}
