import { Component, Input } from '@angular/core';
import { PlanDifference, SPECIAL_VALUES } from '@app/shared/types/plan-diffrence';
import { Plan } from '@b-cube/interfaces/plan';


@Component({
	selector: 'app-widget-wizard-next-plan',
	templateUrl: './widget-wizard-next-plan.component.html'
})
export class WidgetWizardNextPlanComponent  {
	@Input() nextPlan: Plan;
	@Input() planDifference: PlanDifference;

	public getTradingLimitDiffLabel(): string{
		let label = '';

		if(this.planDifference.tradingLimit === SPECIAL_VALUES.PLUS_INFINITY){
			label = '+&#8734;';
		} else if(this.planDifference.tradingLimit === SPECIAL_VALUES.MINUS_INFINITY){
			label = '-&#8734;';
		} else {
			if(this.planDifference.tradingLimit > 0){
				label+= '+';
			}

			if(this.planDifference.tradingLimit === 0){
				label += 'same';
			} else {
				label += String(this.planDifference.tradingLimit) + 'K$';
			}
		}

		return label + ' investment'
	}

	public arePricesDifferent(): boolean {
		return(this.planDifference?.price ?? 0) !== 0;
	}

	public getPricesDifferenceLabel(): string{
		let label = '';

		if(this.planDifference?.price > 0){
			label += '+';
		}

		label += String(this.planDifference?.price ?? 0);

		return label;
	}
}
