<form [formGroup]="form" (ngSubmit)="submit()">
  <div
    class="modal fixed top-0 left-0 flex items-center justify-center w-full h-full bg-muted-800/70 dark:bg-muted-900/80 px-4 z-50">
    <div class="h-auto w-full md:max-w-2xl mx-auto text-left bg-white dark:bg-muted-800 rounded shadow-xl">
      <!-- Header -->
      <div class="flex items-center justify-between p-4 md:p-6 border-b border-muted-200 dark:border-muted-700">
        <div>
          <h3 class="font-heading text-lg font-medium leading-6 text-muted-900 dark:text-white">
            Edit My Settings
          </h3>
        </div>

        <button
          class="flex items-center justify-center rounded-full hover:bg-muted-100 dark:hover:bg-muted-700 h-8 w-8 transition-colors duration-300"
          (click)="dismiss()">
          <svg class="fill-current h-5 w-5 text-muted-700 dark:text-muted-50" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="32" height="32"
            preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256">
            <path fill="currentColor"
              d="M205.7 194.3a8.1 8.1 0 0 1 0 11.4a8.2 8.2 0 0 1-11.4 0L128 139.3l-66.3 66.4a8.2 8.2 0 0 1-11.4 0a8.1 8.1 0 0 1 0-11.4l66.4-66.3l-66.4-66.3a8.1 8.1 0 0 1 11.4-11.4l66.3 66.4l66.3-66.4a8.1 8.1 0 0 1 11.4 11.4L139.3 128Z">
            </path>
          </svg>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <!-- Body -->
      <div class="max-h-[380px] overflow-y-auto slimscroll p-4 md:p-6" formArrayName="botSettings">
        <div class="w-full max-w-xl mx-auto px-5 ">

          <!--Capital allocation-->
          <div class="py-4" *ngFor="let setting of getBotSettings().controls; let index=index">
            <!--Settings-->
            <div class="grid gap-8" [formGroupName]="index">
              <!--Column-->
              <div class="flex flex-col space-y-10">
                <!--Setting block-->
                <div class="flex flex-col">
                  <div
                    class="flex items-center justify-between border-b border-muted-200 dark:border-muted-700 pb-4 mb-4">
                    <h5 class="font-sans font-semibold text-sm text-muted-600 dark:text-muted-100">
                      {{setting.value.label}}
                    </h5>
                    <!--<app-form-switch [checked]="true"></app-form-switch>-->
                  </div>
                  <div>
                    <div>
                      <app-form-bot-settings-slider [value]="setting.value.settingValue"
                        [floor]="setting.value.minValue" [ceil]="setting.value.maxValue"
                        (valueChanged)="onSliderValueChange($event, index)">
                      </app-form-bot-settings-slider>
                    </div>
                    <div class="flex items-center justify-between">
                      <label class="inline-block font-sans text-xs text-slate-400">Custom value
                      </label>
                      <div class="group relative max-w-[66px]">
                        <input type="number" formControlName="settingsBoxScaleValue"
                          (input)="onInputBoxChange($event, index)"
                          class="text-right pr-6 pl-3 py-2 h-8 text-sm leading-5 font-sans w-full rounded-lg bg-white text-muted-600 border border-muted-300 focus:border-muted-300 focus:shadow-lg focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 placeholder:text-muted-300 dark:placeholder:text-muted-500 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-muted-300 dark:focus:outline-muted-600 focus:outline-offset-2 transition-all duration-300" />
                        <div
                          class="absolute top-0 right-0 h-8 w-6 flex items-center justify-center font-sans text-muted-400">
                          <span class="text-sm">{{setting.value.scale}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Help text-->
          <div class="py-6 px-2">
            <p class="font-sans text-muted-500 dark:text-muted-400 text-xs">
              Please consider that trading is at your own risk. The risk of loss
              in trading cryptocurrencies, even when done via an automated trading
              system, can be substantial. By confirming, you agree with our term
              and conditions
            </p>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div class="p-4 md:p-6">
        <div class="flex justify-end w-full gap-x-2">
          <button (click)="setDefaultSettings()" type="button"
            class="lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 border border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700 dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300">
            <!--<app-icon-time-rotate class="w-4 h-4"></app-icon-time-rotate>-->
            <span class="font-sans text-sm">Reset</span>
          </button>
          <button [ngClass]="isConfirmLoading ? 'button-loading' : ''"
            class="lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30">
            <span class="font-sans text-sm">Apply settings</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
