import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { loadingOptions, PerformanceDisplayMode } from '@app/core/constants/charts.constants';
import { FormatNumberHelper } from '@app/core/services/performance/format-number.helper';
import { IPerformance } from '@b-cube/interfaces/performance';
import { EChartsOption } from 'echarts';
import { DateTime } from 'luxon';

@Component({
	selector: 'app-chart-bar',
	templateUrl: './chart-bar.component.html',
})
export class ChartBarComponent implements OnChanges {

	@Input() chartData: IPerformance[];
	@Input() loading = false;

	public options: EChartsOption = {};
	public loadingOptions = loadingOptions;

	constructor(private formatNumberHelper: FormatNumberHelper) { }

	public get isLoading(): boolean {
		return this.loading;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['chartData'] && changes['chartData'].currentValue) {
			this.getChartOptions();
		}
	}

	private generateLast12Months() {
		const end = DateTime.local().startOf('month');
		const start = end.minus({ months: 11 })
		const monthlyDates: string[] = [];
		let currentDate = start;

		while (currentDate <= end) {
			monthlyDates.push(currentDate.toFormat("MMMM yyyy"));
			currentDate = currentDate.plus({ months: 1 });
		}

		return monthlyDates;
	}

	private fillMissingDates(performanceList: IPerformance[]): IPerformance[] {
		if (!performanceList.length) {
			return performanceList;
		}

		const monthlyDates = this.generateLast12Months();

		const newPerformances:{ [key: string]: IPerformance } = {};
		performanceList.forEach((obj: IPerformance) => {
			const monthlyDate = DateTime.fromISO(obj.time).toFormat('MMMM yyyy');

			// if the server returned a too old date, ignore it
			if(monthlyDates.indexOf(monthlyDate) > -1){
				newPerformances[DateTime.fromISO(obj.time).toFormat('MMMM yyyy')] = obj;
			}
		});

		for(const date of monthlyDates){
			if(! newPerformances[date]){
				const datetime = DateTime.fromFormat(date, 'MMMM yyyy');
				const monthNumber =  Number(datetime.toFormat(PerformanceDisplayMode.MONTH));
				const year = Number(datetime.toFormat(PerformanceDisplayMode.FULL_YEAR));

				newPerformances[date] = {
					time: DateTime.utc(year, monthNumber).endOf("month").startOf("day").toISO(),
					value: 1
				};

			}
		}

		return Object.values(newPerformances).sort((a, b) => DateTime.fromISO(a.time).diff(DateTime.fromISO(b.time)).milliseconds);
	}


	public getChartOptions(): void {

		const data = this.chartData ?? [];

		const datePipe = new DatePipe('en-US');

		const performance = this.fillMissingDates(data);

		const xAxisData: any[] = performance.map(point => datePipe.transform(point.time, PerformanceDisplayMode.MONTH_NAME));
		const currentMonth = xAxisData.pop();
		xAxisData.push({
			value: currentMonth,
			textStyle: {
				color: 'rgba(148, 163, 184, 0.5)'
			}
		});

		const yAxisData: any[] = performance.map(point => (point.value - 1) * 100);
		const currentMonthPerf = yAxisData.pop();
		yAxisData.push({
			value: currentMonthPerf,
			itemStyle: {
				color:'rgba(167, 139, 250, 0.4)',
			}
		});

		const isEmptyData = data.length === 0;

		this.options = <EChartsOption>{
			responsive: true,
			maintainAspectRatio: false,
			title: {
				show: isEmptyData && !this.loading,
				textStyle: {
					color: '#64748B',
					fontSize: 12,
					fontStyle: 'italic'
				},
				text: 'No live trading within this period',
				left: 'center',
				top: 'center'
			},
			xAxis: {
				show: !isEmptyData,
				type: 'category',
				data: xAxisData,
				axisLabel: {
					color: '#94a3b8',
				},
				axisTick: {
					lineStyle: {
						color: '#cbd5e1',
					},
				},
				axisLine: {
					lineStyle: {
						color: '#cbd5e1',
					},
				},
			},
			yAxis: {
				type: 'value',
				splitNumber: 5,
				axisLabel: {
					formatter: '{value}%',
					color: '#94a3b8',
				},
				axisTick: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
				axisLine: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
				splitLine: {
					lineStyle: {
						color: 'rgba(226, 232, 240, 0.5)',
					},
				},
			},
			grid: {
				left: 6,
				top: 10,
				right: 6,
				bottom: 0,
				containLabel: true
			},
			tooltip: {
				show: true,
				textStyle: {
					fontSize: 12
				},
				valueFormatter: (value: number) => this.formatNumberHelper.formatNumber(value, '%'),
			},
			series: [
				{
					name: 'Peformance',
					data: yAxisData,
					type: 'bar',
					itemStyle: {
						color: '#23cbd5',
					}
				},
			],
			height: 360
		};
	}
}
