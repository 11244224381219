import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';
import { DialogService } from '@app/core/services/dialog.service';
import { ScreenService } from '@app/core/services/screen.service';
import { SettingsTabsAccountsExchangeModalComponent } from '@app/shared/components/settings/settings-tabs-accounts-exchange-modal/settings-tabs-accounts-exchange-modal.component';

@Component({
	selector: 'app-layout-dashboard',
	templateUrl: './layout-dashboard.component.html',
})
export class LayoutDashboardComponent implements OnInit {
	public isFolded = false;
	public isNotificationPanelOpen = false;


	constructor(
		private viewContainer: ViewContainerRef,
		private dialogService: DialogService,
		private screenService: ScreenService
	) { }

	ngOnInit(): void {
		this.isFolded = !this.screenService.isDesktop();
		this.createDialogs();
	}

	createDialogs() {
		this.viewContainer.clear();

		const dialogComponentRef = this.viewContainer.createComponent(ConfirmDialogComponent);

		// Register the confirm dialog to the dedicated service
		this.dialogService.setConfirmDialog(dialogComponentRef.instance);

		const exchangeAccountComponentRef = this.viewContainer.createComponent(SettingsTabsAccountsExchangeModalComponent);
		this.dialogService.setAddExchangeAccountDialog(exchangeAccountComponentRef.instance);
	}

	toggle() {
		this.isFolded = !this.isFolded
	}

	togglePanel() {
		this.isNotificationPanelOpen = !this.isNotificationPanelOpen
	}
}
