import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-widget-bot-controls',
	templateUrl: './widget-bot-controls.component.html'
})
export class WidgetBotControlsComponent {
	@Input() isEnabled: boolean;
	@Input() botId: number;
	@Input() isLoading: boolean;

	@Output() updateBotStatus: EventEmitter<number> = new EventEmitter();
	@Output() deleteUserBot: EventEmitter<number> = new EventEmitter();

	public updateStatus(botId: number) {
		this.updateBotStatus.emit(botId);
	}

	public removeBot(botId: number) {
		this.isLoading = true;
		this.deleteUserBot.emit(botId);
	}

}
