import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-trash',
  templateUrl: './icon-trash.component.html'
})
export class IconTrashComponent {
  @Input() class = ''

}
