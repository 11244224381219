<svg
  width="21"
  height="20"
  viewBox="0 0 21 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.7289 1.50418V1.52418C11.7289 2.19418 12.1789 2.75418 12.8089 2.95418C15.6589 3.84418 17.7289 6.49418 17.7289 9.63418C17.7289 10.1542 17.6689 10.6442 17.5589 11.1242C17.4189 11.7642 17.6789 12.4242 18.2489 12.7642L18.2589 12.7742C19.1189 13.2742 20.2389 12.8242 20.4689 11.8642C20.6389 11.1442 20.7289 10.3942 20.7289 9.63418C20.7289 5.13418 17.7489 1.31418 13.6489 0.0641819C12.6989 -0.225818 11.7289 0.504182 11.7289 1.50418ZM9.66892 16.5542C6.67892 16.1242 4.24892 13.6942 3.80892 10.7142C3.26892 7.11418 5.46892 3.94418 8.63892 2.95418C9.27892 2.76418 9.72892 2.19418 9.72892 1.52418V1.50418C9.72892 0.504182 8.75892 -0.225818 7.79892 0.0641819C3.28892 1.44418 0.138918 5.92418 0.818918 11.0242C1.40892 15.4042 4.94892 18.9442 9.32892 19.5342C12.4689 19.9542 15.3689 18.9242 17.4589 17.0042C18.1989 16.3242 18.0689 15.1142 17.1989 14.6142C16.6189 14.2742 15.8989 14.3842 15.3989 14.8342C13.9289 16.1742 11.8889 16.8842 9.66892 16.5542Z"
    fill="currentColor"
  />
</svg>
