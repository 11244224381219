import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScreenService } from '@app/core/services/screen.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
	@Input() expanded: boolean;
	// eslint-disable-next-line
	@Output() close = new EventEmitter<any>();

	constructor(private screenService: ScreenService) {
	}

	toggle(): void {
		this.close.emit(null);
	}

	toogleSideMenu(): void {
		if (!this.screenService.isDesktop()) {
			this.close.emit(null);
		}
	}
}
