import { Component, Input } from '@angular/core';
import { FormatNumberHelper } from '@app/core/services/performance/format-number.helper';
import { IBot } from '@b-cube/interfaces/bot';
import { IUserAccountBotPerformance } from '@b-cube/interfaces/performance';

@Component({
	selector: 'app-widget-bot-stats',
	templateUrl: './widget-bot-stats.component.html'
})
export class WidgetBotStatsComponent {
	@Input() bot: IBot;
	@Input() userAccountBotPerformance: IUserAccountBotPerformance;

	constructor(public formatNumberHelper: FormatNumberHelper) { }



}
