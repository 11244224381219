<svg
  width="21"
  height="21"
  viewBox="0 0 21 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.9688 0.867188C5.44875 0.867188 0.96875 5.34719 0.96875 10.8672C0.96875 16.3872 5.44875 20.8672 10.9688 20.8672C16.4888 20.8672 20.9688 16.3872 20.9688 10.8672C20.9688 5.34719 16.4888 0.867188 10.9688 0.867188ZM11.9688 17.8672H9.96875V15.8672H11.9688V17.8672ZM14.0387 10.1172L13.1388 11.0372C12.6388 11.5472 12.2788 12.0072 12.0988 12.7272C12.0188 13.0472 11.9688 13.4072 11.9688 13.8672H9.96875V13.3672C9.96875 12.9072 10.0487 12.4672 10.1888 12.0572C10.3888 11.4772 10.7188 10.9572 11.1388 10.5372L12.3787 9.27719C12.8387 8.83719 13.0588 8.17719 12.9288 7.47719C12.7987 6.75719 12.2387 6.14719 11.5387 5.94719C10.4288 5.63719 9.39875 6.26719 9.06875 7.21719C8.94875 7.58719 8.63875 7.86719 8.24875 7.86719H7.94875C7.36875 7.86719 6.96875 7.30719 7.12875 6.74719C7.55875 5.27719 8.80875 4.15719 10.3587 3.91719C11.8787 3.67719 13.3287 4.46719 14.2288 5.71719C15.4087 7.34719 15.0588 9.09719 14.0387 10.1172Z"
    fill="currentColor"
  />
</svg>
