import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUpdateNotification, IUserNotification } from '@b-cube/interfaces/notification';
import { DateTime} from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { RestURLBuilder } from 'rest-url-builder';
import { BehaviorSubject, catchError, map, Observable, of, share } from 'rxjs';

import { NOTIFICATION_ITEM_URL,NOTIFICATIONS_URL } from '../constants/api-urls.constants';
import { ERROR_OCCURED } from '../constants/error-messages';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	private readonly notification$: BehaviorSubject<IUserNotification[]> = new BehaviorSubject<IUserNotification[]>([]);

	constructor(
		private http: HttpClient,
		private toasterService: ToastrService
	) {
		this.loadNotifications();
	}

	private loadNotifications() {
		this.http.get<any[]>(NOTIFICATIONS_URL).pipe(
			share(),
			map( notifications => notifications.map((notification) => {
				notification.createdAt = DateTime.fromISO(notification.createdAt);
				notification.createdAt = DateTime.fromISO(notification.createdAt);

				return <IUserNotification> notification;
			})),
		).subscribe(plans => {
			this.notification$.next(plans);
		});
	}

	public getNotifications(): Observable<IUserNotification[]> {
		return this.notification$.asObservable();
	}

	public markAsRead(notification: IUserNotification): Observable<IUserNotification>{
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(NOTIFICATION_ITEM_URL);
		urlBuilder.setNamedParameter('notificationId', String(notification.id));

		return this.http.put<IUserNotification>(urlBuilder.get(), <IUpdateNotification>{
			readAt: DateTime.now().toISODate()
		}).pipe(
			share(),
			map( updateNotification => {
				const notifications = this.notification$.value.filter(notification => notification.id !== updateNotification.id);
				this.notification$.next(notifications);

				return updateNotification;
			})
		)
	}

	public markAllAsRead(){
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(NOTIFICATIONS_URL);

		return this.http.put<IUserNotification>(urlBuilder.get(), <IUpdateNotification>{
			readAt: DateTime.now().toISODate()
		}).pipe(
			share(),
			map( updateNotification => {
				this.notification$.value.length = 0;
				this.notification$.next( this.notification$.value);

				return updateNotification;
			}),
			catchError(() => {
				this.toasterService.error(`Unable to clear the notifications. `, ERROR_OCCURED);

				return of(null);
			})
		)
	}
}
