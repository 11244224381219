<svg
  width="8"
  height="13"
  viewBox="0 0 8 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    d="M4.18305 0.144531L4.10254 0.417997V8.35264L4.18305 8.43297L7.86615 6.25585L4.18305 0.144531Z"
    fill="currentColor"
  />
  <path
    d="M4.1832 0.144531L0.5 6.25585L4.1832 8.43297V4.58171V0.144531Z"
    fill="currentColor"
  />
  <path
    d="M4.18307 9.13009L4.1377 9.18542V12.0119L4.18307 12.1443L7.86843 6.9541L4.18307 9.13009Z"
    fill="currentColor"
  />
  <path d="M4.1832 12.1443V9.13009L0.5 6.9541L4.1832 12.1443Z" fill="currentColor" />
  <path
    d="M4.18311 8.4328L7.86621 6.25569L4.18311 4.58154V8.4328Z"
    fill="currentColor"
  />
  <path d="M0.5 6.25569L4.1832 8.4328V4.58154L0.5 6.25569Z" fill="currentColor" />
</svg>
