import { PlanSubscriptionErrorCodes, TransactionErrorCodes,UserBotErrorCodes } from "./error-codes";

export const PlanSubscriptionErrorMessages = {
    [PlanSubscriptionErrorCodes.PLAN_SUBSCRIPTION_PENDING_TRANSACTION]: 'Subscription transaction is still pending.',
    [PlanSubscriptionErrorCodes.PLAN_TOPUP_PENDING_TRANSACTION]: 'Unable to verify subscription update. Ensure related transactions are complete or contact support.'
};

export const BotSubscriptionErrorMessages = {
	[UserBotErrorCodes.BOT_PENDING_TRANSACTION]: 'Agent transaction is still pending.',
	[UserBotErrorCodes.BOT_TOPUP_PENDING_TRANSACTION]: 'Unable to verify agent subscription update. Ensure related transactions are complete or contact support.',
	[UserBotErrorCodes.BOT_ALREADY_ACTIVE]: 'This agent is already active.',
}

export const TransactionErrorMessages = {
	[TransactionErrorCodes.TRANSACTION_FAILED]: 'The payment transaction failed. Please contact our support team.',
	[TransactionErrorCodes.TRANSACTION_UNEXPECTED_ERROR]: 'An unexpected error occurred. Please contact our support team.',

}

export const SubscriptionRetryErrorMessages = {
	DATE_DIFFERENCE_UNMATCHED: 'date_difference_unmatched',
	RETRY_LIMIT_EXCEEDED: 'retry_limit_exceeded',
	PENDING_TRANSACTION: 'pending_transaction',
};

export const CommonErrorMessages = {
    UNABLE_REACH_PAYMENT_SERVICE: 'Unable to reach our payment service. Please try again later',
    UNABLE_UPDATE_PLAN: 'Unable to update your plan. Please contact the support team',
	UNABLE_UPDATE_BOT: 'Unable to update your bot. Please contact the support team'
};
