<div class="flex justify-between gap-x-4 py-3">
	<button (click)="updateStatus(botId)" [ngClass]="[isSubscriptionLoading || isLoading ? 'button-loading' : '']" [disabled]="isSubscriptionLoading" [class]="
		isEnabled
		? 'text-amber-500  hover:border-amber-500'
		: 'text-green-500  hover:border-green-500'"
		class="flex-1 flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-white dark:bg-muted-800  border-2 border-transparent transition-all duration-300">
		<div class="flex items-center justify-center gap-x-2" *ngIf="!isLoading && isEnabled; else play">
			<span class="font-sans text-sm">Pause</span>
			<app-icon-pause class="w-3 h-3"></app-icon-pause>
		</div>
		<ng-template #play>
			<span class="font-sans text-sm">Play</span>
			<app-icon-play></app-icon-play>
		</ng-template>
	</button>
	<button *ngIf="!isCreditCard" [ngClass]="[isSubscriptionLoading  || isLoading ? 'button-loading' : '']" [disabled]="isSubscriptionLoading" (click)="openModal()"
		class="flex-1 flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-white dark:bg-muted-800 text-brand border-2 border-transparent hover:border-brand transition-all duration-300">
		<span class="font-sans text-sm">TopUp</span>
		<app-icon-plus class="w-3 h-3"></app-icon-plus>
	</button>
	<button *ngIf="isCreditCard" [ngClass]="[isSubscriptionLoading  || isLoading ? 'button-loading' : '']" [disabled]="isSubscriptionLoading" (click)="removeBot(botId)"
		class="flex-1 flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-white dark:bg-muted-800 text-rose-500 border-2 border-transparent hover:border-rose-500 transition-all duration-300">
		<span class="font-sans text-sm">Remove</span>
		<app-icon-x class="w-3 h-3"></app-icon-x>
	</button>
</div>

<div
class="flex flex-row justify-around p-6 bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md">
	<div class="flex flex-col items-center justify-between">
		<span class="text-sm text-muted-400 mr-2">Method</span>
		<span class="text-muted-600 dark:text-muted-100 text-sm">{{isCreditCard ? 'Credit Card' : '$BCUBE'}}</span>
	</div>
	<div class="flex flex-col items-center justify-between" *ngIf="isCreditCard">
		<span class="text-sm text-muted-400 mr-2">Next billing</span>
		<span class="text-muted-600 dark:text-muted-100 text-sm">{{userBotSubscription?.nextBillingDate | ldate}}
		</span>
	</div>
	<div class="flex flex-col items-center justify-between" *ngIf="!isCreditCard">
		<span class="text-sm text-muted-400 mr-2">Expiration</span>
		<span class="text-muted-600 dark:text-muted-100 text-sm">{{userBotSubscription?.currentTermEnd | ldate}}
		</span>
	</div>
</div>

<!--Modal-->
<app-widget-marketplace-subscription-modal [@insertRemoveTrigger] [isOpen]="isModalOpen" [bot]="bot"
	[isCompanyProvider]="false" [botPlan]="botPlan" [paymentMethod]="paymentMethod" (closeModal)="closeAddBot($event)"
	[paymentDetails]="bcubePaymentDetails" [userBotSubscription]="userBotSubscription">
</app-widget-marketplace-subscription-modal>
