<svg
  width="33"
  height="37"
  viewBox="0 0 33 37"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    d="M16.8286 30.2709V36.9632L16.8064 36.9761L15.4855 36.215L0.807617 27.7581V9.31995L6.6365 12.6777V24.4003L16.8099 30.2605L16.8286 30.2709Z"
    fill="currentColor"
  />
  <path
    d="M2.12849 8.5589L6.63649 5.961V12.6778L0.807617 9.31997L2.12849 8.5589Z"
    fill="currentColor"
  />
  <path
    d="M32.8078 9.31992L26.9801 12.6777L16.8078 6.81643L9.85547 10.8222V4.10781L16.8078 0.10083L31.4846 8.55652L32.8078 9.31992Z"
    fill="currentColor"
  />
  <path
    d="M32.8081 9.31995V27.7581L18.1232 36.2185L16.8292 36.9632V30.2709L16.8105 30.2605L26.9804 24.4003V12.6777L32.8081 9.31995Z"
    fill="currentColor"
  />
  <path
    d="M21.3591 15.9993H21.3556L21.0462 16.1823L16.8897 18.6193L16.8418 26.5307L19.4286 25.0424L19.3808 20.1823C19.3329 20.1788 19.2897 20.1695 19.2511 20.1473C19.0491 20.0343 19.014 19.7184 19.1717 19.4375C19.327 19.1566 19.6167 19.0214 19.8164 19.1322C20.0149 19.2429 20.0535 19.5611 19.8958 19.8419C19.8421 19.9364 19.7732 20.0156 19.6973 20.075L19.7416 24.8594L21.5927 23.7942L21.574 21.8116L22.7431 20.3967L22.7174 18.5121C22.6695 18.5086 22.6263 18.4993 22.5843 18.4771C22.3822 18.3641 22.3472 18.0482 22.5048 17.7674C22.6602 17.4865 22.9498 17.3513 23.1495 17.462C23.3515 17.5727 23.3866 17.8909 23.2289 18.1718C23.1752 18.2662 23.1063 18.3454 23.0304 18.4014L23.0619 20.5098L21.8905 21.9247L21.9092 23.6147L23.7603 22.5459V14.5902L21.3591 15.9993ZM21.6091 19.99C21.4514 20.2709 21.1641 20.406 20.9621 20.2953C20.7635 20.1846 20.7285 19.8664 20.8827 19.5855C20.9364 19.4911 21.0088 19.4119 21.0847 19.3524L21.0497 16.1846L21.365 16.0016L21.3965 19.2452C21.4444 19.2487 21.4876 19.258 21.5297 19.2802C21.7294 19.3932 21.7644 19.7091 21.6091 19.99Z"
    fill="currentColor"
  />
  <path
    d="M23.7598 14.5331V14.5902L21.3599 15.9993H21.3564L18.5359 14.3886C18.5581 14.3478 18.571 14.3035 18.571 14.2557C18.571 14.0261 18.3094 13.8431 17.987 13.8431C17.6682 13.8431 17.4054 14.0261 17.4054 14.2557C17.4054 14.4853 17.667 14.6683 17.987 14.6683C18.098 14.6683 18.2019 14.6462 18.2907 14.6089L21.048 16.1823L16.8916 18.6193H16.888L9.85742 14.5494V14.5331L12.2679 13.1462L16.7374 15.6904C16.7187 15.7312 16.7059 15.7755 16.7059 15.8198C16.7059 16.0494 16.9651 16.2359 17.2875 16.2359C17.6098 16.2359 17.8691 16.0494 17.8691 15.8198C17.8691 15.5925 17.6098 15.4072 17.2875 15.4072C17.1765 15.4072 17.0726 15.4294 16.9838 15.4667L12.5844 12.9643L14.4507 11.8862L16.38 12.983L18.1867 12.6555L19.8229 13.5984C19.8042 13.6392 19.7879 13.6834 19.7879 13.7312C19.7879 13.9608 20.0495 14.1438 20.3718 14.1438C20.6907 14.1438 20.9534 13.9608 20.9534 13.7312C20.9534 13.5016 20.6918 13.3186 20.3718 13.3186C20.2609 13.3186 20.1604 13.3408 20.0717 13.3781L18.2428 12.3256L16.4326 12.6531L14.7648 11.7044L16.8086 10.5284L23.7598 14.5331Z"
    fill="currentColor"
  />
  <path
    d="M16.8873 18.6183L16.8429 26.5297L16.8078 26.5519L11.2452 23.3456L9.85547 22.5449V21.0997L12.6479 22.6941L13.8484 21.8712L15.0455 22.5554V22.6439C15.0735 22.9342 15.2791 23.1544 15.5033 23.1323C15.7276 23.1101 15.8887 22.8584 15.8607 22.5682C15.835 22.278 15.6306 22.0577 15.4029 22.0798C15.2989 22.0892 15.2067 22.1521 15.1436 22.2465L13.8333 21.4994L12.6327 22.3223L9.85664 20.7395V18.8701L11.9822 20.0833C11.9822 20.1113 11.9822 20.1428 11.9857 20.1743C12.0114 20.4645 12.2157 20.6812 12.44 20.6603C12.6677 20.6381 12.8254 20.3864 12.7974 20.0962C12.7717 19.806 12.5673 19.5892 12.343 19.6113C12.2356 19.6206 12.1445 19.6836 12.0814 19.7757L9.8578 18.5053V16.5006L14.6776 19.2489C14.6776 19.2768 14.6776 19.3083 14.6811 19.3374C14.7092 19.6311 14.9112 19.8479 15.1389 19.8258C15.3632 19.8036 15.5208 19.5519 15.4963 19.2617C15.4683 18.9715 15.2627 18.7547 15.0385 18.7733C14.9311 18.7861 14.84 18.8491 14.7769 18.94L9.85897 16.1311V14.5496L16.8873 18.6183Z"
    fill="currentColor"
  />
</svg>
