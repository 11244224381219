<div class="flex justify-evenly">
	<div class="flex flex-col bg-gradient-to-t border px-5 py-8 rounded-lg from-cyan-700 to-brand w-fit">
		<div class="flex items-center gap-x-2 mb-4">
			<img src="/assets/img/illustrations/safe-box.png" class="object-contain w-14" alt="Illustration" />
			<div>
				<h4 class="text-[0.65rem] font-semibold text-white opacity-50 uppercase">
					Current tier
				</h4>
				<h3 class="font-sans font-medium text-2xl text-white capitalize">
					{{currentTier.name}}
				</h3>
			</div>
		</div>
		<div class="flex divide-x divide-brand/50 w-[10rem]">
			<div class="font-sans px-4 w-full">
				<p class="text-white opacity-90 text-xs whitespace-nowrap pb-2">
					Discount
				</p>
				<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
					<span>Plan/CC</span>
					<span>{{currentTier.planDiscountForCC / 100 | percent:'1.0-0'}}</span>
				</p>
				<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
					<span>Plan/BCUBE</span>
					<span>{{currentTier.planDiscountForBCUBE / 100 | percent:'1.0-0'}}</span>
				</p>
				<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
					<span>Agent/CC</span>
					<span>{{currentTier.botDiscountForCC / 100 | percent:'1.0-0'}}</span>
				</p>
				<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
					<span>Agent/BCUBE</span>
					<span>{{currentTier.botDiscountForBCUBE / 100 | percent:'1.0-0'}}</span>
				</p>
			</div>
		</div>
	</div>


	<div class="flex flex-col bg-gradient-to-t border px-5 py-8 rounded-lg from-gray-700 to-gray-500 w-fit">
		<div class="flex items-center gap-x-2 mb-4">
			<img src="/assets/img/illustrations/safe-box.png" class="object-contain w-14" alt="Illustration" />
			<div>
				<h4 class="text-[0.65rem] font-semibold text-white opacity-50 uppercase">
					Resultant tier
				</h4>
				<h3 class="font-sans font-medium text-2xl text-white capitalize">
					{{eligibleTier.name}}
				</h3>
			</div>
		</div>
		<div class="flex divide-x divide-brand/50 w-[10rem]">
			<div class="font-sans px-4 w-full">
				<p class="text-white opacity-90 text-xs whitespace-nowrap pb-2">
					Discount
				</p>
				<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
					<span>Plan/CC</span>
					<span>{{tierDifference.planDiscountForCC / 100 | percent:'1.0-0'}}</span>
				</p>
				<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
					<span>Plan/BCUBE</span>
					<span>{{tierDifference.planDiscountForBCUBE / 100 | percent:'1.0-0'}}</span>
				</p>
				<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
					<span>Agent/CC</span>
					<span>{{tierDifference.botDiscountForCC  / 100| percent:'1.0-0'}}</span>
				</p>
				<p class="text-white opacity-90 text-xs whitespace-nowrap flex justify-between py-1">
					<span>Agent/BCUBE</span>
					<span>{{tierDifference.botDiscountForBCUBE / 100 | percent:'1.0-0'}}</span>
				</p>
			</div>
		</div>
	</div>
</div>