export enum ExecutionClosingState {
  HIT_TARGET = 'HIT_TARGET',
  HIT_STOPLOSS = 'HIT_STOPLOSS',
  HIT_TRAILING_TARGET = 'HIT_TRAILING_TARGET',
  HIT_TRAILING_STOPLOSS = 'HIT_TRAILING_STOPLOSS',
  LIQUIDATED = 'LIQUIDATED',
  LIQUIDATION_NOT_REQUIRED = 'LIQUIDATION_NOT_REQUIRED',
  LIQUIDATION_REQUIRED = 'LIQUIDATION_REQUIRED',
  EXIT = 'EXIT',
  ERROR = 'ERROR',
  NONE = 'NONE'
}
