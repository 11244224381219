import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from '@angular/router';
import { ReuseRoutesParams, Routes } from '@app/core/constants/router.constants';

export class CustomReuseStrategy implements BaseRouteReuseStrategy {
	public storedRoutes: { [key: string]: DetachedRouteHandle } = {};

	private path: string;

	private shouldApplyReuseStrategy(route: ActivatedRouteSnapshot) {
		if (route.data[ReuseRoutesParams.REUSEROUTESFROM]) {
			return route.data[ReuseRoutesParams.REUSEROUTESFROM].includes(this.path);
		} 
			if (route?.routeConfig?.path && route.routeConfig.path !== Routes.MARKETPLACE) {
					this.path = route.routeConfig.path;
			}

			return false;
		
	}

	public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
		if (!route.data[ReuseRoutesParams.SHOULDREUSE]) {
			return null;
		}

		return this.storedRoutes[route.data[ReuseRoutesParams.KEY]];
	}

	public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		if (route.data[ReuseRoutesParams.SHOULDREUSE]) {
			this.storedRoutes[route.data[ReuseRoutesParams.KEY]] = handle;
		}
	}

	public shouldDetach(route: ActivatedRouteSnapshot): boolean {
		return Boolean(route.data[ReuseRoutesParams.SHOULDREUSE]);
	}

	public shouldAttach(route: ActivatedRouteSnapshot): boolean {
		const isReuseRouteFrom = this.shouldApplyReuseStrategy(route)

		return Boolean(route.data[ReuseRoutesParams.SHOULDREUSE]) && Boolean(this.storedRoutes[route.data[ReuseRoutesParams.KEY]]) && isReuseRouteFrom;
	}

	public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
		return future.routeConfig === current.routeConfig;
	}

}
