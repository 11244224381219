<div class="w-full bg-white dark:bg-muted-800">
	<div class="px-6 py-4" *ngIf="canUseService() && userHasSupportedExchanges">
		<button *ngIf="!isAlreadyPlugged" type="button" [ngClass]="isLoading ? 'button-loading' : ''"
			class="w-full flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300"
			(click)="openModal()">
			<app-icon name="add" class="h-4 w-4"></app-icon>
			<span>Connect</span>
		</button>
		<button *ngIf="isAlreadyPlugged" type="button" [ngClass]="isLoading ? 'button-loading' : ''"
			class="w-full flex items-center justify-center gap-x-2 px-5 rounded-md h-12 bg-danger-500 hover:bg-danger-500/90 text-white hover:shadow-md hover:shadow-danger-500/30 transition-all duration-300"
			(click)="deleteUserBot()">
			<app-icon name="minus" class="h-4 w-4"></app-icon>
			<span>Disconnect</span>
		</button>
	</div>
	<div class="px-6 py-4" *ngIf="! canUseService()">
		<div class="flex items-center gap-x-2 mb-4">
			<div class="flex items-center justify-center h-10 w-10 rounded-full">
				<app-icon-warning class="w-8 h-8 text-orange-500"></app-icon-warning>
			</div>
			<div class="font-sans">
				<h4 class="font-semibold text-muted-700 dark:text-muted-100">
					Restriction
				</h4>
				<p class="text-xs lg:text-sm text-muted-400">{{securityService.getRestrictionTypeLabel(user)}}</p>
			</div>
		</div>

		<p class="font-sans text-sm text-muted-500 dark:text-muted-400">
			{{securityService.getRestrictionDescriptionLabel(user)}}
		</p>
		<div class="flex justify-end mt-4" *ngIf="securityService.shouldRedirectToSecurity(user)" >
			<a [routerLink]="[Routes.SETTINGS]" [fragment]="SettingsTabs.SECURITY"
				class="flex-0 flex w-full max-w-[90px] items-center justify-center gap-x-2 px-2 rounded-md h-10 text-white hover:shadow-md transition-all duration-300 bg-brand hover:bg-brand/90 hover:shadow-brand/30">
				<span class="font-sans text-sm">Settings</span>
			</a>
		</div>
	</div>

	<div class="px-6 py-4" *ngIf="canUseService() && !userHasSupportedExchanges">
		<div class="flex items-center justify-center gap-x-2 mb-4">
			<div class="flex items-center justify-center h-10 w-10 rounded-full">
				<app-icon-warning class="w-8 h-8 text-orange-500"></app-icon-warning>
			</div>
			<div class="flex font-sans">
				<h4 class="font-semibold text-muted-700 dark:text-muted-100">
					No compatible exchange account
				</h4>
			</div>
		</div>

		<p class="px-6 font-sans text-sm text-muted-500 dark:text-muted-400">
			You need to <a class="font-sans text-sm font-semibold text-brand" [routerLink]="'/exchange-account'">plug a
				compatible exchange account</a> to use this bot.
		</p>
	</div>

	<!--Modal-->
	<app-widget-marketplace-subscription-modal [isOpen]="isModalOpen" [bot]="bot" [isCompanyProvider]="true"
		(closeModal)="closeAddBot($event)">
	</app-widget-marketplace-subscription-modal>
</div>
