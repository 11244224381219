import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';
import { SettingsTabsAccountsExchangeModalComponent } from '@app/shared/components/settings/settings-tabs-accounts-exchange-modal/settings-tabs-accounts-exchange-modal.component';
import { IExchangeAccount } from '@b-cube/interfaces/exchange-account';

@Injectable()
export class DialogService {
	confirmDialog: ConfirmDialogComponent;
	addExchangeAccountDialog: SettingsTabsAccountsExchangeModalComponent;

	/*
	 * Register the confirmation dialog component
	 **/
	setConfirmDialog(confirmDialog: ConfirmDialogComponent) {
		this.confirmDialog = confirmDialog;
	}

	public setAddExchangeAccountDialog(addAccountDialog: SettingsTabsAccountsExchangeModalComponent) {
		this.addExchangeAccountDialog = addAccountDialog;
	}

	/*
	 * Display a confirmation dialog
	 **/
	confirm(message: string, title: string): Promise<boolean> {
		return this.confirmDialog.show(message, title);
	}

	public showAddExchangeAccountModal(): Promise<IExchangeAccount> {
		return this.addExchangeAccountDialog.show();
	}
}
