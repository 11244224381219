import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GETTING_STARTED_VIDEO_URL } from '@core/constants/helper-urls.constants'

@Component({
	selector: 'app-video-player-modal',
	templateUrl: './video-player-modal.component.html',
  styleUrls: ['./video-player-modal.component.css'],
	animations: [
		trigger('opacity', [
			transition(':enter', [style({ opacity: 0 }), animate('300ms ease-out', style({ opacity: 1 }))]),
			transition(':leave', [style({ opacity: 1 }), animate('200ms ease-in', style({ opacity: 0 }))]),
		]),
		trigger('opacityTranslateY', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(1rem)' }),
				animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateY(0)' }),
				animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' })),
			]),
		]),
	],
})
export class VideoPlayerModalComponent implements OnInit{
	@Input() showModal = false;
  videoUrl: SafeResourceUrl;

	constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
      this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(GETTING_STARTED_VIDEO_URL);
  }

	toggleModal() {
		this.showModal = !this.showModal;
	}

	closeModal(): void {
		this.showModal = false;
	}
}
