import { PaymentMethod } from '@b-cube/database/types';
import { SubscriptionType, TransactionType } from '@b-cube/interfaces/plan/index';

export interface IUserPlanSubscription {
	planId?: string;
	status: string;
	paymentMethod: PaymentMethod,
	price: number;
	months: number;
	ethWalletAddress?:string;
	ethTransaction?:string;
	addons: string[];
	subscriptionType: SubscriptionType;
	transactionType: TransactionType;
}

export { PaymentMethod } from '@b-cube/database/types';
