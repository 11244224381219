import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { CONFLICT } from '@app/core/constants/http-status-code.constants';
import { FundsStatus, SignatureStatus, STAKING_ERROR_MESSAGES, STAKING_WARN_MESSAGES } from '@app/core/constants/staking.constants';
import { ToastrType } from '@app/core/constants/toast.constants';
import { DialogService } from '@app/core/services/dialog.service';
import { UserService } from '@app/core/services/user.service';
import { Tier, TierName } from '@b-cube/interfaces/staking';
import { UserInfo } from '@b-cube/interfaces/user';
import { ERROR_OCCURED } from '@core/constants/error-messages';
import { BcubeContractService } from '@core/services/bcube-contract.service';
import { EthereumService } from '@core/services/ethereum/ethereum.service';
import { StakingService } from '@core/services/staking.service';
import { environment } from '@env/environment';
import { BigNumber } from 'bignumber.js';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, from, map, Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-header-wallet',
	templateUrl: './header-wallet.component.html',
	animations: [
		trigger('openClose', [
			// ...
			state('open', style({
				transform: 'translateY(0)',
				opacity: 1
			})),
			state('closed', style({
				transform: 'translateY(.25rem)',
				opacity: 0
			})),
			transition('open => closed', [
				animate('0.3s')
			]),
			transition('closed => open', [
				animate('0.2s')
			]),
		]),
	],
})
export class HeaderWalletComponent implements OnInit {

	@Input() user: UserInfo;

	isMenuOpen = false;
	// values from ethereum service
	public isWalletConnected$: BehaviorSubject<boolean>;
	public currentConnectedWallet$: BehaviorSubject<string>;

	// values from bcube service
	public isFundsApproved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);;

	public stakedBcubeBalance$: BehaviorSubject<string> = new BehaviorSubject<string>('0');
	public ownedBcubeBalance$: BehaviorSubject<string> = new BehaviorSubject<string>('0');
	public showScreenLoader$: BehaviorSubject<boolean>;

	public stakingWallet$: Observable<string>;
	public shoudAuthenticate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public walletsMatchStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public isWalletLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public shouldChangeNetwork$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private chainId: number = environment.chainId;
	public network: string = environment.networkName;

	private stakingWallet: string;

	// tiers
	private tiers: Tier[];
	public bcubeBalanceSubscription: Subscription;

	public walletSelectionWarning: string;

	constructor(
		private ethereumService: EthereumService,
		private stakingService: StakingService,
		private toastrService: ToastrService,
		private bcubeContractService: BcubeContractService,
		private dialogService: DialogService,
		private userService: UserService,
		private ngZone: NgZone
	) { }


	public ngOnInit() {
		this.initializeObservables();
		this.watchNetwork();
		this.watchConnection();
		this.loadTiers();
	}

	public ngOnDestroy() {
		this.ethereumService.currentChainId$.unsubscribe();
		this.ethereumService.shouldOpenWallet$.unsubscribe();
		this.bcubeBalanceSubscription.unsubscribe();
	}

	private watchNetwork(): void {
		this.ethereumService.currentChainId$.subscribe((chainId) => {
			if (chainId) {
				this.handleNetworkStatus(chainId !== this.chainId);
			}
		})
	}

	private watchConnection(): void {
		this.ethereumService.shouldOpenWallet$.subscribe((shouldOpen) => {
			this.handleMenuStatus(shouldOpen);
		})
	}

	private handleNetworkStatus(shouldChangeNetwork: boolean): void {
		this.ngZone.run(() => {
			if (shouldChangeNetwork) {
				this.handleMenuStatus(true);
			}
			this.shouldChangeNetwork$.next(shouldChangeNetwork);
		});
	}

	private handleMenuStatus(shouldOpen: boolean): void {
		if (shouldOpen) {
			this.openMenu();
		} else {
			this.closeMenu();
		}
	}

	private loadTiers(): void {
		this.stakingService.getTiers().subscribe(tiers => {
			this.tiers = tiers;
		});
	}

	public toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}

	public openMenu() {
		this.isMenuOpen = true;
	}

	public closeMenu(event?: MouseEvent): void {
		if (event) {
			const toastrElements = Object.values(ToastrType)
				.map((className) => Array.from(document.querySelectorAll(className)))
				.flat();

			for (const toastrElement of toastrElements) {
				if (toastrElement.contains(event.target as Node)) {
					return; // Do not close the modal if the click target is inside any toastr element
				}
			}
		}

		this.isMenuOpen = false;
	}

	private initializeObservables(): void {
		this.isWalletConnected$ = this.ethereumService.isWalletConnected$;
		this.currentConnectedWallet$ = this.ethereumService.currentEthAddress$;
		this.showScreenLoader$ = this.bcubeContractService.showTxLoader$;

		this.stakedBcubeBalance$ = this.bcubeContractService.stakedAmount$;
		this.ownedBcubeBalance$ = this.bcubeContractService.ownedAmount$;

		this.stakingWallet$ = this.getStakingWallet();
	}

	private async loadBcubeWalletBalance(wallet: string): Promise<void> {
		await this.bcubeContractService.loadBcubeWalletBalance(wallet);
	}

	// TODO: check if its possible to refactor with using wagmi event watchers
	public async authenticateBySigning(): Promise<void> {
		this.closeMenu();
		if (this.stakingWallet) {
			const stakingWarnMessage = STAKING_WARN_MESSAGES.WALLET_SWITCH_CONFIRMATION.message
				.replace('{ethAddress}', this.formatEthWallet(this.stakingWallet.toUpperCase()));
			const shouldProceed = await this.dialogService.confirm(stakingWarnMessage, STAKING_WARN_MESSAGES.WALLET_SWITCH_CONFIRMATION.title);
			if (!shouldProceed) {
				return;
			}
		}
		try {
			const signature: string = await this.ethereumService.signMessage(this.user.email);
			await this.stakingService.addStakigEthAddress({ message: this.user.email, signature: signature });

			this.toastrService.success(SignatureStatus.SUCCESS);

			this.stakingWallet$ = this.getStakingWallet();
			this.stakingService.loadStakedAmount();
			this.stakingService.loadStakingWallet();
			this.openMenu();
		} catch (error) {
			this.handleError(error, SignatureStatus.FAILED);
		}
	}

	public formatEthWallet(ethAddress: string): string {
		return `${ethAddress.substring(0, 6)}...${ethAddress.substring(ethAddress.length - 4)}`;
	}

	private handleError(error: any, errorMessage: string): void {
		if (error instanceof HttpErrorResponse && error.status === CONFLICT) {
			this.toastrService.error(STAKING_ERROR_MESSAGES.WALLET_DUPLICATION.message, errorMessage);
		} else {
			this.toastrService.error('An unexpected error occurred.', errorMessage);
		}
	}

	// TODO: check if its possible to refactor with using wagmi event watchers
	public async approveFunds(): Promise<void> {
		try {
			const { from: ethAdress }: { from: string } = await this.bcubeContractService.allowBcubeStake();
			this.isFundsApproved$.next(await this.isFundsApproved(ethAdress));
			this.toastrService.success(FundsStatus.APPROVAL_SUCCESS);
			this.openMenu();
		} catch (error) {
			this.toastrService.error(FundsStatus.APPROVAL_FAILED, ERROR_OCCURED);
		}
	}

	// TODO: check if its possible to refactor with using wagmi event watchers
	private async isFundsApproved(ethAddress: string): Promise<boolean> {
		return Boolean(await this.bcubeContractService.getTokenAllowanceAmount(ethAddress));
	}

	private getStakingWallet(): Observable<string> {
		const stakingEthAddress$ = this.stakingService.getWallet();

		return combineLatest([stakingEthAddress$, this.currentConnectedWallet$, this.shouldChangeNetwork$]).pipe(
			map(([stakingEthAddress, currentEthAddress, shouldChangeNetwork]) => {
				if (shouldChangeNetwork) {
					this.isWalletLoaded$.next(true);

					return stakingEthAddress;
				}
				this.handleStakingWalletChanges(stakingEthAddress, currentEthAddress);
				this.updateTier(stakingEthAddress);

				this.isWalletLoaded$.next(true);

				return stakingEthAddress;
			})
		);
	}

	private async handleStakingWalletChanges(stakingEthAddress: string, currentEthAddress: string): Promise<void> {
		if (currentEthAddress) {
			if (currentEthAddress.toLocaleLowerCase() === stakingEthAddress.toLocaleLowerCase()) {
				this.walletsMatchStatus$.next(true);
				this.shoudAuthenticate$.next(false);
				await this.loadBcubeWalletBalance(currentEthAddress);
				this.isFundsApproved$.next(await this.isFundsApproved(currentEthAddress));
				this.stakingWallet = currentEthAddress;
			} else {
				this.handleNewConnectedWallet(stakingEthAddress, currentEthAddress);
			}
		}
	}

	private async handleNewConnectedWallet(stakingEthAddress: string, currentEthAddress: string): Promise<void> {
		if (stakingEthAddress) {
			this.walletSelectionWarning = STAKING_WARN_MESSAGES.WALLET_SELECTION_CHANGED.message
				.replace('{authenticatedAddress}', this.formatEthWallet(stakingEthAddress.toUpperCase()))
				.replace('{connectedAddress}', this.formatEthWallet(currentEthAddress.toUpperCase()));
			this.walletsMatchStatus$.next(false);
		}
		await this.loadBcubeWalletBalance(currentEthAddress);
		this.shoudAuthenticate$.next(true);
		this.handleMenuStatus(true);
	}

	private getStakedBcubeBalance(ethAddress: string): Observable<string> {
		return from(this.bcubeContractService.getStakedBCubeBalance(ethAddress));
	}

	private updateTier(stakingEthAddress: string): void {
		this.bcubeBalanceSubscription = this.getStakedBcubeBalance(stakingEthAddress).subscribe((amount) => {
			this.updateTierIfChanged(amount);
		});
	}

	private findTierById(tierId: string): Tier | undefined {
		return this.tiers?.find(tier => tier.id === tierId);
	}

	private updateTierIfChanged(stakedBcubeBalance: string): void {
		if (!this.user) {
			return;
		}

		const newTierName = this.getUserTier(stakedBcubeBalance);
		if (newTierName.toLowerCase() === this.user.tier.name.toLowerCase()) {
			return;
		}

		const newTier = this.findTierById(newTierName);
		this.userService.updateTier(newTier);
	}

	private getUserTier(amount: string): string {
		if (amount === null) return TierName.EARTH;

		const amountBigNumber = new BigNumber(amount);

		const foundTier = this.tiers.find((tier, index, array) => {
			const nextTier = array[index + 1];
			const tierMinStakedTokensBigNumber = new BigNumber(tier.minStakedTokens);

			const nextTierMinStakedTokensBigNumber = nextTier ? new BigNumber(nextTier.minStakedTokens) : null;

			return amountBigNumber.gte(tierMinStakedTokensBigNumber) &&
				(!nextTier || amountBigNumber.lt(nextTierMinStakedTokensBigNumber));
		});

		return foundTier ? foundTier.id : TierName.JUPITER;
	}
}
