import { Component, Input } from '@angular/core';
import { IPerformance } from '@b-cube/interfaces/performance';

@Component({
	selector: 'app-widget-marketplace-bar-chart',
	templateUrl: './widget-marketplace-bar-chart.component.html'
})
export class WidgetMarketplaceBarChartComponent {
	@Input() performance: IPerformance[];
	@Input() loading: boolean;
}
