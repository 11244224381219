<svg
  width="13"
  height="12"
  viewBox="0 0 13 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    d="M3.78601 5.63746C3.76044 6.08174 4.10244 6.46209 4.54672 6.48766C4.5627 6.48766 4.57868 6.48766 4.59467 6.48766C5.04214 6.48766 5.40332 6.12649 5.40012 5.67901C5.40012 5.23154 5.03894 4.87036 4.59147 4.87356C4.16317 4.87356 3.80839 5.20916 3.78601 5.63746Z"
    fill="currentColor"
  />
  <path
    d="M0.775146 5.42977C0.631314 5.42018 0.506661 5.53205 0.500268 5.67588C0.493876 5.81972 0.602548 5.94437 0.74638 5.95076C0.890211 5.96035 1.01167 5.84848 1.02126 5.70465C1.03085 5.56082 0.918977 5.43936 0.775146 5.42977Z"
    fill="currentColor"
  />
  <path
    d="M3.73507 0.952181C3.86292 0.888256 3.91406 0.728444 3.85013 0.600593C3.78621 0.472743 3.62639 0.421603 3.49854 0.485528C3.37069 0.549453 3.31955 0.70607 3.38348 0.83392C3.4474 0.964966 3.60402 1.0193 3.73507 0.952181C3.73507 0.952181 3.73187 0.952181 3.73507 0.952181Z"
    fill="currentColor"
  />
  <path
    d="M4.55291 2.38082C4.75107 2.28174 4.83098 2.03882 4.7319 1.84066C4.63281 1.64249 4.3899 1.56258 4.19173 1.66167C3.99356 1.76075 3.91366 2.00367 4.01274 2.20183C4.11182 2.4 4.35474 2.47991 4.55291 2.38082Z"
    fill="currentColor"
  />
  <path
    d="M1.79796 3.36179C1.95138 3.46087 2.15594 3.41932 2.25822 3.2659C2.3573 3.11248 2.31575 2.90792 2.16233 2.80564C2.00891 2.70656 1.80435 2.74811 1.70207 2.90153C1.59979 3.05495 1.64454 3.26271 1.79796 3.36179Z"
    fill="currentColor"
  />
  <path
    d="M2.28711 5.28632C2.06337 5.27354 1.87479 5.44294 1.86201 5.66668C1.84922 5.89041 2.01862 6.07899 2.24236 6.09178C2.4661 6.10456 2.65468 5.93516 2.66746 5.71142C2.68025 5.49088 2.51085 5.29911 2.28711 5.28632Z"
    fill="currentColor"
  />
  <path
    d="M1.84291 7.99324C1.6799 8.07635 1.61278 8.27451 1.69588 8.44072C1.77898 8.60373 1.97715 8.67085 2.14336 8.58775C2.30637 8.50464 2.37349 8.30648 2.29038 8.14027C2.20728 7.97726 2.00592 7.91014 1.84291 7.99324Z"
    fill="currentColor"
  />
  <path
    d="M3.40871 4.45164C3.62925 4.59547 3.92331 4.53474 4.06394 4.3142C4.20778 4.09366 4.14705 3.7996 3.92651 3.65897C3.70596 3.51514 3.41191 3.57587 3.27127 3.79641C3.12744 4.01375 3.18817 4.30781 3.40871 4.45164Z"
    fill="currentColor"
  />
  <path
    d="M8.37898 2.34869C8.56436 2.47015 8.81686 2.41901 8.93832 2.23362C9.05978 2.04824 9.00864 1.79574 8.82326 1.67428C8.63787 1.55282 8.38537 1.60396 8.26391 1.78934C8.13926 1.97792 8.1904 2.22723 8.37898 2.34869Z"
    fill="currentColor"
  />
  <path
    d="M9.20383 0.926262C9.32529 1.00617 9.4851 0.97101 9.56501 0.849552C9.64491 0.728095 9.60975 0.568282 9.4883 0.488376C9.36684 0.408469 9.20703 0.443628 9.12712 0.56189C9.05041 0.683347 9.08557 0.846356 9.20383 0.926262Z"
    fill="currentColor"
  />
  <path
    d="M8.43993 4.86756C7.99565 4.84199 7.6121 5.18399 7.58972 5.62826C7.56415 6.07254 7.90615 6.45609 8.35043 6.47847C8.36641 6.47847 8.3824 6.47847 8.39518 6.47847C8.83946 6.47847 9.20064 6.11729 9.20064 5.66982C9.20383 5.24471 8.86823 4.88993 8.43993 4.86756Z"
    fill="currentColor"
  />
  <path
    d="M4.81848 4.39446C4.95592 4.66614 5.23399 4.83874 5.54083 4.83874C5.98511 4.83874 6.34629 4.47757 6.34629 4.03009C6.34629 3.90544 6.31752 3.78078 6.25999 3.66572C6.05863 3.26619 5.5728 3.10637 5.17646 3.30774C4.77693 3.5123 4.61712 3.99813 4.81848 4.39446Z"
    fill="currentColor"
  />
  <path
    d="M11.1437 3.36207C11.3067 3.27897 11.3706 3.07761 11.2875 2.9146C11.2044 2.75159 11.003 2.68767 10.84 2.77077C10.677 2.85387 10.6131 3.05204 10.693 3.21505C10.7793 3.37806 10.9775 3.44518 11.1437 3.36207Z"
    fill="currentColor"
  />
  <path
    d="M9.09811 3.61769C8.86478 3.73595 8.76889 4.02041 8.88716 4.25374C9.00542 4.48707 9.28988 4.58295 9.52321 4.46469C9.75654 4.34643 9.85242 4.06197 9.73416 3.82864C9.6159 3.59531 9.33143 3.50262 9.09811 3.61769Z"
    fill="currentColor"
  />
  <path
    d="M6.46116 0.804942C6.64334 0.814531 6.79996 0.673895 6.81274 0.491709C6.82553 0.309522 6.6817 0.152906 6.49951 0.140121C6.31732 0.130532 6.16071 0.267971 6.14792 0.450158C6.13833 0.63554 6.27897 0.792157 6.46116 0.804942Z"
    fill="currentColor"
  />
  <path
    d="M6.45831 2.89229C6.72041 2.90827 6.94415 2.70691 6.95693 2.44482C6.97291 2.18272 6.77155 1.95899 6.50945 1.9462C6.24736 1.93022 6.02362 2.13158 6.01084 2.39368C5.99805 2.65577 6.19622 2.87951 6.45831 2.89229Z"
    fill="currentColor"
  />
  <path
    d="M3.88844 7.73758C4.12177 7.61932 4.21766 7.33485 4.0994 7.10153C3.98114 6.8682 3.69667 6.77231 3.46334 6.89057C3.23002 7.00883 3.13413 7.2933 3.25239 7.52663C3.37065 7.75995 3.65512 7.85584 3.88844 7.73758Z"
    fill="currentColor"
  />
  <path
    d="M6.7649 3.58556C6.52199 3.95953 6.62427 4.45814 6.99823 4.70106C7.37219 4.94397 7.87081 4.84169 8.11372 4.46773C8.35664 4.09377 8.25436 3.59515 7.8804 3.35224C7.74935 3.26594 7.59593 3.22119 7.43931 3.22119C7.16763 3.22119 6.91513 3.35863 6.7649 3.58556Z"
    fill="currentColor"
  />
  <path
    d="M8.16819 6.96113C7.96683 6.5616 7.481 6.40178 7.08466 6.60315C6.68513 6.80451 6.52532 7.29034 6.72668 7.68668C6.92805 8.08621 7.41388 8.24602 7.81021 8.04466C8.20655 7.84649 8.36956 7.36705 8.17139 6.96752C8.17139 6.96432 8.17139 6.96432 8.16819 6.96113Z"
    fill="currentColor"
  />
  <path
    d="M9.57738 6.9036C9.35684 6.75977 9.06279 6.8205 8.92215 7.04104C8.77832 7.26158 8.83905 7.55563 9.05959 7.69627C9.28013 7.8401 9.57419 7.77937 9.71482 7.55883C9.85866 7.34148 9.79793 7.04743 9.57738 6.9036Z"
    fill="currentColor"
  />
  <path
    d="M11.1245 5.69189C11.1373 5.46816 10.9679 5.27958 10.7441 5.26679C10.5204 5.25401 10.3318 5.42341 10.319 5.64715C10.3063 5.87088 10.4757 6.05946 10.6994 6.07225C10.9199 6.08503 11.1117 5.91243 11.1245 5.69189Z"
    fill="currentColor"
  />
  <path
    d="M12.24 5.40438C12.0962 5.39479 11.9715 5.50666 11.9651 5.65049C11.9587 5.79432 12.0674 5.91898 12.2112 5.92537C12.3551 5.93496 12.4765 5.82309 12.4861 5.67926C12.4925 5.53543 12.3838 5.41397 12.24 5.40438Z"
    fill="currentColor"
  />
  <path
    d="M11.1888 7.99314C11.0353 7.89406 10.8308 7.93561 10.7285 8.08903C10.6294 8.24245 10.671 8.44701 10.8244 8.54929C10.9778 8.64837 11.1824 8.60682 11.2846 8.4534C11.3869 8.29998 11.3422 8.09222 11.1888 7.99314Z"
    fill="currentColor"
  />
  <path
    d="M3.77973 10.4288C3.65827 10.3489 3.49846 10.3841 3.41855 10.5023C3.33865 10.6238 3.3738 10.7836 3.49207 10.8635C3.61352 10.9434 3.77334 10.9082 3.85324 10.79C3.93634 10.6717 3.90119 10.5087 3.77973 10.4288Z"
    fill="currentColor"
  />
  <path
    d="M9.25147 10.4034C9.12362 10.4673 9.07248 10.6271 9.13641 10.7549C9.20033 10.8828 9.36014 10.9339 9.488 10.87C9.61585 10.8061 9.66699 10.6495 9.60306 10.5216C9.53914 10.3906 9.38252 10.3362 9.25147 10.4034C9.25147 10.4034 9.25467 10.4034 9.25147 10.4034Z"
    fill="currentColor"
  />
  <path
    d="M6.22136 7.76933C6.46427 7.39537 6.36199 6.89676 5.98803 6.65065C5.61407 6.40773 5.11545 6.51001 4.86934 6.88397C4.62323 7.25793 4.72871 7.75655 5.10267 8.00266C5.23371 8.08896 5.38713 8.13371 5.54375 8.13371C5.81863 8.1369 6.07113 7.99946 6.22136 7.76933Z"
    fill="currentColor"
  />
  <path
    d="M4.60765 9.00631C4.42226 8.88485 4.16976 8.93599 4.0483 9.12137C3.92684 9.30676 3.97798 9.55926 4.16337 9.68072C4.34875 9.80218 4.60125 9.75104 4.72271 9.56565C4.84736 9.38027 4.79622 9.12777 4.60765 9.00631Z"
    fill="currentColor"
  />
  <path
    d="M6.49007 10.5503C6.30789 10.5407 6.15127 10.6813 6.13849 10.8635C6.1257 11.0457 6.26953 11.2023 6.45172 11.2151C6.63391 11.2247 6.79052 11.0872 6.80331 10.9051C6.8129 10.7197 6.67226 10.5631 6.49007 10.5503Z"
    fill="currentColor"
  />
  <path
    d="M6.49316 8.46329C6.23106 8.44731 6.00733 8.64867 5.99454 8.91077C5.97856 9.17286 6.17992 9.3966 6.44202 9.40938C6.70411 9.42217 6.92785 9.224 6.94063 8.96191C6.95661 8.70301 6.75525 8.47608 6.49316 8.46329Z"
    fill="currentColor"
  />
  <path
    d="M8.43328 8.97459C8.23511 9.07687 8.15521 9.31979 8.25749 9.51796C8.35977 9.71612 8.60268 9.79603 8.80085 9.69375C8.99902 9.59467 9.07892 9.35175 8.97984 9.15358C8.87756 8.95542 8.63464 8.87231 8.43328 8.97459Z"
    fill="currentColor"
  />
</svg>
