export enum UserBotErrorCodes {
	BOT_PENDING_TRANSACTION = 'BOT_PENDING_TRANSACTION',
	BOT_ALREADY_ACTIVE = 'BOT_ALREADY_ACTIVE',
	BOT_ADDITION_FAILED = 'BOT_ADDITION_FAILED',
	BOT_TOPUP_PENDING_TRANSACTION = 'BOT_TOPUP_PENDING_TRANSACTION',
}

export enum TransactionErrorCodes {
	TRANSACTION_FAILED = 'TRANSACTION_FAILED',
	TRANSACTION_INSUFFICIENT_BALANCE = 'TRANSACTION_INSUFFICIENT_BALANCE',
	TRANSACTION_CREATION_FAILED = 'TRANSACTION_CREATION_FAILED',
	TRANSACTION_UNEXPECTED_ERROR = 'TRANSACTION_UNEXPECTED_ERROR',
}

export enum PlanSubscriptionErrorCodes {
	PLAN_SUBSCRIPTION_PENDING_TRANSACTION = 'PLAN_SUBSCRIPTION_PENDING_TRANSACTION',
	PLAN_TOPUP_PENDING_TRANSACTION = 'PLAN_TOPUP_PENDING_TRANSACTION'
}
