<form [formGroup]="performanceChartForm">
	<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700
		rounded-md p-6 md:min-h-[430px]">
		<div class="flex flex-col lg:flex-row justify-between">
			<div class="mb-5 lg:mb-0">
				<div class="flex items-center mb-5">
					<span class="font-sans text-xl font-semibold text-muted-600 dark:text-muted-200">
						Historical performance
					</span>
				</div>
				<div class="flex flex-row gap-4 pt-2 text-white" *ngIf="!isMetaBot() || isLive()">
					<div class="relative w-[70px]" *ngIf="bot?.market === 'FUTURE'">
						<div class="relative group">
							<label class="text-xs">Leverage</label>
							<select title="select" formControlName="leverage"
								class="appearance-none h-8 px-3 font-sans rounded-md w-full text-xs font-semibold text-brand
									bg-muted-100 hover:bg-muted-200 dark:bg-muted-700 dark:hover:bg-muted-600 placeholder-gray-300 focus:border-muted-300">
								<option *ngFor="let leverage of possibleLeverages" [value]="leverage">{{leverage}}x</option>
							</select>
						</div>
					</div>
					<div class="relative w-[80px]">
						<div class="relative group">
							<label class="text-xs">Allocation</label>
							<select title="select" formControlName="allocation"
								class="appearance-none h-8 px-3 font-sans rounded-md w-full text-xs font-semibold text-brand
									bg-muted-100 hover:bg-muted-200 dark:bg-muted-700 dark:hover:bg-muted-600 placeholder-gray-300 focus:border-muted-300">
								<option *ngFor="let allocation of possibleAllocations" [value]="allocation">{{allocation}}%</option>
							</select>
						</div>
					</div>
					<div class="relative min-w-[150px]">
						<div class="relative group">
							<label class="text-xs">Fees</label>
							<label class="relative inline-flex items-center cursor-pointer w-full my-1">
								<input type="checkbox" class="sr-only peer" checked formControlName="feesEnabled">
								<div class="w-11 h-6 bg-gray-200 rounded-full peer dark:peer-focus:ring-brand
									dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute
									after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5
									after:transition-all dark:border-gray-600 peer-checked:bg-brand"></div>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="flex gap-2 flex-wrap justify-end mb-10" >
					
					<button 
					(click)="selectPerformanceType('live')"
					class="lg:max-w-[100px] font-sans text-xs font-semibold flex-1 text-muted-500
					h-10 px-3 bg-muted-100 hover:bg-muted-200 rounded-md dark:bg-muted-700
					dark:hover:bg-muted-600 dark:text-muted-200 transition-colors duration-300"
						[ngClass]="{
							'bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30': isPerformanceTypeSelected('live'),
							'text-brand bg-muted-100 hover:bg-muted-200 dark:bg-muted-700 dark:hover:bg-muted-600': !isPerformanceTypeSelected('live')
						}"
						>
						Live Data
					</button>
					<button
					(click)="selectPerformanceType('backtest')"
					 class="lg:max-w-[100px] font-sans text-xs font-semibold flex-1 text-muted-500
						h-10 px-3 bg-muted-100 hover:bg-muted-200 rounded-md dark:bg-muted-700
						dark:hover:bg-muted-600 dark:text-muted-200 transition-colors duration-300"
						[ngClass]="{
							'bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30': isPerformanceTypeSelected('backtest'),
							'text-brand bg-muted-100 hover:bg-muted-200 dark:bg-muted-700 dark:hover:bg-muted-600': !isPerformanceTypeSelected('backtest')
						}"
						>
						Backtested
					</button>
				</div>
				<div class="flex gap-2 flex-wrap justify-end h-8" [style.visibility]="performancePeriods.length > 1 ? 'visible' : 'hidden'">
					<button *ngFor="let period of performancePeriods" (click)="selectPeriod(period)"
						class="font-sans text-xs font-semibold flex-1 h-8 px-3 rounded-md  transition-colors duration-300"
						[ngClass]="{
							'bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30': isPeriodSelected(period),
							'text-brand bg-muted-100 hover:bg-muted-200 dark:bg-muted-700 dark:hover:bg-muted-600': !isPeriodSelected(period)
						}">
						{{period.label}}
					</button>
				</div>
			</div>
		</div>

		<div class="mt-6 w-full">
			<!--app-fake-chart-bot class="w-full"></app-fake-chart-bot-->
			<app-chart-line [chartData]="performance" [perfDisplayMode]="getDisplayMode()" [loading]="loading" [isLivePerfMode]="isLive()" [isMetaBot]="isMetaBot()"></app-chart-line>
		</div>
		<!--Help text-->
		<div class="p-2 flex flex-row gap-x-4 items-center">
			<app-icon name="alert" class="w-8 h-8 text-brand"></app-icon>
			<span class="font-sans text-muted-500 dark:text-muted-400 text-2xs">
				<span>Compound performance based on {{isLive() ? 'live trade data' : 'backtest results'}},
				with {{allocation}}% of the capital invested<span *ngIf="bot?.market === 'FUTURE'"> and {{leverage}}x leverage</span>. </span>
				<span *ngIf="feesEnabled">Including trading fees ({{getFeeRate('maker')}}% for makers, {{getFeeRate('taker')}}% for takers)<span *ngIf="bot?.market === 'FUTURE'"><span *ngIf="! isBabaBot(); else baba"> and </span><ng-template #baba>, </ng-template>funding fees ({{FUNDING_RATE}}% / 8 hours)</span><span *ngIf="isBabaBot()"> and slippage ({{SLEEPAGE_RATE}}%)</span>. </span>
				<span>Historical performance does not guarantee future results.</span>
			</span>
		</div>
	</div>
</form>
