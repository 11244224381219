<svg
  data-name="Hexagon"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 145.37 145.37"
  [class]="class"
>
  <path *ngIf="initialized"
    class="transition-all ease-in-out duration-[2000ms]"
    fill="none"
    stroke="currentColor"
    stroke-miterlimit="10"
    [attr.stroke-dasharray]="450"
    [attr.stroke-dashoffset]="offset"
    stroke-width="6px"
    stroke-linecap="round"
    d="M135.63,96V49.38a22.58,22.58,0,0,0-11.3-19.55L84,6.53a22.57,22.57,0,0,0-22.59,0L21,29.83A22.58,22.58,0,0,0,9.74,49.38V96A22.58,22.58,0,0,0,21,115.54l40.35,23.3a22.57,22.57,0,0,0,22.59,0l40.35-23.3A22.58,22.58,0,0,0,135.63,96Z"
  />
</svg>
