<div  class="flex justify-center ml-5" *ngIf="(bot ?? null) !== null">
	<span *ngFor="let crypto of getCryptoList()" [tooltip]="getPairsList()" placement="top"
		class="relative flex justify-center items-center rounded-full border-4 border-white
			dark:border-muted-800 z-10 -ml-5" [class]="'w-' + (iconSize+2) + ' h-' + (iconSize+2)">
		<app-cryptocurrency-icon [name]="crypto" [class]="'w-' + iconSize + ' h-' + iconSize">
		</app-cryptocurrency-icon>
	</span>
	<span *ngIf="shouldDisplayPlus()" [tooltip]="getPairsList()" placement="top" class="relative flex justify-center items-center rounded-full border-4 border-white
			dark:border-muted-800 z-10 -ml-5" [class]="'w-' + (iconSize+2) + ' h-' + (iconSize+2)">
			<div [class]="'w-' + iconSize + ' h-' + iconSize" class="inline-flex items-center justify-center text-base pt-0.5 pl-0.5 text-muted-500 dark:text-white bg-muted-200 dark:bg-muted-700 rounded-full ng-tns-c192-1">+{{getMissingBotsNumber()}} </div>
	</span>
	<span [tooltip]="bot.pairing" placement="top" class="relative flex justify-center items-center rounded-full border-none
			dark:border-muted-800 -ml-4 brightness-50" [class]="'w-' + (iconSize+2) + ' h-' + (iconSize+2)">
		<app-cryptocurrency-icon [name]="bot.pairing" [class]="'w-' + iconSize + ' h-' + iconSize">
		</app-cryptocurrency-icon>
	</span>
</div>