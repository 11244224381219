<div *ngIf="showScreenLoader" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
	<div @opacity class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

	<div class="fixed inset-0  overflow-y-auto">
		<div class="modal z-50 fixed top-0 left-0 flex items-center justify-center w-full h-full bg-muted-800/70  px-4 z-50">
			<div @opacityTranslateY
				class="relative transform overflow-hidden text-left transition-all sm:my-8 w-full sm:max-w-lg sm:p-6  w-full md:max-w-xl mx-auto text-left rounded">

				<div class="flex space-between">
					<div class="flex items-center justify-center mr-4">
						<span class="circle-loader"></span>
					</div>
					<div>
						<p class="font-sans text-s text-muted-400">Your transaction is being registered
							on the Ethereum blockchain.
							The process can take up to several minutes in some cases.
							Please do not close this browser window or refresh the page until the process is completed.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
