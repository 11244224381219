import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-docs',
  templateUrl: './icon-docs.component.html'
})
export class IconDocsComponent {
  @Input() class = '';

}
