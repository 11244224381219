import { Component, Input } from '@angular/core';
import { BotRiskLabel, IBot } from '@b-cube/interfaces/bot';

@Component({
	selector: 'app-widget-marketplace-link',
	templateUrl: './widget-marketplace-link.component.html'
})
export class WidgetMarketplaceLinkComponent {
	@Input() bot: IBot;
	@Input() isCompanyProvider: boolean;
	@Input() publisherName: string;

	public BotRiskLabel = BotRiskLabel;
}
