import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-lightbulb',
  templateUrl: './icon-lightbulb.component.html'
})
export class IconLightbulbComponent {
  @Input() class = ''

}
