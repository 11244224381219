import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { TierDifference } from '@app/shared/types/tier-difference';
import { Tier } from '@b-cube/interfaces/staking';
import { UserInfo } from '@b-cube/interfaces/user';
import { StakingService } from '@core/services/staking.service';
import { Options } from 'ngx-slider-v2';
import { Observable, Subject, Subscription } from 'rxjs';

interface UnstakeEvent {
  eligibleTier: Tier;
  unstakeValue: number;
}

@Component({
	selector: 'app-unstake-confirmation-modal',
	templateUrl: './unstake-confirmation-modal.component.html',
	styleUrls: ['./unstake-confirmation-modal.component.css'],
	animations: [
		trigger('opacity', [
			transition(':enter', [style({ opacity: 0 }), animate('300ms ease-out', style({ opacity: 1 }))]),
			transition(':leave', [style({ opacity: 1 }), animate('200ms ease-in', style({ opacity: 0 }))]),
		]),
		trigger('opacityTranslateY', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(1rem)' }),
				animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateY(0)' }),
				animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' })),
			]),
		]),
	],
})
export class UnstakeConformationModalComponent implements OnInit, OnDestroy {
	@Input() showModal = false;
	@Output() unstakeBcube: EventEmitter<UnstakeEvent> = new EventEmitter<UnstakeEvent>();
	@Input() maxUnstakeAmount: number;

	user$: Observable<UserInfo>;
	
	tierDifference: TierDifference;
	floor = 0;
	unstakeModalStep = 1;
	unstakeValue: number;
	unstakeInputValue: number;
	unstakeSliderValue: number;
	sliderOptions: Options;
	tiers: Tier[];
	eligibleTier: Tier;
	currentTier: Tier;

	private unstakeValueInputSubject: Subject<number> = new Subject<number>();
  private unstakeValueInputSubscription: Subscription;
	
	constructor(private stakingService: StakingService, private userService: UserService, private cdr: ChangeDetectorRef) {}
	
	ngOnInit() {
		this.sliderOptions = {
			floor: this.floor,
			ceil: 5000,
			step: 1,
			showSelectionBar: true,
			hideLimitLabels: true,
			hidePointerLabels: true,
		};
		
		this.processUnstakeInputValue(this.maxUnstakeAmount / 2);

		this.stakingService.getTiers().subscribe((tiers) => {
			this.tiers = tiers;
		});
		
		this.user$ = this.userService.currentUser;
		this.user$.subscribe((user)=> {
			if (user) {
				this.currentTier = user.tier
				this.calculateEligibleTier();
			}
		})

		this.unstakeValueInputSubscription = this.unstakeValueInputSubject
		.subscribe((value) => {
			this.processUnstakeInputValue(value)
			this.calculateEligibleTier();
		});
	}

	ngOnDestroy(): void {
    if (this.unstakeValueInputSubscription) {
      this.unstakeValueInputSubscription.unsubscribe();
    }
  }

	onUnstakeValueInputChange(value: number): void {
    if (!isNaN(value)) {
      this.unstakeValueInputSubject.next(value);
    }

		if (value < this.floor || value === null || isNaN(value)) {
      this.unstakeSliderValue = 0;
      this.unstakeValue = 0;
      this.unstakeInputValue = 0;
    } else if (value > this.maxUnstakeAmount) {
      this.unstakeSliderValue = this.sliderOptions.ceil;
      this.unstakeValue = this.maxUnstakeAmount;
      this.unstakeInputValue = this.maxUnstakeAmount;
    }
  }

  processUnstakeInputValue (value: number): void {
		this.unstakeSliderValue = Math.round((value / this.maxUnstakeAmount) * this.sliderOptions.ceil);
    this.unstakeInputValue = value;
    this.unstakeValue = value;
    this.cdr.detectChanges();
  }
	
	handleSliderValueChange() {
		this.unstakeValue = (this.unstakeSliderValue/this.sliderOptions.ceil) * this.maxUnstakeAmount
		this.unstakeInputValue = this.unstakeValue
		this.calculateEligibleTier()
	}
	
	calculateEligibleTier(): void {
		const tokens = this.maxUnstakeAmount - this.unstakeValue;
		this.eligibleTier = this.getTier(tokens);
		this.tierDifference = this.computeDifference()
	}

	getTier(tokens: number): Tier {
		for (let i = this.tiers.length - 1; i >= 0; i--) {
			if (tokens >= Number(this.tiers[i].minStakedTokens)) {
				return this.tiers[i];
			}
		}

		return this.tiers[0];
	}

	updateStep(value: number): void {
		this.unstakeModalStep += value;
	}

	toggleModal() {
		this.showModal = !this.showModal;
	}

	closeModal(): void {
		this.showModal = false;
	}

	executeUnstake(eligibleTier:Tier, unstakeValue:number) {
		this.unstakeBcube.emit({ eligibleTier, unstakeValue });
		this.toggleModal();
		this.unstakeModalStep = 1;
	}

	private computeDifference() {
		if (!this.currentTier || !this.eligibleTier) return null;

		return <TierDifference>{
			stakedTokens:  this.eligibleTier.minStakedTokens - this.currentTier.minStakedTokens,
			planDiscountForCC:  this.eligibleTier.botDiscountForCC - this.currentTier.botDiscountForCC,
			planDiscountForBCUBE:  this.eligibleTier.planDiscountForBCUBE - this.currentTier.planDiscountForBCUBE,
			botDiscountForCC:  this.eligibleTier.botDiscountForCC - this.currentTier.botDiscountForCC,
			botDiscountForBCUBE:  this.eligibleTier.botDiscountForBCUBE - this.currentTier.botDiscountForBCUBE,
		};
	}
}
