<div
  class="relative h-full flex flex-col bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md">
  <!--Header-->
  <div class="flex items-center justify-between p-6 border-b border-muted-200 dark:border-muted-700">
    <h3 class="font-sans font-semibold text-muted-600 dark:text-muted-100">
      My agent settings
    </h3>
    <a class="font-sans text-xs font-semibold text-muted-400 hover:text-muted-300 transition-colors duration-300 cursor-pointer"
      (click)="openSettings()" [ngStyle]="{'pointer-events': noSettingsForThisBot ? 'none' : '' }">
      <app-icon-gear class="w-4 h-4"></app-icon-gear>
    </a>
  </div>
  <div *ngIf="noSettingsForThisBot"
    class="flex items-center justify-between px-6 py-4 border-b border-muted-200 dark:border-muted-700">
    <h4 class="font-sans text-xs font-semibold ">No settings for this agent</h4>
  </div>
  <!--Item-->
  <div *ngFor="let botSettings of botSettingsWrapper?.botSettings"
    class="flex items-center justify-between px-6 py-4 border-b border-muted-200 dark:border-muted-700">
    <h4 class="font-sans text-xs font-semibold ">{{botSettingsDisplay[botSettings.type]?.label}}</h4>
    <span class="font-sans text-xs font-semibold rounded-lg">
      {{botSettings.value}}{{botSettingsDisplay[botSettings.type]?.scale}}
    </span>
  </div>

  <!--Modal-->
  <app-widget-bot-settings-modal *ngIf="isBotSettingsOpen" [botSettingsWrapper]="botSettingsWrapper"
    (close)="closeBotSettings($event)" [bot]="bot">
  </app-widget-bot-settings-modal>
</div>
