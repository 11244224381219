import { Injectable } from '@angular/core';
import { StakingAbi, TokenAbi } from '@b-cube/interfaces/abi';
import { environment } from '@env/environment';
import { TransactionReceipt,TransactionResponse } from '@ethersproject/abstract-provider';
import { Networkish, Web3Provider } from '@ethersproject/providers';
import { watchContractEvent } from '@wagmi/core';
import { BigNumber as BigNum } from 'bignumber.js';
import { BigNumber, Contract, ethers, Signer } from 'ethers';
import { BehaviorSubject } from 'rxjs';

import { allowStakingValue, unitName } from '../constants/staking.constants';


@Injectable({
	providedIn: 'root'
})
export class BcubeContractService {

	private stakingContractAddress: string = environment.stakingContract;
	private tokenContractAddress: string = environment.tokenContract;
	private paymentWallet: string = environment.paymentWallet;
	private network: string = environment.networkName;
	private chainId: number = environment.chainId;
	private networkSettings: Networkish;
	private provider: Web3Provider;
	private signer: Signer;
	private stakingContract: Contract;
	private tokenContract: Contract;

	public showTxLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public stakedAmount$: BehaviorSubject<string> = new BehaviorSubject<string>('0');
	public ownedAmount$: BehaviorSubject<string> = new BehaviorSubject<string>('0');

	constructor() {
		if (window.ethereum) {
			this.networkSettings = {
				name: this.network,
				chainId: this.chainId
			};
			this.provider = new ethers.providers.Web3Provider(<any>window.ethereum, this.networkSettings);
			this.signer = this.provider.getSigner();
			this.stakingContract = new ethers.Contract(this.stakingContractAddress, StakingAbi, this.signer);
			this.tokenContract = new ethers.Contract(this.tokenContractAddress, TokenAbi, this.signer);
		}
	}

	public async getStakedBCubeBalance(walletAddress: string): Promise<string> {
		try {
			const balance = await this.stakingContract['bcubeStakeRegistry'](walletAddress);
			const formatedBalance = this.formatBalance(balance);

			this.stakedAmount$.next(formatedBalance);
	
			return formatedBalance;
		} catch (error) {
			return '0';
		}

	};

	public async getOwnedBCubeBalance(walletAddress: string): Promise<string> {
		try {
			const balance = await this.tokenContract['balanceOf'](walletAddress);
			const formatedBalance = this.formatBalance(balance);

			this.ownedAmount$.next(formatedBalance);

			return formatedBalance;
		} catch (error) {
			return '0';
		}

	}

	public async allowBcubeStake(): Promise<TransactionReceipt> {
		const maxAmount = this.parseUnits(allowStakingValue);
		const tx = await this.tokenContract['approve'](this.stakingContractAddress, maxAmount);
		this.showLoader();
		const result = await tx.wait(1);
		this.closeLoader();

		return result;
	}

	public async getTokenAllowanceAmount(wallet: string): Promise<number> {
		const [val,] = await this.tokenContract.functions['allowance'](wallet, this.stakingContractAddress);

		if (val.gt(0)) {
			return Number(this.formatUnits(val));
		}

		return 0;
	}

	public async stakeBcube(amount: string): Promise<TransactionReceipt> {
		const amountInWei = this.parseUnits(amount);
		const tx = await this.stakingContract['stake'](amountInWei);
		this.showLoader();
		const res = await tx.wait(1);
		this.closeLoader();

		return res;
	}

	public async unstakeBcube(amount: string): Promise<TransactionReceipt> {
		const amountInWei = this.parseUnits(amount);
		const tx = await this.stakingContract['unstake'](amountInWei);
		this.showLoader();
		const res = await tx.wait(1);
		this.closeLoader();

		return res;
	}

	public async sendBCubeToPaymentWallet(amount: number): Promise<TransactionResponse> {
		const amountInWei = this.parseUnits(amount.toString());
		const tx = await this.tokenContract['transfer'](this.paymentWallet, amountInWei);
		this.showLoader();

		return tx;
	}

	public async waitForBCubeToPaymentTransaction(tx: TransactionResponse): Promise<TransactionReceipt> {
		const res = await tx.wait(1);

		return res;
	}

	public formatBalance(balance: string): string {
		return new BigNum(this.formatUnits(balance)).toFixed(2, BigNum.ROUND_DOWN);
	}

	public closeLoader(): void {
		this.showTxLoader$.next(false);
	}

	private formatUnits(amount: string): string {
		return ethers.utils.formatUnits(amount, unitName);
	}

	private parseUnits(amount: string): BigNumber {
		return ethers.utils.parseUnits(amount, unitName);
	}

	private showLoader(): void {
		this.showTxLoader$.next(true);
	}

	public watchStakingContractEvent(eventName: string, wallet: string) {
		const unwatch = watchContractEvent(
			{
				address: this.stakingContractAddress,
				abi: StakingAbi,
				eventName: eventName,
			},
			async (from, _label, _owner) => {
				if (wallet.toLowerCase() === from.toLowerCase()) {
					this.showTxLoader$.subscribe(async (show) => {
						if (!show) {
							await this.loadBcubeWalletBalance(from);
						}
					});
				}
			}
		);

		// Return the unsubscribe function
		return unwatch;
	}

	public watchTokenContractEvent(eventName: string, wallet: string) {
		const unwatch = watchContractEvent(
			{
				address: this.tokenContractAddress,
				abi: TokenAbi,
				eventName: eventName,
			},
			async (from, _label, _owner) => {
				if (wallet.toLowerCase() === from.toLowerCase()) {
					this.showTxLoader$.subscribe(async (show) => {
						if (!show) {
							await this.loadBcubeWalletBalance(from);
						}
					});
				}
			}
		);

		// Return the unsubscribe function
		return unwatch;
	}

	public async loadBcubeWalletBalance(walletAddress: string) {
		await this.getStakedBCubeBalance(walletAddress);
		await this.getOwnedBCubeBalance(walletAddress);
	}
}
