import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, ToRelativeOptions, ToRelativeUnit } from 'luxon';

@Pipe({ name: 'timeAgo', pure: true })
export class TimeAgoPipe implements PipeTransform {

	transform(date: DateTime, unit?: ToRelativeUnit): string {

		if ( !date || !date.isValid){
			return 'n/a';
		}

		const options: ToRelativeOptions = { locale: 'en' };
		if (unit !== undefined){
			options.unit = unit;
		}

		return date.toRelative(options);
	}
}
