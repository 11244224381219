import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hex-progress-track',
  templateUrl: './hex-progress-track.component.html'
})
export class HexProgressTrackComponent {
  @Input() class = '';

}
