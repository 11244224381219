import {
	animate,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAX_RETRY_ATTEMPTS, RETRY_DELAY } from '@app/core/constants/common.constants';
import { CANT_HAVE_MULTIPLE_BOTS, ERROR_OCCURED, FORBIDDEN_ACTION } from '@app/core/constants/error-messages';
import { BAD_REQUEST, CONFLICT, FORBIDDEN } from '@app/core/constants/http-status-code.constants';
import { CONNECT_WALLET_WARNING } from '@app/core/constants/provider.constants';
import { Routes } from '@app/core/constants/router.constants';
import { TokenContractEvent } from '@app/core/constants/staking.constants';
import { BotSubscriptionStep, BotSubscriptionWizardButtonLabels, DEFAULT_PAYMENT_METHOD, PAYMENT_TEMPLATES } from '@app/core/constants/subscription-wizard';
import { TransactionErrorCodes,UserBotErrorCodes } from '@app/core/constants/subscriptions/error-codes';
import { BotSubscriptionErrorMessages, CommonErrorMessages,SubscriptionRetryErrorMessages, TransactionErrorMessages } from '@app/core/constants/subscriptions/error-messages';
import { TransactionSuccessMessages } from '@app/core/constants/subscriptions/success-messages';
import { CommonWarningMessages } from '@app/core/constants/subscriptions/warning-messages';
import { ExchangeAccount } from '@app/core/models/exchange-account';
import { BcubeContractService } from '@app/core/services/bcube-contract.service';
import { CryptoTransactionService } from '@app/core/services/crypto-transaction-service';
import { EthereumService } from '@app/core/services/ethereum/ethereum.service';
import { ExchangeAccountService } from '@app/core/services/exchange-account.service';
import { PlanService } from '@app/core/services/plan.service';
import { StakingService } from '@app/core/services/staking.service';
import { UserBotService } from '@app/core/services/user-bot.service';
import { TransactionStatus, UserBotStatus } from '@b-cube/database/types';
import { IBot } from '@b-cube/interfaces/bot';
import { IPaymentBCubeDetails, IPaymentCreditCardDetails, PAYMENT_METHOD_EVENT, PAYMENT_TYPE_TOPUP } from '@b-cube/interfaces/payment';
import { BotPlan, GURU_BOT_SPECIAL_CASE, IBotSubscription, ISubscription,SubscriptionType, TransactionType } from '@b-cube/interfaces/plan';
import { PaymentMethod, UserInfo } from '@b-cube/interfaces/user';
import { ICreateUserBot, IUserBot } from '@b-cube/interfaces/user-bot';
import { environment } from '@env/environment';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, lastValueFrom, map, mergeMap, Observable, of, retry, Subscription, tap } from 'rxjs';

declare const Chargebee: any;

@Component({
	selector: 'app-widget-marketplace-subscription-modal',
	templateUrl: './widget-marketplace-subscription-modal.component.html',
	animations: [
		trigger('insertRemoveTrigger', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('100ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	],
})
export class WidgetMarketplaceSubscriptionModalComponent implements OnInit, OnChanges {
	@Input() isOpen = false;
	@Input() bot?: IBot;
	@Input() botPlan?: BotPlan;
	@Input() isCompanyProvider: boolean;
	@Input() paymentMethod?: PAYMENT_METHOD_EVENT;
	@Input() paymentDetails?: IPaymentCreditCardDetails | IPaymentBCubeDetails
	@Input() user?: UserInfo;
	@Input() isSpecialCaseBot?: boolean;
	@Input() userBotSubscription?: IBotSubscription;
	@Output() closeModal = new EventEmitter<IUserBot>();

	public exchangeAccounts$: Observable<ExchangeAccount[]>;
	public isWalletConnected$: BehaviorSubject<boolean>;
	public addBotForm: FormGroup;
	public selectedAccountId: number;
	private isPaymentDone = false;
	public isLoading = false;
	public currentStep: number = BotSubscriptionStep.EXCHANGE_SELECTOR;
	public months = 1;
	public bcubePaymentState: boolean;
	public ccPaymentDetails: IPaymentCreditCardDetails;
	public bcubePaymentDetails: IPaymentBCubeDetails;

	public modalTitle: string;
	public modalDescription: string;

	private closePaymentModalState: boolean;

	private unwatchTokenContractEvent: any;
	private walletAddressSubscription: Subscription;
	public wallet: string;

	constructor(
		private exchangeAccountService: ExchangeAccountService,
		private toasterService: ToastrService,
		private userBotService: UserBotService,
		private planService: PlanService,
		private bcubeContractService: BcubeContractService,
		private ethereumService: EthereumService,
		private formBuilder: FormBuilder,
		private router: Router,
		private zone: NgZone,
		private stakingService: StakingService,
		private cryptoTxService: CryptoTransactionService,
	) { }

	ngOnInit(): void {
		this.addBotForm = this.formBuilder.group({
			exchangeAccountId: [0, [Validators.required]]
		});

		this.isWalletConnected$ = this.ethereumService.isWalletConnected$;

		this.exchangeAccounts$ = this.exchangeAccountService.getExchangeAccounts().pipe(
			map(accounts => {
				return accounts?.filter(account => this.bot?.supportedExchanges.includes(account.exchangeName))
			})
		);

		this.initWallet();
	}

	ngOnDestroy(): void {
		this.walletAddressSubscription?.unsubscribe();
	}

	// Handle the modal opening
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isOpen'] === undefined || this.isOpen === false) {
			return;
		}

		this.currentStep = BotSubscriptionStep.EXCHANGE_SELECTOR;
		this.selectedAccountId = undefined;
		this.isLoading = false;

		this.setModalTitleAndDescription();
		this.setPaymentDetails();
		this.setPaymentState();
	}

	private initWallet(): void {
		this.walletAddressSubscription = this.stakingService.getWallet().subscribe(async wallet => {
			this.wallet = wallet;
		});
	}

	private setModalTitleAndDescription(): void {
		const paymentTemplate = PAYMENT_TEMPLATES[this.paymentMethod] || PAYMENT_TEMPLATES[DEFAULT_PAYMENT_METHOD];
		this.modalTitle = paymentTemplate.title;
		this.modalDescription = paymentTemplate.description.replace('{botname}', this.bot?.name);
		this.currentStep = paymentTemplate.currentStep ?? this.currentStep;
	}

	private setPaymentDetails(): void {
		switch (this.paymentMethod) {
			case PaymentMethod.CREDIT_CARD:
				this.ccPaymentDetails = this.paymentDetails as IPaymentCreditCardDetails;
				break;
			case PAYMENT_TYPE_TOPUP.TOPUP:
			case PaymentMethod.BCUBE:
				this.bcubePaymentDetails = this.paymentDetails as IPaymentBCubeDetails;
				break;
			default:
				break;
		}
	}

	private setPaymentState(): void {
		this.bcubePaymentState = this.paymentMethod === PaymentMethod.BCUBE || this.paymentMethod === PAYMENT_TYPE_TOPUP.TOPUP;
		this.closePaymentModalState = this.isCompanyProvider || !this.isFinalStep() || this.bcubePaymentState;
	}

	public isSubmitDisabled(): boolean {
		switch (this.currentStep) {
			case BotSubscriptionStep.EXCHANGE_SELECTOR:
				return this.selectedAccountId === undefined;
			case BotSubscriptionStep.BOT_PAYMENT_DETAILS:
				if (this.paymentMethod !== PaymentMethod.CREDIT_CARD && !this.ethereumService.isWalletConnected$.value) {
					return true;
				}

				return false;
			default:
				break;
		}

		return true;
	}

	public dismiss() {
		if (this.closePaymentModalState) {
			this.close();

			return
		}
		this.decrementStep();
	}

	public close() {
		this.closeModal.emit(null);
	}

	public async submit() {
		if (this.paymentMethod !== PAYMENT_TYPE_TOPUP.TOPUP && !this.addBotForm.valid) {
			return;
		}

		if (this.isCompanyProvider) {
			this.addUserBot(UserBotStatus.ACTIVE);
		} else {
			if (!this.isFinalStep()) {
				this.incrementStep();

				return;
			}
			switch (this.paymentMethod) {
				case PaymentMethod.CREDIT_CARD:
					if (this.botPlan.id === GURU_BOT_SPECIAL_CASE.planId) {
						return;
					}
					this.payByCreditCard();
					break;
				case PaymentMethod.BCUBE:
					if (!this.ethereumService.isWalletConnected$.value) {
						this.toasterService.warning(CONNECT_WALLET_WARNING);
						this.isLoading = false;

						return;
					}
					await this.payWithBCUBE();
					break;
				case PAYMENT_TYPE_TOPUP.TOPUP:
					this.topUp();
					break;
				default:
					break;
			}
		}
	}

	public getAddButtonLabel(): string {
		if (this.isCompanyProvider) {
			return BotSubscriptionWizardButtonLabels.CONNECT;
		}
		if (!this.isFinalStep()) {
			return BotSubscriptionWizardButtonLabels.CONTINUE;
		}

		return BotSubscriptionWizardButtonLabels.PAY;
	}

	public getCancelButtonLabel(): string {
		if (this.closePaymentModalState) {
			return BotSubscriptionWizardButtonLabels.CANCEL;
		}

		return BotSubscriptionWizardButtonLabels.GO_BACK;
	}

	private incrementStep() {
		this.currentStep = this.currentStep + 1;
	}

	private decrementStep() {
		this.currentStep = this.currentStep - 1;
	}

	private isFinalStep(): boolean {
		// Case for company provider bots
		if (this.isCompanyProvider) {
			return true;
		}

		return this.currentStep === BotSubscriptionStep.BOT_PAYMENT_DETAILS;
	}

	private addUserBot(status: UserBotStatus) {
		this.isLoading = true;
		this.userBotService.addUserBot(<ICreateUserBot>{
			botId: this.bot.id,
			exchangeAccountId: Number(this.selectedAccountId),
			status
		}).pipe(
			catchError(err => {
				if (err.status === CONFLICT) {
					this.toasterService.error(`This agent is already added.`, ERROR_OCCURED);
				} else if (err.status === BAD_REQUEST) {
					this.toasterService.warning(CANT_HAVE_MULTIPLE_BOTS);
				} else if (err.status === FORBIDDEN) {
					this.toasterService.warning(FORBIDDEN_ACTION);
				} else {
					this.toasterService.error(`The agent '${this.bot.name}' cannot be added.`, ERROR_OCCURED);
				}

				return of(err)
			})
		).subscribe((account: IUserBot) => {
			this.isLoading = false;
			if (account instanceof HttpErrorResponse) {
				return;
			}

			if (account === null) {
				this.toasterService.error(`The agent '${this.bot.name}' cannot be plugged.`, ERROR_OCCURED)

				return;
			}

			this.userBotService.addUserBotLocally(account);
			this.closeModal.emit(account);
		});
	}

	private async createPendingTransactionBot(status: UserBotStatus): Promise<IUserBot> {
		const userBotCreationParams: ICreateUserBot = {
			botId: this.bot.id,
			exchangeAccountId: Number(this.selectedAccountId),
			status
		};

		return await lastValueFrom(
			this.userBotService.addUserBot(userBotCreationParams).pipe(
				catchError(_error => {
					throw {
						code: UserBotErrorCodes.BOT_ADDITION_FAILED,
						message: TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_UNEXPECTED_ERROR]
					};
				})
			)
		);
	}

	private async validateUserBotStatus(): Promise<void> {
		const fetchedUserBot: IUserBot = await lastValueFrom(
			this.userBotService.getUserBotById(this.bot.id).pipe(
				catchError(_err => {
					return of(null);
				})
			)
		);

		if (fetchedUserBot && fetchedUserBot.status === UserBotStatus.TX_PENDING) {
			throw {
				code: UserBotErrorCodes.BOT_PENDING_TRANSACTION,
				message: BotSubscriptionErrorMessages[UserBotErrorCodes.BOT_PENDING_TRANSACTION]
			};
		} else if (fetchedUserBot && fetchedUserBot.status === UserBotStatus.ACTIVE) {
			throw {
				code: UserBotErrorCodes.BOT_ALREADY_ACTIVE,
				message: BotSubscriptionErrorMessages[UserBotErrorCodes.BOT_ALREADY_ACTIVE]
			};
		}
	}

	private async fetchActiveUserBot(): Promise<IUserBot> {
		const MAX_ATTEMPTS = 8;
		const RETRY_INTERVAL_MS = 5000;
		let currentAttempt = 0;

		return await lastValueFrom(
			this.userBotService.getUserBotById(this.bot.id).pipe(
				mergeMap((fetchedBot: IUserBot) => {
					if (fetchedBot && fetchedBot.status === UserBotStatus.ACTIVE) {
						return of(fetchedBot);
					}
						throw new Error(SubscriptionRetryErrorMessages.PENDING_TRANSACTION);

				}),
				tap({
					error: _error => {
						if (currentAttempt < MAX_ATTEMPTS) {
							currentAttempt++;
						} else {
							throw new Error(SubscriptionRetryErrorMessages.RETRY_LIMIT_EXCEEDED);
						}
					}
				}),
				retry({ count: MAX_ATTEMPTS, delay: RETRY_INTERVAL_MS }),
				catchError(err => {
					if (err.message === SubscriptionRetryErrorMessages.RETRY_LIMIT_EXCEEDED) {
						this.bcubeContractService.closeLoader();
						throw {
							code: UserBotErrorCodes.BOT_PENDING_TRANSACTION,
							message: CommonErrorMessages.UNABLE_UPDATE_BOT
						};
					}
					throw err;
				})
			)
		);
	}


	private payByCreditCard() {
		this.isLoading = true;

		const chargebeeInstance = Chargebee.init({
			site: environment.chargebeeSite,
			enableRefersionTracking: true
		});

		chargebeeInstance.openCheckout({
			hostedPage: () => {
				return lastValueFrom(this.planService.getHostedPage(this.botPlan.id, []));
			},
			error: (_error: any) => {
				this.toasterService.error(CommonErrorMessages.UNABLE_REACH_PAYMENT_SERVICE);
				this.isLoading = false;
			},
			success: (_hostedPageId: any) => {
				this.addUserBot(UserBotStatus.PENDING);
				this.isPaymentDone = true;
			},
			close: () => {
				this.isLoading = false;
				if (this.isPaymentDone) {
					this.zone.run(() => {
						this.isPaymentDone = false;
						this.router.navigate([Routes.BOTS + '/', this.bot.id]);
					});
					setTimeout(() => {
						this.toasterService.success(TransactionSuccessMessages.TRANSACTION_SUCCESSFUL);
					}, 1000);
				} else {
					this.zone.run(() => {
						this.close();
					});
				}
			}
		});
	}

	public get bcubeFinalPrice(): number {
		const priceDiscount = this.bcubePaymentDetails?.priceDiscount || 0;
		const result = this.months * priceDiscount;

		return Number(result.toFixed(2));
	}

	private checkBcubeForm() {
		if (!this.months) {
			this.toasterService.warning(CommonWarningMessages.ENTER_AMOUNT_OF_MONTH);

			return false;
		}

		const months = Number(this.months);
		if (isNaN(months)) {
			this.toasterService.warning(CommonWarningMessages.ENTER_AMOUNT_OF_MONTH);

			return false;
		}

		return true;
	}

	private async initializeBcubePaymentTransaction(amount: number): Promise<TransactionResponse> {
		try {
			return await this.bcubeContractService.sendBCubeToPaymentWallet(amount);
		} catch (error: any) {
			if (error.reason) {
				throw { code: TransactionErrorCodes.TRANSACTION_FAILED, message: error.reason }
			} else {
				throw { code: TransactionErrorCodes.TRANSACTION_FAILED, message: TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED] }
			}
		}
	}

	private async createCryptoPendingTransaction(tx: TransactionResponse, transactionType: TransactionType, amount: number): Promise<void> {
		try {
			await this.cryptoTxService.createCryptoTransaction({
				planId: this.botPlan.id,
				status: TransactionStatus.PENDING,
				paymentMethod: PaymentMethod.BCUBE,
				price: amount,
				months: this.months,
				ethWalletAddress: tx.from,
				ethTransaction: tx.hash,
				addons: [],
				subscriptionType: SubscriptionType.BOT,
				transactionType: transactionType,
			});
		} catch (error: any) {
			throw { code: TransactionErrorCodes.TRANSACTION_CREATION_FAILED, message: TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED] }
		}
	}

	private displayPaymentError(error: { code: TransactionErrorCodes | UserBotErrorCodes, message: string }): void {
		let errorMessage: string;

		switch (error.code) {
			case TransactionErrorCodes.TRANSACTION_CREATION_FAILED:
			case TransactionErrorCodes.TRANSACTION_FAILED:
			case UserBotErrorCodes.BOT_ADDITION_FAILED:
			case UserBotErrorCodes.BOT_PENDING_TRANSACTION:
			case UserBotErrorCodes.BOT_ALREADY_ACTIVE:
				errorMessage = error.message;
				break;

			default:
				errorMessage = TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED];
				break;
		}

		this.toasterService.error(errorMessage);
	}

	private async payWithBCUBE(): Promise<void> {

		if (!this.checkBcubeForm()) {
			return;
		}

		this.isLoading = true;
		this.unwatchTokenContractEvent = this.bcubeContractService.watchTokenContractEvent(TokenContractEvent.TRANSFER, this.wallet);
		this.close();

		try {

			await this.validateUserBotStatus();
			const tx = await this.initializeBcubePaymentTransaction(this.bcubeFinalPrice);
			await this.createPendingTransactionBot(UserBotStatus.TX_PENDING);
			await this.createCryptoPendingTransaction(tx, TransactionType.SUBSCRIPTION, this.bcubeFinalPrice);
			await this.bcubeContractService.waitForBCubeToPaymentTransaction(tx);

		} catch (error: any) {
			this.bcubeContractService.closeLoader();
			this.displayPaymentError(error);
			this.isLoading = false;
			this.close();

			return;
		} finally {
			this.unwatchTokenContractEvent(); // Unsubscribe from the event
		}

		try {

			const activeUserBot = await this.fetchActiveUserBot();
			this.toasterService.success(TransactionSuccessMessages.TRANSACTION_SUCCESSFUL);
			this.userBotService.addUserBotLocally(activeUserBot);
			this.bcubeContractService.closeLoader();
			this.closeModal.emit(activeUserBot);

		} catch (error: any) {
			if (error.code === UserBotErrorCodes.BOT_PENDING_TRANSACTION) {
				this.toasterService.error(error.message);
			} else {
				this.toasterService.error(TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_UNEXPECTED_ERROR]);
			}
			this.bcubeContractService.closeLoader();
		} finally {
			this.isLoading = false;
		}
	}

	private async fetchUpdatedSubscription(
		subscriptionId: string,
		expectedMonthsToAdd: number,
		currentBotSubscription: IBotSubscription
	): Promise<ISubscription> {

		let currentRetryAttempt = 0;

		return await lastValueFrom(
			this.planService.getSingleBotSubscription(subscriptionId).pipe(
				mergeMap((retrievedSubscription: ISubscription) => {
					const originalEndDate = DateTime.fromMillis(currentBotSubscription.currentTermEnd * 1000);
					const updatedEndDate = DateTime.fromMillis(retrievedSubscription.currentTermEnd * 1000);
					const anticipatedEndDate = originalEndDate.plus({ months: expectedMonthsToAdd });

					if (anticipatedEndDate.equals(updatedEndDate)) {
						return of(retrievedSubscription);
					}
						throw new Error(SubscriptionRetryErrorMessages.DATE_DIFFERENCE_UNMATCHED);

				}),
				tap({
					error: _error => {
						if (currentRetryAttempt < MAX_RETRY_ATTEMPTS) {
							currentRetryAttempt++;
						} else {
							throw new Error(SubscriptionRetryErrorMessages.RETRY_LIMIT_EXCEEDED);
						}
					}
				}),
				retry({ count: MAX_RETRY_ATTEMPTS, delay: RETRY_DELAY }),
				catchError(err => {
					if (err.message === SubscriptionRetryErrorMessages.RETRY_LIMIT_EXCEEDED) {
						throw {
							code: UserBotErrorCodes.BOT_TOPUP_PENDING_TRANSACTION,
							message: BotSubscriptionErrorMessages[UserBotErrorCodes.BOT_TOPUP_PENDING_TRANSACTION]
						};
					}
					throw err;
				})
			)
		);
	}

	private async topUp(): Promise<void> {
		if (!this.checkBcubeForm()) {
			return;
		}

		this.close();
		const finalPrice = this.bcubePaymentDetails.priceDiscount * this.months;
		this.unwatchTokenContractEvent = this.bcubeContractService.watchTokenContractEvent(TokenContractEvent.TRANSFER, this.wallet);

		try {
			const tx = await this.initializeBcubePaymentTransaction(finalPrice);
			await this.createCryptoPendingTransaction(tx, TransactionType.TOPUP, finalPrice);
			await this.bcubeContractService.waitForBCubeToPaymentTransaction(tx);
		} catch (error: any) {
			this.bcubeContractService.closeLoader();
			this.displayPaymentError(error);

			return;
		} finally {
			this.unwatchTokenContractEvent(); // Unsubscribe from the event
		}

		try {
			await this.fetchUpdatedSubscription(this.botPlan.id, this.months, this.userBotSubscription);
			this.planService.botSubscriptions;
			this.bcubeContractService.closeLoader();
			this.toasterService.success(TransactionSuccessMessages.TRANSACTION_SUCCESSFUL);
		} catch (error: any) {
			if (error.code === UserBotErrorCodes.BOT_TOPUP_PENDING_TRANSACTION) {
				this.toasterService.error(error.message);
			} else {
				this.toasterService.error(TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_UNEXPECTED_ERROR]);
			}
			this.bcubeContractService.closeLoader();
		}
	}
}
