import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { DOWNLOAD_METAMASK_URL } from '@app/core/constants/provider.constants';
import { EthereumService } from '@core/services/ethereum/ethereum.service';
import { Web3Provider } from '@core/types/web3-provider';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-wallet-modal',
	templateUrl: './wallet-modal.component.html',
	animations: [
		trigger('opacity', [
			transition(':enter', [style({ opacity: 0 }), animate('300ms ease-out', style({ opacity: 1 }))]),
			transition(':leave', [style({ opacity: 1 }), animate('200ms ease-in', style({ opacity: 0 }))]),
		]),
		trigger('opacityTranslateY', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(1rem)' }),
				animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateY(0)' }),
				animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' })),
			]),
		]),
	],
})
export class WalletModalComponent {
	@Input() showModal = false;

	isMetamaskExist = window.ethereum;
	isBraveWallet$: BehaviorSubject<boolean>

	constructor(private ethereumService: EthereumService) {
		this.isBraveWallet$ = this.ethereumService.isBraveWallet$;
	}

	toggleModal() {
		this.showModal = !this.showModal;
	}

	closeModal(): void {
		this.showModal = false;
	}

	installMetamask(): void {
		this.closeModal()
		window.open(DOWNLOAD_METAMASK_URL, "_blank");
	}

	connectWallet(provider: Web3Provider): void {
		this.ethereumService.connect(provider);
		this.toggleModal();
	}
}
