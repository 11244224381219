import { environment } from '../../../environments/environment';

// Authentication
export const LOGIN_URL = `${environment.apiUrl}/auth/login`;
export const LOGOUT_URL = `${environment.apiUrl}/auth/logout`;

export const TWO_FACTOR_ENABLE_URL = `${environment.apiUrl}/auth/two-factor/enable`;
export const TWO_FACTOR_DISABLE_URL = `${environment.apiUrl}/auth/two-factor/disable`;

// User Account
export const EDIT_USER_ACCOUNT_URL = `${environment.apiUrl}/auth/account/edit`;
export const DELETE_PERMANENTLY_USER_ACCOUNT_URL = `${environment.apiUrl}/auth/account/delete`;

// Dashboard
export const USER_URL = `${environment.apiUrl}/user`;
export const PORTFOLIO_URL = `${environment.apiUrl}/portfolio`;

// Bots
export const BOT_URL = `${environment.apiUrl}/bot`;
export const BOT_DETAIL_URL = `${environment.apiUrl}/bot/:botId`;

// Bots - Performance
export const LIVE_DATA_BOT_PERFORMANCE_HISTORY_URL = `${environment.apiUrl}/bot-performance/:botId/live-data?range=:range&frequency=:frequency&leverage=:leverage&allocation=:allocation&feesEnabled=:feesEnabled`;
export const BACKTEST_DATA_BOT_PERFORMANCE_HISTORY_URL = `${environment.apiUrl}/bot-performance/:botId/backtest-data?frequency=:frequency&leverage=:leverage&allocation=:allocation&feesEnabled=:feesEnabled`;
export const MONTHLY_BOT_PERFORMANCE_HISTORY_URL = `${environment.apiUrl}/bot-performance/:botId/monthly?range=:range&frequency=:frequency`;
export const PERCENTAGE_BOT_PERFORMANCE_HISTORY_URL = `${environment.apiUrl}/bot-performance/:botId/percentage`;
export const BOT_STATISTICS_URL = `${environment.apiUrl}/bot-performance/:botId/statistics`;
export const LIVE_DATA_AGGREGATES_HISTORY_PERFORMANCE_URL = `${environment.apiUrl}/bot-performance/:botId/live-data/aggregates`;
export const BOT_ORDER_HISTORY_URL = `${environment.apiUrl}/bot-performance/order-history?botId=:botId`;
export const LIVE_DATA_PERFORMANCE_SUMMARY_URL = `${environment.apiUrl}/bot-performance/:botId/live-data/summary?range=:range&frequency=:frequency`;

// TODO: query string builder


// User Bots
export const USER_BOTS_URL = `${environment.apiUrl}/user-bot`;
export const USER_ACCOUNT_BOT_PERFORMANCE = `${environment.apiUrl}/user-bot/:botId/bot-performance`;
export const DELETE_USER_BOTS_URL = `${environment.apiUrl}/user-bot/:botId`;
export const USER_BOT_FROM_EXCHANGE_ACCOUNT = `${environment.apiUrl}/user-bot/exchange-account/:exchangeAccountId`;
export const USER_BOT_URL = `${environment.apiUrl}/user-bot/:botId`;
export const USER_BOT_PERFORMANCE_HISTORY = `${environment.apiUrl}/user-bot/:botId/performance-history?range=:range&frequency=:frequency`;
export const USER_BOT_OVERVIEW_PERFORMANCE_HISTORY = `${environment.apiUrl}/user-bot/performance-history?range=:range&frequency=:frequency`;
export const USER_BOT_TRADE_HISTORY = `${environment.apiUrl}/user-bot/:botId/trade-history`;
export const USER_BOT_OVERVIEW_TRADE_HISTORY = `${environment.apiUrl}/user-bot/trade-history`;
export const USER_BOT_TRADE_OPEN = `${environment.apiUrl}/user-bot/:botId/trade-open`;
export const USER_BOT_OVERVIEW_TRADE_OPEN = `${environment.apiUrl}/user-bot/trade-open`;
export const USER_BOT_ACTIVE_BOTS_PNL = `${environment.apiUrl}/user-bot/active-bots?botIds=:botIds`;
export const USER_BOT_CLOSE_TRADE = `${environment.apiUrl}/user-bot/execution/:executionId/exit`;

// Exchange
export const EXCHANGE_URL = `${environment.apiUrl}/exchange`;
export const EXCHANGE_SETTINGS_URL = `${environment.apiUrl}/exchange/settings?exchange=:exchange`;

// Exchange Account
export const EXCHANGE_ACCOUNT_URL = `${environment.apiUrl}/exchange-account`;
export const DELETE_EXCHANGE_ACCOUNT_URL = `${environment.apiUrl}/exchange-account/:exchangeAccountId`;
export const EXCHANGE_ACCOUNT_WITH_KEYS_URL = `${environment.apiUrl}/exchange-account/full`;
export const EXCHANGE_ACCOUNT_OVERVIEW_BALANCE_HISTORY_URL = `${environment.apiUrl}/exchange-account/balance-history?range=:range&frequency=:frequency`;
export const EXCHANGE_ACCOUNT_BALANCE_HISTORY_URL = `${environment.apiUrl}/exchange-account/:exchangeAccountId/balance-history?range=:range&frequency=:frequency`;
export const EXCHANGE_ACCOUNT_ORDER_URL = `${environment.apiUrl}/exchange-account/:exchangeAccountId/order`;
export const EXCHANGE_ACCOUNT_ALLOCATION_URL = `${environment.apiUrl}/exchange-account/:exchangeAccountId/allocation?limit=:limit`;
export const EXCHANGE_ACCOUNT_OVERVIEW_ALLOCATION_URL = `${environment.apiUrl}/exchange-account/allocation?limit=:limit`;
export const OVERVIEW_BALANCE_ACCOUNT_URL = `${environment.apiUrl}/exchange-account/overview`;
export const TEST_EXCHANGE_CONNECTION_URL = `${environment.apiUrl}/exchange-account/test-connection`;

// Plans
export const PLAN_URL = `${environment.apiUrl}/plan`;
export const BOT_PLANS_URL = `${environment.apiUrl}/plan/bots`;
export const SUBSCRIPTION_URL = `${environment.apiUrl}/plan/subscription`;
export const PLAN_CHECKOUT = `${environment.apiUrl}/plan/:planId/checkout?addons=:addons`;
export const CANCEL_PLAN_URL = `${environment.apiUrl}/plan?botId=:botId`;
// Subscription
export const SUBSCRIPTIONS_URL = `${environment.apiUrl}/subscription?type=:type`;
export const BOT_SUBSCRIPTIONS_URL = `${environment.apiUrl}/subscription/bots`;
export const BOT_SUBSCRIPTION_URL = `${environment.apiUrl}/subscription/:botSubscriptionId`;

//Chargebee Portal
export const PORTAL_SESSION = `${environment.apiUrl}/chargebee/session`;

// Notifications
export const NOTIFICATIONS_URL = `${environment.apiUrl}/notification`;
export const NOTIFICATION_ITEM_URL = `${environment.apiUrl}/notification/:notificationId`;

// Staking
export const STAKING_ADDRESS_URL = `${environment.apiUrl}/staking`;
export const STAKING_TIER_URL = `${environment.apiUrl}/staking/tier`;
export const STAKING_AMOUNT = `${environment.apiUrl}/staking/amount`;
export const PLAN_BCUBE_PAYMENT_DETAILS_URL = `${environment.apiUrl}/staking/price-details?planId=:planId&addons=:addons`;
export const BOT_BCUBE_PAYMENT_DETAILS_URL = `${environment.apiUrl}/staking/bot-price-details?planId=:planId`;

// User bot settings
export const USER_BOT_SETTINGS_URL = `${environment.apiUrl}/user-bot-settings/:botId`;
export const USER_BOT_SETTINGS_ALLOCATION_URL = `${environment.apiUrl}/user-bot-settings/allocation?exchangeAccountId=:exchangeAccountId&status=:status`;
export const UPDATE_USER_SETTINGS_ALLOCATION_URL = `${environment.apiUrl}/user-bot-settings/allocation`;

// User
export const USER_UPDATE_URL = `${environment.apiUrl}/user/update`;
export const USER_SUBSCRIPTION = `${environment.apiUrl}/user/subscribe`;
export const USER_ADDON_SUBSCRIPTION = `${environment.apiUrl}/user/subscribe/addon/:itemPriceId`;
export const USER_TOPUP = `${environment.apiUrl}/user/topup`;

// TA Screener
export const SCREENER_ACCESS = `${environment.apiUrl}/ta-screener/access`;
export const SCREENER_FREE_DEFAULT_DATA_URL = `${environment.apiUrl}/ta-screener/default-free`;
export const SCREENER_FREE_DAYTRADE_DATA_URL = `${environment.apiUrl}/ta-screener/day-trade-free`;
export const SCREENER_DEFAULT_DATA_URL = `${environment.apiUrl}/ta-screener/default?rankFrom=:rankFrom&rankTo=:rankTo`;
export const SCREENER_RSI_DATA_URL = `${environment.apiUrl}/ta-screener/rsi?rankFrom=:rankFrom&rankTo=:rankTo`;
export const SCREENER_TREND_DATA_URL = `${environment.apiUrl}/ta-screener/trend?rankFrom=:rankFrom&rankTo=:rankTo`;
export const SCREENER_MACD_DATA_URL = `${environment.apiUrl}/ta-screener/macd?rankFrom=:rankFrom&rankTo=:rankTo`;
export const SCREENER_DAY_TRADE_DATA_URL = `${environment.apiUrl}/ta-screener/day-trade?rankFrom=:rankFrom&rankTo=:rankTo`;
export const SCREENER_LONG_TERM_DATA_URL = `${environment.apiUrl}/ta-screener/long-term?rankFrom=:rankFrom&rankTo=:rankTo`;


// Access to Sentiment Analysis
export const SENTIMENT_ANALYSIS_ACCESS = `${environment.apiUrl}/sentiment-analysis`;

export const SA_ACCESS = `${environment.apiUrl}/sentiment-analysis/access`;
export const SA_TOKENS = `${environment.apiUrl}/sentiment-analysis/tokens`;
export const SA_SENTIMENT_SCORE = `${environment.apiUrl}/sentiment-analysis/tokens/:token/analysis/sentiment_score?period=:period`;
export const SA_POST_VOLUME = `${environment.apiUrl}/sentiment-analysis/tokens/:token/analysis/post_volume?period=:period`;
export const SA_EMOTIONS = `${environment.apiUrl}/sentiment-analysis/tokens/:token/analysis/emotions?period=:period`;
export const SA_MARKET = `${environment.apiUrl}/sentiment-analysis/tokens/:token/market?period=:period`;
export const SA_TRENDS_SENTIMENT_SCORE = `${environment.apiUrl}/sentiment-analysis/trends/sentiment_score?period=:period`;
export const SA_TRENDS_POST_VOLUME = `${environment.apiUrl}/sentiment-analysis/trends/post_volume?period=:period`;

// Transaction
export const CRYPTO_TRANSACTION_URL = `${environment.apiUrl}/crypto-transaction`;

// KYC
export const KYC_ACCESS_TOKEN_URL = `${environment.apiUrl}/kyc/token`;
