<div class="group relative" (clickOutside)="closeMenu()">
  <button type="button" class="relative flex items-center justify-center h-9 w-9" (click)="toggleMenu()">
    <div
      class="inline-flex items-center justify-center text-base w-8 h-8 pt-0.5 pl-0.5 text-white bg-brand rounded-full">
      {{ initials }}
    </div>
    <!-- <img
      src="https://vulk.cssninja.io/assets/demo/img/avatars/56.jpg"
      class="w-full h-full object-cover mask mask-hexed"
      alt="Profile image"
    /> -->
  </button>

  <div [@openClose]="isMenuOpen ? 'open' : 'closed'" class="absolute top-0 right-0 w-64 mt-14"
    [class]="!isMenuOpen ? 'pointer-events-none' : ''">
    <div class="bg-white border border-muted-200 dark:bg-muted-800 dark:border-muted-600 rounded-md">
      <!--Header-->
      <div class="flex items-center gap-x-2 py-4 px-6 border-b border-muted-200 dark:border-muted-700">
        <div class="relative flex items-center justify-center h-12 w-12">
          <!-- <img
            src="https://vulk.cssninja.io/assets/demo/img/avatars/56.jpg"
            class="w-full h-full object-cover mask mask-hexed"
            alt="Profile image"
          /> -->
          <div
            class="inline-flex items-center justify-center text-base w-8 h-8 pt-0.5 pl-0.5 text-white bg-brand rounded-full">
            {{ initials }}
          </div>
        </div>
        <h3 class="font-sans text-sm font-medium uppercase text-muted-500 dark:text-muted-200">
          Hi {{ user?.username }},
        </h3>
      </div>
      <!--body-->
      <ul class="px-3 py-3 space-y-1">
        <li>
          <a [routerLink]="Routes.PLAN" (click)="closeMenu()"
            class="flex gap-x-2 p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/50 transition-colors duration-300">
            <div class="relative flex items-center justify-center h-8 w-8 min-w-[2rem]">
              <app-icon-dots class="h-4 w-4 text-muted-400"></app-icon-dots>
            </div>
            <div class="flex items-center justify-center">
              <h4 class="font-sans text-xs text-muted-800 dark:text-white font-semibold">
                Plans
              </h4>

            </div>
          </a>
        </li>
        <li>
          <a [routerLink]="Routes.STAKING" (click)="closeMenu()"
            class="flex gap-x-2 p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/50 transition-colors duration-300">
            <div class="relative flex items-center justify-center h-8 w-8 min-w-[2rem]">
              <app-icon-staking class="h-4 w-4 text-muted-400"></app-icon-staking>
            </div>
            <div class="flex items-center justify-center">
              <h4 class="font-sans text-xs text-muted-800 dark:text-white font-semibold">
                Staking
              </h4>
            </div>
          </a>
        </li>
        <li>
          <a [routerLink]="Routes.EXCHANGE_ACCOUNT" (click)="closeMenu()"
            class="flex gap-x-2 p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/50 transition-colors duration-300">
            <div class="relative flex items-center justify-center h-8 w-8 min-w-[2rem]">
              <app-icon-pie class="h-4 w-4 text-muted-400">
              </app-icon-pie>
            </div>
            <div class="flex items-center justify-center">
              <h4 class="font-sans text-xs text-muted-800 dark:text-white font-semibold">
                Exchange Account
              </h4>

            </div>
          </a>
        </li>
        <li>
          <a [routerLink]="Routes.SETTINGS" (click)="closeMenu()"
            class="cursor-pointer flex gap-x-2 p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/50 transition-colors duration-300">
            <div class="relative flex items-center justify-center h-8 w-8 min-w-[2rem]">
              <app-icon-gear class="h-4 w-4 text-muted-400"></app-icon-gear>
            </div>
            <div class="flex items-center justify-center">
              <h4 class="font-sans text-xs text-muted-800 dark:text-white font-semibold">
                Settings
              </h4>

            </div>
          </a>
        </li>
        <li>
          <a [attr.href]="getLogoutUrl"
            class="flex gap-x-2 p-2 rounded-md hover:bg-muted-100 dark:hover:bg-muted-700/50 transition-colors duration-300">
            <div class="relative flex items-center justify-center h-8 w-8 min-w-[2rem]">
              <app-icon-ring class="h-4 w-4 text-muted-400"></app-icon-ring>
            </div>
            <div class="flex items-center justify-center">
              <h4 class="font-sans text-xs text-muted-800 dark:text-white font-semibold">
                Logout
              </h4>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
