import { BotRisk } from "@b-cube/interfaces/bot";
import { IBotPerformance } from "@b-cube/interfaces/performance";

export interface FilterAndSortParams {
	supportedExchanges?: string;
	crypto?: string;
	markets?: string;
	source?: string;
	publisherName?: string;
	order? : string;
}

export enum Filter {
	ALL = 'all',
	EXCHANGE = 'supportedExchanges',
	CRYPTO = 'crypto',
	MARKET = 'markets',
	CATEGORY = 'source',
	PROVIDER = 'publisherName',
	ORDER = 'order'	
}

export enum OrderBy {
	NAME = 'name',
	WEEKLY_PERFORMANCE = 'weekly',
	MONTHLY_PERFORMANCE = 'monthly',
	QUARTERLY_PERFORMANCE = 'quarterly',
	OVERALL_PERFORMANCE = 'overall',
	RECENCY = 'recency'
}

export interface FormatedBot {
    id: number,
    name: string,
    description: string,
    category: string,
    strategyType: number,
    cryptos: string[],
    pairing: string,
    market: string,
    status: string,
    supportedExchanges: string[],
	isCompanySource: boolean,
	liveDate: Date,
	risk: BotRisk;

	chargebeeAddonUid?: string,
    publisherName?: string,
    publisherEmail?: string,
    publisherPresentation?: string,

	performance: IBotPerformance,

  }
