import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SCREENS } from '@app/ta-screener/components/ta-screener.constants';

@Component({
	selector: 'app-screener-switch',
	templateUrl: './ta-screener-switch.component.html',
	styleUrls: ['./ta-screener-switch.component.css'],
	animations: [
		trigger('openClose', [
			state('open', style({
				transform: 'translateY(0)',
				opacity: 1
			})),
			state('closed', style({
				transform: 'translateY(.25rem)',
				opacity: 0
			})),
			transition('open => closed', [
				animate('0.3s')
			]),
			transition('closed => open', [
				animate('0.2s')
			]),
		]),
	],
})
export class TaScreenerSwitchComponent {

	@Output() selectScreenEvent = new EventEmitter<string>();
	@Input() public selectedScreen: string;
	@Input() public hasAccess: boolean;

	public screens = SCREENS;
	public isMenuOpen = false;

	selectScreen(screen: string): void {
		this.selectedScreen = screen;
		this.selectScreenEvent.emit(this.selectedScreen);
		this.closeMenu();
	}

	toggleMenu(): void {
		this.isMenuOpen = !this.isMenuOpen;
	}

	closeMenu(): void {
		this.isMenuOpen = false;
	}
}
