import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class TrackerService {

    public getTrackerInitScript(matomoCDN: string, matomoContainer: string): string {
        return `
			var _mtm = window._mtm = window._mtm || [];
			_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
			(function() {
				var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
				g.async=true; g.src='${matomoCDN}/container_${matomoContainer}.js'; s.parentNode.insertBefore(g,s);
			})();

			!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','oefwm');
			`
    }
}
