<form [formGroup]="form" (ngSubmit)="submit()">
	<div
		class="modal fixed top-0 left-0 flex items-center justify-center w-full h-full bg-muted-800/70 dark:bg-muted-900/80 px-4 z-50">
		<div class="h-auto w-full md:max-w-xl mx-auto text-left bg-white dark:bg-muted-800 rounded shadow-xl">
			<!-- Header -->

			<div class=" flex items-center justify-between p-4 md:p-6 border-b border-muted-200 dark:border-muted-700">
				<div>
					<h3
						class="flex flex-row items-center font-heading text-lg font-medium leading-6 text-muted-900 dark:text-white">
						<span>Allocation</span>
						<span
							class="opacity-50 relative flex justify-center items-center h-7 w-7 rounded-full ml-6 mr-2">
							<app-exchange-icon [name]="selectedAccount?.exchangeName" [svgClass]="'w-7 h-7'"
								[addon]="'circle'">
							</app-exchange-icon>
						</span>
						<span
							class="opacity-50  block font-sans text-sm font-medium text-muted-600 dark:text-muted-200">
							{{selectedAccount.name}}
						</span>
						<span *ngIf="bot"
							class="opacity-50  block font-sans text-sm font-medium text-muted-600 dark:text-muted-200 mx-4">/
						</span>
						<span *ngIf="bot"
							class="opacity-50 relative flex justify-center items-center h-8 w-8 rounded-full mr-2 ">
							<app-cryptocurrency-icon [name]="bot.pairing" [svgClass]="'w-7 h-7'">
							</app-cryptocurrency-icon>
						</span>
						<span *ngIf="bot"
							class="opacity-50  text-sm block font-sans text-muted-600 dark:text-muted-200">
							{{bot.pairing}}
						</span>
					</h3>
				</div>

				<button
					class="flex items-center justify-center rounded-full hover:bg-muted-100 dark:hover:bg-muted-700 h-8 w-8 transition-colors duration-300"
					(click)="dismiss()">
					<svg class="fill-current h-5 w-5 text-muted-700 dark:text-muted-50"
						xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
						role="img" width="32" height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256">
						<path fill="currentColor"
							d="M205.7 194.3a8.1 8.1 0 0 1 0 11.4a8.2 8.2 0 0 1-11.4 0L128 139.3l-66.3 66.4a8.2 8.2 0 0 1-11.4 0a8.1 8.1 0 0 1 0-11.4l66.4-66.3l-66.4-66.3a8.1 8.1 0 0 1 11.4-11.4l66.3 66.4l66.3-66.4a8.1 8.1 0 0 1 11.4 11.4L139.3 128Z">
						</path>
					</svg>
					<span class="sr-only">Close</span>
				</button>
			</div>
			<!-- Body -->
			<div class="max-h-[380px] overflow-y-auto slimscroll p-4 md:p-6 pt-2 md:pt-4">
				<div formArrayName="settings" class="w-full max-w-lg mx-auto">
					<!--Groups-->
					<div class="space-y-8" *ngFor="let setting of getSettings().controls; let index=index">
						<!--Group-->
						<div [formGroupName]="index"
							class="w-full px-6 pt-6 pb-2 border-b border-muted-200 dark:border-muted-700">
							<!--Group header-->
							<div *ngIf="!bot" class="flex items-center gap-x-2 mb-4">
								<span class="relative flex justify-center items-center h-8 w-8 rounded-full">
									<app-cryptocurrency-icon [name]="setting.value.pairing" [svgClass]="'w-8 h-8'">
									</app-cryptocurrency-icon>
								</span>
								<span
									class="block font-sans font-semibold text-sm text-muted-500 dark:text-muted-100">{{setting.value.pairing}}</span>
							</div>
							<!--Toolbar-->
							<div
								class="flex items-center justify-between pb-3 mb-6 border-b border-muted-200 dark:border-muted-700">
								<div class="font-sans">
									<span class="text-sm text-muted-400 mr-4">Available</span>
									<span class="text-sm font-semibold text-muted-800 dark:text-muted-100">
										{{setting?.value?.amountAvailable ?? 0}}</span>
								</div>
								<div class="font-sans">
									<span class="text-xs font-medium"
										[ngClass]="[setting.value.totalAllocation > sliderCeil ? 'text-red-500' : 'text-green-500 ']">Total
										Allocation
										{{setting.value.totalAllocation}}%</span>
								</div>
							</div>
							<div formArrayName="bots">
								<!--Bot blocks-->
								<div class="space-y-14" *ngFor="let bot of getBots(index).controls; let botIndex=index">
									<!--Bot block-->
									<div [formGroupName]="botIndex" class="pl-4 pb-8">
										<div class="flex items-center justify-between">
											<div class="flex items-center gap-x-2">
												<div
													class="flex items-center justify-center w-8 h-8 rounded-full bg-muted-200 dark:bg-muted-700">
													<app-logo class="text-brand w-4 h-4"></app-logo>
												</div>
												<span>{{bot.value.name}}</span>
											</div>

											<div class="relative max-w-[66px]">
												<div class="group relative">
													<input type="number" formControlName="allocationPercentage"
														(input)="onAllocationPercentageChange($event, index, botIndex)"
														class="text-right pl-3 pr-6 py-2 h-8 text-sm leading-5 font-sans w-full rounded-lg bg-white text-muted-600 border border-muted-300 focus:border-muted-300 focus:shadow-lg focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 placeholder:text-muted-300 dark:placeholder:text-muted-500 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-muted-300 dark:focus:outline-muted-600 focus:outline-offset-2 transition-all duration-300" />
													<div
														class="absolute top-0 right-0 h-8 w-6 flex items-center justify-center font-sans text-muted-400">
														<span class="text-sm">%</span>
													</div>
												</div>
											</div>
										</div>
										<app-form-bot-settings-slider [value]="bot.value.allocation"
											[floor]="sliderFloor" [ceil]="sliderCeil"
											(valueChanged)="onSliderValueChange($event, index, botIndex)">
										</app-form-bot-settings-slider>
										<!--Buttons and Input-->
										<div class="flex items-center justify-between gap-x-4 mt-4">
											<div class="flex">

											</div>
											{{bot.value.allocationAsset | cryptoCurrency:setting.value.pairing}}
											{{setting.value.pairing}}

										</div>
									</div>
								</div>
							</div>
						</div>


					</div>

					<!--Help text-->
					<div class="p-2">
						<p class="font-sans text-muted-500 dark:text-muted-400 text-xs">
							Please consider that trading is at your own risk. The risk of loss
							in trading cryptocurrencies, even when done via an automated trading
							system, can be substantial. By confirming, you agree with our term
							and conditions
						</p>
					</div>
				</div>
			</div>
			<!-- Footer -->
			<div class="p-4 md:p-6">
				<div class="flex justify-end w-full gap-x-2">
					<button type="button" (click)="setDefaultSettings()"
						class="lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 border border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700 dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300">
						<!--<app-icon-time-rotate class="w-4 h-4"></app-icon-time-rotate>-->
						<span class="font-sans text-sm">Reset</span>
					</button>
					<button type="submit" [ngClass]="isConfirmLoading ? 'button-loading' : ''"
						class=" w-36 lg:inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30">
						<span class="font-sans text-sm">Apply settings</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</form>
