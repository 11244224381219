<div class="relative flex flex-col bg-white dark:bg-muted-800 border border-muted-200
		dark:border-muted-700 rounded-md p-6 min-h-[430px]">
	<div class="flex flex-col lg:flex-row justify-between">
		<div class="mb-5 lg:mb-0">
			<div class="flex items-center mb-5">
				<span class="font-sans text-xl font-semibold text-muted-600 dark:text-muted-200">
					PnL history
				</span>
			</div>
			<div class="min-w-[260px] space-y-2">
				<div class="block">
					<span class="font-sans text-sm font-medium text-muted-500 mb-2 mr-2">
						Profit/loss
					</span>
					<span class="font-sans text-xl font-bold" [class]="pnl.cssClass">
						{{pnl.sign}} {{pnl.abs | currency:'USD':'symbol':'1.2-2'}}
					</span>
				</div>
			</div>
		</div>
		<div>
			<!-- <div class="flex gap-2 flex-wrap justify-end mb-4">
				<button class="lg:max-w-[100px] font-sans text-xs font-semibold flex-1 text-brand h-10 px-3 border-2
					border-brand bg-brand/20 rounded-md dark:hover:bg-brand/30 transition-colors duration-300">
					Perf ($)
				</button>
				<button class="lg:max-w-[100px] font-sans text-xs font-semibold flex-1 text-muted-500 h-10 px-3
					bg-muted-100 hover:bg-muted-200 rounded-md dark:bg-muted-700 dark:hover:bg-muted-600
					dark:text-muted-200 transition-colors duration-300">
					Perf (%)
				</button>
			</div> -->
			<div class="flex gap-2 flex-wrap justify-end">
				<button  *ngFor="let period of performancePeriods" (click)="selectPeriod(period)"
				class="font-sans text-xs font-semibold flex-1 text-brand h-8 px-3 bg-muted-100 hover:bg-muted-200
				rounded-md dark:bg-muted-700 dark:hover:bg-muted-600 transition-colors duration-300"
				[ngClass]="{
					'bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30': isPeriodSelected(period),
					'text-brand bg-muted-100 hover:bg-muted-200 dark:bg-muted-700 dark:hover:bg-muted-600': !isPeriodSelected(period)
				}">
					{{period.label}}
				</button>
			</div>
		</div>
	</div>

	<div class="mt-10 w-full">
		<!--app-fake-chart-bot class="w-full"></app-fake-chart-bot-->
		<app-chart-area-big [chartData]="performance" [perfDisplayMode]="getDisplayMode()" [loading]="loading"></app-chart-area-big>
	</div>

	<div class="p-2 flex flex-row gap-x-4 items-center">
		<app-icon name="alert" class="w-8 h-8 text-brand"></app-icon>
		<span class="font-sans text-muted-500 dark:text-muted-400 text-2xs">
			<span>The PnL displayed in this chart does not include the trading fees.</span>
		</span>
	</div>
</div>
