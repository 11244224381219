import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-bot',
  templateUrl: './icon-bot.component.html',
})
export class IconBotComponent {
  @Input() class = '';

}
