import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '@app/core/services/notification.service';
import { IUserNotification, NotificationType } from '@b-cube/interfaces/notification';
import { map, Observable } from 'rxjs';

interface IWrappedNotification {
	notification: IUserNotification,
	hide: boolean,
	removed: boolean
}

@Component({
	selector: 'app-panel',
	templateUrl: './panel.component.html',
})
export class PanelComponent implements OnInit {
	@Input() isPanelOpened = false;
	@Output() closePanel = new EventEmitter<any>();

	public notificationDisplay = {
		[NotificationType.EXCHANGE_ERROR]: {
			icon: 'bot',
			class: 'bg-danger-500'
		},
		[NotificationType.USER_TRADE]: {
			icon: 'bot',
			class: 'bg-brand'
		},
		[NotificationType.BLOCKCHAIN_EVENT]: {
			icon: 'staking',
			class: 'bg-brand'
		},
		[NotificationType.KYC_COMPLETED]: {
			icon: 'passport',
			class: 'bg-green-500'
		},
		[NotificationType.KYC_RETRY]: {
			icon: 'passport',
			class: 'bg-amber-500'
		},
		[NotificationType.KYC_REJECTED]: {
			icon: 'passport',
			class: 'bg-danger-500'
		},
		[NotificationType.KYC_APPROVED]: {
			icon: 'passport',
			class: 'bg-green-500'
		},
		[NotificationType.TOKEN_PAYMENT_ERROR]: {
			icon: 'staking',
			class: 'bg-danger-500'
		},
		[NotificationType.TOKEN_PAYMENT_SUCCESS]: {
			icon: 'staking',
			class: 'bg-green-500'
		},
	}

	notifications$: Observable<IWrappedNotification[]>;

	constructor(
		private notificationService: NotificationService,
	) { }

	ngOnInit(): void {
		this.notifications$ = this.notificationService.getNotifications().pipe(
			map((notifications: IUserNotification[]) => {
				return notifications.map(notification => <IWrappedNotification>{
					notification: notification,
					hide: false,
					removed: false
				})
			}
		));
	}

	public close() {
		if (this.isPanelOpened) {
			this.isPanelOpened = false;
			this.closePanel.emit(null);
		}
	}

	public markAsRead(wNotification: IWrappedNotification) {
		wNotification.hide = true;
		setTimeout(() => {
			wNotification.removed = true;
			this.notificationService.markAsRead(wNotification.notification).subscribe();
		}, 500);
	}

	public markAllAsRead(wNotifications: IWrappedNotification[]){
		for(const wNotification of wNotifications){
			wNotification.hide = true;
		}

		this.notificationService.markAllAsRead().subscribe();

		setTimeout(() => {
			for(const wNotification of wNotifications){
				wNotification.removed = true;
			}
		}, 500);
	}
}
