<div *ngIf="(notifications$ | async) as notifications" class="fixed top-0 right-0 w-3/4 md:w-[340px] max-w-full h-full bg-white dark:bg-muted-900 border-l
		border-muted-200 dark:border-muted-800 transition-transform duration-300 z-40"
	[ngClass]="[isPanelOpened ? 'translate-x-0' : 'translate-x-full']" (clickOutside)="close()">
	<div class="flex items-center gap-4 px-5 h-[70px] border-b border-muted-200 dark:border-muted-800">
		<div class="flex items-center gap-x-2">
			<app-icon-bell class="w-5 h-5 text-muted-400"></app-icon-bell>
			<h4 class="font-sans font-semibold text-muted-800 dark:text-muted-100">
				Notifications ({{ (notifications$ | async)?.length}})
			</h4>
		</div>
		<button class="group inline-flex items-center justify-center h-10 w-10 ml-auto rounded-full
				text-muted-300 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100
				dark:hover:bg-muted-700 transition-colors duration-300" (click)="close()">
			<app-icon-x class="h4 w-4 group-hover:scale-95 transition-transform duration-300"></app-icon-x>
		</button>
	</div>
	<div class="h-[calc(100%_-_70px)] overflow-y-auto px-5 py-6 slimscroll">
		<div class="w-full flex flex-col">
			<div *ngIf="notifications.length > 0" class="italic text-right text-sm mt-[-10px]">
				<button (click)="markAllAsRead(notifications)">Clear all</button>
			</div>
			<div *ngIf="notifications.length === 0" class="italic text-center text-sm">
				No notification
			</div>
			<!--Item-->
			<div *ngFor="let wNotification of notifications" class="relative px-2 rounded-md
					bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 transition-all
					duration-600 ease-in-out" [ngClass]="{
						'opacity-0 max-h-0 py-0 my-0': wNotification.hide,
						'opacity-100 max-h-48 py-2 my-2': !wNotification.hide,
						'hidden':  wNotification.removed
					}">
				<div class="w-full">
					<div class="w-full flex">
						<div class="flex flex-1 gap-x-2 p-2 rounded-md">
							<div class="flex gap-2" head>
								<div class="relative h-9 w-9 min-w-[2.25rem]">
									<div class="flex items-center justify-center w-full h-full object-cover mask mask-hexed"
										[class]="notificationDisplay[wNotification?.notification.type].class">
										<app-icon [name]="notificationDisplay[wNotification?.notification.type].icon"
											class="w-4 h-4 text-white transition-colors">
										</app-icon>
									</div>
								</div>
								<div class="shrink">
									<p class="font-sans text-xs text-muted-500 dark:text-muted-200 leading-2">
										{{wNotification?.notification.title}}
									</p>
									<span class="font-sans text-xs text-muted-400 leading-2">
										{{wNotification?.notification.createdAt | timeAgo}}
									</span>
								</div>
							</div>
						</div>
						<div class="relative flex flex-none items-center justify-center h-7 w-4">
							<button (click)="markAsRead(wNotification)">
								<app-icon name="close" class="w-4 h-4 ml-auto text-muted-400">
								</app-icon>
							</button>
						</div>
					</div>
				</div>
				<div>
					<div class="pt-2 border-t break-words whitespace-normal border-muted-200 dark:border-muted-700">
						<p class="font-sans text-xs text-muted-400" [innerText]="wNotification.notification.body"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
