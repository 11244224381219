<div
	class="flex-1 bg-gradient-to-t border px-5 py-8 rounded-lg from-secondary-400 to-secondary-600 border-secondary-500">
	<div class="flex items-center gap-x-2 mb-4">
		<img src="/assets/img/illustrations/credit-card.png" class="object-contain w-14" alt="Illustration" />
		<div>
			<h4 class="text-[0.65rem] font-semibold text-white opacity-50 uppercase">
				Next plan
			</h4>
			<h3 class="font-sans font-medium text-2xl text-white capitalize">
				{{nextPlan.name}}
			</h3>
		</div>
	</div>
	<div class="flex divide-x divide-brand/50">
		<div class="font-sans px-4">
			<p class="text-white opacity-90 text-xs whitespace-nowrap pb-2">
				Advantage
			</p>
			<p class="text-white opacity-90 text-xs whitespace-nowrap" [innerHTML]="getTradingLimitDiffLabel()"></p>
		</div>
		<div class="font-sans px-4">
			<p class="text-white opacity-90 text-xs whitespace-nowrap pb-2">
				Cost
			</p>
			<p class="text-white opacity-90 text-xs whitespace-nowrap" *ngIf="arePricesDifferent()">
				{{getPricesDifferenceLabel()}} € / month
			</p>
		</div>
	</div>
</div>
