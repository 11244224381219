<div *ngIf="showModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
	<div @opacity class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

	<div class="fixed inset-0 z-10 overflow-y-auto">
		<div class="modal fixed top-0 left-0 flex items-center justify-center w-full h-full bg-muted-800/70  px-4 z-50">
			<div @opacityTranslateY
				class="relative transform overflow-hidden rounded-md bg  text-left shadow-xl transition-all sm:my-8 w-[25rem] p-6 sm:max-w-[25rem] sm:p-6  md:max-w-[25rem] mx-auto text-left bg-white dark:bg-muted-800 rounded shadow-xl">
				<div class="flex justify-between items-center mb-2">
					<div>
						<h3 class="text-lg font-medium leading-6" id="modal-title">Connect Wallet</h3>
					</div>
					<button
						class="group inline-flex items-center justify-center h-10 w-10 rounded-full text-muted-300 hover:text-muted-500 hover:bg-muted-100 dark:hover:text-muted-100 dark:hover:bg-muted-700 transition-colors duration-300"
						(click)="closeModal()">
						<app-icon-x class="h4 w-4 group-hover:scale-95 transition-transform duration-300"></app-icon-x>
					</button>
				</div>
				<span class="isolate text-center rounded shadow-sm w-full">
					<ng-container *ngIf="!(isBraveWallet$ | async)">
						<button *ngIf="!isMetamaskExist; else metamask" type="button"
							class="flex justify-between w-full card items-center rounded px-4 py-4 font-medium dark:bg-muted-700 text-muted-500 dark:text-muted-300 dark:border-muted-500 border-brand provider-card"
							(click)="installMetamask()">
							<app-wallet-provider-icon name="metamask" class="h-10 w-10" alt="metamask">
							</app-wallet-provider-icon>
							<h4>Install Metamask</h4>
						</button>
						<ng-template #metamask>
							<button type="button"
								class="flex justify-between w-full card items-center rounded px-4 py-4 font-medium dark:bg-muted-700 text-muted-500 dark:text-muted-300 dark:border-muted-500 border-brand provider-card"
								(click)="connectWallet('metamask')">
								<app-wallet-provider-icon name="metamask" class="h-10 w-10" alt="metamask">
								</app-wallet-provider-icon>
								<h4>MetaMask</h4>
							</button>
						</ng-template>
					</ng-container>
					<button *ngIf="isBraveWallet$ | async" type="button" type="button"
						class="flex justify-between w-full card items-center rounded px-4 py-4 font-medium dark:bg-muted-700 text-muted-500 dark:text-muted-300 dark:border-muted-500 border-brand provider-card"
						(click)="connectWallet('brave')">
						<app-wallet-provider-icon name="brave" class="h-10 w-10" alt="brave">
						</app-wallet-provider-icon>
						<h4>Brave Wallet</h4>
					</button>
					<button type="button"
						class="flex justify-between w-full card items-center rounded px-4 py-4 font-medium dark:bg-muted-700 text-muted-500 dark:text-muted-300 dark:border-muted-500 border-brand provider-card"
						(click)="connectWallet('coinbase')">
						<app-wallet-provider-icon name="coinbase" class="h-10 w-10" alt="coinbase">
						</app-wallet-provider-icon>
						<h4>Coinbase Wallet</h4>
					</button>
				</span>
			</div>
		</div>
	</div>
</div>