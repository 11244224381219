<div class="relative bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md p-6 h-full">
	<!--Header-->
	<div class="flex items-center justify-between mb-5">
		<h4 class="font-sans text-sm font-semibold tracking-wider text-left text-muted-700 dark:text-muted-300">
			Balance allocation <span class="text-muted-400">- {{botsNumber}} agents</span>
		</h4>

		<div class="flex items-center justify-end">
			<button
				class="inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-10 bg-brand hover:bg-brand/90 text-white hover:shadow-md hover:shadow-brand/30 transition-all duration-300"
				(click)="openSettings()">
				<app-icon-gear class="w-4 h-4 opacity-75"></app-icon-gear>
				<span *ngIf="screenService.isDesktop()" class="font-sans text-sm">Manage</span>
			</button>
		</div>
	</div>
	<!--Body-->
	<div class="space-y-10">
		<!--Group-->
		<div *ngFor="let balanceAllocation of balanceAllocations?.allocations">
			<!--Group header-->
			<div class="flex items-center gap-x-2 mb-4">
				<span class="relative flex justify-center items-center h-8 w-8 rounded-full bg-amber-500">
					<app-cryptocurrency-icon [name]="balanceAllocation.pairing" [svgClass]="'w-8 h-8'">
					</app-cryptocurrency-icon>
				</span>
				<span
					class="block font-sans font-semibold text-sm text-muted-500 dark:text-muted-100">{{balanceAllocation.pairing}}</span>
			</div>
			<!--Group table-->
			<div class="overflow-x-auto">
				<div class="inline-block min-w-full align-middle">
					<div class="overflow-hidden">
						<table class="min-w-full table-fixed text-left">
							<thead>
								<tr>
									<th class="font-sans p-4">
										<span
											class="font-sans mr-5 text-xs font-semibold tracking-wider text-left text-muted-400 dark:text-muted-300">Available</span>
										<span
											class="text-muted-800 dark:text-muted-200 font-semibold">{{balanceAllocation.amountAvailable}}</span>
									</th>
									<th
										class="font-sans text-xs font-semibold tracking-wider text-muted-400 dark:text-muted-300 p-4 min-w-[140px]">
										Allocation (%)
									</th>
									<th
										class="font-sans text-xs font-semibold tracking-wider text-muted-400 dark:text-muted-300 p-4">
										Allocation
									</th>
								</tr>
							</thead>
							<tbody>
								<!--Row-->
								<tr *ngFor="let bot of balanceAllocation.bots"
									class="border-t border-muted-200 dark:border-muted-700">
									<!--Cell-->
									<td class="font-sans text-muted-600 whitespace-nowrap dark:text-white p-4">
										<div class="flex items-center gap-x-4">
											<div
												class="inline-flex justify-center items-center relative w-3 h-3 rounded-full bg-green-500">
											</div>
											<div
												class="flex items-center justify-center w-8 h-8 rounded-full bg-muted-200 dark:bg-muted-700">
												<app-logo class="text-brand w-4 h-4"></app-logo>
											</div>
											<span>{{bot.name}}</span>
										</div>
									</td>
									<!--Cell-->
									<td
										class="font-sans py-4 px-6 text-sm text-muted-500 whitespace-nowrap dark:text-muted-400">
										<span>{{bot.allocatedValue}}%</span>
									</td>
									<!--Cell-->
									<td
										class="font-sans py-4 px-6 text-sm text-muted-500 whitespace-nowrap dark:text-muted-400">
										<span>{{bot.allocatedAsset | cryptoCurrency:balanceAllocation.pairing}}
											{{balanceAllocation.pairing}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

	</div>

	<app-widget-balance-allocation-modal *ngIf="isSettingsOpen" (close)="closeBalanceAllocation($event)"
		[selectedAccount]="exchangeAccount" [balanceAllocations]="balanceAllocations">
	</app-widget-balance-allocation-modal>
</div>
