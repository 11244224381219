import { Component, Input } from '@angular/core';
import { COLOR_PALETTE } from '@app/core/constants/charts.constants';
import { PieUnit, RingData } from '@app/shared/components/chart/chart-ring/chart-ring.component';

@Component({
	selector: 'app-widget-marketplace-allocation-pie',
	templateUrl: './widget-marketplace-allocation-pie.component.html'
})
export class WidgetMarketplaceAllocationsPieComponent{
	@Input() allocations: RingData[];
	@Input() loading: boolean;

	colorPalette = COLOR_PALETTE;
	PieUnit = PieUnit;
}
