import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BOT_SETTINGS_DISPLAY } from '@app/core/constants/bots.constants';
import { Settings } from '@b-cube/database/types';
import { IBot } from '@b-cube/interfaces/bot';
import { BotSettingsScale, IBotSettingsWrapper } from '@b-cube/interfaces/user-bot-settings';

@Component({
  selector: 'app-widget-bot-settings',
  templateUrl: './widget-bot-settings.component.html'
})
export class WidgetBotSettingsComponent implements OnChanges {
  @Input() isBotSettingsOpen = false
  @Input() botSettingsWrapper: IBotSettingsWrapper;
  @Input() bot: IBot;

  @Output() updateBotSettings = new EventEmitter<boolean>();

  public botSettingsDisplay: { [key in Settings]?: {
    scale: BotSettingsScale, label: string, minValue: number, maxValue: number
  } } = BOT_SETTINGS_DISPLAY;

  public noSettingsForThisBot = false;


  ngOnChanges(changes: SimpleChanges) {
    if (changes['botSettingsWrapper'] && this.botSettingsWrapper) {
      this.noSettingsForThisBot = this.botSettingsWrapper?.botSettings.length === 0;
    }
  }

  public openSettings(): void {
    this.isBotSettingsOpen = true
  }

  public closeBotSettings($event: any): void {
    this.isBotSettingsOpen = false;
    if ($event) {
      this.updateBotSettings.emit(true);
    }
  }
}
