<svg
  width="20"
  height="19"
  viewBox="0 0 20 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class]="class"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.65625 13.1963V5.19629C7.65625 4.09629 8.54625 3.19629 9.65625 3.19629H18.6562V2.19629C18.6562 1.09629 17.7563 0.196289 16.6562 0.196289H2.65625C1.54625 0.196289 0.65625 1.09629 0.65625 2.19629V16.1963C0.65625 17.2963 1.54625 18.1963 2.65625 18.1963H16.6562C17.7563 18.1963 18.6562 17.2963 18.6562 16.1963V15.1963H9.65625C8.54625 15.1963 7.65625 14.2963 7.65625 13.1963ZM10.6562 5.19629C10.1062 5.19629 9.65625 5.64629 9.65625 6.19629V12.1963C9.65625 12.7463 10.1062 13.1963 10.6562 13.1963H19.6562V5.19629H10.6562ZM13.6562 10.6963C12.8263 10.6963 12.1562 10.0263 12.1562 9.19629C12.1562 8.36629 12.8263 7.69629 13.6562 7.69629C14.4862 7.69629 15.1562 8.36629 15.1562 9.19629C15.1562 10.0263 14.4862 10.6963 13.6562 10.6963Z"
    fill="currentColor"
  />
</svg>
