import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IconType } from '@app/core/models/icon-type.enum';
import { IconService } from '@app/core/services/icon.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.css'],
})
export class IconComponent implements OnInit, OnChanges {
	@Input() name: string;
	@Input() class?: string;
	@Input() svgClass?: string;
	@Input() addon?: string

	public src$: Observable<string>;

	constructor(protected iconService: IconService) { }

	ngOnInit(): void {
		this.src$ = this.buildIconPath(IconType.DEFAULT, this.name, this.addon);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['name'] || changes['type']) {
			this.src$ = this.buildIconPath(IconType.DEFAULT, this.name, this.addon);
		}
	}

	protected buildIconPath(type: IconType, name: string, addon: string): Observable<string> {
		const iconPath: Observable<string> = this.iconService.getIcon(type, name, addon);

		return iconPath;
	}

}
