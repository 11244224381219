import { Component, Input } from '@angular/core';
import { MAXDROWDOWN_TOOLTIP, PROFIT_FACTOR_TOOLTIP } from '@app/core/constants/tips.constants';
import { FormatNumberHelper } from '@app/core/services/performance/format-number.helper';
import { IBotStatistic } from '@b-cube/interfaces/performance';

@Component({
	selector: 'app-widget-marketplace-bot-stats-details',
	templateUrl: './widget-marketplace-bot-stats-details.component.html'
})
export class WidgetMarketplaceBotStatsDetailsComponent {

	@Input() public statistics: Partial<IBotStatistic>;
	@Input() loading: boolean;

	MAXDROWDOWN_TOOLTIP = MAXDROWDOWN_TOOLTIP
	PROFIT_FACTOR_TOOLTIP = PROFIT_FACTOR_TOOLTIP

	constructor(public formatNumberHelper: FormatNumberHelper) { }

}
